import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-wallet-history',
  templateUrl: './my-wallet-history.component.html',
  styleUrls: ['./my-wallet-history.component.scss']
})
export class MyWalletHistoryComponent implements OnInit {
  public sessionUserData:any = {};

  public reportFilter:any ={};
  public isButtonDisabled:boolean = false;
  public walletData:any = {};
  constructor() { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
    this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
  }

  submit(){}
}
