<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">Mobile Recharge</span>
                </div>
            </div>
        </div>

        <!-----Prepaid Mobile ---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>Prepaid Mobile Recharge</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="procceedRechargePrepaid('Jio',88)">
                        <img src="assets/images/jio.png" class="panServicesIcon">
                        <p class="tCenter">Jio</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="procceedRechargePrepaid('VI',2)">
                        <img src="assets/images/vi.png" class="panServicesIcon"> 
                        <p class="tCenter">VI</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="procceedRechargePrepaid('AIRTEL',1)">
                        <img src="assets/images/airtel.png" class="panServicesIcon" >
                        <p class="tCenter">AIRTEL</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                    <div class="tCenter cp" (click)="procceedRechargePrepaid('BSNL',8)">
                        <img src="assets/images/bsnl.png" class="panServicesIcon">
                        <p class="tCenter">BSNL</p>
                    </div>
                </div>


            </div>
        </div>

        <br>
        
            <!-----POST_PAID_RECHAGE---->
            <div class="sectionCard">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                        <div class="sectionHeading">
                            <h5>Postpaid Mobile Recharge</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                        <div class="tCenter cp" (click)="procceedRechargePostPaid('Jio Postpaid',153)">
                            <img src="assets/images/jio.png" class="panServicesIcon">
                            <p class="tCenter">Jio Postpaid</p>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                        <div class="tCenter cp" (click)="procceedRechargePostPaid('VI Postpaid',25)">
                            <img src="assets/images/vi.png" class="panServicesIcon"> 
                            <p class="tCenter">VI Postpaid</p>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                        <div class="tCenter cp" (click)="procceedRechargePostPaid('AIRTEL Postpaid',23)">
                            <img src="assets/images/airtel.png" class="panServicesIcon" >
                            <p class="tCenter">AIRTEL Postpaid</p>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                        <div class="tCenter cp" (click)="procceedRechargePostPaid('BSNL Postpaid',58)">
                            <img src="assets/images/bsnl.png" class="panServicesIcon">
                            <p class="tCenter">BSNL Postpaid</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</section>