<section>
  <div class="container sectionContainer">

    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div class="breadCum">
          <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
          <span class="activeBreadCum">Travel Booking </span>
        </div>
      </div>
    </div>

    <!-----Section Heading---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div class="sectionHeading">
          <h5>Travel Booking</h5>
        </div>
      </div>
    </div>

    <!-----Section Container---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">

        <img src="assets/images/coming_soon.jpg" alt="coming soon" width="70%">
        
        <div class="card">
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
            <div class="row">
              <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4 pRelative">
                <label>From <span class="astric">*</span> </label>
                <input type="text" class="form-control" placeholder="Enter From destination"
                  [(ngModel)]="formData.sourceCity" name="fromDistination" #fromDistination="ngModel" required>
                <span class="text-danger"
                  *ngIf="(fromDistination.touched || appForm.submitted) && fromDistination.errors?.required">
                  From destination is required
                </span>
                <div class="swapIcon" (click)="exchangeCity()">
                  <i class="fa fa-exchange" aria-hidden="true"></i>
                </div>
              </div>

              <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <label>To <span class="astric">*</span> </label>
                <input type="text" class="form-control" placeholder="Enter to destination"
                  [(ngModel)]="formData.destinationCity" name="toDistination" #toDistination="ngModel" required>
                <span class="text-danger"
                  *ngIf="(toDistination.touched || appForm.submitted) && toDistination.errors?.required">
                  To destination is required
                </span>
              </div>

              <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <label>Journey Date <span class="astric">*</span></label>
                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="formData.journeyDate"
                  name="Date" #Date="ngModel" required>
                <span class="text-danger" *ngIf="(Date.touched || appForm.submitted) && Date.errors?.required">
                  Journey Date is required
                </span>
              </div>


              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 tRight">
                <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled"
                  [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">Search Bus</span>
                </button> &nbsp;&nbsp;
                <button type="reset" class="btn btn-secondary">Reset</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>


    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="availableBusHead">
            <div>Available buses</div>
            <div class="tRight">Pune to delhi &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  < 12 Jul > </div>
          </div>
          <div class="card busCard" *ngFor="let bus of busList">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div><b>20.05</b>-06.50</div>
                <div class="f13">9h 45m   &nbsp; &nbsp; 11 Seat</div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div>
                  <div><b>Sai Ram Travels</b></div>
                  <div class="f13">A/C Sleeper (2+1)</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-lx-2">
                <div>
                  <div>From</div>
                  <div><b>₹ 800</b></div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-lx-2 tRight">
                <button type="submit" class="btn btn-warning" (click)="openModal()">View Seats </button>
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>



<!-- Modal -->
<div id="ComplaintForm" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        <div class="row w100">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
            <div><b>20.05</b>-06.50</div>
            <div class="f13">9h 45m   &nbsp; &nbsp; 11 Seat</div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
            <div>
              <div><b>Sai Ram Travels</b></div>
              <div class="f13">A/C Sleeper (2+1)</div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-lx-2">
            <div>
              <div>From</div>
              <div><b>₹ 800</b></div>
            </div>
          </div>
      </div>
      </div>

      <div class="modal-body">

      </div>
    </div>
  </div>
</div>