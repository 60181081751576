<div class="appHeader">
    <app-header></app-header>
</div>
<div class="row mrg0">
    <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-lx-2 desktopView pad0" *ngIf="isShowSideBar">
        <div class="tab-column desktopView">
            <app-sidebar></app-sidebar>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-lx-10 pad0 " [ngClass]="{'col-lg-12':!isShowSideBar  ,'col-lx-12':!isShowSideBar }">
        <div class="routlet">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>

<div class="footerDiv" *ngIf="isShowSideBar">
    <app-footer></app-footer>
</div>

<app-footer *ngIf="!isShowSideBar"></app-footer>