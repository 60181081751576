<section>
    <div class="container sectionContainer">
      <!-----Bread-cum---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
          <div class="breadCum">
            <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
            <span class="activeBreadCum">DTH Recharge</span>
          </div>
        </div>
      </div>
  
   
      <div class="row" *ngIf="tab != 'successMessage'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12">
          <div class="rechargeDialogue">
            <i (click)="back()" class="icofont-close closeIcon"></i>
            <div class="rechageCartHeading">
              <h5><b>DTH Recharge</b></h5>
              <img src="assets/images/airtel.png" class="panServicesIcon" *ngIf="Provider == 'Airtel DTH' ">
              <img src="assets/images/dishtv.png" class="panServicesIcon" *ngIf="Provider == 'Dish TV DTH' ">
              <img src="assets/images/sundirect.png" class="panServicesIcon" *ngIf="Provider == 'Sun TV DTH' ">
              <img src="assets/images/tatasky.png" class="panServicesIcon" *ngIf="Provider == 'Tata Sky DTH' ">
              <img src="assets/images/videocon.png" class="panServicesIcon" *ngIf="Provider == 'Videocon DTH' ">
              <div class="operatorName">{{Provider}}</div>
            </div>
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                  <label>Customer ID <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Customer ID" [(ngModel)]="formData.mobile"
                    name="mobile" #mobile="ngModel" required   autocomplete="off" (ngModelChange)="changeNo()">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Customer ID is required
                  </span>
                </div>
                <div class="checkPlan" *ngIf="formData.mobile && formData.mobile.length > 3" (click)="getDthPlan()">Check Plans</div>
  
                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="!selectedPlan || !selectedPlan.rs">
                  <label>Amount <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Amount" [(ngModel)]="formData.amount"
                    name="Amount" #Amount="ngModel" required (keypress)="dataService.appConstant.keyPress($event)" autocomplete="off">
                  <span class="text-danger" *ngIf="(Amount.touched || appForm.submitted) && Amount.errors?.required">
                    Amount is required
                  </span>
                </div>
  
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="selectedPlan && selectedPlan.rs">
                     <div class="row selectedPlanSection">
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                        <small>Plan : </small> <strong> {{selectedPlan.plan_name}}</strong>
                      </div>
                      <div class="col-6 col-sm-6  col-md-6 col-lg-6 col-lx-6 tRight">
                        Amount: ₹ {{selectedPlan.amount}}
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                        {{selectedPlan.desc}}
                      </div>
                      <div class="selectedPlanLabel">Selected Plan</div>
                     </div>
                </div>
  
              </div>
              <br>
              <div class="buttonGroup">
                <button type="submit" class="btn btn-primary submitBtn"
                  [disabled]="isButtonDisabled || !appForm.form.valid " [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">SUBMIT</span>
                </button> &nbsp;&nbsp;
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <br>
      <div class="loader" *ngIf="isLoading"></div>
  
      <!-------MOBILE RECHARGE PLAN-------->
      <!-- <div id="rechargePlans" *ngIf="isShowPlanList && tab != 'successMessage'">
        <div class="planHeader">
          <div class="row">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4 " [ngClass]="{'activePlanTab':planMobileType=='Plan'}"
              (click)="showPlans('Plan')">
              <div class="planTabLabel">Plan</div>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4" [ngClass]="{'activePlanTab':planMobileType=='Add-On Pack'}"
              (click)="showPlans('Add-On Pack')">
              <div class="planTabLabel">Add-On Pack</div>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4">
            </div>
          </div>
        </div>
        <br>
  
        <div class="noPlanFound" *ngIf="selectedPlanList?.length <= 0 && !isLoading">
          <h6>Oops...</h6>
          <div>No plans found.....</div>
        </div> -->
  
        <!-- <div class="plansSection" *ngIf="selectedPlanList?.length > 0 && !isLoading">
          <div class="availablePlanContainer " *ngFor="let plan of selectedPlanList let i = index"
            [ngClass]="{'availablePlanContainerActive':selectedIndex == i}" >
            <div class="placeInfoSection">
              <div class="row">
                <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                  <div class="planInfoValue">

                    <select class="form-control ruppesPlanDropDown" [(ngModel)]="selectedPlanList[i].plan" name="PaymentMode" #PaymentMode="ngModel" 
                    (ngModelChange)="selectPrice(i)">
                    <option *ngIf="plan.rs['1 MONTHS'] != undefined" value="1 MONTHS">  {{'1 Month :  ₹'+ plan.rs['1 MONTHS']}}</option>
                    <option *ngIf="plan.rs['6 MONTHS'] != undefined" value="6 MONTHS">  {{'6 Month :  ₹'+ plan.rs['6 MONTHS']}}</option>
                    <option *ngIf="plan.rs['1 YEAR'] != undefined" value="1 YEAR">  {{'1 YEAR :  ₹'+ plan.rs['1 YEAR']}}</option>
                  </select>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                  <div class="planInfoValue">{{plan.desc}}</div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                  <div class="planInfoValue">{{plan.plan_name}}</div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3" (click)="selectPlan(i)">
                  <div class="planInfoValue buyPlanBtn">Buy</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
  
  
               <!-----SUCCESS MESSAGE-->
         <div class="processSuccess" *ngIf="tab == 'successMessage'">
                <i class="icofont-check-circled"></i>
                <br> <br>
                <h6><b>Done</b>.</h6>
                <div class="font14 grayClr">Rs. {{formData.amount}} </div>
                <div class="font14 grayClr"> Your recharge done successfully</div>
                <br>
                <br>
                <button class="btn btn-warning submitBtn" (click)="back()">OK</button>
        </div>
  
     

          <div class="modal fade" id="dthPlanModal"  tabindex="-1" role="dialog" aria-labelledby="dthPlanLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">DTH Plans</h5>
                  <button type="button" class="close" (click)="closePlanModal()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" *ngIf="rechargePlan?.length">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" *ngFor="let plan of plans">
                          <button class="nav-link" attr.id="{{plan + '-tab'}}" data-bs-toggle="tab" attr.data-bs-target="{{'#'+plan}}" role="tab" attr.aria-controls="{{plan}}" aria-selected="true">{{plan}}</button>
                        </li>
                    </ul>
                    
                        <div class="tab-content mt-md-3 mt-3" id="myTabContent" *ngIf="rechargePlan?.length">
                            <div class="tab-pane fade show active" id="{{rechargePlan}}" role="tabpanel" aria-labelledby="{{rechargePlan + '-tab'}}">
                                <div class="row plan-header">
                                    <div class="col-3 col-md-3">
                                       <p class="text-center">Price</p>
                                    </div>
                                    <div class="col-3 col-md-5">
                                        <p class="text-center">Description</p>
                                    </div>
                                    <div class="col-3 col-md-2">
                                        <p class="text-center">Validity</p>
                                    </div>
                                    <div class="col-3 col-md-2">
                                        <p class="text-center">Plan Name</p>
                                    </div>
                                </div>
                                
                                    <div  *ngFor="let plans of rechargePlan">
                                      <div class="row plan" *ngFor="let item of plans.rs | keyvalue" (click)="selectPlan(item.value)">
                                        <div class="col-3 col-md-3">
                                            <h3 class="plan-price text-center">&#8377; {{item.value}}</h3>
                                        </div>
                                        <div class="col-3 col-md-5">
                                            <p class="text-secondary plan-desc text-center">{{plans.desc}}</p>
                                        </div>
                                        <div class="col-3 col-md-2">
                                            <p class="plan-validity text-center">{{item.key}}</p>
                                        </div>
                                        <div class="col-3 col-md-2">
                                            <p class="plan-validity text-center">{{plans.plan_name}}</p>
                                        </div>
                                      </div>
                                    </div>
                                
                                
                            </div>
                        </div>
                    
                      
                    
                    
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="closePlanModal()" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

    </div>
  </section>