<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">Mobile Recharge</span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tab != 'successMessage'">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12">
                <div class="rechargeDialogue">
                    <i (click)="back()" class="icofont-close closeIcon"></i>
                    <div class="rechageCartHeading">
                        <h5><b>Mobile Recharge</b></h5>
                        <img src="assets/images/jio.png" class="panServicesIcon" *ngIf="Provider == 'Jio' || Provider == 'Jio Postpaid' ">
                        <img src="assets/images/vi.png" class="panServicesIcon" *ngIf="Provider == 'VI' || Provider == 'VI Postpaid'">
                        <img src="assets/images/airtel.png" class="panServicesIcon" *ngIf="Provider == 'AIRTEL' || Provider == 'AIRTEL Postpaid'">
                        <img src="assets/images/bsnl.png" class="panServicesIcon" *ngIf="Provider == 'BSNL' || Provider == 'BSNL Postpaid'">
                        <div class="operatorName">{{Provider}}</div>
                    </div>
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Mobile Number <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter mobile number" [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required minlength="10" maxlength="10" (keypress)="dataService.appConstant.keyPress($event)" autocomplete="off" (ngModelChange)="changeNo()">
                                <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                  Mobile number is required
                </span>
                                <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.minlength">
                  Mobile number must be 10 digit
                </span>
                            </div>
                            <div class="checkPlan" *ngIf="formData.mobile && formData.mobile.length == 10 && recharge == 'Prepaid' && formData.circle" (click)="getRechargePlan()">Check Plans</div>
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="recharge == 'Prepaid'">
                                <label>Select your circle / Area / region<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="formData.circle" name="circle" #circle="ngModel" (click)="fetchCircles()" required>
                                    <option value="">Select circle</option>
                                    <option value="{{circle.key}}" *ngFor="let circle of circles | keyvalue">{{circle.value}}</option>
                                </select>
                                <span class="text-danger" *ngIf="(circle.touched || appForm.submitted) && circle.errors?.required">
                  region is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="!selectedPlan || !selectedPlan.rs">
                                <label>Amount <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Amount" [(ngModel)]="formData.amount" name="Amount" #Amount="ngModel" required (keypress)="dataService.appConstant.keyPress($event)" autocomplete="off">
                                <span class="text-danger" *ngIf="(Amount.touched || appForm.submitted) && Amount.errors?.required">
                  Amount is required
                </span>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="selectedPlan && selectedPlan.rs">
                                <div class="row selectedPlanSection">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                        <small>Amount : </small> <strong>₹ {{selectedPlan.rs}}</strong>
                                    </div>
                                    <div class="col-6 col-sm-6  col-md-6 col-lg-6 col-lx-6 tRight">
                                        <small>Validity : </small> <strong>₹ {{selectedPlan.validity}}</strong>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                        {{selectedPlan.desc}}
                                    </div>
                                    <div class="selectedPlanLabel">Selected Plan</div>
                                </div>
                            </div>

                        </div>
                        <br>
                        <div class="buttonGroup">
                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled || !appForm.form.valid " [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <br>
        <div class="loader" *ngIf="isLoading"></div>

        <!-------MOBILE RECHARGE PLAN-------->
        <div id="rechargePlans" *ngIf="isShowPlanList && tab != 'successMessage'">

            <!-- <div class="planHeader">
        <div class="row">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4 " [ngClass]="{'activePlanTab':planMobileType=='TOPUP'}"
            (click)="showPlans('TOPUP')">
            <div class="planTabLabel">TopUp</div>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4" [ngClass]="{'activePlanTab':planMobileType=='3G/4G'}"
            (click)="showPlans('3G/4G')">
            <div class="planTabLabel">3G/4G</div>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4  col-lx-4" [ngClass]="{'activePlanTab':planMobileType=='FULLTT'}"
            (click)="showPlans('FULLTT')">
            <div class="planTabLabel">Full Talktime</div>
          </div>
        </div>
      </div> -->
            <br>

            <!-- <div class="noPlanFound" *ngIf="selectedPlanList?.length <= 0 && !isLoading">
                <h6>Oops...</h6>
                <div>No plans found.....</div>
            </div> -->

            <!-- <div class="plansSection" *ngIf="selectedPlanList?.length > 0 && !isLoading">
                <div class="availablePlanContainer " *ngFor="let plan of selectedPlanList let i = index" [ngClass]="{'availablePlanContainerActive':selectedIndex == i}" (click)="selectPlan(i)">
                    <div class="topSection">{{plan.desc}}</div>
                    <div class="placeInfoSection">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                                <div class="planInfoLabel">Customer Pay</div>
                                <div class="planInfoValue">₹ {{plan.rs}}.00</div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">

                            </div>
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                                <div class="planInfoLabel">Validity</div>
                                <div class="planInfoValue">₹ {{plan.validity}}</div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3  col-lx-3">
                                <div class="planInfoLabel">Commission</div>
                                <div class="planInfoValue">₹ 00.00</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>


        <!-----SUCCESS MESSAGE-->
        <div class="processSuccess" *ngIf="tab == 'successMessage'">
            <i class="icofont-check-circled"></i>
            <br> <br>
            <h6><b>Done</b>.</h6>
            <div class="font14 grayClr">Rs. {{formData.amount}} </div>
            <div class="font14 grayClr"> Your recharge done successfully</div>
            <br>
            <br>
            <button class="btn btn-warning submitBtn" (click)="back()">OK</button>
        </div>

        <!-- <button class="fixedBtmRightBtn desktopView" (click)="getRechargePlan()" *ngIf="tab != 'successMessage' && recharge == 'Prepaid'" data-toggle="modal" data-target="#exampleModal">Recharge Plans <i class="fa fa-angle-double-down" aria-hidden="true"></i></button> -->

       
          
    </div>

    <div class="modal fade" id="rechargePlanModal"  tabindex="-1" role="dialog" aria-labelledby="rechargePlanLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Recharge Plans</h5>
              <button type="button" class="close" (click)="closePlanModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="plans?.length">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" *ngFor="let plan of plans">
                        <button class="nav-link" [ngClass]="currentPlan === plan? 'nav-link active': 'nav-link'" (click)="currentPlan = plan; showPlan()" attr.id="{{plan.categories + '-tab'}}" data-bs-toggle="tab" attr.data-bs-target="{{'#'+plan}}" role="tab" attr.aria-controls="{{plan}}" aria-selected="true">{{plan}}</button>
                    </li>
                </ul>
                
                    <div class="tab-content mt-md-4 mt-3" id="myTabContent" *ngIf="selectedPlanList?.length">
                        <div class="tab-pane fade show active" id="{{currentPlan}}" role="tabpanel" aria-labelledby="{{currentPlan + '-tab'}}">
                            <div class="row plan-header">
                                <div class="col-3 col-md-3">
                                   <p class="text-center">Price</p>
                                </div>
                                <div class="col col-md">
                                    <p class="text-center">Description</p>
                                </div>
                                <div class="col-3 col-md-3">
                                    <p class="text-center">Validity</p>
                                </div>
                            </div>
                            
                                <div class="row mt-2 mt-md-3 plan" (click)="selectPlan(plans)" *ngFor="let plans of selectedPlanList">
                                    <div class="col-3 col-md-3">
                                        <h3 class="plan-price text-center">&#8377; {{plans.rs}}</h3>
                                    </div>
                                    <div class="col col-md">
                                        <p class="text-secondary plan-desc text-center">{{plans.desc}}</p>
                                    </div>
                                    <div class="col-3 col-md-3">
                                        <p class="plan-validity text-center">{{plans.validity}}</p>
                                    </div>
                                </div>
                            
                            
                        </div>
                    </div>
                
                  
                <p *ngIf="currentPlan?.length" class="text-secondary ps-md-2 mt-3 mt-md-4">*The plans are subjected to change. Please cross verify</p>
                
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="closePlanModal()" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
</section>