<section class="topContainer">
    <div class="container-fluid">
        <header class="header-area">
            <div class="main-header">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-header-item">
                            <div class="main-header-menus flexAlignCenter">
                                <div class="header-logo">
                                    <img src="assets/images/logo1.png" />
                                </div>
                                <div class="toggleBars">
                                    <i class="fa fa-bars"></i>
                                </div>
                                <div class="header-menu d-none d-lg-block">
                                    <ul>
                                        <li>
                                            <a href="https://myereturn.com">Home</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#about">About</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#services">Services</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#distributor">Distributor</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#retailer">Retailer</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#footer">Contact</a>
                                        </li>
                                        <li class="menu-item-has-children active menuDropdown">
                                            <a>Login <i class="fa fa-angle-down"></i></a>

                                            <div class="loginDropdown">
                                                <ul class="sub-menu">
                                                    <li class="subMenuLi">
                                                        <a href="https://distributor.myereturn.com">Distributor</a>
                                                    </li>
                                                    <li class="subMenuLi">
                                                        <a href="https://retailer.myereturn.com">Retailer</a>
                                                        <!-- <a href="http://localhost:4200/#/retailer">Retailer</a> -->
                                                    </li>
                                                    <li class="subMenuLi">
                                                        <a href="http://employee.myereturn.com">Employee</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="https://retailer.myereturn.com/#/registration">Join As Retailer</a>
                                            <!-- <a href="http://localhost:4200/#/registration">Join As Retailer</a> -->
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </header>
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-content">
                    <h3 class="title">
                        <b>Login as<br />Retailer</b>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbM">
        <div class="breadcrumb-itemM">
            <a href="#">Home</a>
        </div>
        <div class="breadcrumb-itemM breadcrumb-item-active">
            <a>For Retailer</a>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid secondSection">
        <div class="row formRow">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-lx-8">
                <h4 *ngIf="tab == 'login'"><b>Login Details</b></h4>
                <h4 *ngIf="tab == 'forgotPassword'"><b>Forgot Password</b></h4>
                <h4 *ngIf="tab == 'registration'"><b>Create Account</b></h4>


                <!-------LOGIN------->
                <div *ngIf="tab == 'login'">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input type="text" placeholder="Username" [(ngModel)]="formData.mobileNo" name="mobileNo" #mobileNo="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (mobileNo.touched || appForm.submitted) &&
                        mobileNo.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (mobileNo.touched || appForm.submitted) &&
                      mobileNo.errors?.required
                    ">
                                        <b>!</b> User name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input [type]="pass" placeholder="Password" autocomplete="off" [(ngModel)]="formData.password" name="password" #password="ngModel" required [ngClass]="{
                      inputFieldReq:
                        (password.touched || appForm.submitted) &&
                        password.errors?.required
                    }" />
                                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'show'"></i>
                                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'hide'"></i>
                                    <div class="validation_error" *ngIf="
                      (password.touched || appForm.submitted) &&
                      password.errors?.required
                    ">
                                        <b>!</b> Password is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 tRight">
                                <div class="forgotPassword tRight">
                                    <p class="forgotPasswordBTNP" (click)="openTab('forgotPassword')">Forgot Password?
                                    </p>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="input-box">
                                    <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                        <span class="button__text">Login</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="forgotPassword">
                                    <p>
                                        Don't have an account? <a href="https://retailer.myereturn.com/#/registration">
                                            Create Account</a>
                                        <!-- Don't have an account? <a href="http://localhost:4200/#/registration"> Create Account</a>       -->
                                        <!-- <a class="cp" (click)="openTab('registration')"> Create Account</a> -->

                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-------FORGOT PASSWORD------->
                <div *ngIf="tab == 'forgotPassword'">
                    <div *ngIf="forgotPasswordTab == 'EnterUserName'">
                        <form #forgotPassword="ngForm" (ngSubmit)="forgotPassword.form.valid && requestForOTP(appForm)">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                    <div class="input-box">
                                        <input type="text" placeholder="Username" [(ngModel)]="formData.mobileNo" name="mobileNo" #mobileNo="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (mobileNo.touched || forgotPassword.submitted) &&
                          mobileNo.errors?.required
                      }" />
                                        <div class="validation_error" *ngIf="
                        (mobileNo.touched || forgotPassword.submitted) &&
                        mobileNo.errors?.required
                      ">
                                            <b>!</b> User name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                    <div class="input-box">
                                        <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                            <span class="button__text">Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="forgotPasswordTab == 'enterOTP'">
                        <div>OTP has been send on {{ formData.mobileNo }}</div>

                        <form #forgotPasswordOtp="ngForm" (ngSubmit)="forgotPasswordOtp.form.valid && verifyOTP(appForm)">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                    <div class="input-box">
                                        <input [type]="pass" placeholder="OTP" [(ngModel)]="formData.Otp" name="Otp" #Otp="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (Otp.touched || forgotPasswordOtp.submitted) &&
                          Otp.errors?.required
                      }" (keypress)="dataService.appConstant.keyPress($event)" maxlength="4" minlength="4" />

                                        <i class="fa fa-eye-slash passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'show'"></i>
                                        <i class="fa fa-eye passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'hide'"></i>

                                        <div class="validation_error" *ngIf="
                        (Otp.touched || forgotPasswordOtp.submitted) &&
                        Otp.errors?.required
                      ">
                                            <b>!</b> OTP is required
                                        </div>
                                        <div class="validation_error" *ngIf="
                        (Otp.touched || forgotPasswordOtp.submitted) &&
                        Otp.errors?.minlength
                      ">
                                            <b>!</b> OTP must be 4 digit.
                                        </div>
                                        <div *ngIf="timeLeft > 0" class="timeLeft">
                                            Time left:{{ timeLeft }}
                                        </div>
                                        <div *ngIf="timeLeft <= 0" class="timeLeft">
                                            <div class="forgotPassword" (click)="resendOTP()">
                                                <p>Resend OTP</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                    <div class="input-box">
                                        <button type="submit" [disabled]="timeLeft <= 0" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                            <span class="button__text">Verify OTP</span>
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12" *ngIf="timeLeft <= 0">
                                    <div class="forgotPassword" (click)="resendOTP()">
                                        <p>Resend OTP</p>
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>

                    <div *ngIf="forgotPasswordTab == 'changePassword'">
                        <form #forgotPasswordChangePass="ngForm" (ngSubmit)="
                forgotPasswordChangePass.form.valid && changePassword(appForm)
              ">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                    <div class="input-box">
                                        <input type="password" placeholder="New password" [(ngModel)]="formData.newPassword" name="newPassword" #newPassword="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (newPassword.touched ||
                            forgotPasswordChangePass.submitted) &&
                          newPassword.errors?.required
                      }" />
                                        <div class="validation_error" *ngIf="
                        (newPassword.touched ||
                          forgotPasswordChangePass.submitted) &&
                        newPassword.errors?.required
                      ">
                                            <b>!</b> New password is required
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                    <div class="input-box">
                                        <input type="password" placeholder="Confirm password" [(ngModel)]="formData.ConfirmPassword" name="ConfirmPassword" #ConfirmPassword="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (ConfirmPassword.touched ||
                            forgotPasswordChangePass.submitted) &&
                          ConfirmPassword.errors?.required
                      }" />
                                        <div class="validation_error" *ngIf="
                        (ConfirmPassword.touched ||
                          forgotPasswordChangePass.submitted) &&
                        ConfirmPassword.errors?.required
                      ">
                                            <b>!</b> Confirm password is required
                                        </div>
                                        <div class="validation_error" *ngIf="
                        formData.ConfirmPassword &&
                        formData.newPassword &&
                        formData.ConfirmPassword != formData.newPassword
                      ">
                                            <b>!</b> Password && Confirm password must be same
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                    <div class="input-box">
                                        <button type="submit" [disabled]="
                        !forgotPasswordChangePass.form.valid ||
                        formData.ConfirmPassword != formData.newPassword
                      " [ngClass]="{ 'button--loading': isButtonDisabled }">
                                            <span class="button__text">Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div *ngIf="tab == 'registration'">
                    <form #registrationForm="ngForm" *ngIf="!isOTPRequested" (ngSubmit)="registrationForm.form.valid && submitRegistration()">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input type="text" placeholder="Mobile No" [(ngModel)]="formData.MobileNumber" name="MobileNo" #MobileNo="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.required
                    }" (keypress)="dataService.appConstant.keyPress($event)" maxlength="10" minlength="10" [disabled]="isOTPRequested" />
                                    <div class="validation_error" *ngIf="
                      (MobileNo.touched || registrationForm.submitted) &&
                      MobileNo.errors?.required
                    ">
                                        <b>!</b> Mobile No is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                      (MobileNo.touched || registrationForm.submitted) &&
                      MobileNo.errors?.minlength
                    ">
                                        <b>!</b> Mobile No must be 10 digit.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="input-box">
                                    <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                        <span class="button__text">Submit</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="forgotPassword">
                                    <p>
                                        Already have an account?
                                        <a class="cp" (click)="openTab('login')"> Login</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form #registrationForm="ngForm" *ngIf="isOTPRequested" (ngSubmit)="registrationForm.form.valid && submitRegistration()">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input type="text" placeholder="Mobile No" [(ngModel)]="formData.MobileNumber" name="MobileNo" #MobileNo="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.required
                    }" (keypress)="dataService.appConstant.keyPress($event)" maxlength="10" minlength="10" [disabled]="isOTPRequested" />
                                    <div class="validation_error" *ngIf="
                      (MobileNo.touched || registrationForm.submitted) &&
                      MobileNo.errors?.required
                    ">
                                        <b>!</b> Mobile No is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                      (MobileNo.touched || registrationForm.submitted) &&
                      MobileNo.errors?.minlength
                    ">
                                        <b>!</b> Mobile No must be 10 digit.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isOTPRequested">
                                <div class="input-box">
                                    <input [type]="pass" placeholder="OTP" [(ngModel)]="formData.Otp" name="Otp" #Otp="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (Otp.touched || registrationForm.submitted) &&
                        Otp.errors?.required
                    }" (keypress)="dataService.appConstant.keyPress($event)" (ngModelChange)="verifyOTPRegistration()" maxlength="4" minlength="4" [disabled]="isRegistrationOTPVerified" />

                                    <i class="fa fa-eye-slash passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'show' && !isRegistrationOTPVerified"></i>
                                    <i class="fa fa-eye passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'hide' && !isRegistrationOTPVerified"></i>

                                    <div class="validation_error" *ngIf="
                      (Otp.touched || registrationForm.submitted) &&
                      Otp.errors?.required
                    ">
                                        <b>!</b> OTP is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                      (Otp.touched || registrationForm.submitted) &&
                      Otp.errors?.minlength
                    ">
                                        <b>!</b> OTP must be 4 digit.
                                    </div>
                                    <div class="timeLeft" *ngIf="!isRegistrationOTPVerified">
                                        Time left:{{ timeLeft }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Full Name" [(ngModel)]="formData.ContactPersoneName" name="FullName" #FullName="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (FullName.touched || registrationForm.submitted) &&
                        FullName.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (FullName.touched || registrationForm.submitted) &&
                      FullName.errors?.required
                    ">
                                        <b>!</b> Full Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="email" placeholder="Email" [(ngModel)]="formData.EmailId" name="EmailId" #EmailId="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (EmailId.touched || registrationForm.submitted) &&
                        EmailId.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (EmailId.touched || registrationForm.submitted) &&
                      EmailId.errors?.required
                    ">
                                        <b>!</b> Email is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                      (EmailId.touched || registrationForm.submitted) &&
                      EmailId.errors?.email
                    ">
                                        <b>!</b> Enter valid email address.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input [type]="pass1" placeholder="Password" autocomplete="off" [(ngModel)]="formData.Password" name="password" #password="ngModel" required [ngClass]="{
                      inputFieldReq:
                        (password.touched || registrationForm.submitted) &&
                        password.errors?.required
                    }" />

                                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true" *ngIf="passIcon1 == 'show'"></i>
                                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true" *ngIf="passIcon1 == 'hide'"></i>

                                    <div class="validation_error" *ngIf="
                      (password.touched || registrationForm.submitted) &&
                      password.errors?.required
                    ">
                                        <b>!</b> Password is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Shop Name" [(ngModel)]="formData.ShopName" name="ShopName" #ShopName="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (ShopName.touched || registrationForm.submitted) &&
                        ShopName.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (ShopName.touched || registrationForm.submitted) &&
                      ShopName.errors?.required
                    ">
                                        <b>!</b> Shop name is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.Category" name="Category" #Category="ngModel" required (ngModelChange)="selectProfileState()">
                                        <option value="">Select Shop Category</option>
                                        <option value="Mobile Shop">Mobile Shop</option>
                                        <option value="Grahak Seva kendra">
                                            Grahak Seva kendra
                                        </option>
                                        <option value="General Store">General Store</option>
                                        <option value="Grocery Shop">Grocery Shop</option>
                                        <option value="Xerox Center">Xerox Center</option>
                                        <option value="Cyber Cafe">Cyber Cafe</option>
                                        <option value="Stationary Shop">Stationary Shop</option>
                                        <option value="Paan Shop">Paan Shop</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                      (Category.touched || registrationForm.submitted) &&
                      Category.errors?.required
                    ">
                                        Category is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Address" [(ngModel)]="formData.Address" name="Address" #Address="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (Address.touched || registrationForm.submitted) &&
                        Address.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (Address.touched || registrationForm.submitted) &&
                      Address.errors?.required
                    ">
                                        <b>!</b> Address is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Street" [(ngModel)]="formData.Street" name="Street" #Street="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (Street.touched || registrationForm.submitted) &&
                        Street.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (Street.touched || registrationForm.submitted) &&
                      Street.errors?.required
                    ">
                                        <b>!</b> Street is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Area" [(ngModel)]="formData.Area" name="Area" #Area="ngModel" required autocomplete="off" [ngClass]="{
                      inputFieldReq:
                        (Area.touched || registrationForm.submitted) &&
                        Area.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (Area.touched || registrationForm.submitted) &&
                      Area.errors?.required
                    ">
                                        <b>!</b> Area is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <!-- <select
                    class="form-control"
                    [(ngModel)]="formData.State"
                    name="StateId"
                    #StateId="ngModel"
                    required
                    (ngModelChange)="selectProfileState()"
                  >
                    <option value="">Select State</option>
                    <option *ngFor="let state of stateList" [value]="state.id">
                      {{ state.sName }}
                    </option>
                  </select>
                  <span
                    class="validation_error"
                    *ngIf="
                      (StateId.touched || registrationForm.submitted) &&
                      StateId.errors?.required
                    "
                  >
                    State is required
                  </span> -->
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.City" name="CityId" #CityId="ngModel" required (ngModelChange)="selectProfileState()">
                                        <option value="">Select District/City</option>
                                        <option *ngFor="let city of cityList" [value]="city.id">
                                            {{ city.name }}
                                        </option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                      (CityId.touched || registrationForm.submitted) &&
                      CityId.errors?.required
                    ">
                                        District/City is required
                                    </span>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.PostOffice" name="TahsilsId" #TahsilsId="ngModel" required>
                                        <option value="">Select Tahsils/Area/Mandal</option>
                                        <option *ngFor="let tahsil of tahsilList" [value]="tahsil.id">
                                            {{ tahsil.tName }}
                                        </option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                    (TahsilsId.touched || registrationForm.submitted) &&
                    TahsilsId.errors?.required
                  ">
                                        Tahsils/Area/Mandal is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="PinCode" [(ngModel)]="formData.Pincode" name="Pincode" #Pincode="ngModel" required autocomplete="off" autocomplete="off" (keypress)="dataService.appConstant.keyPress($event)" maxlength="6" minlength="6" [ngClass]="{
                      inputFieldReq:
                        (Pincode.touched || registrationForm.submitted) &&
                        Pincode.errors?.required
                    }" />
                                    <div class="validation_error" *ngIf="
                      (Pincode.touched || registrationForm.submitted) &&
                      Pincode.errors?.required
                    ">
                                        <b>!</b> PinCode is required.
                                    </div>
                                    <div class="validation_error" *ngIf="
                      (Pincode.touched || registrationForm.submitted) &&
                      Pincode.errors?.minlength
                    ">
                                        <b>!</b> PinCode must be 6 digit.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="isRegistrationOTPVerified">
                                <div class="IconsPage__icon__category___1SNBQ">
                                    <label for="Terms_Condition">
                                        <input type="checkbox" id="Terms_Condition" required
                                            [(ngModel)]="formData.Terms_Condition" name="Terms_Condition"
                                            #Terms_Condition="ngModel">
                                        <span>I have read and agree to the <a [href]="termConditonPage"
                                                target="_blank">Terms & Condtions</a>.</span>
                                    </label>
                                    <div class="text-danger" *ngIf="
                    (Terms_Condition.touched || registrationForm.submitted) &&
                    Terms_Condition.errors?.required
                  ">
                                        <b>!</b> Accept Terms & Condtions.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="input-box">
                                    <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                        <span class="button__text">Submit</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="forgotPassword">
                                    <p>Already have an account? <a class="cp" (click)="openTab('login')"> Login</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="book-a-tour-info">
                    <h5><b>Need help?</b></h5>
                    <br />
                    <div>
                        Drop us a email and we will connect you with a team member who can help.
                    </div>
                    <br />
                    <h4><b>info@myereturn.com</b></h4>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 col-md-6 col-sm-8">
                <div class="footer-about mt-30">
                    <div class="logo cp">
                        <a href="https://myereturn.com">
                            <img src="assets/images/logo-2.png" alt="logo" />
                        </a>
                    </div>
                    <p>
                        With an aim to ease to day to day money transaction tasks and enable the small businesses to target a greater audience for higher revenue, MyEReturn- the flagship unit of Skatomozo Group, has been established and registered under “Ministry of Corporate
                        affairs” Government of India.
                    </p>
                    <ul>
                        <li>
                            <div style="color: #979797">Customer Support</div>
                            <a href="tel:+919859003900">98-5900-3900</a><br />
                            <a href="mailto:info@myereturn.com">info@myereturn.com</a>
                        </li>
                        <li>
                            <div style="color: #979797">Sales</div>
                            <a href="tel:+919132626260"> 91-3262-6260</a><br />
                            <a href="mailto:sales@myereturn.com">sales@myereturn.com</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-address mt-30">
                    <h3 class="title"><b>Head Office</b></h3>
                    <p>
                        <b>Head Office:</b><br /> H wing, 2nd Floor, Chhoriya Market, Raver Tal- Raver Dist- Jalgaon 424508, Maharashtra
                    </p>

                    <p>
                        <b>Regional Office:</b><br /> 1347/04, kasturbhai block near GCS hospital ,chamunda, saijpurha Ahmedabad 382345 (Gujarat)
                    </p>


                </div>
            </div>
        </div>
    </div>

    <div class="footer-copyright-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright">
                        <p>© Copyright 2021</p>
                        <ul class="desktopView">
                            <li>
                                <a><i class="fa fa-facebook-square"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 mobileView">
                    <div class="footer-copyright d-block">
                        <ul>
                            <li>
                                <a><i class="fa fa-facebook-square"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>