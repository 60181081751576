<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">BBPS</span>
                </div>
            </div>
        </div>

        <!-----Prepaid Mobile ---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>BBPS</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngFor="let provider of providerList">
                    <div class="tCenter cp" (click)="open(provider.option,provider.name)">
                        <img [src]="provider.img" class="panServicesIcon">
                        <p class="tCenter">{{provider.name }}<img *ngIf="provider.comingsoon" width="30%" src="assets/images/Coming-Soon-png.png"/></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>