<section>
    <div class="container sectionContainer">

        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">Dashboard</span>
                </div>
            </div>
        </div>


        <!-----Section Container---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div><b>Hello {{sessionUserData.Data.FullName}}</b></div>
                <div class="grayClr f13">Here is your dashboard</div>
            </div>
        </div>

        <div class="row ">
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-lx-9">
                <div class="dashboardMainSection">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3">
                            <div class="dashboardIconActionBtn mb">
                                <div><img src="assets/images/doller.png"></div>
                                <div class="mainThemeBgClr">
                                    <div><b>WALLET</b></div>
                                    <div><b>₹ {{walletData?.MainWallet?walletData.MainWallet:0}}</b></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3">
                            <div class="dashboardIconActionBtn mb">
                                <div class="mainThemeBgClr">
                                    <div><b>TRADE TRANSFER</b></div>
                                    <div><b>₹ {{walletData?.TreadWallet?walletData.TreadWallet:0}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/images/border.png" class="w100">

                    <div class="row dashboardCartRow">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Mobile_Recharge"><b class="font14"><img src="assets/images/d2.png" /></b></a>
                                        <div class="grayClr f13">Mobile Recharge</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/DTH_Recharge"><b class="font14"><img src="assets/images/d3.png" /></b></a>
                                        <div class="grayClr f13">DTH Recharge</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/AEPS"><b class="font14"><img src="assets/images/d4.png" /></b></a>
                                        <div class="grayClr f13">AEPS</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard" >
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Money_Transfer"><b class="font14"><img src="assets/images/d5.png" /></b></a>
                                        <div class="grayClr f13">Money Transfer</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
              <div class="dashboardCard" style="background-color: #f1fcfc;">
                <div class="sectionCount" style="border-color: #3aa5a5;">34</div>
                <div class="sectionInfo">
                  <div>
                    <div><b class="font14">Branch Business</b></div>
                    <div class="grayClr f13">Know More</div>
                  </div>
                </div>
              </div>
            </div> -->
                        <!-- 

            <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0" routerLink="/Bank_Account_Transfer">
              <div class="dashboardCard" style="background-color: #f9f7e2;">
                <div class="sectionCount" style="border-color: #a59c34;">34</div>
                <div class="sectionInfo">
                  <div>
                    <div><b class="font14">Bank Account Transfer</b></div>
                    <div class="grayClr f13">Know More</div>
                  </div>
                </div>
              </div>
            </div> -->

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/BBPS"><b class="font14"><img src="assets/images/d6.png" /></b></a>
                                        <div class="grayClr f13">BBPS</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Pan_Card_Services"><b class="font14"><img src="assets/images/d7.png" /></b></a>
                                        <div class="grayClr f13">Pan Card</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Insurance" ><b class="font14"><img src="assets/images/d8.png" /></b></a>
                                        <div class="grayClr f13">Insurance</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Travel_booking"><b class="font14"><img src="assets/images/d9.png" /></b></a>
                                        <div class="grayClr f13">Travel Booking</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Income_Tax_Service"><b class="font14"><img src="assets/images/d10.png" /> </b></a>
                                        <div class="grayClr f13">Income Tax Return</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/GST"><b class="font14"><img src="assets/images/d11.png" /></b></a>
                                        <div class="grayClr f13">GST</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-lx-4 pad0">
                            <div class="dashboardCard">
                                <div class="sectionInfo">
                                    <div>
                                        <a href="#/Other_Services"><b class="font14"><img src="assets/images/d12.png" /> </b></a>
                                        <div class="grayClr f13">Other Services</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <!----NOTIFICATION----->
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                        <div class="notificationSection">
                            <div class="sectionHeading">
                                <h6><i class="icofont-alarm"></i> Notifications</h6>
                            </div>
                            <ol class="f13">
                                <li>Money transferred successfully to 9011509884.</li>
                                <li>Money transferred successfully to 8544751145.</li>
                                <li>DTH Recharge done for 3547854466.</li>
                                <li>New Update on Request #78458.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-lx-3 mrgT16">
                <!-- <div class="dashboardIconActionBtn lightOrangeBG" routerLink="/My_wallet">
                    <div><img src="assets/images/left1.png"></div>
                    <div class="actionLabel"> Wallet History </div>
                </div> -->
                <div class="dashboardIconActionBtn" style="background: #2154a4;" routerLink="/Wallet_Transfer">
                    <div><img src="assets/images/Transfer-logo.png"></div>
                    <div class="actionLabel"> Wallet Transfer</div>
                </div>
                <!-- <li class="active" (click)="openTab('Wallet_Transfer')"
                    [ngClass]="{ active: sideBarTab == 'Wallet_Transfer' }">
                    <img src="assets/images/d5.png" /> <span>Wallet Transfer</span>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </li> -->
                <div class="dashboardIconActionBtn" style="background: #fe8b43;" routerLink="/Add_Payment_History">
                    <div><img src="assets/images/left.png"></div>
                    <div class="actionLabel">Payment History</div>
                </div>
                <div class="otherDashPanel">
                    <div><b>Other Dashboard Panel</b></div>
                    <div class="font14">For account activities</div>
                </div>
            </div>
        </div>


    </div>
</section>