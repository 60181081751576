import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ExternalAPIService } from 'src/app/service/external-api.service';
import { DataServiceService } from 'src/app/service/data-service.service';

@Component({
  selector: 'app-wallet-transfer',
  templateUrl: './wallet-transfer.component.html',
  styleUrls: ['./wallet-transfer.component.scss']
})

export class WalletTransferComponent implements OnInit {

  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public sessionUserData: any = {};
  public walletData: any = {};

  public recharge: any;
  public Provider: any;
  public uid: any;
  public rechargePlan: any = {};

  //Plans
  public planMobileType: any;
  public isLoading: boolean = false;
  public disabled: boolean = true;
  public selectedPlanList: any = [];
  public selectedIndex: any;
  public selectedPlan: any = {};
  public isShowPlanList: boolean = false;
  public tab: any;

  constructor(
    public dataService: DataServiceService,
    public router: ActivatedRoute,
    public _location: Location,
    public externalAPIService: ExternalAPIService,
    private datePipe: DatePipe
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.planMobileType = "TOPUP"
    this.router.queryParams.subscribe(params => {
      if (params.recharge) {
        this.recharge = params.recharge;
      }
      if (params.Provider) {
        this.Provider = params.Provider;
      }
      if (params.uid) {
        this.uid = params.uid;
      }
    })
    this.dataService.getMessage().subscribe((msg) => {
      if (msg == "walletUpdated") {
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
      }
    })
  }

  ngOnInit(): void { }

  back() {
    this._location.back();
  }



  /**
   * Following api are use to recharge this api made recharge by p2all from backen side
   * 
   * 
   */


  submit(appForm: NgForm) {
    this.isButtonDisabled = true;
    let request = {
      "RegistrationId": this.sessionUserData.RegistrationId,
      "FromWallet": this.formData.fromWallet,
      "Amount": this.formData.amount
    }
    console.log(request)
    this.externalAPIService.InternalWalletTransfer(request).subscribe(response => {
      this.apiResponse = response;
      console.log(this.apiResponse,"this.apiResponse")
      if (this.apiResponse.status) {
        this.isButtonDisabled = false;
        alert(this.apiResponse.message)
        this.formData.RegistrationId = this.sessionUserData.RegistrationId;
        this.formData.PaymentFor = "Self";
        this.formData.PaymentStatus = "Approve";
        this.formData.PaidAmount = this.formData.amount + ".0"
        this.formData.PaymentType =  "main";
        this.formData.ReferenceNo = "trade wallet";
        this.formData.PaymentMode = "Self Transfer";
        this.formData.Remarks = "transfer from trade to main wallet";
        this.isButtonDisabled = true;
        this.dataService.post(this.formData, this.dataService.appConstant.APIS['ADD_MONEY']).subscribe(response => {
          this.apiResponse = response
          if(this.apiResponse.status){
            this.isButtonDisabled = false;
            this.dataService.appConstant.successToast(this.apiResponse.message);
          }else{
            this.isButtonDisabled = false;
            this.dataService.appConstant.errorToast(this.apiResponse.message);
          }
        },err=>{
          this.isButtonDisabled = false;
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
      } else {
        alert(this.apiResponse.message)
        this.isButtonDisabled = false;
      }
      console.log("-----Recharge Done----");
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      console.log("-----Wallet Data Get Done----");
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
    })
  }


  showPlans(plan) {
    this.planMobileType = plan
    this.selectedPlanList = this.rechargePlan.data[plan];
  }

  scrollToPlan() {
    document.getElementById('rechargePlans').scrollTop = document.getElementById('rechargePlans').scrollHeight
  }



  getRechargePlan() {
    this.isLoading = true;
    sessionStorage.setItem('setheadType', 'PayToAllIn');
    this.formData.circleId = 4;
    this.formData.providerId = this.uid;
    this.externalAPIService.getPlans(this.formData).subscribe(response => {
      this.apiResponse = response
      this.rechargePlan = this.apiResponse;
      this.selectedPlanList = this.rechargePlan.data.TOPUP;
      this.isShowPlanList = true;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
    sessionStorage.setItem('setheadType', null);
  }


  selectWalletType() {
    if (this.formData.fromWallet == "MainWallet") {
      this.formData.toWallet = "TradeWallet"
    }
    if (this.formData.fromWallet == "TradeWallet") {
      this.formData.toWallet = "MainWallet"
    }
  }

  selectPlan(i) {
    this.selectedPlan = this.selectedPlanList[i];
    this.selectedIndex = i;
    this.formData.amount = this.selectedPlan.rs
  }

  changeNo() {
    this.selectedPlan = {}
    this.isShowPlanList = false;
  }

}
