import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../../service/data-service.service';
@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})
export class ServiceRequestComponent implements OnInit {
  confirmed: boolean = false;

  constructor(public router: Router, public dataService: DataServiceService,) { }

  ngOnInit(): void {
  }

  onSubmit() {
    let userData = JSON.parse(sessionStorage.getItem('myEReturn_User'));
    this.dataService.get(this.dataService.appConstant.APIS['SERVICE_PAN_REQUEST'] + '?RegistrationID=' + userData.Data.RegistrationId)
      .subscribe(response => {
        if (response.Message)
          this.dataService.appConstant.successToast(response.Message);
      }, err => {
        this.dataService.appConstant.errorToast("Server error....Please try again");
      });
  }

  back() {
    this.router.navigate(['/Pan_Card_Services'], { replaceUrl: true });
  }

}
