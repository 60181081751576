import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-add-payment-history',
  templateUrl: './add-payment-history.component.html',
  styleUrls: ['./add-payment-history.component.scss']
})
export class AddPaymentHistoryComponent implements OnInit {


  fileName = 'PaymentHistoryReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public reportFilter:any = {numPerPage:10,pageNum:1};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public sessionUserData:any = {};

  public reportList:any = [];
  public isLoading:boolean = false;
  public isShowFilter:boolean = true;

  public formData:any = {};
  public isButtonDisabledPop :boolean = false;

  constructor(
    public dataService:DataServiceService,
    private datePipe: DatePipe
  ) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    if (window.innerWidth < 768) {
      this.isShowFilter = false;
    }
    

  }

  ngOnInit(): void {
    this.getReportData();
    
  }


  submit(appForm:NgForm){}

  getReportData(){
    sessionStorage.setItem('setheadType', null);
    this.isLoading = true;
    this.dataService.get(this.dataService.appConstant.APIS['ADD_PAYMENT_HISTORY']+ this.sessionUserData.RegistrationId+"&PageNo=5").subscribe(response => {
      this.reportList = response
      this.isLoading = false;
    },err=>{
      this.isLoading = false;
    })
  }

  openComplaintForm(item){
    this.formData = item;
     $('#ComplaintForm').modal('show');
  }


  submitCompltain(appForm:NgForm){
    this.isButtonDisabledPop = true;
    this.formData.ComplaintType = "Add Money"
    this.formData.TransactionId = this.formData.PaymentId; 
    var firstDay = new Date();
    this.formData.CreatedDate =  this.datePipe.transform(firstDay, 'yyyy-MM-dd')
    this.formData.Message = `Transaction of Amount: ${this.formData.PaidAmount}, Message: ${this.formData.Message}`;
    this.dataService.post(this.formData,this.dataService.appConstant.APIS['ADD_COMPLAINT']).subscribe(response => {
      this.isButtonDisabledPop = false;
      this.dataService.appConstant.successToast("Complaint submitted successfully")
      appForm.onReset();
      $('#ComplaintForm').modal('hide');
      this.getReportData();
    },err=>{
      this.isButtonDisabledPop = false;
    })
  }


  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  
  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('AddPaymentHistory.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }


}
