import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AddMoneyComponent } from './component/add-money/add-money.component';
import { ReportComponent } from './component/report/report.component';
import { PanCardComponent } from './component/pan-card/pan-card.component';
import { PanServiceReportComponent } from './component/pan-card/pan-service-report/pan-service-report.component';
import { BuyCouponComponent } from './component/pan-card/buy-coupon/buy-coupon.component';
import { InsuranceComponent } from './component/insurance/insurance.component';
import { TravelBookingComponent } from './component/travel-booking/travel-booking.component';
import { IncomeTaxReturnComponent } from './component/income-tax-return/income-tax-return.component';
import { GstComponent } from './component/gst/gst.component';
import { OtherServiceComponent } from './component/other-service/other-service.component';
import { ComplaintComponent } from './component/complaint/complaint.component';
import { MobileRechargeComponent } from './component/mobile-recharge/mobile-recharge.component';
import { DthRechargeComponent } from './component/dth-recharge/dth-recharge.component';
import { AepsComponent } from './component/aeps/aeps.component';
import { MoneyTransferComponent } from './component/money-transfer/money-transfer.component';
import { BbpsComponent } from './component/bbps/bbps.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AddPaymentHistoryComponent } from './component/add-payment-history/add-payment-history.component';
import { MyWalletHistoryComponent } from './component/my-wallet-history/my-wallet-history.component';
import { BankAccountTransferComponent } from './component/bank-account-transfer/bank-account-transfer.component';
import { ProccedRechargeComponent } from './component/mobile-recharge/procced-recharge/procced-recharge.component';
import { ProccedDthRechargeComponent } from './component/dth-recharge/procced-dth-recharge/procced-dth-recharge.component';
import { LoginComponent } from './component/login/login.component';
import { MoneyTransferReportComponent } from './component/money-transfer/money-transfer-report/money-transfer-report.component';
import { OnBoardingComponent } from './component/on-boarding/on-boarding.component';
import { LayoutComponent } from './component/layout/layout.component';
import { OperatorsComponent } from './component/bbps/operators/operators.component';
import { PaybillComponent } from './component/bbps/paybill/paybill.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { WalletTransferComponent } from './component/wallet-transfer/wallet-transfer.component';
import { ServiceRequestComponent } from './component/pan-card/service-request/service-request.component';
import { UtilityComponent } from './component/pan-card/utility/utility.component';

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "registration",
    component: RegistrationComponent
  },
  {
    path: "complete-your-profile",
    component: OnBoardingComponent
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: DashboardComponent,
      },
      {
        path: "Dashboard",
        component: DashboardComponent,
      },
      {
        path: "Bank_Account_Transfer",
        component: BankAccountTransferComponent
      },
      {
        path: "Mobile",
        component: ProccedRechargeComponent
      },
      {
        path: "Wallet_Transfer",
        component: WalletTransferComponent
      },
      {
        path: "DTH",
        component: ProccedDthRechargeComponent
      },
      {
        path: "Profile",
        component: ProfileComponent
      },
      {
        path: "Profile/:tab",
        component: ProfileComponent
      },
      {
        path: "Add_Payment_History",
        component: AddPaymentHistoryComponent
      },
      {
        path: "My_wallet",
        component: MyWalletHistoryComponent
      },
      {
        path: "Add_Money",
        component: AddMoneyComponent
      },
      {
        path: "Mobile_Recharge",
        component: MobileRechargeComponent
      },

      {
        path: "DTH_Recharge",
        component: DthRechargeComponent
      },
      {
        path: "AEPS",
        component: AepsComponent
      },
      {
        path: "Money_Transfer",
        component: MoneyTransferComponent
      },

      {
        path: "Money_Transfer_Report",
        component: MoneyTransferReportComponent
      },
      {
        path: "BBPS",
        component: BbpsComponent
      },
      {
        path: "BBPS/operator",
        component: OperatorsComponent
      },
      {
        path: "BBPS/Pay_bill",
        component: PaybillComponent
      },
      {
        path: "Pan_Card_Services",
        component: PanCardComponent,
      },
      {
        path: "Pan_Card_Services/Reports",
        component: PanServiceReportComponent
      },
      {
        path: "Pan_Card_Services/Buy_Coupon",
        component: BuyCouponComponent
      },
      {
        path: "Pan_Card_Services/Service_Request",
        component: ServiceRequestComponent
      },
      {
        path: "Pan_Card_Services/Utility",
        component: UtilityComponent
      },
      {
        path: "Insurance",
        component: InsuranceComponent
      },
      {
        path: "Travel_booking",
        component: TravelBookingComponent
      },
      {
        path: "Income_Tax_Service",
        component: IncomeTaxReturnComponent
      },
      {
        path: "GST",
        component: GstComponent
      },
      {
        path: "Other_Services",
        component: OtherServiceComponent
      },
      {
        path: "Complaint",
        component: ComplaintComponent
      },
      {
        path: "Reports",
        component: ReportComponent
      }
    ]
  },

];









@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
