import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-buy-coupon',
  templateUrl: './buy-coupon.component.html',
  styleUrls: ['./buy-coupon.component.scss']
})
export class BuyCouponComponent implements OnInit {

  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public couponPrice: any;
  public sessionUserData: any = {};
  public walletData: any = {};
  public physical: any = {};
  public without_physical: any = {};


  constructor(
    public dataService: DataServiceService,
    public router: Router,
    private datePipe: DatePipe
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.couponPrice = this.physical.Retailer;
    this.formData.TotalAmount = 0;
    this.formData.NoOfQty = 0;

    this.dataService.getMessage().subscribe((msg) => {
      if (msg == "walletUpdated") {
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
      }
    })
  }

  ngOnInit(): void {
    if (this.sessionUserData.RegistrationId) {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    }
    this.get_price()
  }

  get_price() {
    console.log('get_price');
    this.dataService.get(this.dataService.appConstant.APIS['GET_PAN_COUPNE_PRICE']).subscribe(response => {
      console.log(response);
      if (response.Status == true) {
        var data = response.data.ResList;
        data.forEach(element => {
          if (element.ServiceType == "Physical") {
            this.physical = element;
          } else {
            this.without_physical = element;
          }
          this.selectCoupon(this.physical.Retailer)
        });
      } else {
        this.dataService.appConstant.errorToast("Server error....Please try again");
      }
      setTimeout(() => {
        console.log(this.physical);
        console.log(this.without_physical);
      }, 5000);
    })
  }


  back() {
    this.router.navigate(['/Pan_Card_Services'], { replaceUrl: true });
  }

  selectCoupon(price) {
    this.couponPrice = price;
    this.calculateTotal();
  }

  calculateTotal() {
    this.formData.TotalAmount = this.formData.NoOfQty * this.couponPrice;
  }

  submit(appForm: NgForm) {
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if (this.formData.TotalAmount && this.formData.TotalAmount > 0) {
      if (this.walletData.TreadWallet > this.formData.TotalAmount) {
        this.formData.RegistrationId = this.sessionUserData.Data.RegistrationId;
        this.formData.Price = this.couponPrice;
        this.formData.Date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        this.dataService.post(this.formData, this.dataService.appConstant.APIS['BUY_COUPON']).subscribe(response => {
          this.apiResponse = response
          if (this.apiResponse.status) {
            appForm.onReset();
            this.dataService.appConstant.successToast(this.apiResponse.message);
            this.dataService.getWalletData(this.sessionUserData.RegistrationId);
          } else {
            this.dataService.appConstant.errorToast(this.apiResponse.message);
          }
        }, err => {
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
      } else {
        alert("You have insufficient balance in TRADE WALLET, Please add money in to wallet TRADE WALLET.")
      }
    } else {
      alert("Enter valid coupon quantity")
    }
  }



}
