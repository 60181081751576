<div class="desktopView">
    <div class="headerContainer">
        <div class="dashbordlogo" [ngClass]="{ dashbordlogoSidebar: !isShowSideBar }">
            <img (click)="goToDashbarod()" src="assets/images/logo1.png" />
        </div>

        <div class="addMoney"  [ngClass]="{ addMoneyNoSidebar: !isShowSideBar }">
            <span style="margin:auto; display:table; border:1px solid #fe8b43;" routerLink="/Add_Money"> + ADD MONEY</span>
        </div>

        <!------WALLET CONTAINER-->
        <div class="walletContainer">
            <div class="mainwallet">
                <span style="text-align: center;">Main Wallet</span>
                <span style="text-align: center; margin-top: -5px;">₹ {{ walletData?.MainWallet ? walletData.MainWallet : 0 }}</span>
            </div>
            <div class="tradewallet">
                <span style="text-align: center;">Trade Wallet</span>
                <span style="text-align: center; margin-top: -5px;">₹ {{ walletData?.TreadWallet ? walletData.TreadWallet : 0 }}</span>
            </div>
                <!-- <i class="fa fa-plus addFunds" aria-hidden="true"></i> -->
        </div>

        <div class="addAutoCollect" [ngClass]="{ addMoneyNoSidebar: !isShowSideBar }">
            <span style="margin:auto; display:table; border:1px solid #2154a4;" data-toggle="modal" data-target="#autoCollect"> Collect MONEY</span>
        </div>

        <div id="autoCollect" class="modal fade" role="dialog">
            <div class="modal-dialog overlay-popup">
                <div class="modal-content overlay-content overlay-scroll">
                    <div class="modal-header">
                        Collect Money
                    </div>
                    <div class="modal-body overla">
                        <div class="row">
                            <div class="col pad0 ml-5">
                                <div class="tabs" [ngClass]="{'activeTabs':tab=='UPI'}" (click)="changeTab('UPI')">
                                    UPI
                                </div>
                            </div>
                            <div class="col pad0 mr-5">
                                <div class="tabs" [ngClass]="{'activeTabs':tab=='QR'}" (click)="changeTab('QR')">
                  QR
                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                                    <div class="row modalFixedInfo" *ngIf="tab=='UPI'">

                                        <div class="col-12 form-group">
                                            <div class="col-12">
                                                <div>PAYEE UPI</div>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" class="w-100" formControlName="upi" class="form-control">


                                            </div>
                                        </div>
                                        <div class="col-12 form-group">
                                            <div class="col-12">
                                                <div>Amount</div>
                                            </div>
                                            <div class="col-12 form-group">
                                                <input type="text" class="w-100" formControlName="amount" (keypress)="numberOnly($event)" class="form-control">


                                            </div>
                                        </div>
                                        <div class="col-12 form-group">
                                            <div class="col-12">
                                                <div>Remark</div>
                                            </div>
                                            <div class="col-12">
                                                <input type="text" class="w-100" formControlName="remark" class="form-control">

                                            </div>
                                        </div>

                                        <div class="col-12 mt-3 mb-3 form-group">
                                            <div class="buttonGroup tCenter">
                                                <button type="submit" class="btn btn-primary submitBtn payNow" (click)="sendUpiCollectLink()" [disabled]="form.invalid" [ngClass]="{ 'button--loading': isButtonDisabled }">
                          <span class="button__text">COLLECT MONEY</span>
                        </button> &nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row modalFixedInfo" *ngIf="tab=='QR'" style="overflow-x: hidden;overflow-y: auto">
                                        <div class="col-12 mt-3">
                                            <div>PHONE NUMBER</div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 mt-3">
                                            <input type="text" class="w-100" [(ngModel)]="qrMobile" [ngModelOptions]="{standalone: true}" disabled>
                                        </div>

                                        <div class="col-12 mt-3 mb-3">
                                            <div class="buttonGroup tCenter">
                                                <button type="submit" class="btn btn-primary submitBtn payNow">
                          <span class="button__text" (click)="getQRUrlData()">GENERATE NEW QR CODE</span>
                        </button> &nbsp;&nbsp;
                                            </div>
                                            <div class="row" *ngIf="showQR" id="image-section">
                                                <div class="col-md-12 d-flex justify-content-center mt-1" style="padding-top: 3rem;">
                                                    <h6 style="font-weight: bold;">Skatomozo Services</h6>
                                                </div>
                                                <div class="col-md-12 d-flex justify-content-center ">
                                                    <qrcode #parent [qrdata]="qrLink" width="256" elementType="'img'" errorCorrectionLevel="'M'"></qrcode>
                                                </div>
                                                <div class="col-md-12 d-flex justify-content-center mt-3 mb-3 p-3">
                                                    <img src="assets/images/logo1.png" width="300" height="60" />
                                                </div>
                                                <div class="col-md-12 d-flex justify-content-center" style="width: 400px; margin: auto;">
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/gpay.png" />
                                                    </div>
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/paytm.jpg" />
                                                    </div>
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/Amazon.png" />
                                                    </div>
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/phonepay.jpg" />
                                                    </div>
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/BHIM.png" />
                                                    </div>
                                                    <div class="col-sm">
                                                        <img class="imgsize" src="assets/images/Razor.jfif" />
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12 mt-3 mb-3" *ngIf="showDownload">
                          <div class="buttonGroup tCenter">
                            <button type="submit" (click)="showDownload != showDownload; downloadImage(imageSection)" class="btn btn-primary submitBtn payNow">
                              <span class="button__text">Download</span>
                            </button> &nbsp;&nbsp;
                          </div>
                        </div> -->
                                            </div>
                                            <div class="col-12 mt-3 mb-3" *ngIf="showQR">
                                                <div class="buttonGroup tCenter">
                                                    <button type="submit" (click)=" downloadImage(imageSection)" class="btn btn-primary submitBtn payNow">
                            <span class="button__text">Download</span>
                          </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                                        <img class="walletQRCode" *ngIf="tab=='trade'" src="assets/images/tradeWalletQR.jpg">
                                        <img class="walletQRCode" *ngIf="tab=='main'" src="assets/images/mainWalletQR.jpg">
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="modal-footer  d-flex justify-content-center">

            <div class="row">
              <div class="col-sm">
                <img class="imgsize" src="assets/images/gpay.png" />
              </div>
              <div class="col-sm">
                <img class="imgsize" src="assets/images/paytm.jpg" />
              </div>
              <div class="col-sm">
                <img class="imgsize" src="assets/images/Amazon.png" />
              </div>
              <div class="col-sm">
                <img class="imgsize" src="assets/images/phonepay.jpg" />
              </div>
              <div class="col-sm">
                <img class="imgsize" src="assets/images/BHIM.png" />
              </div>
              <div class="col-sm">
                <img class="imgsize" src="assets/images/Razor.png" />
              </div>
            </div>
          </div> -->
                </div>
            </div>
        </div>

        <!-----RETAILER CONTAINER-->
        <div class="retailerInfoContainer">
            <div class="userRole">Your Distributor</div>
            <div class="userBasicInfo"><b>RAHUL UTTAMRAO PATIL</b></div>
            <div class="userBasicInfo"><b>+91 9763900900</b></div>
        </div>

        <!-----PROFILE INFO-->
        <div class="avatarContainer" (click)="goToProfile()">
            <div>
                <img src="{{ sessionUserData.Data.ProfilePhoto }}" onError="this.src='assets/images/avtar.png'" class="avatar" />
            </div>
            <div class="infoContainer pad10">
                <div class="userName">{{ sessionUserData.Data.FullName }}</div>
                <div class="userId">
                    User ID : <span>{{ sessionUserData.Data.BranchCode }}</span>
                </div>
                <div class="userRole">Retailer</div>
            </div>
        </div>

        <!-----LOGOUT CONTAINER-->
        <div class="logoutSection" (click)="logOut()">
            <div>
                <i class="fa fa-power-off" style="font-size: 17px" aria-hidden="true"></i>
            </div>
            <div>
                <span class="theamColor"><strong> Logout </strong></span>
            </div>
        </div>
    </div>
</div>

<div class="mobileView mV">
    <div class="row headerRow mrg0">
        <div class="col-2 col-sm-2" data-toggle="modal" data-target="#myModal">
            <div class="flexJustifyAlignCenter">
                <i class="icofont-navigation-menu toggleIcon"></i>
            </div>
        </div>
        <div class="col-8 col-sm-8 tCenter" routerLink="">
            <img class="mVLogoImg" src="assets/images/logo1.png" />
        </div>
        <div class="col-2 col-sm-2 pad0 tCenter" routerLink="/Profile">
            <div class="flexJustifyAlignCenter tCenter">
                <i class="icofont-ui-user toggleIcon"></i>
            </div>
        </div>
    </div>
</div>

<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div>
                <div class="app-tab-menu">
                    <div class="modal-body">
                        <!---WITHOUT MY PROFILE --->
                        <ul class="close" data-dismiss="modal" aria-label="Close">
                            <li class="active" (click)="openTab('Dashboard')" [ngClass]="{ active: sideBarTab == 'Dashboard' }">
                                <img src="assets/images/d1.png" /> <span>Dashboard</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>

                            <li class="active" (click)="openTab('Mobile_Recharge')" [ngClass]="{ active: sideBarTab == 'Mobile_Recharge' }">
                                <img src="assets/images/d2.png" /> <span>Mobile Recharge</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>

                            <li class="active" (click)="openTab('DTH_Recharge')" [class.active]="sideBarTab == 'DTH_Recharge'">
                                <img src="assets/images/d3.png" /> <span>DTH Recharge</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>

                            <li (click)="openTab('AEPS')" [class.active]="sideBarTab == 'AEPS'">
                                <img src="assets/images/d4.png" /> <span>AEPS</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('Money_Transfer')" [class.active]="sideBarTab == 'Money_Transfer'">
                                <img src="assets/images/d5.png" /> <span>Money Transfer</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('BBPS')" [class.active]="sideBarTab == 'BBPS'">
                                <img src="assets/images/d6.png" /> <span>BBPS</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('Pan_Card_Services')" [class.active]="sideBarTab == 'Pan_Card_Services'">
                                <img src="assets/images/d7.png" /> <span>Pan Card</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <!-- <li (click)="openTab('Insurance')" [class.active]="sideBarTab == 'Insurance'">
                                <img src="assets/images/d8.png" /> <span>Insurance</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li> -->
                            <!-- <li (click)="openTab('Travel_booking')" [class.active]="sideBarTab == 'Travel_booking'">
                                <img src="assets/images/d9.png" /> <span>Travel Booking</span><i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li> -->
                            <li (click)="openTab('Income_Tax_Service')" [class.active]="sideBarTab == 'Income_Tax_Service'">
                                <img src="assets/images/d10.png" />
                                <span>Income Tax Return</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('GST')" [class.active]="sideBarTab == 'GST'">
                                <img src="assets/images/d11.png" /> <span>GST</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('Other_Services')" [class.active]="sideBarTab == 'Other_Services'">
                                <img src="assets/images/d12.png" /> <span>Other Services</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('Complaint')" [class.active]="sideBarTab == 'Complaint'">
                                <img src="assets/images/d13.png" /> <span>Complaint</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>
                            <li (click)="openTab('Reports')" [class.active]="sideBarTab == 'Reports'">
                                <img src="assets/images/d14.png" /> <span>Report</span>
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </li>

                            <li (click)="logOut()">
                                <i class="fa fa-logout text-danger" aria-hidden="true"></i>
                                <span><b>Logout</b></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>