
export const environment = {
  production: false,
   //apiBaseUrl: 'http://localhost:24634'
  //apiBaseUrl: 'http://192.168.1.230:81'
   apiBaseUrl: 'https://webapi.myereturn.com',
   apiBaseUrl1: 'https://055gc9w7ze.execute-api.ap-south-1.amazonaws.com/dev',
   apiFileUploadUrl: 'https://3fnfowmzxg.execute-api.us-west-2.amazonaws.com/dev/api/v1/document-upload/'


  // apiBaseUrl: 'http://ereturnapi.gtpdemo.co.in'
  //http://localhost:24634/
};
