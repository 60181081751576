import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {
  public sessionUserData:any = {};

  constructor() { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
  }

}
