<section class="topContainer">
    <div class="container-fluid">
        <header class="header-area">
            <div class="main-header">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-header-item">
                            <div class="main-header-menus flexAlignCenter">
                                <div class="header-logo">
                                    <img src="assets/images/logo1.png" />
                                </div>
                                <div class="toggleBars">
                                    <i class="fa fa-bars"></i>
                                </div>
                                <div class="header-menu d-none d-lg-block">
                                    <ul>
                                        <li>
                                            <a href="https://myereturn.com">Home</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#about">About</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#services">Services</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#distributor">Distributor</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#retailer">Retailer</a>
                                        </li>
                                        <li>
                                            <a href="http://myereturn.com/#footer">Contact</a>
                                        </li>
                                        <li class="menu-item-has-children active menuDropdown">
                                            <a>Login <i class="fa fa-angle-down"></i></a>

                                            <div class="loginDropdown">
                                                <ul class="sub-menu">
                                                    <li class="subMenuLi">
                                                        <a href="https://distributor.myereturn.com">Distributor</a>
                                                    </li>
                                                    <li class="subMenuLi">
                                                        <a href="https://retailer.myereturn.com">Retailer</a>
                                                        <!-- <a href="http://localhost:4200/#/">Retailer</a> -->
                                                    </li>
                                                    <li class="subMenuLi">
                                                        <a href="http://employee.myereturn.com">Employee</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="https://retailer.myereturn.com/#/registration">Join As Retailer</a>
                                            <!-- <a href="http://localhost:4200/#/registration">Join As Retailer</a> -->
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-content">
                    <h3 class="title">
                        <b>Login as<br />Retailer</b>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbM">
        <div class="breadcrumb-itemM">
            <a href="#">Home</a>
        </div>
        <div class="breadcrumb-itemM breadcrumb-item-active">
            <a>For Retailer</a>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid secondSection">
        <div class="row formRow">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-lx-8">
                <h4 *ngIf="tab == 'forgotPassword'"><b>Forgot Password</b></h4>
                <h4 *ngIf="tab == 'registration'"><b>Create Account</b></h4>


                <!-- registration -->
                <div *ngIf="tab == 'registration'">
                    <form #registrationForm="ngForm" *ngIf="!isOTPRequested" (ngSubmit)="registrationForm.form.valid && submitRegistration()">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input type="text" placeholder="Mobile No" [(ngModel)]="formData.MobileNumber" name="MobileNo" #MobileNo="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (MobileNo.touched || registrationForm.submitted) &&
                          MobileNo.errors?.required
                      }" (keypress)="dataService.appConstant.keyPress($event)" maxlength="10" minlength="10" [disabled]="isOTPRequested" />
                                    <div class="validation_error" *ngIf="
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.required
                      ">
                                        <b>!</b> Mobile No is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.minlength
                      ">
                                        <b>!</b> Mobile No must be 10 digit.
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6 ">
                                <select class="form-control" placeholder="Distributor" [(ngModel)]="formData.distributor" name="distributor" #distributor="ngModel" required (ngModelChange)="selectProfileState()" style="position: relative !important; top: 19% !important; height: 4.2rem !important;">
                                    <option value="" selected>Select Distributor</option>
                                    <option [value]="item.RegistrationId" *ngFor="let item of distributorData">
                                        {{item.BranchCode}}-{{item.FullName}}</option>
                                </select>
                                <div class="validation_error" *ngIf="
                        (distributor.touched || registrationForm.submitted) &&
                        distributor.errors?.required
                      " style="position: relative !important; top: 24% !important;">
                                    <b>!</b> Distributer is required.
                                </div>
                            </div> -->

                            

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="input-box">
                                    <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                        <span class="button__text">Submit</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="forgotPassword">
                                    <p>
                                        Already have an account?
                                        <a class="cp" (click)="openTab('login')"> Login</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form #registrationForm="ngForm" *ngIf="isOTPRequested" (ngSubmit)="registrationForm.form.valid && submitRegistration()">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <div class="input-box">
                                    <input type="text" placeholder="Mobile No" [(ngModel)]="formData.MobileNumber" name="MobileNo" #MobileNo="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (MobileNo.touched || registrationForm.submitted) &&
                          MobileNo.errors?.required
                      }" (keypress)="dataService.appConstant.keyPress($event)" maxlength="10" minlength="10" [disabled]="isOTPRequested" />
                                    <div class="validation_error" *ngIf="
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.required
                      ">
                                        <b>!</b> Mobile No is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                        (MobileNo.touched || registrationForm.submitted) &&
                        MobileNo.errors?.minlength
                      ">
                                        <b>!</b> Mobile No must be 10 digit.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isOTPRequested">
                                <div class="input-box">
                                    <input [type]="pass" placeholder="OTP" [(ngModel)]="formData.Otp" name="Otp" #Otp="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (Otp.touched || registrationForm.submitted) &&
                          Otp.errors?.required
                      }" (keypress)="dataService.appConstant.keyPress($event)" (ngModelChange)="verifyOTPRegistration()" maxlength="4" minlength="4" [disabled]="isRegistrationOTPVerified" />

                                    <i class="fa fa-eye-slash passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'show' && !isRegistrationOTPVerified"></i>
                                    <i class="fa fa-eye passHideShow eye" (click)="hideShowPassword()" aria-hidden="true" *ngIf="passIcon == 'hide' && !isRegistrationOTPVerified"></i>
                                    <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
                                        <div class="validation_error" *ngIf="
                            (Otp.touched || registrationForm.submitted) &&
                            Otp.errors?.required
                        ">
                                            <b>!</b> OTP is required
                                        </div>
                                        <div class="validation_error" *ngIf="
                            (Otp.touched || registrationForm.submitted) &&
                            Otp.errors?.minlength
                        ">
                                            <b>!</b> OTP must be 4 digit.
                                        </div>
                                        <div class="resendOTP" *ngIf="!isRegistrationOTPVerified" (click)="resendOTP()">
                                            resend otp
                                        </div>
                                        <div class="timeLeft" *ngIf="!isRegistrationOTPVerified">
                                            Time left:{{ timeLeft }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Full Name" [(ngModel)]="formData.ContactPersoneName" name="FullName" #FullName="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (FullName.touched || registrationForm.submitted) &&
                          FullName.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (FullName.touched || registrationForm.submitted) &&
                        FullName.errors?.required
                      ">
                                        <b>!</b> Full Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="email" placeholder="Email" [(ngModel)]="formData.EmailId" name="EmailId" #EmailId="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (EmailId.touched || registrationForm.submitted) &&
                          EmailId.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (EmailId.touched || registrationForm.submitted) &&
                        EmailId.errors?.required
                      ">
                                        <b>!</b> Email is required
                                    </div>
                                    <div class="validation_error" *ngIf="
                        (EmailId.touched || registrationForm.submitted) &&
                        EmailId.errors?.email
                      ">
                                        <b>!</b> Enter valid email address.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input [type]="pass1" placeholder="Password" autocomplete="off" [(ngModel)]="formData.Password" name="password" #password="ngModel" required [ngClass]="{
                        inputFieldReq:
                          (password.touched || registrationForm.submitted) &&
                          password.errors?.required
                      }" />

                                    <i class="fa fa-eye-slash passHideShow" (click)="hideShowPassword1()" aria-hidden="true" *ngIf="passIcon1 == 'show'"></i>
                                    <i class="fa fa-eye passHideShow" (click)="hideShowPassword1()" aria-hidden="true" *ngIf="passIcon1 == 'hide'"></i>

                                    <div class="validation_error" *ngIf="
                        (password.touched || registrationForm.submitted) &&
                        password.errors?.required
                      ">
                                        <b>!</b> Password is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Shop Name" [(ngModel)]="formData.ShopName" name="ShopName" #ShopName="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (ShopName.touched || registrationForm.submitted) &&
                          ShopName.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (ShopName.touched || registrationForm.submitted) &&
                        ShopName.errors?.required
                      ">
                                        <b>!</b> Shop name is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.Category" name="Category" #Category="ngModel" required (ngModelChange)="selectProfileState()">
                                        <option value="">Select Shop Category</option>
                                        <option value="Mobile Shop">Mobile Shop</option>
                                        <option value="Grahak Seva kendra">
                                            Grahak Seva kendra
                                        </option>
                                        <option value="General Store">General Store</option>
                                        <option value="Grocery Shop">Grocery Shop</option>
                                        <option value="Xerox Center">Xerox Center</option>
                                        <option value="Cyber Cafe">Cyber Cafe</option>
                                        <option value="Stationary Shop">Stationary Shop</option>
                                        <option value="Paan Shop">Paan Shop</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                        (Category.touched || registrationForm.submitted) &&
                        Category.errors?.required
                      ">
                                        Category is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Address" [(ngModel)]="formData.Address" name="Address" #Address="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (Address.touched || registrationForm.submitted) &&
                          Address.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (Address.touched || registrationForm.submitted) &&
                        Address.errors?.required
                      ">
                                        <b>!</b> Address is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Street" [(ngModel)]="formData.Street" name="Street" #Street="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (Street.touched || registrationForm.submitted) &&
                          Street.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (Street.touched || registrationForm.submitted) &&
                        Street.errors?.required
                      ">
                                        <b>!</b> Street is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Area" [(ngModel)]="formData.Area" name="Area" #Area="ngModel" required autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (Area.touched || registrationForm.submitted) &&
                          Area.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (Area.touched || registrationForm.submitted) &&
                        Area.errors?.required
                      ">
                                        <b>!</b> Area is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.State" name="StateId" #StateId="ngModel" required (ngModelChange)="selectProfileState()">
                                        <option value="">Select State</option>
                                        <option *ngFor="let state of stateList" [value]="state.id">
                                            {{ state.sName }}
                                        </option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                        (StateId.touched || registrationForm.submitted) &&
                        StateId.errors?.required
                      ">
                                        State is required
                                    </span>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.City" name="CityId" #CityId="ngModel" required (ngModelChange)="selectProfileState()">
                                        <option value="">Select District/City</option>
                                        <option *ngFor="let city of cityList" [value]="city.id">
                                            {{ city.name }}
                                        </option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                        (CityId.touched || registrationForm.submitted) &&
                        CityId.errors?.required
                      ">
                                        District/City is required
                                    </span>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <select class="form-control" [(ngModel)]="formData.PostOffice" name="TahsilsId" #TahsilsId="ngModel" required>
                                        <option value="">Select Tahsils/Area/Mandal</option>
                                        <option *ngFor="let tahsil of tahsilList" [value]="tahsil.id">
                                            {{ tahsil.tName }}
                                        </option>
                                    </select>
                                    <span class="validation_error" *ngIf="
                      (TahsilsId.touched || registrationForm.submitted) &&
                      TahsilsId.errors?.required
                    ">
                                        Tahsils/Area/Mandal is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="PinCode" [(ngModel)]="formData.Pincode" name="Pincode" #Pincode="ngModel" required autocomplete="off" autocomplete="off" (keypress)="dataService.appConstant.keyPress($event)" maxlength="6" minlength="6" [ngClass]="{
                        inputFieldReq:
                          (Pincode.touched || registrationForm.submitted) &&
                          Pincode.errors?.required
                      }" />
                                    <div class="validation_error" *ngIf="
                        (Pincode.touched || registrationForm.submitted) &&
                        Pincode.errors?.required
                      ">
                                        <b>!</b> PinCode is required.
                                    </div>
                                    <div class="validation_error" *ngIf="
                        (Pincode.touched || registrationForm.submitted) &&
                        Pincode.errors?.minlength
                      ">
                                        <b>!</b> PinCode must be 6 digit.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6 " *ngIf="isRegistrationOTPVerified">
                                <div class="distributor-code">
                                    <span>Do you have a distributor reference code ?</span> <br>
                                    <div style="display: flex; justify-content: space-around; margin-top: 5px;">
                                        <span>
                                            <input type="radio" value="yes" name="Dcode" (click)="toggleDistributorCodeTrue()"> Yes,i have
                                        </span>
                                        <span>
                                            <input type="radio" value="no" name="Dcode" (click)="toggleDistributorCodeFalse()" checked> No
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6 " *ngIf="isRegistrationOTPVerified">
                                <div class="input-box">
                                    <input type="text" placeholder="Distributor Code" [(ngModel)]="formData.distributor" name="distributor" #distributor="ngModel" required [disabled]="!isDistributorCode" (change)="verifyDistributorCode($event.target.value)" autocomplete="off" [ngClass]="{
                        inputFieldReq:
                          (distributor.touched || registrationForm.submitted) &&
                          distributor.errors?.required
                      }" />
                        <div class="validation_error" *ngIf="!isDistributorRefVerified && isDistributorCode" style="position: relative !important; top: 24% !important;">
                                        <b>!</b> Distributor code is incorrect
                                    </div>
                                <div class="validation_error" *ngIf="
                        (distributor.touched || registrationForm.submitted) &&
                        distributor.errors?.required
                      " style="position: relative !important; top: 24% !important;">
                                    <b>!</b> Distributor code is required.
                                </div>
                            </div>
                        </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" *ngIf="isRegistrationOTPVerified">
                                <div class="IconsPage__icon__category___1SNBQ">
                                    <label for="Terms_Condition">
                                        <input type="checkbox" id="Terms_Condition" required
                                            [(ngModel)]="formData.Terms_Condition" name="Terms_Condition"
                                            #Terms_Condition="ngModel">
                                        <span>I have read and agree to the <a href="javascript:void(0)"
                                                data-toggle="modal" data-target="#exampleModal_terms-condition">Terms &
                                                Condtions</a>.</span>
                                    </label>
                                    <div class="text-danger" *ngIf="
                      (Terms_Condition.touched || registrationForm.submitted) &&
                      Terms_Condition.errors?.required
                    ">
                                        <b>!</b> Accept Terms & Condtions.
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="input-box">
                                    <button type="submit" [ngClass]="{ 'button--loading': isButtonDisabled }">
                                        <span class="button__text">Submit</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <div class="forgotPassword">
                                    <p>Already have an account? <a class="cp" (click)="openTab('login')"> Login</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="book-a-tour-info">
                    <h5><b>Need help?</b></h5>
                    <br />
                    <div>
                        Drop us a email and we will connect you with a team member who can help.
                    </div>
                    <br />
                    <h4><b>info@myereturn.com</b></h4>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 col-md-6 col-sm-8">
                <div class="footer-about mt-30">
                    <div class="logo cp">
                        <a href="https://myereturn.com">
                            <img src="assets/images/logo-2.png" alt="logo" />
                        </a>
                    </div>
                    <p>
                        With an aim to ease to day to day money transaction tasks and enable the small businesses to target a greater audience for higher revenue, MyEReturn- the flagship unit of Skatomozo Group, has been established and registered under “Ministry of Corporate
                        affairs” Government of India.
                    </p>
                    <ul>
                        <li>
                            <div style="color: #979797">Customer Support</div>
                            <a href="tel:+919859003900">98-5900-3900</a><br />
                            <a href="mailto:info@myereturn.com">info@myereturn.com</a>
                        </li>
                        <li>
                            <div style="color: #979797">Sales</div>
                            <a href="tel:+919132626260"> 91-3262-6260</a><br />
                            <a href="mailto:sales@myereturn.com">sales@myereturn.com</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-address mt-30">
                    <h3 class="title"><b>Head Office</b></h3>
                    <p>
                        <b>Head Office:</b><br /> H wing, 2nd Floor, Chhoriya Market, Raver Tal- Raver Dist- Jalgaon 424508, Maharashtra
                    </p>

                    <p>
                        <b>Regional Office:</b><br /> 1347/04, kasturbhai block near GCS hospital ,chamunda, saijpur bogha Ahmedabad 382345 (Gujarat)
                    </p>

                </div>
            </div>
        </div>
    </div>

    <div class="footer-copyright-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright">
                        <p>© Copyright 2021</p>
                        <ul class="desktopView">
                            <li>
                                <a><i class="fa fa-facebook-square"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 mobileView">
                    <div class="footer-copyright d-block">
                        <ul>
                            <li>
                                <a><i class="fa fa-facebook-square"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>







<!-- terms-condition -->
<div class="modal fade" id="exampleModal_terms-condition" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Terms & Condtions</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                RETAIL FRANCHISEE AGREEMENT This Retail Franchisee Agreement (this “Agreement”) is made effective as of at the time of user (retailer) register (the “Effective Date”), by and between M/s Skatmozzo Services LLP office at Raver, Jalgaon(“hereinafter referred
                to as “Company”), WHEREAS, Retailer is engaged in the retail Business and services of (collectively, the “Business”); and AND WHEREAS, the company engaged in the business of providing the services of recharge (mobile + DTH), AEPS (Aadhar
                enable payment system), Money Transfer, Bill Payment, Income Tax return Filling, GST related Services, PAN CARD and other services like Digital Signatures ,FSSAI LICENSE (DRAFTING), MSME Registration, AUDIT, PROJECT REPORT through its
                online portal namely “myEreturn.com” AND WHEREAS the company has appointed various distributors of the services of the company territory wise throughout India. AND WHEREAS, Retailer desires to sell and offer for sale one or more of Company’s
                Services and services (collectively, the “Services”) in the operation of the Business. the appointment of the retailer shall be below the concerned appointed distributor of the area. NOW, THEREFORE, in consideration of the mutual covenants
                and promises of the parties set forth herein and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties hereto agree as follows: 1. Authorized Retailer: Subject to the terms
                and conditions of this Agreement, Company hereby appoints Retailer and the Second Party hereby accepts such appointment for itself. as a non-exclusive authorized retailer of the Services for the term of this Agreement. The Company has
                also authorised distributors territory wise and the company shall inform the retailer about the distributor in his area and the retailer shall be touch with the concerned distributor for every service. From time to time during the term
                of this Agreement, Company may agree to establish certain business development programs available to authorized retailers on an annual basis. Participation in any such program is subject to Retailer’s compliance with the terms and conditions
                of this Agreement and the terms of any such Program. 2. Services: 2.1 During the term of this Agreement, Retailer may sell or offer for sale the Services at one or more physical store locations operated by Retailer, one or more Events
                in which Retailer participates or one or more websites/webpages operated by or on behalf of Retailer. Retailer has no obligation to sell or offer for sale any specified amount of the Services, except as expressly set forth herein. 2.2
                Retailer and Company shall mutually agree upon the Services available for sale through Retailer from time to time hereunder. 2.3 Company reserves the right to: (i) change the Style of or modify any Service; (ii) discontinue any Service;
                and (iii) add new and additional Services to Retailer’s Service lines, which Services shall constitute Services for purposes of this Agreement. Further, Company will not discontinue or change any Service unless Company has provided Retailer
                with, in the case of Services offered only online, at least 15 days’ prior written notice. 2.4 The company declares that the service of the “myereturn” platform are taken from the third party vendors, any withdrawal of services by those
                vendors or complaints in the services shall not make the company liable. 2.5 In order to use this service like AEP, Money Transfer, PAN card services, the retailer has to compulsorily update the details given in the profile, without updating
                the profile details, the retailer will not be able to use these services and Retailer shall be bound to provide timely and updated information necessary for the use of any Services. 3. Compliance with Laws: Company will comply with all
                applicable laws, rules, orders, treaties, and regulations related to the Service Provider , sale, use of all Services, to the extent not inconsistent with the laws of India. 4. Pricing: 4.1 Retailer shall purchase the Services from Company
                at the prices set by Company and agreed to by Retailer from time to time (the “Prices”). Such Prices may be increased or changed anytime by the company and the company shall keep the retailers informed directly or through distributors.
                Further, if Company offers a better price to a third party for similar quantities of identical Services, the price of the Services will be adjusted to the price offered to such third party. Such price adjustment will not apply retroactively,
                and Retailer shall only be entitled to such lower price on future purchases of such Services. 4.2 Company will set the advertised retail price for all the Services, including any discounts, after considering in good faith input from Retailer.
                Company and Retailer may sell the Services through their respective agreed channels, in parity with the advertised prices set by Company. Advertised prices for all Services shall be universally applicable to all retailers of the Services.
                4.3 The Retailer would be free to avail the services for their consumers by pre recharging its wallet on the “myereturn” platform and the commission shall be given in the wallet only. The commission structure shall be given by the company
                separately which shall form part of the present agreement. Company shall be free to change the commission structure in its own discretion at any time. 4.4 The retailer will be given the option of 02 types of wallets on the Myreturn portal.
                1st Main Wallet and 2nd Trade Wallet, Retailer cannot withdraw money from main, Retailer has to must use that amount. 4.5 2nd After adding the amount to Trade Wallet, if the Retailer wants the amount back from the Trade Wallet, then the
                charges decided by the Company will be deducted from the Retailer's Wallet. 5. Service Delivery and Completion: 5.1 It would not be the sole responsibility of the company to complete and deliver the services to the consumer. It is clarified
                that the company is just a platform, the services are provided by the third parties and in any case the third parties shall be liable to provide and complete the services on time. 5.2 It is understood that the services provided are related
                to the government authorities and no fix time of completion can be given, in case of the delay for any reason company shall not be held liable. 6. Payment Terms and Incentive Plans: Company shall invoice Retailer within five business days
                of the initiation of the services purchased by Retailer. Retailer shall pay the amount set forth on such invoice, in INR, unless disputed in good faith, within 15 days after receipt of the Invoice. Either party may deduct any amount owed
                by the other party to such party as a setoff against any amount due or credit owed to the other party under this Agreement. 7. Term and Termination: 7.1 Agreement will commence on the Effective Date and continue until terminated as provided
                herein. 7.2 Either party may terminate this Agreement at any time without notice to the other party (the “Notice Period”). Additionally, either party may terminate this Agreement immediately upon written or Digital notice in the event
                of a material breach (including but not limited to any uncured payment default) by the other party, if such other party has failed to cure such breach or default within 15 days of written notice thereof. 7.3 All rebates, subsidies, and
                credits set forth herein in effect on the date of termination (collectively, the “Subsidies”) shall continue to accrue on all Services sold by, or, in the case of return credits, returned to, Retailer during the Notice Period. 7.4 During
                the Notice Period, Company shall continue to timely complete the Services already ordered and invoiced and Retailer shall pay for all such Services in accordance with this Agreement. 8. Effect of Termination: Following the effective date
                of termination (the “Termination Date”): 8.1 Company shall remit to Retailer all accrued but unpaid Subsidies; provided, however, that any such unpaid Subsidies shall be offset by any unpaid amounts owed by Retailer to Company under this
                Agreement except to the extent disputed in good faith; 8.2 Company shall remit to Retailer the balance of any Service credit memorandum in immediately available funds within 30 days of the effective date of termination; provided, however,
                that any such service credit shall be offset by any unpaid amounts owed by Retailer to Company under this Agreement except. 8.3 Unless otherwise agreed in writing by the parties, during any post-termination run-out period, Retailer will
                be entitled to continue to market and sell any Services to honor any non-cancellable open customer orders. 8.4 Retailer shall promptly cease and desist use of all Company intellectual property and shall cease and desist holding itself
                out in any way as an authorized retailer of the Services. 9. Advertising: 9.1 Retailer shall pay for customary point of purchase advertising displays or exterior signage at Retailer’s stores, participation in Retailer managed advertising,
                contest prizes, marketing for Retailer events and expositions, or other pop up marketing collaborations with Retailer, at Company’s reasonable discretion. Retailer shall market and advertise to promote the sale of Company’s Services at
                Retailer’s stores. Retailer shall comply with Company’s branding guidelines communicated by Company to Retailer. 9.2 Company will use best efforts to drive customers to Retailer’s retail stores through its marketing campaigns. In furtherance
                of the foregoing, Company will mention Retailer list prominently on its website to those customers identified as living within areas in which Retailer is selling the Services. 10. Volume Rebate Funds: Company will accrue and award volume
                rebate funds (the “Volume Rebate”) to Retailer on an annual basis during the term of this Agreement as may be agreed from time to time in a Promotional Program. The Volume Rebates will be calculated by the company at its sole discretion
                and the retailer shall not have any dispute or claim over the rebate. 11. Franchise Introductions: Retailer may make introductions between Company and other proposed Retailer franchisees (the “Franchisees”). Any purchases made by such
                Franchisees shall be included in the Volume Rebate calculation for the benefit of Retailer; provided, however, that Company will not be obligated to supply to such Franchisees, and any such relationship would be subject to creditworthiness
                or other requirements in the sole discretion of Company and may include different payment terms. Company reserves the right not to do business with any Franchisee for any reason. 12. Warranty: 12.1 Company warrants to Retailer that each
                Service meets all applicable Service specifications. This warranty is in addition to any standard Service warranty offered by Company to its customers. 12.2 Subject to the above, warranty claims on any service sold by Retailer will be
                handled through Retailer’s customer service. Company will be available to Retailer to answer any questions and assist as reasonably necessary to comply with warranty and return obligations. 13. Brand Standards: 13.1 During the term of
                this Agreement, Retailer agrees to comply with any reasonable marketing or online advertisement requirements as well as any brand standards or other requirements or criteria relating to the display, marketing or sale techniques regarding
                the Services, in each case, established by Company and applicable to and followed by all authorized retailers of the Services, including Company. 13.2 Retailer will use the most current displays and point-of-sale materials provided or
                approved by Company, if required by applicable marketing standards. 14. Service Details and Trademark License: 14.1 For each Service, Company shall provide to Retailer a full description of the features and benefits of each such Service,
                a complete list of such Service’s specifications. Retailer may incorporate such descriptions and pictures on the website(s) in connection with each Service offered online and, as applicable, into any print advertisement. 14.2 Company may,
                from time to time, reasonably request changes or revisions to a website or any pages of a website that is controlled by Retailer and which references, depicts or describes the Services or Retailer’s relationship to Company, which requested
                changes or revisions shall be considered by Retailer in good faith and, unless unreasonable, incorporated promptly in the applicable website. 14.3 Company hereby grants to Retailer a worldwide, non-exclusive, royalty-free license to use
                such descriptions and pictures, and Company’s trademarks, trade names, images and other Company-provided promotional materials in connection with the sale and promotion of Services during the term of this Agreement. 14.4 Company will retain
                ownership of all intellectual property and Company branded marketing materials related to the Services, including, without limitation, the Services’ designs and all improvements to or modifications thereof, in each case developed through
                the parties’ collaboration. Company will have the right to make all decisions with respect to the registration of such a Service design with the Indian Intellectual Property Offices. Retailer agrees to assign, and hereby does assign, to
                Company all of Retailer’s rights in and to any improvements or modifications to the Services. 15. Training, Reporting Obligations: 15.1 Company through its Distributors shall provide Service training to Retailer’s sales associates from
                time to time, as reasonably requested by Retailer. 15.2 an employee of the distributor shall be a Retailer’s primary point of contact for the business relationship contemplated by this Agreement. Concerned Distributor shall respond to
                all written (e-mail transmissions acceptable) inquiries of Retailer within two business days of receipt. 15.3 It is understood that the retailer shall communicate with the Area distributor for the concerns and queries. 16. Limitation of
                Liability: Except for claims arising out of wilful misconduct, breach of Confidentiality, or third-party indemnification obligations, in no event will either party be liable to the other for any special, punitive, exemplary, reliance or
                consequential damages, however caused, whether for breach of contract, tort or otherwise, and whether or not advised of the possibility of such damages. 17. Indemnification: 17.1 Retailer shall indemnify, defend and hold harmless Company,
                its affiliates, successors and permitted assigns, and its and their officers, directors, shareholders, members, partners and employees (each, a “Company Indemnified Party”) from and against any and all claims, actions, proceedings, judgments
                and other liabilities and expenses (including reasonable attorneys’ fees and costs) of any nature arising out of or relating to the present Agreement. 18. Expenses: Except as otherwise expressly agreed herein, each party will each bear
                their own costs and expenses (including legal fees and expenses) incurred in connection with this Agreement and the transactions contemplated hereby. 19. Relationship of the Parties: Company and Retailer are independent contractors and
                neither shall represent itself as having any power to bind the other or to assume or to create any obligation or responsibility, express or implied, on behalf of the other party to this agreement. Nothing contained in this Agreement shall
                be deemed to establish a relationship of principal and agent between Company and Retailer, nor any of their agents or employees for any purpose whatsoever. This Agreement shall not be construed as constituting Company and Retailer as partners,
                or to create any other form of legal association or arrangement which would impose liability upon one party for the act or failure to act of any other party. 20. Representations and Warranties: Each party hereby represents and warrants
                to the other party as follows: 20.1 Such party is duly organized, validly exists and is in good standing under the laws of its jurisdiction of organization. 20.2 Such party is authorized to execute and perform this Agreement. 20.3 Such
                party has had the opportunity to retain independent counsel regarding its obligations and commitments hereunder. 20.4 The performance of this Agreement by such party will not conflict with or violate any material agreement, arrangement
                or commitment, whether written or oral, with any third party. 21. Confidentiality: 21.1 Each party expressly agrees to (i) retain in confidence all information transmitted to such party that the disclosing party has identified as being
                proprietary and/or confidential or which, by the nature of the circumstances surrounding the disclosure or the content of the disclosed information, ought in good faith to be treated as proprietary and/or confidential, and (ii) use such
                information and/or know-how solely for the purposes set forth in this Agreement. The receiving party’s obligation hereunder shall survive termination of this Agreement. The parties acknowledge that they may be exposed to confidential information
                of the other party that is unrelated to the matters set forth in this Agreement, and that such information will be subject to the same protections from disclosure and misuse hereunder. Notwithstanding the foregoing, if a receiving party
                is required to disclose any confidential information of the other party by any court, tribunal or other governmental or regulatory authority, the receiving party may disclose such confidential information, provided that the receiving party,
                to the extent legally permissible, provides reasonable prior notice to the disclosing party of any such requirements and provides reasonable assistance to the disclosing party in obtaining a protective order or similar protection for such
                information. 21.2 Confidential information subject to the obligations in the prior paragraph shall not include any information that: (i) is or becomes publicly available without the receiving party’s breach of any obligations owed the
                disclosing party; (ii) was known to the receiving party prior to the disclosing party’s disclosure of such information to the receiving party; (iii) became known to the receiving party from a source other than the disclosing party without
                breach of an obligation of confidentiality; or (iv) is independently developed by the receiving party, as demonstrated by the receiving parties’ records. 21.3 Neither party will make a public announcement, publicly disclose or discuss
                with third parties the terms and conditions of this Agreement. Nothing herein prevents either party from making public announcements or disclosing in general that they have a business relationship under which Services are jointly sold
                by Company and Retailer. 22. Notices: All notices and other communications under this Agreement must be delivered in writing at the modes and addresses given below: Retailer: Company: 23. Governing law and Jurisdiction: This Agreement
                shall be governed by the laws of India without giving effect to the conflicts of laws principles thereof. In case of any dispute between the parties, both the parties shall make efforts to resolve it through mediation and in case the mediation
                fails, the parties are free to take legal recourse. The courts of Pune, Maharashtra shall have the sole jurisdiction to decide and adjudicate any dispute relating to the present agreement. 24. Amendment and Assignment: Except as expressly
                set forth herein, this Agreement may be amended or modified only by written agreement signed by both parties. Neither party may assign this Agreement without the prior written consent of the other party, except that either party may assign
                this Agreement to an affiliate without the other party’s prior written consent. 25. Force Majeure: Neither party shall be held responsible for any failure of performance in the event such failure was due, in whole or in part, to federal,
                state or municipal action, statute, ordinance or regulation, strike or other employee trouble, fire or other damage to or destruction of, in whole or in part, the Services or the manufacturing facility for the Services, the lack of or
                inability to obtain raw materials, labor, fuel, electrical power, water or supplies, or any other cause, act of God, contingency or circumstance not subject to the reasonable control of that party, which causes delays or hinders the manufacture
                or delivery of the Services, sale of the Services or reconciliation of amounts owed on the sale of the Services. 26. Waiver: No waiver of any term or condition of this Agreement shall be effective or binding unless such waiver is in writing
                and is signed by the waiving party, nor shall this Agreement be changed, modified, discharged or terminated other than in accordance with its terms, in whole or in part, except by a writing signed by both parties. Waiver by any party of
                any term, provision or condition of this Agreement shall not be construed to be a waiver of any other term, provision or condition nor shall such waiver be deemed a subsequent waiver of the same term, provision or condition. 27. Severability:
                The provisions of this Agreement are fully severable and the invalidity or unenforceability of any provision of this Agreement shall in no way affect the validity or enforceability of any other provision hereof. IN WITNESS WHEREOF, the
                parties have duly executed this Agreement to be effective as of the Effective Date. (Company) (Retailer)



            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>