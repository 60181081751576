import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AppConstantService {


  public BASE_API: string = environment.apiBaseUrl;
  public BASE_API1: string = environment.apiBaseUrl1;
  public BASE_FILE_API: string = environment.apiFileUploadUrl;

  public sessionUserData: any = {};
  public isProfileCompleted: boolean = true;



  constructor() { }


  //ACCEPT ONLY NUMBER
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }



  //Toaster
  public successToast(message: any) {
    var x = document.getElementById("success-snackbar");
    x.innerHTML = '<i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + message;
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  public errorToast(message: any) {
    var x = document.getElementById("error-snackbar");
    x.innerHTML = '<i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;&nbsp;' + message;
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  public warningToast(message: any) { }




  /***
   * CHECK PROFILE VALIDATION
   */
  public checkProfileValidation() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.sessionUserData = this.sessionUserData.Data;
    console.log('sesstion data', this.sessionUserData);


    //CHECK PROFILE VALIDATION
    if (!this.sessionUserData.FullName) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.MobileNo) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.EmailId) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.StateId) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.CityId) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.TahsilsId) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Pincode) {
      this.isProfileCompleted = false;
    }

    //SHOP DETAIL
    if (!this.sessionUserData.ShopName) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.ShopCategory) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.ShopAddress1) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.ShopAddress2) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.ShopAreaLocality) {
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.ShopPostOffice) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.ShopState) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.ShopCity) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.ShopPincode) {
      this.isProfileCompleted = false;
    }

    //BANK DETAIL
    if (!this.sessionUserData.Bank_Name) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Account_Holder_Name) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Bank_Account_Number) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.IFSC_Code) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Account_Type) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Bank_Address) {
      this.isProfileCompleted = false;
    }


    //DOCUMENT
    if (!this.sessionUserData.Adhar_Number) {
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Pan_Number) {
      this.isProfileCompleted = false;
    }

    let profileValidationData = {
      isProfileCompleted: this.isProfileCompleted
    }
    sessionStorage.setItem('profileStatus', JSON.stringify(profileValidationData))
    return this.isProfileCompleted;
  }



  public APIS: any = {
    LOGIN: "/api/UserLogin",
    FORGOT_PASSWORD: "/api/Registration/ForgotPassword?MobileNumber=",
    NEW_REGISTRATION: "/api/Registration/NewRegistration",
    CHANGE_PASSWORD: "/api/Registration/ChangePasswoed?MobileNumber=",
    ONBOARD_VERIFY_OTP: "/api/Registration/VerifyOtp?MobileNumber=",
    RESEND_OTP: "/api/Registration/ResendOtp?MobileNumber=",
    UPDATE_PROFILE: "/api/Registration/UpdateProfile",
    SEND_REGISTRATION_OTP: "/api/Registration/SendOtp?MobileNumber=",

    UPLOAD_DOCUMENT: "/api/UploadFile",

    ADD_MONEY: "/api/Payment",
    ITR:"/api/v1/new/details ",
    ITR_DOCUMENTS: "/api/v1/new/documents",
    ITR_PAYMENT: "/api/v1/payment",
    GET_STATE: "/api/State",
    GET_WALLET_DATA: "/api/Ereturn/WalletDetails?RegistrationId=",
    ADD_PAYMENT_HISTORY: "/api/payment?regID=",
    BUY_COUPON: "/api/Ereturn/AddPanCard",
    UPDATE_SHOP_DETAIL: "/api/Ereturn/Updateshopedetails",
    ADD_PROFILE_DOCUMENT: "/api/Ereturn/AddDocuments",
    UPDATE_BANK_DETAIL: "/api/Ereturn/AddBankDetails",

    //RECHARGE
    SAVE_RECHARGE_DETAIL: "/api/Rechrage/UpdateStatus",
    INITIATE_RECHARGE_TRANSACTION: "/api/Rechrage/IntialTransaction",
    DO_RECHARGE: "/api/Rechrage/RechargeIntial",


    //MONEY TRANSFER
    ADD_SENDER: "/api/Beneficiary/AddSender",
    VERIFY_OTP: "/api/Beneficiary/VerifyOtp?MobileNumber=",
    ADD_BENEFICIARY: "/api/Beneficiary/AddBeneficaryAccount",
    BENEFICIARY_LIST: "/api/Beneficiary/BeneficaryList?SenderId=",
    TRANSFER_MONEY: "/api/Beneficiary/OrderMoneyTransfer",
    INITIAL_TRANSACTION: "/api/Beneficiary/IntialTransaction",
    UPDATE_STATUS: "/api/Beneficiary/UpdateStatus",
    SEARCH_SENDER: "/api/Beneficiary/Search?MobileNumber=",
    TRANSACTION_HISTORY: "/api/Ereturn/MoneyTransferHistory?RegistrationId=",
    UPDATE_TRADE_WALLET: "/api/Ereturn/DeductionAmounts?RegistrationId=",
    SUBMIT_TRANSFER_AMOUNT: "/api/Beneficiary/UpdateStatus",
    MONEY_TRANSFER: "/api/Beneficiary/MoneyTransferIntial",
    DELETE_BENEFICIAY: "/api/Beneficiary/DeleteBeneficiary",

    //UPI
    AUTOUPDATE_TRADE_WALLET: "/api/Ereturn/AddAmountWalletDetails",
    ADD_UPI_REQUEST: "/api/Ereturn/AddUPIRequest",

    //COMPLAINT
    ADD_COMPLAINT: "/api/Ereturn/AddComplaintRegister",

    GET_COMPLAINT: "/api/Ereturn/GetComplaintRegister?",

    GET_PAN_CARD_REPORT: "/api/Ereturn/GetpanlistByDate?",

    //REPORT
    MOBILE_RECHARGE_REPORT: "/api/Rechrage?regId=",
    RECHARGE_HISTORY: "/api/Ereturn/RechargeHistory?",
    EARNING_REPORT: "/api/Ereturn/YourEarning?RegistrationId=",

    //AEPS
    SEND_REQUEST: "/api/Ereturn/Aepsstatus?RegistrationId=",

    //WALLET TRANSFER
    INTERNAL_WALLET_TRANSFER: "/api/Ereturn/InternalWalletTransfer",


    //PAN REQUEST
    SERVICE_PAN_REQUEST: "/api/Ereturn/PanRequest?RegistrationID=",
    GET_PAN_COUPNE_PRICE: "/api/Ereturn/GetPriceOfCoupon",
    GET_PAN_AGENT_ID: "/api/Ereturn/GetRetailerPanAgentId?RegistrationId=",


    //DISTRIBUTORS
    GET_ALL_DISTRIBUTORS: "/api/Ereturn/GetAllDistributor",

    GET_ALL_CHARGES: "/api/Ereturn/GetPayoutChargesByAmount",

    // get all bank
    GET_ALL_BANKNAME: "/api/BankDetails/GetBank",

    // upload photo
    UPLOAD_RETPHOTO:"/api/PancardUtility/",

    // merge photo
    Merge_PDF:"/api/PancardUtility/MergePDF"
  }

}
