import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import { Router,ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { ExternalAPIService } from '../../../service/external-api.service';
import { DatePipe } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-procced-dth-recharge',
  templateUrl: './procced-dth-recharge.component.html',
  styleUrls: ['./procced-dth-recharge.component.scss']
})
export class ProccedDthRechargeComponent implements OnInit {

  public formData:any = {};
  public apiResponse:any;
  public apiResponse1: any;
  public isButtonDisabled:boolean = false;
  public sessionUserData:any = {};
  public walletData:any = {};


  public Provider:any;
  public uid:any;
  public billerCode: any;
  public rechargePlan:any  = {};


  //Plans
  public planMobileType:any;
  public isLoading:boolean = false;
  public selectedPlanList:any = [];
  public selectedIndex:any;
  public selectedPlan:any = {};
  public isShowPlanList:boolean = false;
  public tab:any;
  public plans = ["dth-plan"];
  // public plans = ["channel-plan", "dth-plan"];
  // public channelPlans = {};
  public dthPlans = {};
  public respMsg: any = "";
  public responseMsg: any = "";

  constructor(
    public dataService:DataServiceService,
    public router:ActivatedRoute,
    public _location:Location,
    public externalAPIService:ExternalAPIService,
    private datePipe: DatePipe
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.planMobileType = "Plan"
    this.router.queryParams.subscribe(params => {
      if (params.Provider) {
        this.Provider = params.Provider;
      }
      if (params.uid) {
        this.uid = params.uid;
      }
      if(params.billerCode){
        this.billerCode = params.billerCode
      }
    })

    this.dataService.getMessage().subscribe((msg) => {
      if(msg == "walletUpdated"){
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
      }    
  })


   }

  ngOnInit(): void {
    if(this.sessionUserData.RegistrationId){
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
   }
   }

  back(){
    this._location.back();
  }




  /**
   * Following api are use to recharge this api made recharge by p2all from backen side
   * 
   * 
   */


   submit(appForm:NgForm){
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if(this.walletData.MainWallet > this.formData.amount ){
     this.isButtonDisabled = true;

     let request = {
      "RegistrationId": this.sessionUserData.RegistrationId,
      "CustomerMobile": this.formData.mobile,
      "OperatorCode": parseInt(this.uid),
      "OperatorName": this.Provider,
      "Date": this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      "RechargeAmount": parseInt(this.formData.amount),
      "CommissionAmt": parseFloat("1"),
      "BillAmount": parseInt(this.formData.amount),
      "PaymentType": "Main Wallet",
      "RechargeType": "Dth",
      "OrderType": "DTH Recharge"
    }
     this.dataService.post(request, this.dataService.appConstant.APIS['DO_RECHARGE']).subscribe(response => {
      this.apiResponse = response;
      if(this.apiResponse.Status){
        this.tab = 'successMessage';
        this.isButtonDisabled = false;
      }else{
        this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
      }
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);

     },err=>{
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
     })
    }else{
      alert("You have insufficient balance in MAIN WALLET, Please add money in to wallet MAIN WALLET.")
    }
  }




  /**
   * Following api we was using when we are doing recharge from front end side
   * 
   * 
   */

 

  submit1(appForm:NgForm){
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if(this.walletData.MainWallet > this.formData.amount ){
     this.isButtonDisabled = true;
     this.formData.providerId =  this.uid;



     this.formData.RegistrationId = this.sessionUserData.RegistrationId;
     this.formData.OrderType = "DTH Recharge";
     this.formData.CustomerMobile = this.formData.mobile;
     this.formData.OperatorCode = this.formData.providerId;
     this.formData.OperatorName = this.Provider;
     this.formData.Date = new Date();
     this.formData.RechargeAmount = this.formData.amount;
     this.formData.CommissionAmt = 0.0;
     this.formData.BillAmount = 0.0;
     this.formData.CloseBalance = 0.0;
     this.formData.OpenBalance = null;
     this.formData.PaymentType ='Main Wallet';
     this.formData.TransactionId = this.formData.reports_id;
     this.formData.RechargeType = "Dth";
     this.dataService.post(this.formData, this.dataService.appConstant.APIS['INITIATE_RECHARGE_TRANSACTION']).subscribe(response => {
      this.formData.Client_Id = response.ClientId;
      sessionStorage.setItem('setheadType', 'Auth');
      this.externalAPIService.doRecharge(this.formData).subscribe(response => {
        this.apiResponse = response
        this.isButtonDisabled = false;
  
        this.formData.report_Id =  this.apiResponse.report_id;
        this.formData.Utr =  this.apiResponse.utr;
        this.formData.OrderId = this.apiResponse.OrderId;
        this.formData.Message =  this.apiResponse.message;
        this.formData.status = this.apiResponse.status;
        this.formData.status_id = this.apiResponse.status_id;
        if(this.apiResponse['status'] == 0){
          this.tab = 'successMessage';
        }
        if(this.apiResponse['status'] == 2){
          this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
        }
        this.saveRechargeData();
      },err=>{
        this.dataService.appConstant.errorToast("Server error....Please try again");
      })
     })
    }else{
      alert("You have insufficient balance in MAIN WALLET, Please add money in to wallet MAIN WALLET.")
    }
  }



  /***
   * SAVE RECHARGE REPONSE
   */

  saveRechargeData(){
     this.dataService.post(this.formData, this.dataService.appConstant.APIS['SAVE_RECHARGE_DETAIL']).subscribe(response => {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
     })
  }


  showPlans(plan){
      this.planMobileType = plan
      this.selectedPlanList = this.rechargePlan.data[plan];
      this.selectedPlanList.forEach(element => {
        if(element.rs['1 MONTHS']){
          element.plan = "1 MONTHS";
          element.amount = element.rs['1 MONTHS']
        }else if(element.rs['6 MONTHS']){
          element.plan =  "6 MONTHS";
          element.amount = element.rs['6 MONTHS']
        }else{
          element.plan = "1 YEAR";
          element.amount = element.rs['1 YEAR']
        }
      });
  }

  scrollToPlan(){
    document.getElementById('rechargePlans').scrollTop =  document.getElementById('rechargePlans').scrollHeight
  }

  


  getDthPlan(){
     this.isLoading = true;
     console.log(this.isShowPlanList);
     if (this.isShowPlanList) {
      $('#dthPlanModal').modal('show')
      return;
     }
     this.formData.providerId = this.uid;
     this.externalAPIService.refreshPay2allToken(() => {
        this.externalAPIService.getDTHPlan(this.formData).subscribe(response => {
        this.apiResponse = response
        if (this.uid == 17) {
          this.respMsg = this.apiResponse.message
          this.respMsg = this.respMsg.substr(this.respMsg.indexOf("{"), this.respMsg.lastIndexOf("}"));
          this.respMsg = this.respMsg.substr(0, this.respMsg.lastIndexOf("}"));
          console.log(this.respMsg);
          this.dthPlans = JSON.parse(this.respMsg + "}");
          this.dthPlans = this.dthPlans["data"]["Plan"];
        } else {
          this.dthPlans = this.apiResponse.data.Plan;
        }
        this.rechargePlan = this.dthPlans;
        $('#dthPlanModal').modal('show')

        this.isShowPlanList = true;
        this.isLoading = false;
      },err=>{
        this.isLoading = false;
        this.dataService.appConstant.errorToast("Server error....Please try again");
      })
     });
    // this.externalAPIService.getDTHChannelPlan(this.formData).subscribe(response => {
    //   this.apiResponse1 = response
    //   this.responseMsg = this.apiResponse1.message
    //   this.responseMsg = this.responseMsg.substr(this.responseMsg.indexOf("{"), this.responseMsg.lastIndexOf("}"));
    //   this.responseMsg = this.responseMsg.substr(0, this.responseMsg.lastIndexOf("}"));
    //   console.log(this.responseMsg);
    //   this.channelPlans = JSON.parse(this.responseMsg + "}");
    //   this.channelPlans = this.channelPlans["data"]["Plan"];
    //   console.log(this.channelPlans);
      

    //   this.isShowPlanList = true;
    //   this.isLoading = false;
    // },err=>{
    //   this.isLoading = false;
    //   this.dataService.appConstant.errorToast("Server error....Please try again");
    // })
  }

  // showPlan(plan) {
  //   this.rechargePlan = plan === "channel-plan" ? this.channelPlans : this.dthPlans;
  // }

  closePlanModal(){
    $('#dthPlanModal').modal('hide')
  }

  // selectPrice(i){
  //   let planKey = this.selectedPlanList[i].plan;
  //   this.selectedPlanList[i].amount = this.selectedPlanList[i].rs[planKey];
  // }

  // selectPlan(i){
  //   this.selectedPlan = this.selectedPlanList[i];
  //   this.selectedIndex = i;
  //   this.formData.amount = this.selectedPlan.amount
  // }
  selectPlan(rs){
    console.log(rs);
    this.formData.amount = rs;
    $('#dthPlanModal').modal('hide');
  }
  
  changeNo(){
    this.selectedPlan = {}
    this.isShowPlanList =false;
  }

}
