import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstantService } from '../service/app-constant.service';

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  private subject = new Subject<any>();
  constructor(
    public appConstant: AppConstantService,
    public http: HttpClient
  ) { }

  get(url: string): Observable<any> {
    return this.http.get<any>(this.appConstant.BASE_API + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  post(data: any, url: string): Observable<any> {
    return this.http.post<any>(this.appConstant.BASE_API + url, data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
  post1(data: any, url: string): Observable<any> {
    return this.http.post<any>(this.appConstant.BASE_API1 + url, JSON.stringify(data), {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
  post_file(formdata: FormData, id: string): Observable<any> {
    return this.http.post<any>(this.appConstant.BASE_FILE_API + id, formdata);
  }

  put1(data: any, url: string): Observable<any> {
    return this.http.put<any>(this.appConstant.BASE_API1 + url, JSON.stringify(data), {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  put(data: any, url: string): Observable<any> {
    return this.http.put<any>(this.appConstant.BASE_API + url, data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(this.appConstant.BASE_API + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }


  postFile(
    registrationId,
    file: File,
    type: any,
    url: string
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('photo', file);
    formData.append('type', type);
    formData.append('RegistrationId', registrationId);
    return this.http.post<any>(this.appConstant.BASE_API + url, formData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public sendFormData(formData) {
    return this.http.post<any>(
      // 'http://ereturnapi.gtpdemo.co.in/api/UploadFile',
      'https://webapi.myereturn.com/api/UploadFile',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getWalletData(RegistrationId) {
    sessionStorage.setItem('setheadType', null);
    this.get(
      this.appConstant.APIS['GET_WALLET_DATA'] + RegistrationId
    ).subscribe((response) => {
      let apiResponse = response;
      console.warn(response);
      if (apiResponse.status) {
        sessionStorage.setItem('walletData', JSON.stringify(apiResponse));
        this.sendMessage('walletUpdated');
      }
    });
  }

  sendMessage(message) {
    this.subject.next(message);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
