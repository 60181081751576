<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">DTH Recharge</span>
                </div>
            </div>
        </div>

        <!-----Prepaid Mobile ---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>DTH Recharge</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="proceedRecharge('Airtel DTH',17, 'ATV')">
                        <img src="assets/images/airtel.png" class="panServicesIcon">
                        <p class="tCenter">Airtel DTH</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="proceedRecharge('Dish TV DTH',12, 'DTV')">
                        <img src="assets/images/dishtv.png" class="panServicesIcon">
                        <p class="tCenter">Dish TV DTH</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="proceedRecharge('Sun TV DTH',14, 'STV')">
                        <img src="assets/images/sundirect.png" class="panServicesIcon">
                        <p class="tCenter">Sun TV DTH</p>
                    </div>
                </div>
        
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="proceedRecharge('Tata Sky DTH',13, 'TTV')">
                        <img src="assets/images/tatasky.png" class="panServicesIcon">
                        <p class="tCenter">Tata Sky DTH</p>
                    </div>
                </div>

                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2 ">
                    <div class="tCenter cp" (click)="proceedRecharge('Videocon DTH',15, 'VTV')">
                        <img src="assets/images/videocon.png" class="panServicesIcon">
                        <p class="tCenter">Videocon DTH</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>