import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-money-transfer-report',
  templateUrl: './money-transfer-report.component.html',
  styleUrls: ['./money-transfer-report.component.scss']
})
export class MoneyTransferReportComponent implements OnInit {

  fileName = 'MoneyTransferReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public reportFilter:any = {numPerPage:10,pageNum:1};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public sessionUserData:any = {};

  public reportList:any = [];
  public isLoading:boolean = false;
  public isShowFilter:boolean = true;
  public formData:any = {};
  public isButtonDisabledPop :boolean = false;
  public singleTransaction:any = {};
  constructor(
    public dataService:DataServiceService,
    public router:Router,
    private datePipe: DatePipe
  ) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    if (window.innerWidth < 768) {
      this.isShowFilter = false;
    }
    this.dataService.sendMessage('pageChange');
    this.dataService.sendMessage('showSideBar');
    var firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    // this.reportFilter.startDate =  this.datePipe.transform(firstDay, 'yyyy-MM-dd');
    // this.reportFilter.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
   this.getTransactionReport();
  }


  back(){
    this.router.navigate(['/Money_Transfer'], { replaceUrl: true });
  }

  submit(){
    if(this.reportFilter.startDate <= this.reportFilter.toDate ){
     this.isButtonDisabled  = true;
     this.reportList = [];
     this.isLoading = true;
     this.getTransactionReport();
    }else{
      this.dataService.appConstant.errorToast("End date must be greater than start date")
    }
   }

   clear(){
    this.reportFilter.startDate = null;
    this.reportFilter.toDate = null;
    this.reportFilter.fromDate= null;
    this.reportFilter.endDate= null;
    this.reportFilter.searchKey= null;
    this.getTransactionReport();
   }
   
  openComplaintForm(item){
    this.formData = item;
    this.formData.ComplaintRegisterProduct = "Money Transfer";
     $('#ComplaintForm').modal('show');
  }


  submitCompltain(appForm:NgForm){
    this.isButtonDisabledPop = true;
    this.formData.TransactionId = this.formData.OrderTransferId;
    this.formData.ComplaintType = "Money Transfer";
    var firstDay = new Date();
    this.formData.CreatedDate =  this.datePipe.transform(firstDay, 'yyyy-MM-dd')
    this.formData.Message = `Transaction of Amount: ${this.formData.Amount}, Message: ${this.formData.Message}`;
    this.dataService.post(this.formData,this.dataService.appConstant.APIS['ADD_COMPLAINT']).subscribe(response => {
      this.isButtonDisabledPop = false;
      this.dataService.appConstant.successToast("Complaint submitted successfully")
      appForm.onReset();
      $('#ComplaintForm').modal('hide');
      this.getTransactionReport();
    },err=>{
      this.isButtonDisabledPop = false;
    })
  }

 

  //REPORT
  getTransactionReport(){
    this.isLoading = true;
    if(this.reportFilter.startDate){
      this.reportFilter.fromDate = this.reportFilter.startDate +"T23:39:14"
    }

    if(this.reportFilter.toDate){
      this.reportFilter.endDate = this.reportFilter.toDate +"T23:39:14"
    }


    this.dataService.get(this.dataService.appConstant.APIS['TRANSACTION_HISTORY']+this.sessionUserData.Data.RegistrationId+"&Fromdate="+this.reportFilter.fromDate+"&Todate="+this.reportFilter.endDate+"&PageNo=1").subscribe(result => {
      this.apiResponse = result;
      this.reportList = this.apiResponse;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.appConstant.errorToast('Something is wrong..please try again');
    });
  }


  
  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  
  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }



  printToCart(item) {
    this.singleTransaction =item;
    $('#printReceiptModal').modal('show');
    setTimeout(() => {
      var innerContents = document.getElementById('printSectionId').innerHTML;
      var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="resources/dist/css/AdminLTE.min.css" /><script>function printData(){setTimeout(function(){window.print();},500);}</script></head><body onload="printData();">' + innerContents + '</html>');
      popupWinindow.document.close();
    }, 1000);
  }



}
