<section>
    <div class="container sectionContainer">

        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10  col-lx-10 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span>Pan Card Services / </span>
                    <span class="activeBreadCum"> Buy Coupon </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2 ">
                <button (click)="back()" class="btn btn-warning submitBtn">
          <span class="button__text">Back</span>
        </button>
            </div>
        </div>

        <div class="CouponCard">
            <!-----Section Heading---->
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>Buy Coupon</h5>
                    </div>
                </div>
            </div>

            <!-----Section Container---->
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Buy Coupon </label>
                                <input type="text" class="form-control" placeholder="Enter coupon quantity" autocomplete="off" [(ngModel)]="formData.NoOfQty" (keypress)="dataService.appConstant.keyPress($event)" name="quantity" #quantity="ngModel" required (ngModelChange)="calculateTotal()">
                                <span class="text-danger" *ngIf="(quantity.touched || appForm.submitted) && quantity.errors?.required">
                  Coupon quantity is required
                </span>
                            </div>
                        </div>


                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Price of Coupon </label>

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" (click)="selectCoupon(physical.Retailer)">
                                        <div class="flex100 couponCodeSection" [ngClass]="{'couponCodeSectionActive':couponPrice == physical.Retailer}">
                                            <div class="cuponPrice" [ngClass]="{'cuponPriceActive':couponPrice == physical.Retailer}">
                                                ₹{{physical.Retailer}}</div>
                                            <div class="cuponPriceDescription">With Physical Laminated Pan Card</div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" (click)="selectCoupon(without_physical.Retailer)">
                                        <div class="flex100 couponCodeSection" [ngClass]="{'couponCodeSectionActive':couponPrice == without_physical.Retailer}">
                                            <div class="cuponPrice" [ngClass]="{'cuponPriceActive':couponPrice == without_physical.Retailer}">
                                                ₹{{without_physical.Retailer}}</div>
                                            <div class="cuponPriceDescription">Without Physical Laminated Pan Card</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h5>Total : <b>₹{{formData.TotalAmount}}</b></h5>
                        <br>
                        <div class="buttonGroup">
                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled || !appForm.form.valid || !formData.NoOfQty || formData.NoOfQty <= 0 " [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>