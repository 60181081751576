
    <div class="container sectionContainer">
  
      <!-----Bread-cum---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="breadCum">
            <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
            <span class="activeBreadCum" (click)="documentTab = 'table'">Income Tax Service </span>
          </div>
        </div>
      </div>
      
      <!-----remove this whole div after implementing this section---->
      <div class="row" *ngIf="documentTab=='table'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div id="reportTable" class="tableMv">
            <table id="htmlData">
              <thead>
                <tr>
                  <th class="brdWhiteR brdTLR">#</th>
                  <th class="brdWhiteR">Type</th>
                  <th class="brdWhiteR">Amount</th>
                  <th class="brdWhiteR">Commission</th>
                  <th class="brdWhiteR">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                class="bodyTr">
                <td>1</td>
                <td>ITR 1-Note : If Your Turnover Upto 20 lacs Then You Must Have GSTNo.</td>
                <td>{{ITR1.amount}}</td>
                <td>{{ITR1.comm}}</td>
                <td class="text-center align-middle">
                  <div class="btn-group">
                      <button class="btn btn-warning submitBtn tableFilterBtn" (click)="changeTab('details1', 'ITR1')">Apply</button>
                  </div>                
              </td>
              </tr>
              <tr
                class="bodyTr">
                <td>2</td>
                <td>ITR 2-Note : If Your Turnover Upto 20 lacs Then You Must Have GSTNo.</td>
                <td>{{ITR2.amount}}</td>
                <td>{{ITR2.comm}}</td>
                <td class="text-center align-middle">
                  <div class="btn-group" >
                      <button class="btn btn-warning submitBtn tableFilterBtn" (click)="changeTab('details2', 'ITR2')">Apply</button>
                  </div>                
              </td>
              </tr>
              <tr
                class="bodyTr">
                <td>3</td>
                <td>ITR 3-Note : If Your Turnover Upto 20 lacs Then You Must Have GSTNo.</td>
                <td>{{ITR3.amount}}</td>
                <td>{{ITR3.comm}}</td>
                <td class="text-center align-middle">
                  <div class="btn-group">
                      <button class="btn btn-warning submitBtn tableFilterBtn" (click)="changeTab('details3', 'ITR3')">Apply</button>
                  </div>                
              </td>
              </tr>
              <tr
                class="bodyTr">
                <td>4</td>
                <td>ITR 4-Note : If Your Turnover Upto 20 lacs Then You Must Have GSTNo.</td>
                <td>{{ITR4.amount}}</td>
                <td>{{ITR4.comm}}</td>
                <td class="text-center align-middle">
                  <div class="btn-group">
                      <button class="btn btn-warning submitBtn tableFilterBtn" (click)="changeTab('details4', 'ITR4')"> Apply</button>
                  </div>
              </td>
            </tbody>
          </table>
        </div>

      </div>
    </div>
     <!-- <button (click)="changeTab('happy')">hello</button> -->
      <!-----Section Heading---->
      <div >
      <div class="row" *ngIf="documentTab=='details1' || documentTab=='details2' || documentTab=='details3' || documentTab=='details4'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" >
          <div class="sectionHeading" >
            <h5>Details</h5>
          </div>
        </div>
     
  
      <!-----Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
                <div class="row">
                  <div class="sub-entry">
                  <section>
                     <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Return Type <span class="astric">*</span></label>
                    <!-- <select class="form-control" [(ngModel)]="formData.ShopCity" name="ShopCity" #ShopCity="ngModel" required
                    (change)="setTahsils()">
                      <option value="">Select Return Type</option>
                      <option *ngFor="let c of cityList" value="{{c.id}}"> {{c.name}} </option>
                    </select> -->
                    <select
                    class="form-control"
                    [(ngModel)]="formData.itr_return_type"
                    name="itr_return_type"
                    #itr_return_type="ngModel"
                    required
                  >
                    <option value="">Select Return Type</option>
                    <option value="Revised">Revised</option>
                    <option value="Original">Original</option>
                  </select>
                    <span class="text-danger" *ngIf="(itr_return_type.touched || appForm.submitted) && itr_return_type.errors?.required">
                        Return Type is required
                    </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Ass. Year<span class="astric">*</span></label>
                    <select class="form-control" [(ngModel)]="formData.asst_year"
                      name="asst_year" #asst_year="ngModel" required>
                      <option value="">Select Asst. year</option>
                      <option value="2020-21">2020-21</option>
                      <option value="2021-22">2021-22</option>
                      <option value="2022-23">2022-23</option>
                      <option value="2023-24">2023-24</option>
                    </select>
                    <span class="text-danger" *ngIf="(asst_year.touched || appForm.submitted) && asst_year.errors?.required">
                        Ass. Year  is required
                    </span>
                  </div>
                </section>


                   

                  
                 
               <section class=”form-horizontal”>
                  <p style="margin-left: 1em;">As per pan-card</p>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>First Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter First Name" [(ngModel)]="formData.first_name"
                      name="first_name" #first_name="ngModel" required>
                    <span class="text-danger" *ngIf="(first_name.touched || appForm.submitted) && first_name.errors?.required">
                        First Name is required
                    </span>
                  </div>

                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Father Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Father  Name" [(ngModel)]="formData.fathers_name"
                      name="fathers_name" #fathers_name="ngModel" required>
                    <span class="text-danger" *ngIf="(fathers_name.touched || appForm.submitted) && fathers_name.errors?.required">
                        Father Name is required
                    </span>
                  </div>


                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Last  Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Last  Name" [(ngModel)]="formData.surname"
                      name="surname" #surname="ngModel" required>
                    <span class="text-danger" *ngIf="(surname.touched || appForm.submitted) && surname.errors?.required">
                        Last Name is required
                    </span>
                  </div>
                </section>


                  <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Father Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Father  Name" [(ngModel)]="formData.FatherName"
                      name="FatherName" #FatherName="ngModel" required>
                    <span class="text-danger" *ngIf="(FatherName.touched || appForm.submitted) && FatherName.errors?.required">
                        Father Name is required
                    </span>
                  </div> -->
                  <section>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Date of Birth <span class="astric">*</span> </label>
                    <input type="date" class="form-control" placeholder="YYYY-MM-DD" [(ngModel)]="formData.date_of_birth"
                      name="date_of_birth" #date_of_birth="ngModel" max="{{maxBirthDate | date:'yyyy-MM-dd'}}" required>
                    <span class="text-danger" *ngIf="(date_of_birth.touched || appForm.submitted) && date_of_birth.errors?.required">
                        Date of Birth is required
                    </span>
                    <span class="text-danger" *ngIf="date_of_birth.errors?.max">
                      should be 18+
                  </span>
                  </div>
                  </section>
                  <section>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Pan Number <span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Pan Number" [(ngModel)]="formData.pan_number"
                      name="pan_number" #pan_number="ngModel" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" required>
                    <span class="text-danger" *ngIf="(pan_number.touched || appForm.submitted) && pan_number.errors?.required">
                        Pan Number is required
                    </span>
                    <span class="text-danger" *ngIf="pan_number.errors?.pattern">
                      Pan Number Should be in AYFGH1254H format
                    </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>ITR password <span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter ITR password" [(ngModel)]="formData.itr_password"
                      name="itr_password" #itr_password="ngModel" required>
                    <!-- <span class="text-danger" *ngIf="(itrpass.touched || appForm.submitted) && itrpass.errors?.required">
                      ITR password is required
                    </span> -->
                  </div>
                  

                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Adhar  Number <span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Adhar  Number" [(ngModel)]="formData.aadhar_number"
                      name="aadhar_number" #aadhar_number="ngModel" (keypress)="onlyNumberKey($event)" maxlength="12" minlength="12" required>
                    <span class="text-danger" *ngIf="(aadhar_number.touched || appForm.submitted) && aadhar_number.errors?.required">
                        Adhar Number is required
                    </span>
                    <span class="text-danger" *ngIf="aadhar_number.errors?.maxlength || aadhar_number.errors?.minlength">
                      Adhar Number should be 12 digits long
                  </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <div class="sectionHeading">
                      <h5>Income</h5>
                    </div>
                    <input *ngIf="documentTab === 'details1' || documentTab === 'details2'" type="text" class="form-control" placeholder="Enter Income" [(ngModel)]="formData.income"
                      name="incomes" #incomes="ngModel" required>
                  </div>
                  <div *ngIf="documentTab === 'details1' || documentTab === 'details2'" class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Employee Company Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Company" [(ngModel)]="formData.company"
                      name="company" #company="ngModel" required>
                      <span class="text-danger" *ngIf="(company.touched || appForm.submitted) && company.errors?.required">
                        Company Name is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details2'">
                    <label>Agriculture Income<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter agree income" [(ngModel)]="formData.agree_income"
                      name="agree_income" #agree_income="ngModel" required>
                      <span class="text-danger" *ngIf="(agree_income.touched || appForm.submitted) && agree_income.errors?.required">
                        Agriculture Income is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details2'">
                    <label>Agriculture Area<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter agree area" [(ngModel)]="formData.agree_area"
                      name="agree_area" #agree_area="ngModel" required>
                      <span class="text-danger" *ngIf="(agree_area.touched || appForm.submitted) && agree_area.errors?.required">
                        Agriculture Area is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details2'">
                    <label>Agriculture Address<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter Agree address" [(ngModel)]="formData.agree_address"
                      name="agree_address" #agree_address="ngModel" required>
                      <span class="text-danger" *ngIf="(agree_address.touched || appForm.submitted) && agree_address.errors?.required">
                        Agriculture Address is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details3' || documentTab=='details4'">
                    <label>Business Income<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter business income" [(ngModel)]="formData.business_income"
                      name="business_income" #business_income="ngModel" required>
                      <span class="text-danger" *ngIf="(business_income.touched || appForm.submitted) && business_income.errors?.required">
                        business income is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details3' || documentTab=='details4'">
                    <label>Business Name<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter business name" [(ngModel)]="formData.business_name"
                    name="business_name" #business_name="ngModel" required>
                    <span class="text-danger" *ngIf="(business_name.touched || appForm.submitted) && business_name.errors?.required">
                      Business Name is required
                    </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details3' || documentTab=='details4'">
                    <label>Business Type<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter business type" [(ngModel)]="formData.business_type"
                    name="business_type" #business_type="ngModel" required>
                    <span class="text-danger" *ngIf="(business_type.touched || appForm.submitted) && business_type.errors?.required">
                      Company Name is required
                    </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details2' || documentTab=='details3' || documentTab=='details4'">
                    <label>Other Income<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter other income" [(ngModel)]="formData.other_income"
                      name="other_income" #other_income="ngModel" required>
                      <span class="text-danger" *ngIf="(other_income.touched || appForm.submitted) && other_income.errors?.required">
                        Other Income is required
                      </span>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab=='details2' || documentTab=='details3' || documentTab=='details4'">
                    <label>Source of Income<span class="astric">*</span> </label>
                    <input type="text" class="form-control" placeholder="Enter source of income" [(ngModel)]="formData.source_of_income"
                      name="source_of_income" #source_of_income="ngModel" required>
                      <span class="text-danger" *ngIf="(source_of_income.touched || appForm.submitted) && source_of_income.errors?.required">
                        Source of Income is required
                      </span>
                  </div>
                </section>
                  </div>
                  <div class="sub-entry">
                    <section>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <div class="sectionHeading">
                      <h5>Personal Details</h5>
                    </div>
                  </div>
                </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label>Gender <span class="astric">*</span> </label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" (click)="isMale()" name="inlineRadioOptions" id="inlineRadio1" value="male" required>
                            <label class="form-check-label" for="inlineRadio1">Male</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" (click)="isFemale()" name="inlineRadioOptions" id="inlineRadio2" value="Female" required>
                            <label class="form-check-label" for="inlineRadio2">Female</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" (click)="isOther()" name="inlineRadioOptions" id="inlineRadio2" value="Other" required>
                            <label class="form-check-label" for="inlineRadio2">Other</label>
                          </div>
                          <span class="text-danger" *ngIf="(!formData.gender && appForm.submitted)">
                            gender is required
                          </span>
                      </div>
                      <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"> -->
                        <label>Naitionality<span class="astric">*</span> </label>
                        <input type="text" class="form-control" placeholder="Enter Naitionality" [(ngModel)]="formData.nationality"
                          name="nationality" #nationality="ngModel" required>
                        <span class="text-danger" *ngIf="(nationality.touched || appForm.submitted) && nationality.errors?.required">
                          Naitionality is required
                        </span>
                      <!-- </div> -->
                      <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"> -->
                        <label>Contact<span class="astric">*</span> </label>
                        <input type="text" class="form-control" placeholder="Enter Contact" [(ngModel)]="formData.contact"
                          name="contact" #contact="ngModel" (keypress)="onlyNumberKey($event)" maxlength="10" minlength="10" required >
                        <span class="text-danger" *ngIf="(contact.touched || appForm.submitted) && contact.errors?.required">
                            Contact is required
                        </span>
                        <span class="text-danger" *ngIf="contact.errors?.minlength || contact.errors?.maxlength">
                          Contact should be 10 digit long
                      </span>
                      <!-- </div> -->
                      <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"> -->
                        <label>E-mail ID<span class="astric">*</span> </label>
                        <input type="email" class="form-control" placeholder="Enter E-mail ID" [(ngModel)]="formData.email"
                          name="email" #email="ngModel" required>
                        <span class="text-danger" *ngIf="(email.touched || appForm.submitted) && email.errors?.required">
                            Email ID is required
                        </span>
                      <!-- </div> -->
                      <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"> -->
                        <label>Whatsapp No. <span class="astric">*</span> </label>
                        <input type="text" class="form-control" placeholder="Enter Whatsapp No." [(ngModel)]="formData.whatsapp_no"
                          name="whatsapp_no" #whatsapp_no="ngModel" (keypress)="onlyNumberKey($event)" maxlength="10" minlength="10" required>
                        <span class="text-danger" *ngIf="(whatsapp_no.touched || appForm.submitted) && whatsapp_no.errors?.required">
                          Whatsapp No. is required
                        </span>
                        <span class="text-danger" *ngIf="whatsapp_no.errors?.maxlength || whatsapp_no.errors?.minlength">
                          whatsapp_no should be 10 digit long
                        </span>
                      <!-- </div> -->

                    </div>
                  </section>
                
                <br> 
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <div class="sectionHeading">
                      <h5>Bank Details</h5>
                    </div>
                  </div>
                </div>
                <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Bank Name <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Bank Name"
                  [(ngModel)]="formData.name_of_bank"
                  name="name_of_bank"
                  #name_of_bank="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (name_of_bank.touched || appForm.submitted) &&
                    name_of_bank.errors?.required
                  "
                >
                  Bank Name is required
                </span>
              </div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Branch Address <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Branch Address"
                  [(ngModel)]="formData.branch_address"
                  name="branch_address"
                  #branch_address="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (branch_address.touched || appForm.submitted) &&
                    branch_address.errors?.required
                  "
                >
                  Bank Address is required
                </span>
              </div>
              <div
                    class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
                  >
                    <label>Account Number <span class="astric">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Account Number"
                      [(ngModel)]="formData.bank_account_number"
                      name="bank_account_number"
                      #bank_account_number="ngModel"
                      required
                      (keypress)="dataService.appConstant.keyPress($event)"
                    />
                    <span
                      class="text-danger"
                      *ngIf="
                        (bank_account_number.touched || appForm.submitted) &&
                        bank_account_number.errors?.required
                      "
                    >
                      Account Number is required
                    </span>
                  </div>
                  <div
                    class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
                  >
                    <label>IFSC Code Number<span class="astric">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter IFSC Code Number"
                      [(ngModel)]="formData.ifsc_code"
                      name="ifsc_code"
                      #ifsc_code="ngModel"
                      required
                      minlength="11"
                      maxlength="11"
                    />
                    <span
                      class="text-danger"
                      *ngIf="
                        (ifsc_code.touched || appForm.submitted) &&
                        ifsc_code.errors?.required
                      "
                    >
                      IFSC Code Number is required
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (ifsc_code.touched || appForm.submitted) &&
                        ifsc_code.errors?.pattern
                      "
                    >
                      Enter valid IFSC code</span
                    >
    
                    <span
                      class="text-danger"
                      *ngIf="
                        (ifsc_code.touched || appForm.submitted) &&
                        ifsc_code.errors?.minlength
                      "
                    >
                      IFSC code must be 11 digit.
                    </span>
                  </div>
                  <div
                  class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
                >
                  <label>Account Type<span class="astric">*</span></label>
                  <select
                    class="form-control"
                    [(ngModel)]="formData.type_of_bank_account"
                    name="type_of_bank_account"
                    #type_of_bank_account="ngModel"
                    required
                  >
                    <option value="">Select Account Type</option>
                    <option value="Saving Account">Saving Account</option>
                    <option value="Current Account">Current Account</option>
                  </select>
                  <span
                    class="text-danger"
                    *ngIf="
                      (type_of_bank_account.touched || appForm.submitted) &&
                      type_of_bank_account.errors?.required
                    "
                  >
                    Account Type is required
                  </span>
                </div>
                
              
              </div>
                <div class="buttonGroup">
                  <button type="submit" class="btn btn-primary submitBtn" (click)="convert(formData.contact,formData.whatsapp_no)"
                    [disabled]="isButtonDisabled || !appForm.form.valid " [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                  <button type="reset" class="btn btn-secondary">Cancel</button>
                </div>
              
              
              </div>
              </form>
        </div>
      </div>
    </div>

    <!-- document tab -->
    <div class="row" *ngIf="documentTab=='document1' || documentTab=='document2' || documentTab=='document3' || documentTab=='document4'">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" >
        <div class="sectionHeading" >
          <h5>Documents</h5>
        </div>
      </div>
   

    <!-----Section Container---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && uploadFiles(appForm)">
              <div class="row">
                <div style="display: flex; flex-wrap: wrap;">
                   <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <label>Pan Card <span class="astric">*</span></label>
                  <input
                  type="file"
                  class="form-control"
                  [(ngModel)]="formData.pan_card_file"
                  name="pan_card_file"
                  #pan_card_file="ngModel"
                  (change)="handleFileChange($event.target)"
                  required
                >
                  <span class="text-danger" *ngIf="appForm.submitted && pan_card_file.errors?.required">
                      Pan card is required
                  </span>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <label>Aadhar card<span class="astric">*</span></label>
                  <input type="file" class="form-control" [(ngModel)]="formData.aadhar_card"
                    name="aadhar_card" #aadhar_card="ngModel" (change)="handleFileChange($event.target)" required>
                  <span class="text-danger" *ngIf="appForm.submitted && aadhar_card.errors?.required">
                    aadhar card  is required
                  </span>
                </div>

            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <label>Bank Statement<span class="astric">*</span> </label>
                  <input type="file" class="form-control" [(ngModel)]="formData.bank_statement"
                    name="bank_statement" #bank_statement="ngModel" (change)="handleFileChange($event.target)" required>
                  <span class="text-danger" *ngIf="appForm.submitted && bank_statement.errors?.required">
                    bank statement is required
                  </span>
                </div>

                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab == 'document1' || documentTab == 'document2'">
                  <label>Salary certificate / form 16<span class="astric">*</span> </label>
                  <input type="file" class="form-control" [(ngModel)]="formData.salary_cert"
                    name="salary_cert" #salary_cert="ngModel" (change)="handleFileChange($event.target)" required>
                  <span class="text-danger" *ngIf="appForm.submitted && salary_cert.errors?.required">
                    salary certificate / form 16 is required
                  </span>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab == 'document3' || documentTab == 'document4'">
                  <label>Capital Accounts of Partners </label>
                  <input type="file" class="form-control" [(ngModel)]="formData.capital_accounts"
                    name="capital_accounts" #capital_accounts="ngModel" (change)="handleFileChange($event.target)">
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab == 'document3' || documentTab == 'document4'">
                  <label>Profit and Loss </label>
                  <input type="file" class="form-control" [(ngModel)]="formData.profit_loss"
                    name="profit_loss" #profit_loss="ngModel" (change)="handleFileChange($event.target)" >
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="documentTab == 'document3' || documentTab == 'document4'">
                  <label>Balance Sheet</label>
                  <input type="file" class="form-control" [(ngModel)]="formData.balance_sheet"
                    name="balance_sheet" #balance_sheet="ngModel" (change)="handleFileChange($event.target)">
                </div>
                

                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <label>Any other document <span class="astric"> Note: name the file properly before upload</span> </label>
                  <input type="file" class="form-control" (change)="handleFileChange($event.target)" [(ngModel)]="formData.any_doc1"
                    name="any_doc1" #any_doc1="ngModel">
                </div>


                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <label>Any other document <span class="astric"> Note: name the file properly before upload</span> </label>
                  <input type="file" class="form-control" (change)="handleFileChange($event.target)" [(ngModel)]="formData.any_doc2"
                    name="any_doc2" #any_doc2="ngModel">
                </div>
              
            
            </div>
              <div class="buttonGroup">
                <button type="submit" class="btn btn-primary" [disabled]="!appForm.form.valid" style="background: #00ff00;">upload</button>
                &nbsp;&nbsp;
                <button class="btn btn-primary submitBtn"
                  [disabled]="isButtonDisabled" (click)="submitDocument()">
                  <span class="button__text">SUBMIT</span>
                </button> &nbsp;&nbsp;
                <button type="reset" class="btn btn-secondary">Cancel</button>
              </div>
            
            
            </div>
            </form>
      </div>
    </div>
  </div>
  <!--document tab end-->

  <!--payment tab-->
  <div class="row" *ngIf="documentTab === 'payment'">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" >
      <div class="sectionHeading" >
        <h5>payment</h5>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; width: 100%;" >
      <div style="display: flex; justify-content: space-between; gap: 0.5rem; align-items: last baseline;" >
        <input type="checkbox" id="ca_seal" (change)="handleCaSeal($event.target.checked)">
        <p>with CA seal (₹ 50)</p>
      </div>
      <div>
        <table>
          <tbody>
            <tr><td>Amount: </td><td style="text-align: center;">₹ {{current_itr_payment.amount}}</td></tr>
            <tr><td>commision: </td><td style="text-align: center;">₹ {{current_itr_payment.comm}}</td></tr>
            <tr><td>CA seal: </td><td style="text-align: center;">₹ {{ca_seal_amount}}</td></tr>
            <tr><td>Total: </td><td style="text-align: center;">{{current_itr_payment.amount + current_itr_payment.comm + ca_seal_amount}}</td></tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary submitBtn" style="margin-top: 1rem;" (click)="payItr()"> pay {{current_itr_payment.amount + current_itr_payment.comm + ca_seal_amount}} </button>
    </div>
    </div>

  </div>

    </div>

    <div >
      <div class="row" *ngIf="documentTab=='Documents1'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" >
          <div class="sectionHeading" >
            <h5>Details</h5>
          </div>
        </div>
</div>