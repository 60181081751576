import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';

@Component({
  selector: 'app-add-money',
  templateUrl: './add-money.component.html',
  styleUrls: ['./add-money.component.scss']
})
export class AddMoneyComponent implements OnInit {

  public formData:any = {};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public sessionUserData:any = {};

  public tab:any;

  public mainWalletAccount:any;
  public tradeWalletAccount:any;

  public base64Image:any; 
  public base64ImageName:any; 
  

  constructor(
    public dataService:DataServiceService
  ) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage("Page_Dashboard");
    this.dataService.sendMessage('showSideBar');
    this.tab  = "main"

  }

  ngOnInit(): void {
    this.mainWalletAccount = "50200040805888 (IFSC Code-HDFC0002241) SKATMOZZO SERVICE LLP";
    this.tradeWalletAccount = "093005003886 (IFSC Code-ICIC0000930) SKATMOZZO SERVICE LLP";
    this.formData.PaymentId = this.mainWalletAccount;
    this.formData.PaymentMode = "";
    this.formData.PaymentType = "";


    if(this.sessionUserData.RegistrationId){
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
   }
  }
  
  changeTab(tab){
    this.tab = tab;
    if(this.tab == 'main'){
        this.formData.paymentId=    this.mainWalletAccount
    }
    if(this.tab == 'trade'){
      this.formData.paymentId=    this.tradeWalletAccount
    }
  }

  submit(appForm:NgForm){
    this.formData.RegistrationId = this.sessionUserData.RegistrationId;
    this.formData.PaymentFor = "Payment";
    this.formData.PaymentStatus = "Pending";
    this.formData.PaidAmount = this.formData.PaidAmount + ".0"
    this.formData.PaymentType =  this.tab;
    this.isButtonDisabled = true;
     this.dataService.post(this.formData, this.dataService.appConstant.APIS['ADD_MONEY']).subscribe(response => {
      this.apiResponse = response
      if(this.apiResponse.status){
        appForm.onReset();
        this.formData.UploadfileIMage  = null
        this.isButtonDisabled = false;
        this.dataService.appConstant.successToast(this.apiResponse.message);
      }else{
        this.isButtonDisabled = false;
        this.dataService.appConstant.errorToast(this.apiResponse.message);
      }
    },err=>{
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }

  selectFile(files: FileList) {
    const reader = new FileReader();
     reader.readAsDataURL(files[0]);
     reader.onload = () => {
         if(reader.result){
            this.base64Image = reader.result;
             this.base64ImageName = files[0].name;

             this.formData.UploadfileIMage = this.base64Image;
             this.formData.Uploadfilename    = this.base64ImageName;
              let base64 = this.base64Image.split(",")
              this.formData.Uploadfile  = base64[1];
 
         }
     };
   }
 

  


}
