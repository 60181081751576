import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-dth-recharge',
  templateUrl: './dth-recharge.component.html',
  styleUrls: ['./dth-recharge.component.scss']
})
export class DthRechargeComponent implements OnInit {
  public sessionUserData:any = {};

  constructor(  public router:Router) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
  }


  proceedRecharge(providerName,id, biller_code){
    this.router.navigate(['/DTH'], { queryParams: {Provider:providerName,uid:id, billerCode: biller_code}});
  }

}
