<style>
    .button--loading-delete::after {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        top: 0;
        left: 115px;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-left-color: #ffffff;
        border-radius: 50%;
        -webkit-animation: button-loading-spinner infinite linear 1s;
        animation: button-loading-spinner infinite linear 1s;
    }
</style>
<section class="pRelative">
    <div class="container-fluid">
        <br />
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home /
                    </span>
                    <span class="activeBreadCum"> Money Transfer </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-lx-4 tRight">
                <button class="btn btn-dark goToReport" routerLink="/Money_Transfer_Report">
                    Reports</button>&nbsp;
                <button routerLink="/Dashboard" class="btn btn-warning submitBtn">
                    <span class="button__text">Back To Dashboard</span>
                </button>
            </div>
        </div>

        <!-----Section Heading---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div class="sectionHeading">
                    <h5>Money Transfer</h5>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tab == 'transferForm'">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="card">
                    <div class="card-header">
                        <span class="cardHeading">Sender Detail </span>
                        <button type="button" class="btn btn-sm btn-primary floatR" *ngIf="!isAddSenderEnable && !isSenderOtpFormEnabled" (click)="resetSender()">
                            Reset
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="form-group" *ngIf="!isAddSenderEnable && !isSenderOtpFormEnabled">
                            <label for="exampleInputEmail1 " class="inputLabel">Search / Add Mobile Number</label>
                            <div class="pRelative">
                                <input type="text" class="form-control fWBold" (keypress)="keyPress($event)" minlength="10" maxlength="10" aria-describedby="emailHelp" placeholder="Enter mobile number" [(ngModel)]="searchKeySender" [disabled]="isVendorSearching" (ngModelChange)="searchSender()"
                                    autocomplete="off" />
                            </div>
                            <b class="text-danger">Transfer limit ₹2,00,000/-</b>
                        </div>

                        <div class="loader" *ngIf="
                isVendorSearching &&
                !isAddSenderEnable &&
                !isSenderOtpFormEnabled
              "></div>

                        <div class="existingUser" *ngIf="
                newSenderData.SenderId &&
                !isAddSenderEnable &&
                !isSenderOtpFormEnabled
              ">
                            <div>
                                <span class="infoLabel">Name: </span>
                                <span class="infoValue"> {{ newSenderData.Name }}</span>
                            </div>
                            <div>
                                <span class="infoLabel">Mobile Number: </span>
                                <span class="infoValue"> {{ newSenderData.MobileNumber }}</span>
                            </div>
                            <div class="existingUserLabel">Existing User</div>
                        </div>

                        <div *ngIf="isAddSenderEnable && !isSenderOtpFormEnabled">
                            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && addSender(appForm)" novalidate>
                                <div><b>Add Sender</b></div>
                                <div class="form-group">
                                    <label class="inputLabel">Mobile Number<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter mobile number" (keypress)="keyPress($event)" (ngModelChange)="clearSender()" minlength="10" maxlength="10" [(ngModel)]="senderData.MobileNumber" name="MobileNumber"
                                        #MobileNumber="ngModel" required autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (MobileNumber.touched || appForm.submitted) &&
                      MobileNumber.errors?.required
                    ">Mobile number is required</span>
                                    <div>
                                        <b class="text-danger">Transfer limit ₹2,00,000/-</b>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="inputLabel">First Name<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter first name" [(ngModel)]="senderData.FirstName" name="FirstName" #FirstName="ngModel" required autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (FirstName.touched || appForm.submitted) &&
                      FirstName.errors?.required
                    ">
                                        First name is required</span>
                                </div>
                                <div class="form-group">
                                    <label class="inputLabel">Last Name<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter last name" [(ngModel)]="senderData.LastName" name="LastName" #LastName="ngModel" required autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (LastName.touched || appForm.submitted) &&
                      LastName.errors?.required
                    ">
                                        Last name is required</span>
                                </div>
                                <div class="form-group">
                                    <label class="inputLabel">Address line 1<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter address line 1" [(ngModel)]="senderData.Address1" name="Address1" #Address1="ngModel" required autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (Address1.touched || appForm.submitted) &&
                      Address1.errors?.required
                    ">
                                        Address line 1 is required</span>
                                </div>

                                <div class="form-group">
                                    <label class="inputLabel">Address line 2<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter address line 2" [(ngModel)]="senderData.Address2" name="Address2" #Address2="ngModel" required autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (Address2.touched || appForm.submitted) &&
                      Address2.errors?.required
                    ">
                                        Address line 2 is required</span>
                                </div>

                                <div class="form-group">
                                    <label class="inputLabel">PinCode<span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter pin code" [(ngModel)]="senderData.Pincode" name="Pincode" (keypress)="keyPress($event)" minlength="6" maxlength="6" #Pincode="ngModel" required autocomplete="off"
                                    />
                                    <span class="text-danger" *ngIf="
                      (Pincode.touched || appForm.submitted) &&
                      Pincode.errors?.required
                    ">
                                        Pincode is required</span>
                                </div>
                                <br />

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                        <button type="submit" class="btn btn-primary submitBtn" [disabled]="isSenderDisabled" [ngClass]="{ 'button--loading': isSenderDisabled }">
                                            <span class="button__text"> Add Sender</span></button>&nbsp;
                                        <button type="reset" (click)="resetSender()" class="btn btn-secondary fLarger">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-----VERIFY OTP----->
                        <div *ngIf="!isAddSenderEnable && isSenderOtpFormEnabled">
                            <form #verifyOTP="ngForm" (ngSubmit)="verifyOTP.form.valid && VerifyOtp(verifyOTP)" novalidate>
                                <div><b>Verify OTP</b></div>
                                <div class="form-group">
                                    <label class="inputLabel">Enter OTP</label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter OTP" (keypress)="keyPress($event)" [(ngModel)]="senderData.otp" name="otp" #otp="ngModel" required />
                                    <span class="text-danger" *ngIf="
                      (otp.touched || verifyOTP.submitted) &&
                      otp.errors?.required
                    ">OTP is required</span>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                        <button type="submit" class="btn btn-primary submitBtn" [disabled]="isSenderDisabled" [ngClass]="{ 'button--loading': isSenderDisabled }">
                                            <span class="button__text"> Submit</span></button>&nbsp;
                                        <button type="reset" (click)="cancelVerifyOtp()" class="btn btn-secondary fLarger">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="card">
                    <div class="card-header">
                        <span class="cardHeading">Beneficiary Detail </span>
                        <button type="button" *ngIf="!isAddBeneficiaryEnabled" style="float: right" (click)="openBeneficiaryForm()" [disabled]="!newSenderData.SenderId" class="btn btn-primary btn-sm fLarger">
                            <i class="fa fa-plus" aria-hidden="true"></i> Add Beneficiary
                        </button>
                        <button type="button" *ngIf="!isAddBeneficiaryEnabled" [ngClass]="{ 'button--loading-delete': isdeleteloding }" style="float: right;min-width: auto !important;" (click)="deleteBeneficiary()" [disabled]="!newSenderData.SenderId" class="btn btn-danger btn-sm fLarger mr-1">Delete</button>
                    </div>
                    <div class="card-body">
                        <div class="beneficiaryContainer" *ngIf="newBeneficiaryList?.length > 0 && !isAddBeneficiaryEnabled">
                            <div class="form-group" *ngIf="!isAddSenderEnable && !isSenderOtpFormEnabled">
                                <label for="exampleInputEmail1 " class="inputLabel">Search Beneficiary</label>
                                <div class="pRelative">
                                    <input type="text" class="form-control fWBold" aria-describedby="emailHelp" (ngModelChange)="newBeneficiaryData = {}" placeholder="Search Beneficiary" [(ngModel)]="searchBeneficiary" />
                                </div>
                            </div>

                            <div class="beneficiaryListSection">
                                <div *ngFor="
                    let beneficiary of newBeneficiaryList
                      | filter: searchBeneficiary;
                    let i = index
                  ">
                                    <div class="row beneficiaryRow activeBeneficiaryRow" (click)="selectBeneficiary(beneficiary)" [ngClass]="{
                      activeBeneficiaryRow:
                        newBeneficiaryData.BeneficiaryId ==
                        beneficiary.BeneficiaryId
                    }">
                                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-lx-2">
                                            <div class="beneficiaryOption">
                                                <div class="selectedBeneficiary" *ngIf="
                            newBeneficiaryData.BeneficiaryId ==
                            beneficiary.BeneficiaryId
                          "></div>
                                            </div>
                                        </div>
                                        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-lx-10">
                                            <div class="beneficiaryInfo transformCapital">
                                                <b>{{ beneficiary.BeneficiaryName }}</b>
                                            </div>
                                            <div class="beneficiaryInfo transformCapital">
                                                <b> Mobile Number</b> :{{ beneficiary.MobileNumber }}
                                            </div>
                                            <div class="beneficiaryInfo transformCapital">
                                                <b>Bank</b>: {{ beneficiary.BankName }}
                                            </div>
                                            <div class="beneficiaryInfo transformCapital">
                                                <b>A/C No</b>: {{ beneficiary.AccountNumber }}
                                            </div>
                                            <div class="beneficiaryInfo transformCapital">
                                                <b>IFSC</b>: {{ beneficiary.BankIfsc }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider"></div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isAddBeneficiaryEnabled">
                            <form #addBeneficiaryForm="ngForm" (ngSubmit)="
                  addBeneficiaryForm.form.valid &&
                    addBeneficiary(addBeneficiaryForm)
                " novalidate>
                                <div class="form-group">
                                    <label class="inputLabel">Select Bank <span class="astric">*</span></label>
                                    <!-- <select class="form-control" [(ngModel)]="formData.BankName" name="BankName" #BankName="ngModel"
                    required (ngModelChange)="selectProfileState()"> -->
                                    <select class="form-control" [(ngModel)]="beneficiaryData.BankName" name="BankName" #BankName="ngModel" required>
                                        <option value="">Select Bank Name</option>
                                        <option *ngFor="let row of BankNameList" value="{{row.BankId}}">
                                            {{row.BANKName}} </option>
                                    </select>
                                    <!-- <input list="BankNameList" class="form-control" [(ngModel)]="beneficiaryData.BankName" name="BankName" (change)="onBankChange(BankName)" #BankName="ngModel" required />
                                        <datalist id="BankNameList">
                                            <option *ngFor="let row of BankNameList" value="{{row.BankId}}">
                                                {{row.BANKName}} </option>
                                        </datalist> -->
                                    <!-- <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter bank name"
                    [(ngModel)]="beneficiaryData.BankName" name="BankName" #BankName="ngModel" required
                    autocomplete="off" />
                  <span class="text-danger" *ngIf="
                      (BankName.touched || addBeneficiaryForm.submitted) &&
                      BankName.errors?.required
                    ">
                    Bank name is required</span> -->
                                </div>
                                <div class="form-group">
                                    <label class="inputLabel">Account Number <span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" (keypress)="keyPress($event)" placeholder="Enter account number" [(ngModel)]="beneficiaryData.AccountNumber" name="AccountNumber" #AccountNumber="ngModel" required autocomplete="off"
                                    />
                                    <span class="text-danger" *ngIf="
                      (AccountNumber.touched || addBeneficiaryForm.submitted) &&
                      AccountNumber.errors?.required
                    ">
                                        Account number is required</span>
                                </div>
                                <div class="form-group">
                                    <label class="inputLabel">Bank IFSC <span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter Bank IFSC " [(ngModel)]="beneficiaryData.BankIfsc" name="BankIfsc" #BankIfsc="ngModel" required minlength="11" maxlength="11" autocomplete="off" />

                                    <span class="text-danger" *ngIf="(BankIfsc.touched || addBeneficiaryForm.submitted) && BankIfsc.errors?.minlength">
                                        IFSC code must be 11 digit.
                                    </span>


                                    <span class="text-danger" *ngIf="
                      (BankIfsc.touched || addBeneficiaryForm.submitted) &&
                      BankIfsc.errors?.required
                    ">
                                        Bank IFSC is required</span>
                                    <span class="text-danger" *ngIf="
                      (BankIfsc.touched || addBeneficiaryForm.submitted) &&
                      BankIfsc.errors?.pattern
                    ">
                                        Enter valid IFSC code</span>
                                </div>

                                <div class="form-group" id="beneficiary_name_div">
                                    <label class="inputLabel">Beneficiary Name <span class="astric">*</span></label>
                                    <input type="text" id="beneficiary_name_id" class="form-control" aria-describedby="emailHelp" placeholder="Enter Beneficiary Name" [(ngModel)]="beneficiaryData.BeneficiaryName" name="BeneficiaryName" #BeneficiaryName="ngModel" (keyup)="change_beneficiary_name(this)"
                                        autocomplete="off" />
                                    <!-- <span class="text-danger" *ngIf="
                      (BeneficiaryName.touched ||
                        addBeneficiaryForm.submitted) &&
                      BeneficiaryName.errors?.required
                    ">
                    Beneficiary name is required</span> -->
                                </div>

                                <div class="form-group">
                                    <label class="inputLabel">Mobile Number <span class="astric">*</span></label>
                                    <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter mobile number" (keypress)="keyPress($event)" minlength="10" maxlength="10" [(ngModel)]="beneficiaryData.MobileNumber" name="MobileNumber" #MobileNumber="ngModel" required
                                        autocomplete="off" />
                                    <span class="text-danger" *ngIf="
                      (MobileNumber.touched || addBeneficiaryForm.submitted) &&
                      MobileNumber.errors?.required
                    ">Mobile number is required</span>
                                    <span class="text-danger" *ngIf="
                      (MobileNumber.touched || addBeneficiaryForm.submitted) &&
                      MobileNumber.errors?.minlength
                    ">Mobile number at least 10 digit</span>
                                    <span class="text-danger" *ngIf="
                      (MobileNumber.touched || addBeneficiaryForm.submitted) &&
                      MobileNumber.errors?.maxlength
                    ">Mobile number less than 11 digit</span>
                                </div>
                                <div class="form-group">
                                    <label for="IsVerify">
                                        <input type="checkbox" id="IsVerify" [(ngModel)]="beneficiaryData.IsVerify"
                                            name="IsVerify" #IsVerify="ngModel" ng-checked="true" value="true"
                                            (change)="changeVerify($event)"><span>
                                            Verify this Account?</span>
                                    </label>
                                </div>
                                <div>
                                    <p>Note: You will be charged Rs. 2 + 1 for validating bank account.</p>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                        <button type="submit" class="btn btn-primary submitBtn" [disabled]="isBeneficiaryDisabled" [ngClass]="{ 'button--loading': isBeneficiaryDisabled }">
                                            <span class="button__text"> Add Beneficiary</span></button>&nbsp;
                                        <button type="reset" (click)="isAddBeneficiaryEnabled = false" class="btn btn-secondary fLarger">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="card">
                    <div class="card-header">
                        <span class="cardHeading">Transaction Detail </span>
                    </div>
                    <div class="card-body" *ngIf="isTransferAmountSection">
                        <form #moneyTransferForm="ngForm" (ngSubmit)="
                moneyTransferForm.form.valid && transferMoney(moneyTransferForm)
              " novalidate>
                            <div class="form-group">
                                <label class="inputLabel">Amount (in RS dsadsad) </label>
                                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter amount" (keypress)="keyPress($event)" [(ngModel)]="transferData.transferAmount" name="Amount" (ngModelChange)="calculateTotalTransfer()" #Amount="ngModel" required
                                    autocomplete="off" [disabled]="
                    !newBeneficiaryData.BeneficiaryId || !newSenderData.SenderId
                  " />
                                <span class="text-danger" *ngIf="
                    (Amount.touched || moneyTransferForm.submitted) &&
                    Amount.errors?.required
                  ">Enter amount</span>
                            </div>

                            <div class="form-group mrg17Px">
                                <div class="flex">
                                    <div class="transferNowLabel">Transfer Mode</div>
                                    <div class="flex w20">
                                        <div class="beneficiaryOption">
                                            <div class="selectedBeneficiary"></div>
                                        </div>
                                        <div class="transferModeOptionLabel"><b>IMPS</b></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row amountDetail">
                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8">
                                    Customer Charges
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 tRight">
                                    <b>₹ {{ transferData.customerCharges }}</b>
                                </div>

                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8">
                                    Retailer Charges
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 tRight">
                                    <b>₹ {{ transferData.retailerCharges }}</b>
                                </div>

                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8">
                                    Retailer Commission
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 tRight">
                                    <b>₹ {{ transferData.retailerCommission }}</b>
                                </div>
                                <div class="divider"></div>
                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8">
                                    TOTAL(Deducted From Wallet)
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 tRight">
                                    <b>₹ {{ transferData.Amount }}</b>
                                </div>
                            </div>
                            <br /><br />
                            <div class="tCenter">
                                <button type="submit" class="btn btn-primary submitBtn" [disabled]="
                    !newBeneficiaryData.BeneficiaryId || !newSenderData.SenderId
                  " [ngClass]="{ 'button--loading': isTransferLoading }">
                                    <span class="button__text"> Transfer Now</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-------TRANSACTION DONE------>
        <div class="row" *ngIf="tab == 'successMessage'">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4"></div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
                <div class="card">
                    <div class="card-header">
                        <span class="cardHeading">Transaction Detail </span>
                    </div>
                    <div class="card-body">
                        <div class="transactionSuccessful">
                            <i class="fa fa-check-circle-o" aria-hidden="true"></i> Transaction is successful
                        </div>

                        <div class="row">
                            <div class="
                  col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12
                  transactionDetail
                ">
                                Order ID: {{ transferData.OrderId }}
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  transactionDetail
                ">
                                Amount: ₹ {{ transferData.transferAmount }}
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Mode<b>: IMPS</b>
                            </div>
                        </div>

                        <div class="row amountDetail">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">CCF</div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6 tRight">
                                <b>₹ {{ transferData.customerCharges }}</b>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Retailer Charges
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6 tRight">
                                <b>₹ {{ transferData.retailerCharges }}</b>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Retailer Commission
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6 tRight">
                                <b>₹ {{ transferData.retailerCommission }}</b>
                            </div>

                            <div class="divider"></div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">TOTAL</div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6 tRight">
                                <b>₹ {{ transferData.Amount }}</b>
                            </div>
                        </div>
                        <br />

                        <div class="row amountDetail">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Sender:
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  tRight
                  tUpperCase
                ">
                                <b>{{ transferData.Sender }}</b>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Beneficiary:
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  tRight
                  tUpperCase
                ">
                                <b>{{ transferData.Beneficiry }}</b>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Bank Name:
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  tRight
                  tUpperCase
                ">
                                <b>{{ newBeneficiaryData.BankName }}</b>
                            </div>

                            <div class="divider"></div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Beneficiary A/C:
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  tRight
                  tUpperCase
                ">
                                <b>{{ transferData.Accountno }}</b>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                                Bank Ref No:
                            </div>
                            <div class="
                  col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6
                  tRight
                  tUpperCase
                ">
                                <b>{{ transferData.BankRefNo }}</b>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <button class="btn printBtn fLarger" (click)="printToCart()">
                                    <i class="fa fa-print" aria-hidden="true"></i> Print
                                </button> &nbsp;
                                <button type="submit" class="btn btn-primary" (click)="clearTransaction()">
                                    Next Transaction
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4"></div>
        </div>
    </div>
</section>

<div class="modal" tabindex="-1" id="printReceiptModal" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!-------PRINT SECTION------>
                <div id="printSectionId" style="padding: 3px 10px; border: solid 1px #dedede">
                    <div style="text-align: center">Transaction Receipt</div>
                    <div style="margin-top: 13px">
                        <div>
                            <img class="ReceiptLogoImg" style="width: 139px;float: right;" src="assets/images/logo1.png" />
                        </div>
                        <div style="font-size: 12px">
                            <div>
                                <b>{{ sessionUserData?.Data?.ShopName }}</b>
                            </div>
                            <div>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAddress1
                  ">
                                    {{ sessionUserData?.Data?.ShopAddress1 }},
                                </span>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAddress2
                  ">
                                    {{ sessionUserData?.Data?.ShopAddress2 }},
                                </span>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAreaLocality
                  ">
                                    {{ sessionUserData?.Data?.ShopAreaLocality }},
                                </span>
                                <br />
                                <span *ngIf="sessionUserData && sessionUserData.CityName">
                                    {{ sessionUserData?.CityName }},
                                </span>

                                <span *ngIf="sessionUserData && sessionUserData.StateName">
                                    {{ sessionUserData?.StateName }},
                                </span>

                                <span *ngIf="sessionUserData && sessionUserData.CountryName">
                                    {{ sessionUserData?.CountryName }}
                                </span>

                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopPincode
                  ">
                                    -{{ sessionUserData?.Data?.ShopPincode }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style="
              text-align: center;
              border-bottom: solid 2px #ff3b00;
              padding-bottom: 5px;
              margin-bottom: 15px;
            ">
                        <span> Receipt </span>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Transaction Date</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transactionDate }} {{ transactionTime }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Transaction Mode.</div>
                        <div style="width: 50%; text-align: right"><b>IMPS</b></div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Bank Ref No.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.BankRefNo }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Order ID.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.OrderId }}</b>
                        </div>
                    </div>

                    <div c style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Sender Name.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.Sender }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Sender Mobile Number.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.MobileNumber }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Beneficiary Name.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.Beneficiry }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">
                            Beneficiary Account Number.
                        </div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ transferData.Accountno }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Bank Name.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ newBeneficiaryData.BankName }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">IFSC Code.</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ newBeneficiaryData.BankIfsc }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Transaction Amount.</div>
                        <div style="width: 50%; text-align: right">
                            <b>₹ {{ transferData.transferAmount }}</b>
                        </div>
                    </div>
                    <hr />
                    <h6>
                        Note: <b>MyEReturn</b> is not liable if the money is transferred in wrong bank account or wrong account number.
                    </h6>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>