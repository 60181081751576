import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataServiceService } from '../../service/data-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {


  public sessionUserData: any = {};
  public isLoginPage: boolean = false;
  public isShowSideBar: boolean = true;


  constructor(
    private router: Router,
    public dataService: DataServiceService,
    private location: Location
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    if (this.sessionUserData) {
      // this.router.navigate(['/Dashboard']);
    } else {
      this.location.replaceState('/');
      this.router.navigate(['/login']);
    }
    this.checkPage();
    this.dataService.getMessage().subscribe((msg) => {
      if (msg == 'LoggedIn') {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
        this.checkPage();
      }
      if (msg == 'hideSideBar') {
        this.isShowSideBar = false;
      }
      if (msg == 'showSideBar') {
        this.isShowSideBar = true;
      }
    })
  }

  ngOnInit() {

  }



  checkPage() {
    if (this.router.url === '/login') {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }
  }



  onActivate(event) {
    window.scrollTo(0, 0)
  }

}
