<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home /
          </span>
                    <span class="activeBreadCum">AEPS Services</span>
                </div>
            </div>
        </div>

        <!-----Section Heading---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <div class="sectionHeading">
                        <h5>AEPS Services</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                    <div class="tCenter cp" routerLink="/Pan_Card_Services/Buy_Coupon">
                        <img src="assets/images/pan1.png" class="panServicesIcon">
                        <p class="tCenter">AEPS Services</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" routerLink="/Pan_Card_Services/Reports">
                    <div class="tCenter cp">
                        <img src="assets/images/pan2.png" class="panServicesIcon">
                        <p class="tCenter">Settlements</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                    <div class="tCenter cp">
                        <img src="assets/images/pan3.png" class="panServicesIcon">
                        <p class="tCenter">Earning Reports</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                    <div class="tCenter cp exampleModalCenter">
                        <span data-toggle="modal" data-target="#exampleModalCenter">
              <img src="assets/images/pan1.png" class="panServicesIcon" />
              <p class="tCenter">Transaction History</p>
            </span>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2">
                    <div class="tCenter cp exampleModalCenter">
                        <span data-toggle="modal" data-target="#exampleModalCenter">
              <img src="assets/images/pan1.png" class="panServicesIcon" />
              <p class="tCenter">Request</p>
            </span>
                    </div>
                </div>
                <div class="processSuccess">
                    <i class="icofont-close-circled text-danger"></i>
                    <br /><br />
                    <h6>
                        Your request has been Rejected Please Contact Customer Care For
                        <b>AEPS Service </b> activation.
                    </h6>
                    <div class="font14 grayClr">Our team will get back to you shortly.</div>
                    <div class="font14 grayClr">
                        Thank you for your interest in <b>AEPS Service</b>!
                    </div>
                </div>
                <div class="processSuccess">
                    <i class="icofont-check-circled"></i>
                    <br /><br />
                    <h6>
                        Your request has been successfully submitted to for
                        <b>AEPS Service </b> activation.
                    </h6>
                    <div class="font14 grayClr">Our team will get back to you shortly.</div>
                    <div class="font14 grayClr">
                        Thank you for your interest in <b>AEPS Service</b>!
                    </div>
                </div>
            </div>
        </div>

        <!-----Section Container---->
        <div class="row" *ngIf="tab == 'formSection'">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div style="line-height: 32px">
                    <div *ngIf="userData.ShopName">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Shop Name</b>
                    </div>
                    <div *ngIf="!userData.ShopName">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Shop Name</b>
                    </div>

                    <div *ngIf="userData.ShopCategory">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Shop Category</b>
                    </div>
                    <div *ngIf="!userData.ShopCategory">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Shop Category</b>
                    </div>

                    <div *ngIf="userData.ShopAddress1">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Flat/Door/Building</b>
                    </div>
                    <div *ngIf="!userData.ShopAddress1">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Flat/Door/Building</b>
                    </div>

                    <div *ngIf="userData.ShopAddress2">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Road/street/Block/Sector</b>
                    </div>
                    <div *ngIf="!userData.ShopAddress2">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Road/street/Block/Sector</b>
                    </div>

                    <div *ngIf="userData.ShopAreaLocality">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Area Locality</b>
                    </div>
                    <div *ngIf="!userData.ShopAreaLocality">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Area Locality</b>
                    </div>

                    <div *ngIf="userData.ShopPostOffice">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Tahsils/Area/Mandal</b>
                    </div>
                    <div *ngIf="!userData.ShopPostOffice">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Tahsils/Area/Mandal</b>
                    </div>

                    <div *ngIf="userData.ShopCity">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;District/City</b>
                    </div>
                    <div *ngIf="!userData.ShopCity">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;District/City</b>
                    </div>

                    <div *ngIf="userData.ShopPincode">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Pincode</b>
                    </div>
                    <div *ngIf="!userData.ShopPincode">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Pincode</b>
                    </div>

                    <div *ngIf="userData.ShopState">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;State</b>
                    </div>
                    <div *ngIf="!userData.ShopState">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;State</b>
                    </div>

                    <div *ngIf="userData.ShopPhoto">
                        <i class="fa fa-check-circle checkMark" aria-hidden="true"></i>
                        <b> &nbsp;Update Shop Photo</b>
                    </div>
                    <div *ngIf="!userData.ShopPhoto">
                        <i class="fa fa-times-circle crossMark" aria-hidden="true"></i>
                        <b> &nbsp;Update Shop Photo</b>
                    </div>

                    <br />

                    <div class="buttonGroup" *ngIf="
              userData.ShopName &&
              userData.ShopCategory &&
              userData.ShopAddress1 &&
              userData.ShopAddress2 &&
              userData.ShopAreaLocality &&
              userData.ShopPostOffice &&
              userData.ShopCity &&
              userData.ShopPincode &&
              userData.ShopState &&
              userData.ShopPhoto
            ">
                        <button type="submit" class="btn btn-primary submitBtn" (click)="submit()" [disabled]="isButtonDisabled || !appForm.form.valid" [ngClass]="{ 'button--loading': isButtonDisabled }">
              <span class="button__text">SEND REQUEST</span>
            </button> &nbsp;&nbsp;
                    </div>

                    <div *ngIf="
              !userData.ShopName ||
              !userData.ShopCategory ||
              !userData.ShopAddress1 ||
              !userData.ShopAddress2 ||
              !userData.ShopAreaLocality ||
              !userData.ShopPostOffice ||
              !userData.ShopCity ||
              !userData.ShopPincode ||
              !userData.ShopState ||
              !userData.ShopPhoto
            " (click)="goToProfile()">
                        <div>
                            <i>Please go to <b>Shop detail</b> and fill above field. to submit
                <b>AEPS</b> request.<br /></i>
                        </div>

                        <button (click)="goToUpdateShopDetail()" type="submit" class="btn btn-warning">
              Complete Profile
            </button>
                    </div>
                </div>
                <br /><br /><br />
            </div>
        </div>

        <!-----SUCCESS MESSAGE-->
        <div class="processSuccess" *ngIf="tab == 'successMessage'">
            <i class="icofont-check-circled"></i>
            <br />
            <br />
            <h6>
                Your request has been successfully submitted to for
                <b>AEPS</b> activation.
            </h6>
            <div class="font14 grayClr">Our team will get back to you shortly.</div>
            <div class="font14 grayClr">
                Thank you for your interest in <b>AEPS</b>!
            </div>
        </div>

        <!-- <div class="loader" *ngIf="isLoading"></div>
                <div [innerHTML]="apiResponse"></div> -->
    </div>
</section>