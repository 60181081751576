<section>
    <div class="container-fluid sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-lx-10">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home /
          </span>
                    <span (click)="back()">Money Transfer / </span>
                    <span class="activeBreadCum">Money Transfer Report </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2">
                <button (click)="back()" class="btn btn-warning submitBtn">
          <span class="button__text">Back To Money Transfer</span>
        </button>
            </div>
        </div>

        <!-----Section Heading---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div class="sectionHeading">
                    <h5>Money Transfer Report</h5>
                </div>
            </div>
        </div>
        <!-----Section Contain---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <!---FILTER-->
                <div *ngIf="isShowFilter">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit()">
                        <div class="row">
                            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                                <label>Start Date </label>
                                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.startDate" name="startDate1" #startDate1="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (startDate1.touched || appForm.submitted) &&
                    startDate1.errors?.required
                  ">
                  Start date is required
                </span>
                            </div>
                            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                                <label>End Date </label>
                                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.toDate" name="toDate1" #toDate1="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (toDate1.touched || appForm.submitted) &&
                    toDate1.errors?.required
                  ">
                  End date is required
                </span>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-2 col-lx-2">
                                <div class="flexAlignCenter">
                                    <button type="submit" class="btn btn-warning submitBtn tableFilterBtn" [disabled]="isLoading || !appForm.form.valid" [ngClass]="{ 'button--loading': isLoading }">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                                    <button type="reset" (click)="clear()" class="btn btn-secondary tableFilterBtn">
                    Reset
                  </button>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-4 col-lx-4">
                                <input type="text" class="form-control reportSearchFilter" placeholder="Search record..." [(ngModel)]="reportFilter.searchKey" name="searchKey" #searchKey="ngModel" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- <div class="row" >
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 ">
           <span class="grayClr font14">Show</span> <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
               </select>
            <span class="grayClr font14">entity</span>   
        </div>
      </div> -->

        <!---TABLE--->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div id="reportTable" class="tableMv">
                    <table id="htmlData">
                        <thead>
                            <tr>
                                <th class="brdWhiteR brdTLR">Transfer Id</th>
                                <th class="brdWhiteR">Date</th>
                                <th class="brdWhiteR">Sender</th>
                                <th class="brdWhiteR">Beneficiary</th>
                                <th class="brdWhiteR">Amount</th>
                                <th class="brdWhiteR">Charges</th>
                                <th class="brdWhiteR">Type</th>
                                <th class="brdWhiteR">Status</th>
                                <th class="brdWhiteR">Print</th>
                                <th class="brdTRR">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bodyTr" *ngFor="
                  let item of reportList
                    | filter: reportFilter.searchKey
                    | paginate
                      : {
                          id: 'reportFilterList',
                          itemsPerPage: reportFilter.numPerPage,
                          currentPage: reportFilter.pageNum,
                          totalItems: reportList.length
                        };
                  let i = index
                " id="eachRecord">
                                <td class="brdGrayR">{{ item.OrderId }}</td>
                                <td class="brdGrayR">
                                    {{ item.TransferDateTime | date: "dd/MM/yyyy" }} {{ item.TransferDateTime | date: "hh:mm" }}
                                </td>
                                <td class="brdGrayR">{{ item.Sender }}</td>
                                <td class="brdGrayR">
                                    {{ item.Beneficiry }}<br />
                                    <div style="font-size: 12px">
                                        A/c No : {{ item.Accountno }}
                                    </div>
                                </td>
                                <td class="brdGrayR">₹{{ item.Amount }}</td>
                                <td class="brdGrayR">
                                    ₹{{ item.retailerCharges ? item.retailerCharges : "0" }}
                                </td>
                                <td class="brdGrayR">{{ item.Type }}</td>
                                <td class="brdGrayR">{{ item.Status }}</td>
                                <td class="brdGrayR" (click)="printToCart(item)">
                                    <i class="icofont-print printIcon"></i>
                                </td>
                                <td>
                                    <button type="button" *ngIf="!item.Complaint || !item.Complaint.ComplaintId" class="btn btn-warning ComplaintBTN" (click)="openComplaintForm(item)">
                    Submit Complaint
                  </button>
                                    <div *ngIf="item.Complaint && item.Complaint.ComplaintId">
                                        <b class="greenCLR">Submitted</b>
                                        <div class="pendingComplaintSts">
                                            Admin Status : {{ item.Complaint.Status }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="bodyTr" *ngIf="reportList?.length <= 0 && !isLoading">
                                <td colspan="50" class="noTableRecord">
                                    No record found.......
                                </td>
                            </tr>
                            <tr class="bodyTr" *ngIf="isLoading">
                                <td colspan="50" class="noTableRecord">
                                    <div class="loader"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!---FOOTER--->
        <br />
        <div class="row cp tableFooterRow" *ngIf="reportList?.length > 0">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3">
                <div class="font14">
                    <span class="grayClr">Showing</span>
                    <b> {{ reportFilter.pageNum - 1 + 1 }} </b>
                    <span class="grayClr"> to </span>
                    <b> {{ reportFilter.numPerPage }} </b>
                    <span class="grayClr"> of </span> <b> {{ reportList?.length }} </b>
                    <span class="grayClr"> entries</span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4"></div>
            <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportsExcel()">
                <div><i class="icofont-download"></i> Excel</div>
            </div>
            <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportPDF()">
                <div><i class="icofont-download"></i> PDF</div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3 tCenter font14">
                <pagination-controls id="reportFilterList" (pageChange)="reportFilter.pageNum = $event"></pagination-controls>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div id="ComplaintForm" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header modalHeader">Submitting a complaint</div>
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submitCompltain(appForm)">
                <div class="modal-body modelFrom">
                    <div class="row modalFixedInfo">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Complaint For</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.ComplaintRegisterProduct }}</b>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Order Transfer Id</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.OrderTransferId }}</b>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Transaction Date</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.TransferDateTime | date: "dd/MM/yyyy" }}
                {{ formData.TransferDateTime | date: "hh:mm" }}</b>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                            <label>Subject <span class="astric">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Subject" [(ngModel)]="formData.Subject" name="Subject" #Subject="ngModel" required />
                            <span class="text-danger" *ngIf="
                  (Subject.touched || appForm.submitted) &&
                  Subject.errors?.required
                ">
                Subject is required
              </span>
                        </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                            <label>Message <span class="astric">*</span></label>
                            <textarea type="text" class="form-control" placeholder="Enter Message" [(ngModel)]="formData.Message" name="Message" #Message="ngModel" required>
              </textarea>
                            <span class="text-danger" *ngIf="
                  (Message.touched || appForm.submitted) &&
                  Message.errors?.required
                ">
                Message is required
              </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="buttonGroup">
                        <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              Cancel</button>&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabledPop" [ngClass]="{ 'button--loading': isButtonDisabledPop }">
              <span class="button__text">SUBMIT</span>
            </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="printReceiptModal" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!-------PRINT SECTION------>
                <div id="printSectionId" style="padding: 3px 10px; border: solid 1px #dedede">
                    <div style="text-align: center">Transaction Receipt</div>
                    <div style="margin-top: 13px">
                        <div style="float: right;">
                            <img class="ReceiptLogoImg" style="width: 139px" src="assets/images/logo1.png" />
                        </div>
                        <div style="font-size: 12px">
                            <div>
                                <b>{{ sessionUserData?.Data?.ShopName }}</b>
                            </div>
                            <div>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAddress1
                  ">
                  {{ sessionUserData?.Data?.ShopAddress1 }},
                </span>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAddress2
                  ">
                  {{ sessionUserData?.Data?.ShopAddress2 }},
                </span>
                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopAreaLocality
                  ">
                  {{ sessionUserData?.Data?.ShopAreaLocality }},
                </span>
                                <br />
                                <span *ngIf="sessionUserData && sessionUserData.CityName">
                  {{ sessionUserData?.CityName }},
                </span>

                                <span *ngIf="sessionUserData && sessionUserData.StateName">
                  {{ sessionUserData?.StateName }},
                </span>

                                <span *ngIf="sessionUserData && sessionUserData.CountryName">
                  {{ sessionUserData?.CountryName }}
                </span>

                                <span *ngIf="
                    sessionUserData &&
                    sessionUserData.Data &&
                    sessionUserData.Data.ShopPincode
                  ">
                  -{{ sessionUserData?.Data?.ShopPincode }}
                </span>
                            </div>
                        </div>
                    </div>
                    <div style="
              text-align: center;
              border-bottom: solid 2px #ff3b00;
              padding-bottom: 5px;
              margin-bottom: 15px;
            ">
                        <span> Receipt </span>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Transfer Id</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.OrderId }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Date</div>
                        <div style="width: 50%; text-align: right">
                            {{ singleTransaction.TransferDateTime | date: "dd/MM/yyyy" }} {{ singleTransaction.TransferDateTime | date: "hh:mm" }}
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Sender</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Sender }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Beneficiary</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Beneficiry }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Beneficiary A/c No</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Accountno }}</b>
                        </div>
                    </div>

                    <div c style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Amount</div>
                        <div style="width: 50%; text-align: right">
                            <b>₹{{ singleTransaction.Amount }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Type</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Type }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Status</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Status }}</b>
                        </div>
                    </div>

                    <div style="
              display: inline-flex;
              width: 100%;
              border: solid 1px #e2e2e2;
              align-items: center;
              padding: 6px 14px;
            ">
                        <div style="width: 50%; font-size: 13px">Client Id</div>
                        <div style="width: 50%; text-align: right">
                            <b>{{ singleTransaction.Client_Id }}</b>
                        </div>
                    </div>
                    <hr />
                    <h6>
                        Note: <b>MyEReturn</b> is not liable if the money is transferred in wrong bank account or wrong account number.
                    </h6>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
            </div>
        </div>
    </div>
</div>