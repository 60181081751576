import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.scss']
})
export class GstComponent implements OnInit {
  public sessionUserData:any = {};

  constructor() { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
  }

}
