<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span routerLink="/BBPS"> BBPS /</span>
                      <span class="activeBreadCum"> Provider</span>
                </div>
            </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end;">
            <select id="selectState" style="margin-right: 1rem; width: 15%" (change)="onProviderChange($event.target.value)">
                <option value="">Select state</option>
                <option value="maharashtra">Maharashtra</option>
                <option value="gujarat" >Gujarat</option>
            </select>
            <input style="margin-right: 2rem; width: 15%" (keyup)="onKeySearch($event.target.value)" />
        </div>
        <!-----Prepaid Mobile ---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>{{Provider}}</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngFor="let provider of providerListFiltered">
                    <div class="tCenter cp" (click)="proccedToPay(provider)">
                        <img [src]="provider.img" class="panServicesIcon">
                        <p class="tCenter">{{provider.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>