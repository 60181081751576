<section>
  <div class="container sectionContainer">

    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-10  col-lx-10 ">
        <div class="breadCum">
          <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
          <span routerLink="/Dashboard">Dashboard / </span>
          <span class="activeBreadCum">My Wallet</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2 ">
        <button routerLink="/Dashboard" class="btn btn-warning submitBtn">
          <span class="button__text">Back</span>
        </button>
      </div>
    </div>




    <!-----Section Contain---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">


        <div >
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit()">
            <div class="row">
              <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3 ">
                <label>Start Date </label>
                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.startDate"
                  name="startDate" #startDate="ngModel" required>
                <span class="text-danger"
                  *ngIf="(startDate.touched || appForm.submitted) && startDate.errors?.required">
                  Start date is required
                </span>
              </div>
              <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                <label>End Date </label>
                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.endDate"
                  name="endDate" #endDate="ngModel" required>
                <span class="text-danger" *ngIf="(endDate.touched || appForm.submitted) && endDate.errors?.required">
                  End date is required
                </span>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-2 col-lx-2">
                <div class="flexAlignCenter">
                  <button type="submit" class="btn btn-warning submitBtn tableFilterBtn"
                    [disabled]="isButtonDisabled || !appForm.form.valid "
                    [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                  <button type="reset" class="btn btn-secondary tableFilterBtn">Reset</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
<br>
    <!-----Section Container---->
    <div class="row">
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-lx-3">
        <div class="walletCard">
          <div>
            <h4><b>₹   {{walletData?.MainWallet?walletData.MainWallet:0}}</b></h4>
          </div>
          <div>Main Wallet</div>
        </div>
      </div>

      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-lx-3">
        <div class="walletCard debit">
          <div>
            <h4><b>₹     {{walletData?.TreadWallet?walletData.TreadWallet:0}}</b></h4>
          </div>
          <div>Tread Wallet</div>
        </div>
      </div>



      <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-lx-3">
        <div class="walletCard balance">
          <div>
            <h4><b>₹ 5000</b></h4>
          </div>
          <div>Wallet Balance </div>
        </div>
      </div> -->

    </div>
  </div>
</section>