<section>
  <div class="container sectionContainer">
    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-lx-10">
        <div class="breadCum">
          <span routerLink="/Dashboard"
            ><i class="icofont-home"></i> Home /
          </span>
          <span>Pan Card Services / </span>
          <span class="activeBreadCum"> Service Request </span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2">
        <button (click)="back()" class="btn btn-warning submitBtn">
          <span class="button__text">Back</span>
        </button>
      </div>
    </div>

    <div class="CouponCard">
      <!-----Section Heading---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Service Request</h5>
          </div>
        </div>
      </div>

      <!-----Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <form>
            <div class="row">
              <div
                class="
                  form-group
                  col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12
                "
              >
                <input
                  type="checkbox"
                  [checked]="confirmed"
                  placeholder=""
                  (change)="confirmed = !confirmed"
                />
                <div class="buttonGroup">
                  <button
                    type="submit"
                    class="btn btn-primary submitBtn"
                    (click)="onSubmit()"
                    [disabled]="!confirmed"
                  >
                    <span class="button__text">SUBMIT</span>
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
