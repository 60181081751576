<section>
  <div class="container sectionContainer">
    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div class="breadCum">
          <span routerLink="/Dashboard"
            ><i class="icofont-home"></i> Home /
          </span>
          <span class="activeBreadCum" *ngIf="tab == 'My_Profile'"
            >Retailer Profile</span
          >
          <span class="activeBreadCum" *ngIf="tab == 'Shop_Details'"
            >Shop Details</span
          >
          <span class="activeBreadCum" *ngIf="tab == 'Documents_Details'"
            >Documents Details</span
          >
          <span class="activeBreadCum" *ngIf="tab == 'Bank_Details'"
            >Bank Details</span
          >
          <span class="activeBreadCum" *ngIf="tab == 'Change_Password '"
            >Change Password</span
          >
          <span class="activeBreadCum" *ngIf="tab == 'Download'">Download</span>
        </div>
      </div>
    </div>

    <div *ngIf="tab == 'My_Profile'">
      <!-----PROFILE Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Retailer Profile</h5>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #appForm="ngForm"
            (ngSubmit)="appForm.form.valid && submitProfileDetail(appForm)"
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Full Name <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control cNotAllow"
                  placeholder="Enter Full Name"
                  [(ngModel)]="myProfileData.FullName"
                  name="FullName"
                  #FullName="ngModel"
                  disabled
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (FullName.touched || appForm.submitted) &&
                    FullName.errors?.required
                  "
                >
                  Full Name is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Mobile No <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control cNotAllow"
                  placeholder="Enter Mobile No"
                  [(ngModel)]="myProfileData.MobileNo"
                  name="MobileNo"
                  #MobileNo="ngModel"
                  disabled
                  (keypress)="dataService.appConstant.keyPress($event)"
                  maxlength="10"
                  minlength="10"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (MobileNo.touched || appForm.submitted) &&
                    MobileNo.errors?.required
                  "
                >
                  Mobile No is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (MobileNo.touched || appForm.submitted) &&
                    MobileNo.errors?.minlength
                  "
                >
                  Mobile No must be 10 digit.
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Email<span class="astric">*</span></label>
                <input
                  type="email"
                  class="form-control cNotAllow"
                  placeholder="Enter Email"
                  [(ngModel)]="myProfileData.EmailId"
                  name="EmailId"
                  #EmailId="ngModel"
                  disabled
                  email
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (EmailId.touched || appForm.submitted) &&
                    EmailId.errors?.required
                  "
                >
                  Email is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (EmailId.touched || appForm.submitted) &&
                    EmailId.errors?.email
                  "
                >
                  Enter valid email address.
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Date Of Birth<span class="astric">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select Date Of Birth"
                  [(ngModel)]="myProfileData.DOB"
                  name="dateOfBirth"
                  #dateOfBirth="ngModel"
                  [readonly]="myProfileData.DOB"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (dateOfBirth.touched || appForm.submitted) &&
                    dateOfBirth.errors?.required
                  "
                >
                  Date Of Birth is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>State<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="myProfileData.StateId"
                  name="StateId"
                  #StateId="ngModel"
                  required
                  (ngModelChange)="selectProfileState()"
                  [disabled]="myProfileData.StateId"
                >
                  <option value="">Select State</option>
                  <option *ngFor="let state of stateList" [value]="state.id">
                    {{ state.sName }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (StateId.touched || appForm.submitted) &&
                    StateId.errors?.required
                  "
                >
                  State is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>District/City<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="myProfileData.CityId"
                  name="CityId"
                  #CityId="ngModel"
                  required
                  (ngModelChange)="selectProfileCity()"
                  [disabled]="myProfileData.CityId"
                >
                  <option value="">Select District/City</option>
                  <option *ngFor="let city of cityList" [value]="city.id">
                    {{ city.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (CityId.touched || appForm.submitted) &&
                    CityId.errors?.required
                  "
                >
                  District/City is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Tahsils/Area/Mandal<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="myProfileData.TahsilsId"
                  name="TahsilsId"
                  #TahsilsId="ngModel"
                  required
                  [disabled]="myProfileData.TahsilsId"
                >
                  <option value="">Select Tahsils/Area/Mandal</option>
                  <option
                    *ngFor="let tahasil of tahsilList"
                    [value]="tahasil.id"
                  >
                    {{ tahasil.tName }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (TahsilsId.touched || appForm.submitted) &&
                    TahsilsId.errors?.required
                  "
                >
                  Tahsils/Area/Mandal: is required
                </span>

                <div
                  class="
                    form-group
                    col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12
                    pad0
                  "
                  style="margin-top: 10px"
                >
                  <label>PinCode <span class="astric">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter PinCode"
                    [(ngModel)]="myProfileData.Pincode"
                    name="Pincode"
                    #Pincode="ngModel"
                    required
                    [readonly]="myProfileData.Pincode"
                  />
                  <span
                    class="text-danger"
                    *ngIf="
                      (Pincode.touched || appForm.submitted) &&
                      Pincode.errors?.required
                    "
                  >
                    PinCode is required
                  </span>

                  <br class="desktopView" />
                  <div class="buttonGroup desktopView">
                    <button
                      type="submit"
                      class="btn btn-primary submitBtn"
                      [disabled]="isButtonDisabled"
                      [ngClass]="{ 'button--loading': isButtonDisabled }"
                    >
                      <span class="button__text">SUBMIT</span>
                    </button>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Upload Profile Photo </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="selectFile($event.target.files, 'Profile')"
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div
                  class="uploadedImage"
                  *ngIf="myProfileData.ProfilePhotoImage"
                >
                  <img
                    class="profilePic"
                    src="{{ myProfileData.ProfilePhotoImage }}"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="buttonGroup mobileView">
              <button
                type="submit"
                class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled"
                [ngClass]="{ 'button--loading': isButtonDisabled }"
              >
                <span class="button__text">SUBMIT</span>
              </button>
              &nbsp;&nbsp;
              <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-----Shop_Details Section ---->
    <div *ngIf="tab == 'Shop_Details'">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Shop Details</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #shopForm="ngForm"
            (ngSubmit)="shopForm.form.valid && submitShopDetail(shopForm)"
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Shop Name <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Shop Name"
                  [(ngModel)]="shopData.ShopName"
                  name="ShopName"
                  #ShopName="ngModel"
                  required
                  [readonly]="shopData.ShopName"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopName.touched || shopForm.submitted) &&
                    ShopName.errors?.required
                  "
                >
                  Shop Name is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Shop Category<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="shopData.ShopCategory"
                  name="ShopCategory"
                  #ShopCategory="ngModel"
                  required
                  [disabled]="shopData.ShopCategory"
                >
                  <option value="">Select Shop Category</option>
                  <option value="Mobile Shop">Mobile Shop</option>
                  <option value="Grahak Seva kendra">Grahak Seva kendra</option>
                  <option value="General Store">General Store</option>
                  <option value="Grocery Shop">Grocery Shop</option>
                  <option value="Xerox Center">Xerox Center</option>
                  <option value="Cyber Cafe">Cyber Cafe</option>
                  <option value="Stationary Shop">Stationary Shop</option>
                  <option value="Paan Shop">Paan Shop</option>
                  <option value="Other">Other</option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopCategory.touched || shopForm.submitted) &&
                    ShopCategory.errors?.required
                  "
                >
                  Shop Category is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Flat/Door/Building <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Flat/Door/Building"
                  [(ngModel)]="shopData.ShopAddress1"
                  name="ShopAddress1"
                  #ShopAddress1="ngModel"
                  required
                  [readonly]="shopData.ShopAddress1"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopAddress1.touched || shopForm.submitted) &&
                    ShopAddress1.errors?.required
                  "
                >
                  Flat/Door/Building is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label
                  >Road/street/Block/Sector<span class="astric">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Road/street/Block/Sector"
                  [(ngModel)]="shopData.ShopAddress2"
                  name="ShopAddress2"
                  #ShopAddress2="ngModel"
                  required
                  [readonly]="shopData.ShopAddress2"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopAddress2.touched || shopForm.submitted) &&
                    ShopAddress2.errors?.required
                  "
                >
                  Road/street/Block/Sector required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Area Locality<span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Area Locality"
                  [(ngModel)]="shopData.ShopAreaLocality"
                  name="ShopAreaLocality"
                  #ShopAreaLocality="ngModel"
                  required
                  [readonly]="shopData.ShopAreaLocality"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopAreaLocality.touched || shopForm.submitted) &&
                    ShopAreaLocality.errors?.required
                  "
                >
                  Area Locality required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>State<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="shopData.ShopState"
                  name="ShopState"
                  #ShopState="ngModel"
                  required
                  (ngModelChange)="selectShopState()"
                  [disabled]="shopData.ShopState"
                >
                  <option value="">Select State</option>
                  <option *ngFor="let state of stateList" [value]="state.id">
                    {{ state.sName }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopState.touched || shopForm.submitted) &&
                    ShopState.errors?.required
                  "
                >
                  State is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>District/City<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="shopData.ShopCity"
                  name="ShopCity"
                  #ShopCity="ngModel"
                  required
                  (ngModelChange)="selectShopCity()"
                  [disabled]="shopData.ShopCity"
                >
                  <option value="">Select District/City</option>
                  <option *ngFor="let city of shopCityList" [value]="city.id">
                    {{ city.name }}
                  </option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopCity.touched || shopForm.submitted) &&
                    ShopCity.errors?.required
                  "
                >
                  District/City is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Tahsils/Area/Mandal<span class="astric">*</span></label>

                <select
                  class="form-control"
                  [(ngModel)]="shopData.ShopPostOffice"
                  name="ShopPostOffice"
                  #ShopPostOffice="ngModel"
                  required
                  [disabled]="shopData.ShopPostOffice"
                >
                  <option value="">Select Tahsils/Area/Mandal</option>
                  <option
                    *ngFor="let tahsil of shopTahsilList"
                    [value]="tahsil.id"
                  >
                    {{ tahsil.tName }}
                  </option>
                </select>

                <span
                  class="text-danger"
                  *ngIf="
                    (ShopPostOffice.touched || shopForm.submitted) &&
                    ShopPostOffice.errors?.required
                  "
                >
                  Tahsils/Area/Mandal required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>PinCode <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter PinCode"
                  [(ngModel)]="shopData.ShopPincode"
                  name="ShopPincode"
                  #ShopPincode="ngModel"
                  required
                  [readonly]="shopData.ShopPincode"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (ShopPincode.touched || shopForm.submitted) &&
                    ShopPincode.errors?.required
                  "
                >
                  PinCode is required
                </span>

                <br class="desktopView" />
                <div class="buttonGroup desktopView">
                  <button
                    type="submit"
                    class="btn btn-primary submitBtn"
                    [disabled]="isButtonDisabled"
                    [ngClass]="{ 'button--loading': isButtonDisabled }"
                  >
                    <span class="button__text">SUBMIT</span>
                  </button>
                  &nbsp;&nbsp;
                  <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
                </div>
              </div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Upload Shop Photo </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="
                            selectFile($event.target.files, 'ShopPhoto')
                          "
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div class="uploadedImage" *ngIf="shopData.ShopPhotoImage">
                  <img src="{{ shopData.ShopPhotoImage }}" />
                </div>
              </div>
            </div>
            <br />
            <div class="buttonGroup mobileView">
              <button
                type="submit"
                class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled"
                [ngClass]="{ 'button--loading': isButtonDisabled }"
              >
                <span class="button__text">SUBMIT</span>
              </button>
              &nbsp;&nbsp;
              <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-----Documents_Details---->
    <div *ngIf="tab == 'Documents_Details'">
      <!-----PROFILE Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Documents Details</h5>
          </div>
        </div>
      </div>

      <div class="row tabRowMV">
        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3 pad0">
          <div
            class="tabs"
            [ngClass]="{ activeTabs: documentTab == 'Aadhaar' }"
            (click)="changeTab('Aadhaar')"
          >
            Aadhaar Card
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3 pad0">
          <div
            class="tabs"
            [ngClass]="{ activeTabs: documentTab == 'Pan_Card' }"
            (click)="changeTab('Pan_Card')"
          >
            Pan Card
          </div>
        </div>
      </div>
      <br />

      <div class="row" *ngIf="documentTab == 'Aadhaar'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #documentForm="ngForm"
            (ngSubmit)="
              documentForm.form.valid && submitDocumentDetail(documentForm)
            "
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Aadhar Card Number <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Aadhar Card Number"
                  [(ngModel)]="documentData.Adhar_Number"
                  name="Adhar_Number"
                  #Adhar_Number="ngModel"
                  (keypress)="dataService.appConstant.keyPress($event)"
                  [readonly]="documentData.Adhar_Number"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Adhar_Number.touched || documentForm.submitted) &&
                    Adhar_Number.errors?.required
                  "
                >
                  Aadhar Card Number is required
                </span>
                <br />
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              ></div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Addhar Front Side </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="
                            selectFile($event.target.files, 'AdharFront')
                          "
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div
                  class="uploadedImage"
                  *ngIf="documentData.Adhar_Front_Image_Image"
                >
                  <img src="{{ documentData.Adhar_Front_Image_Image }}" />
                </div>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Addhar Back Side </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="
                            selectFile($event.target.files, 'adharback')
                          "
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div
                  class="uploadedImage"
                  *ngIf="documentData.Adhar_Back_Image_Image"
                >
                  <img src="{{ documentData.Adhar_Back_Image_Image }}" />
                </div>
              </div>
            </div>
            <br />
            <div class="buttonGroup">
              <button
                type="submit"
                class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled"
                [ngClass]="{ 'button--loading': isButtonDisabled }"
              >
                <span class="button__text">SUBMIT</span>
              </button>
              &nbsp;&nbsp;
              <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>

      <div class="row" *ngIf="documentTab == 'Pan_Card'">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #documentForm="ngForm"
            (ngSubmit)="
              documentForm.form.valid && submitDocumentDetail(documentForm)
            "
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Pan Card Number<span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Pan Card Number"
                  [(ngModel)]="documentData.Pan_Number"
                  name="Pan_Number"
                  #Pan_Number="ngModel"
                  [readonly]="documentData.Pan_Number"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Pan_Number.touched || documentForm.submitted) &&
                    Pan_Number.errors?.required
                  "
                >
                  Pan Card Number is required
                </span>
                <br />
              </div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              ></div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Pan Card Front Side </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="selectFile($event.target.files, 'pancard')"
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="uploadedImage"
                  *ngIf="documentData.Pan_Card_Image_Image"
                >
                  <img src="{{ documentData.Pan_Card_Image_Image }}" />
                </div>
              </div>
            </div>
            <br />
            <div class="buttonGroup">
              <button
                type="submit"
                class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled"
                [ngClass]="{ 'button--loading': isButtonDisabled }"
              >
                <span class="button__text">SUBMIT</span>
              </button>
              &nbsp;&nbsp;
              <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-----Bank_DetailsContainer---->
    <div *ngIf="tab == 'Bank_Details'">
      <!-----PROFILE Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Bank Details</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #bankForm="ngForm"
            (ngSubmit)="bankForm.form.valid && submitBankDetail(bankForm)"
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Bank Name <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Bank Name"
                  [(ngModel)]="bankData.Bank_Name"
                  name="Bank_Name"
                  #Bank_Name="ngModel"
                  required
                  [readonly]="bankData.Bank_Name"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Bank_Name.touched || bankForm.submitted) &&
                    Bank_Name.errors?.required
                  "
                >
                  Bank Name is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Account Holder Name<span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Account Holder Name"
                  [(ngModel)]="bankData.Account_Holder_Name"
                  name="Account_Holder_Name"
                  #Account_Holder_Name="ngModel"
                  required
                  [readonly]="bankData.Account_Holder_Name"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Account_Holder_Name.touched || bankForm.submitted) &&
                    Account_Holder_Name.errors?.required
                  "
                >
                  Account Holder Name is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Account Number <span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Account Number"
                  [(ngModel)]="bankData.Bank_Account_Number"
                  name="Bank_Account_Number"
                  #Bank_Account_Number="ngModel"
                  required
                  (keypress)="dataService.appConstant.keyPress($event)"
                  [readonly]="bankData.Bank_Account_Number"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Bank_Account_Number.touched || bankForm.submitted) &&
                    Bank_Account_Number.errors?.required
                  "
                >
                  Account Number is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>IFSC Code Number<span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter IFSC Code Number"
                  [(ngModel)]="bankData.IFSC_Code"
                  name="IFSC_Code"
                  #IFSC_Code="ngModel"
                  required
                  minlength="11"
                  maxlength="11"
                  [readonly]="bankData.IFSC_Code"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.required
                  "
                >
                  IFSC Code Number is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.pattern
                  "
                >
                  Enter valid IFSC code</span
                >

                <span
                  class="text-danger"
                  *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.minlength
                  "
                >
                  IFSC code must be 11 digit.
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Account Type<span class="astric">*</span></label>
                <select
                  class="form-control"
                  [(ngModel)]="bankData.Account_Type"
                  name="Account_Type"
                  #Account_Type="ngModel"
                  required
                  [disabled]="bankData.Account_Type"
                >
                  <option value="">Select Account Type</option>
                  <option value="Saving Account">Saving Account</option>
                  <option value="Current Account">Current Account</option>
                </select>
                <span
                  class="text-danger"
                  *ngIf="
                    (Account_Type.touched || bankForm.submitted) &&
                    Account_Type.errors?.required
                  "
                >
                  Account Type is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Bank Address<span class="astric">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Bank Address"
                  [(ngModel)]="bankData.Bank_Address"
                  name="Bank_Address"
                  #Bank_Address="ngModel"
                  [readonly]="bankData.Bank_Address"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (Bank_Address.touched || bankForm.submitted) &&
                    Bank_Address.errors?.required
                  "
                >
                  Bank Address is required
                </span>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Upload Document (Cancelled cheque/passbook) </label>
                <div class="uploadImageContainer">
                  <div class="flex100 flexAlignCenter">
                    <div class="w40">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          (change)="
                            selectFile($event.target.files, 'bankDocument')
                          "
                          capture
                        />
                        Choose file
                      </label>
                    </div>
                    <div class="no_file_choose">No file chosen</div>
                    <div class="pictureIcon">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <br />
                <div class="buttonGroup">
                  <button
                    type="submit"
                    class="btn btn-primary submitBtn"
                    [disabled]="isButtonDisabled"
                    [ngClass]="{ 'button--loading': isButtonDisabled }"
                  >
                    <span class="button__text">SUBMIT</span>
                  </button>
                  &nbsp;&nbsp;
                  <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
                </div>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
                *ngIf="bankData.CancelledchequephotoImage"
              >
                <div>
                  <div class="uploadedImage">
                    <img src="{{ bankData.CancelledchequephotoImage }}" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-----Change_Password Container---->
    <div *ngIf="tab == 'Change_Password'">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Change Password</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
          <form
            #changePasswordForm="ngForm"
            (ngSubmit)="
              changePasswordForm.form.valid &&
                submitChangePasswordData(changePasswordForm)
            "
          >
            <div class="row">
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Old Password <span class="astric">*</span></label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter Old Password"
                  [(ngModel)]="changePasswordData.oldPassword"
                  name="oldPassword"
                  #oldPassword="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (oldPassword.touched || changePasswordForm.submitted) &&
                    oldPassword.errors?.required
                  "
                >
                  Old Password is required
                </span>
              </div>

              <div class="form-group col-12 col-sm-12"></div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>New Password <span class="astric">*</span></label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter Old Password"
                  [(ngModel)]="changePasswordData.newPassword"
                  name="newPassword"
                  #newPassword="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (newPassword.touched || changePasswordForm.submitted) &&
                    newPassword.errors?.required
                  "
                >
                  New Password is required
                </span>
              </div>
              <div class="form-group col-12 col-sm-12"></div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"
              >
                <label>Confirm Password<span class="astric">*</span></label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter Old Password"
                  [(ngModel)]="changePasswordData.ConfirmPassword"
                  name="ConfirmPassword"
                  #ConfirmPassword="ngModel"
                  required
                />
                <div
                  class="text-danger"
                  *ngIf="
                    (ConfirmPassword.touched || changePasswordForm.submitted) &&
                    ConfirmPassword.errors?.required
                  "
                >
                  Confirm Password is required
                </div>
                <div
                  class="text-danger"
                  *ngIf="
                    changePasswordData.ConfirmPassword !=
                    changePasswordData.newPassword
                  "
                >
                  Password & Confirm Password Must be same
                </div>
              </div>
            </div>
            <br />
            <div class="buttonGroup">
              <button
                type="submit"
                class="btn btn-primary submitBtn"
                [disabled]="
                  isButtonDisabled ||
                  changePasswordData.ConfirmPassword !=
                    changePasswordData.newPassword
                "
                [ngClass]="{ 'button--loading': isButtonDisabled }"
              >
                <span class="button__text">SUBMIT</span>
              </button>
              &nbsp;&nbsp;
              <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="tab == 'Download'">
      <!-----Section Heading---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>Transaction Report</h5>
          </div>
        </div>
      </div>
      <!-----Section Contain---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <!---FILTER-->
          <div class="row">
            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
              <label>Financial year <span class="astric">*</span></label>
              <select
                class="form-control"
                [(ngModel)]="reportFilter.reportType"
                name="ReportType"
                #ReportType="ngModel"
              >
                <option value="">Select Financial year</option>
                <option value="">2021-22</option>
                <option value="">2020-21</option>
                <option value="">2019-20</option>
                <option value="">2018-19</option>
                <option value="">2017-18</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
          <span class="grayClr font14">Show</span>
          <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span class="grayClr font14">entity</span>
        </div>
      </div>

      <!---TABLE--->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div id="reportTable" class="tableMv">
            <table id="htmlData">
              <thead>
                <tr>
                  <th class="brdWhiteR brdTLR">Sr No</th>
                  <th class="brdWhiteR">Name</th>
                  <th class="brdWhiteR">Date</th>
                  <th class="brdTRR">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bodyTr"
                  *ngFor="
                    let item of reportList
                      | filter: reportFilter.searchKey
                      | paginate
                        : {
                            id: 'reportFilterList',
                            itemsPerPage: reportFilter.numPerPage,
                            currentPage: reportFilter.pageNum,
                            totalItems: reportList.length
                          };
                    let i = index
                  "
                  id="eachRecord"
                >
                  <td class="brdGrayR">1</td>
                  <td class="brdGrayR">2021-Financial</td>
                  <td class="brdGrayR">30-04-2021</td>
                  <td (click)="exportPDF()">
                    <i class="icofont-download"></i>
                  </td>
                </tr>
                <tr
                  class="bodyTr"
                  *ngIf="reportList?.length <= 0 && !isLoading"
                >
                  <td colspan="50" class="noTableRecord">
                    No record found.......
                  </td>
                </tr>
                <tr class="bodyTr" *ngIf="isLoading">
                  <td colspan="50" class="noTableRecord">
                    <div class="loader"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
