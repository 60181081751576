import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import {Location} from '@angular/common';
import { ExternalAPIService } from '../../service/external-api.service';

@Component({
  selector: 'app-mobile-recharge',
  templateUrl: './mobile-recharge.component.html',
  styleUrls: ['./mobile-recharge.component.scss']
})
export class MobileRechargeComponent implements OnInit {

  public sessionUserData:any = {};
  public apiResponse:any;
  public providers:any = [];
  public providersPostPaid:any = [];

  constructor(  
    public router:Router,
    public externalAPIService:ExternalAPIService,
    public _location:Location) { 
      this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    }

  ngOnInit(): void {}

  
  /***
   * GET PAY2ALL MOBILE RECHARGE PROVIDERS LIST
   * SERVICE ID OF MOBILE RECHARGE IS : 1
   */

   getProviders(){
    localStorage.setItem('setheadType', 'Auth');
    this.externalAPIService.getProviders().subscribe(response => {
      this.apiResponse = response
      console.log(response)
      if( this.apiResponse.providers){
        this.apiResponse.providers.forEach(provider => {
            if(provider.id == 88 || provider.id == 2 || provider.id == 1 || provider.id == 8){
              this.providers.push(provider)
            }
            if(provider.id == 153 || provider.id == 25 || provider.id == 23 || provider.id == 58){
              this.providersPostPaid.push(provider)
            }
        });
      }
    })
  }


  procceedRechargePrepaid(providerName,id){
    this.router.navigate(['/Mobile'], { queryParams: { recharge: "Prepaid",Provider:providerName,uid:id } });
  }
  procceedRechargePostPaid(providerName,id){
    this.router.navigate(['/Mobile'], { queryParams: { recharge: "Postpaid",Provider:providerName,uid:id} });
  }
}
