<div class="headerContainer">
    <div class="dashbordlogo"><img src="assets/images/logo1.png" /></div>
    <div class="desktopView">
        <h4 class="pageHeading" *ngIf="tab != 'Success_Page'">
            Complete your profile detail
        </h4>
    </div>
    <div class="logoutSection" (click)="logOut()">
        <div>
            <i class="fa fa-power-off" style="font-size: 17px" aria-hidden="true"></i>
        </div>
        <div>
            <span class="theamColor"><strong> Logout </strong></span>
        </div>
    </div>
</div>

<section>
    <div class="container-fluid">
        <h5 class="pageHeading mrgT mobileView" *ngIf="tab != 'Success_Page'">
            Complete your profile detail
        </h5>

        <ul class="progressBar" *ngIf="tab != 'Success_Page'">
            <li (click)="changeMainTab('Shop_Details')">
                <div class="stepsImg" [ngClass]="{
            stepsImgActive: isShopTabCompleted,
            inProgressBack: tab == 'Shop_Details'
          }">
                    <img *ngIf="!isShopTabCompleted && tab != 'Shop_Details'" src="assets/images/pro2.png" />
                    <img *ngIf="isShopTabCompleted || tab == 'Shop_Details'" src="assets/images/pro2w.png" />
                </div>
                <div class="steps-heading" [ngClass]="{
            active: isShopTabCompleted,
            inProgressClr: tab == 'Shop_Details'
          }">
                    Shop Detail
                </div>
                <div class="stepConnector"></div>
            </li>

            <li (click)="changeMainTab('My_Profile')">
                <div class="stepsImg" [ngClass]="{
            stepsImgActive: isProfileTabCompleted,
            inProgressBack: tab == 'My_Profile'
          }">
                    <img *ngIf="!isProfileTabCompleted && tab != 'My_Profile'" src="assets/images/pro1.png" />
                    <img *ngIf="isProfileTabCompleted || tab == 'My_Profile'" src="assets/images/pro1w.png" />
                </div>
                <div class="steps-heading active" [ngClass]="{
            active: isProfileTabCompleted,
            inProgressClr: tab == 'My_Profile'
          }">
                    Profile Detail
                </div>
                <div class="stepConnector"></div>
            </li>
            <li (click)="changeMainTab('Documents_Details')">
                <div class="stepsImg" [ngClass]="{
            stepsImgActive: isDocumentTabCompleted,
            inProgressBack: tab == 'Documents_Details'
          }">
                    <img *ngIf="!isDocumentTabCompleted && tab != 'Documents_Details'" src="assets/images/pro3.png" />
                    <img *ngIf="isDocumentTabCompleted || tab == 'Documents_Details'" src="assets/images/pro3w.png" />
                </div>
                <div class="steps-heading" [ngClass]="{
            active: isDocumentTabCompleted,
            inProgressClr: tab == 'Documents_Details'
          }">
                    Document Detail
                </div>
                <div class="stepConnector"></div>
            </li>
            <li (click)="changeMainTab('Bank_Details')">
                <div class="stepsImg" [ngClass]="{
            stepsImgActive: isBankTabCompleted,
            inProgressBack: tab == 'Bank_Details'
          }">
                    <img *ngIf="!isBankTabCompleted && tab != 'Bank_Details'" src="assets/images/pro4.png" />
                    <img *ngIf="isBankTabCompleted || tab == 'Bank_Details'" src="assets/images/pro4w.png" />
                </div>
                <div class="steps-heading" [ngClass]="{
            active: isBankTabCompleted,
            inProgressClr: tab == 'Bank_Details'
          }">
                    Bank Detail
                </div>
            </li>
        </ul>
    </div>
</section>
<br class="desktopView" />
<section>
    <div class="container-fluid">
        <div *ngIf="tab == 'My_Profile'">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submitProfileDetail(appForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Full Name <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Full Name" [(ngModel)]="myProfileData.FullName" name="FullName" #FullName="ngModel" required />
                                <span class="text-danger" *ngIf="
      (FullName.touched || appForm.submitted) &&
      FullName.errors?.required
    ">Full Name is required</span>
                            </div>
                            <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label>Full Name <span class="astric">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Full Name"
                  [(ngModel)]="myProfileData.FullName" name="FullName" #FullName="ngModel" />
                <span class="text-danger" *ngIf="
                    (FullName.touched || appForm.submitted) &&
                    FullName.errors?.required
                  ">
                  Full Name is required
                </span>
              </div> -->

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Mobile No <span class="astric">*</span></label>
                                <input type="text" class="form-control cNotAllow" placeholder="Enter Mobile No" [ngClass]="{ cNotAllow: myProfileData.MobileNo }" [(ngModel)]="myProfileData.MobileNo" name="MobileNo" #MobileNo="ngModel" disabled (keypress)="dataService.appConstant.keyPress($event)"
                                    maxlength="10" minlength="10" />
                                <span class="text-danger" *ngIf="
                    (MobileNo.touched || appForm.submitted) &&
                    MobileNo.errors?.required
                  ">
                  Mobile No is required
                </span>
                                <span class="text-danger" *ngIf="
                    (MobileNo.touched || appForm.submitted) &&
                    MobileNo.errors?.minlength
                  ">
                  Mobile No must be 10 digit.
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Email <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Email" [(ngModel)]="myProfileData.EmailId" name="EmailId" #EmailId="ngModel" required />
                                <span class="text-danger" *ngIf="
      (EmailId.touched || appForm.submitted) &&
      EmailId.errors?.required
    ">Email is required</span>
                                <span class="text-danger" *ngIf="
      (EmailId.touched || appForm.submitted) &&
      EmailId.errors?.email
    ">
                  Enter valid email address.
                </span>
                            </div>
                            <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label>Email<span class="astric">*</span></label>
                <input type="email" class="form-control" placeholder="Enter Email" [(ngModel)]="myProfileData.EmailId"
                  name="EmailId" #EmailId="ngModel" email />
                <span class="text-danger" *ngIf="
                    (EmailId.touched || appForm.submitted) &&
                    EmailId.errors?.required
                  ">
                  Email is required
                </span>
                <span class="text-danger" *ngIf="
                    (EmailId.touched || appForm.submitted) &&
                    EmailId.errors?.email
                  ">
                  Enter valid email address.
                </span>
              </div> -->

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Date Of Birth<span class="astric">*</span></label>
                                <input type="date" class="form-control" placeholder="Select Date Of Birth" [(ngModel)]="myProfileData.DOB" name="DOB" #DOB="ngModel" />
                                <span class="text-danger" *ngIf="
                    ((DOB.touched || appForm.submitted) &&
                    DOB.errors?.required) || birthDate
                  ">
                  Date Of Birth is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>State<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="myProfileData.StateId" name="StateId" #StateId="ngModel" required (ngModelChange)="selectProfileState()">
                  <option value="">Select State</option>
                  <option *ngFor="let state of stateList" [value]="state.id">
                    {{ state.sName }}
                  </option>
                </select>
                                <span class="text-danger" *ngIf="
                    (StateId.touched || appForm.submitted) &&
                    StateId.errors?.required
                  ">
                  State is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>District/City<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="myProfileData.CityId" name="CityId" #CityId="ngModel" required (ngModelChange)="selectProfileCity()">
                  <option value="">Select District/City</option>
                  <option *ngFor="let city of cityList" [value]="city.id">
                    {{ city.name }}
                  </option>
                </select>
                                <span class="text-danger" *ngIf="
                    (CityId.touched || appForm.submitted) &&
                    CityId.errors?.required
                  ">
                  District/City is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Tahsils/Area/Mandal<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="myProfileData.TahsilsId" name="TahsilsId" #TahsilsId="ngModel" required>
                  <option value="">Select Tahsils/Area/Mandal</option>
                  <option *ngFor="let tahasil of tahsilList" [value]="tahasil.id">
                    {{ tahasil.tName }}
                  </option>
                </select>
                                <span class="text-danger" *ngIf="
                    (TahsilsId.touched || appForm.submitted) &&
                    TahsilsId.errors?.required
                  ">
                  Tahsils/Area/Mandal: is required
                </span>

                                <div class="row">
                                    <div class="
                      form-group
                      col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12
                    ">
                                        <label>PinCode <span class="astric">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter PinCode" [(ngModel)]="myProfileData.Pincode" name="Pincode" #Pincode="ngModel" required (keypress)="dataService.appConstant.keyPress($event)" maxlength="6" minlength="6" />
                                        <span class="text-danger" *ngIf="
                        (Pincode.touched || appForm.submitted) &&
                        Pincode.errors?.required
                      ">
                      PinCode is required
                    </span>
                                        <span class="text-danger" *ngIf="
                        (Pincode.touched || appForm.submitted) &&
                        Pincode.errors?.minlength
                      ">
                      PinCode must be 6 digit
                    </span>

                                        <br class="desktopView" />
                                        <div class="buttonGroup desktopView">
                                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                        <span class="button__text">SUBMIT</span>
                      </button> &nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Upload Profile Photo </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="selectFile($event.target.files, 'Profile')" capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="uploadedImage" *ngIf="myProfileData.ProfilePhotoImage">
                                    <img class="profilePic" src="{{ myProfileData.ProfilePhotoImage }}" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="buttonGroup mobileView">
                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
            </div>
        </div>

        <!-----Shop_Details Section ---->
        <div *ngIf="tab == 'Shop_Details'">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                    <form #shopForm="ngForm" (ngSubmit)="shopForm.form.valid && submitShopDetail(shopForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Shop Name <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Shop Name" [(ngModel)]="shopData.ShopName" name="ShopName" #ShopName="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (ShopName.touched || shopForm.submitted) &&
                    ShopName.errors?.required
                  ">
                  Shop Name is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Shop Category<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="shopData.ShopCategory" name="ShopCategory" #ShopCategory="ngModel" required>
                  <option value="">Select Shop Category</option>
                  <option value="Mobile Shop">Mobile Shop</option>
                  <option value="Grahak Seva kendra">Grahak Seva kendra</option>
                  <option value="General Store">General Store</option>
                  <option value="Grocery Shop">Grocery Shop</option>
                  <option value="Xerox Center">Xerox Center</option>
                  <option value="Cyber Cafe">Cyber Cafe</option>
                  <option value="Stationary Shop">Stationary Shop</option>
                  <option value="Paan Shop">Paan Shop</option>
                  <option value="Other">Other</option>
                </select>
                                <span class="text-danger" *ngIf="
                    (ShopCategory.touched || shopForm.submitted) &&
                    ShopCategory.errors?.required
                  ">
                  Shop Category is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Flat/Door/Building <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Flat/Door/Building" [(ngModel)]="shopData.ShopAddress1" name="ShopAddress1" #ShopAddress1="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (ShopAddress1.touched || shopForm.submitted) &&
                    ShopAddress1.errors?.required
                  ">
                  Flat/Door/Building is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Road/street/Block/Sector<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Road/street/Block/Sector" [(ngModel)]="shopData.ShopAddress2" name="ShopAddress2" #ShopAddress2="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (ShopAddress2.touched || shopForm.submitted) &&
                    ShopAddress2.errors?.required
                  ">
                  Road/street/Block/Sector required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Area Locality<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Area Locality" [(ngModel)]="shopData.ShopAreaLocality" name="ShopAreaLocality" #ShopAreaLocality="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (ShopAreaLocality.touched || shopForm.submitted) &&
                    ShopAreaLocality.errors?.required
                  ">
                  Area Locality required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>State<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="shopData.ShopState" name="ShopState" #ShopState="ngModel" required (ngModelChange)="selectShopState()">
                  <option value="">Select State</option>
                  <option *ngFor="let state of stateList" [value]="state.id">
                    {{ state.sName }}
                  </option>
                </select>
                                <span class="text-danger" *ngIf="
                    (ShopState.touched || shopForm.submitted) &&
                    ShopState.errors?.required
                  ">
                  State is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>District/City<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="shopData.ShopCity" name="ShopCity" #ShopCity="ngModel" required (ngModelChange)="selectShopCity()">
                  <option value="">Select District/City</option>
                  <option *ngFor="let city of shopCityList" [value]="city.id">
                    {{ city.name }}
                  </option>
                </select>
                                <span class="text-danger" *ngIf="
                    (ShopCity.touched || shopForm.submitted) &&
                    ShopCity.errors?.required
                  ">
                  District/City is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Tahsils/Area/Mandal<span class="astric">*</span></label>

                                <select class="form-control" [(ngModel)]="shopData.ShopPostOffice" name="ShopPostOffice" #ShopPostOffice="ngModel" required>
                  <option value="">Select Tahsils/Area/Mandal</option>
                  <option *ngFor="let tahsil of shopTahsilList" [value]="tahsil.id">
                    {{ tahsil.tName }}
                  </option>
                </select>

                                <span class="text-danger" *ngIf="
                    (ShopPostOffice.touched || shopForm.submitted) &&
                    ShopPostOffice.errors?.required
                  ">
                  Tahsils/Area/Mandal required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>PinCode <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter PinCode" [(ngModel)]="shopData.ShopPincode" name="ShopPincode" #ShopPincode="ngModel" required (keypress)="dataService.appConstant.keyPress($event)" maxlength="6" minlength="6" />
                                <span class="text-danger" *ngIf="
                    (ShopPincode.touched || shopForm.submitted) &&
                    ShopPincode.errors?.required
                  ">
                  PinCode is required
                </span>
                                <span class="text-danger" *ngIf="
                    (ShopPincode.touched || shopForm.submitted) &&
                    ShopPincode.errors?.minlength
                  ">
                  PinCode must be 6 digit
                </span>

                                <br class="desktopView" />
                                <div class="buttonGroup desktopView">
                                    <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Upload shop photo </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="
                            selectFile($event.target.files, 'ShopPhoto')
                          " capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="uploadedImage" *ngIf="shopData.ShopPhotoImage">
                                    <img src="{{ shopData.ShopPhotoImage }}" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="buttonGroup mobileView">
                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
            </div>
        </div>

        <!-----Documents_Details---->
        <div *ngIf="tab == 'Documents_Details'">
            <div class="row" *ngIf="documentTab == 'Aadhaar'">
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                    <form #documentForm="ngForm" (ngSubmit)="
              documentForm.form.valid && submitDocumentDetail(documentForm)
            ">
                        <div class="row">
                            <div class="col-12 col-sm-12 subTabHeading">
                                <b>Adhar Card Detail</b>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Aadhar Card Number <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Aadhar Card Number" [(ngModel)]="documentData.Adhar_Number" name="Adhar_Number" #Adhar_Number="ngModel" (keypress)="dataService.appConstant.keyPress($event)" />
                                <span class="text-danger" *ngIf="
                    (Adhar_Number.touched || documentForm.submitted) &&
                    Adhar_Number.errors?.required
                  ">
                  Aadhar Card Number is required
                </span>
                                <br />
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"></div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Addhar Front Side </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="
                            selectFile($event.target.files, 'AdharFront')
                          " capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="uploadedImage" *ngIf="documentData.Adhar_Front_Image_Image">
                                    <img src="{{ documentData.Adhar_Front_Image_Image }}" />
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Addhar Back Side </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="
                            selectFile($event.target.files, 'adharback')
                          " capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="uploadedImage" *ngIf="documentData.Adhar_Back_Image_Image">
                                    <img src="{{ documentData.Adhar_Back_Image_Image }}" />
                                </div>
                            </div>

                            <!-----PAN CARD DETAIL---->
                            <div class="subTabHeading col-12 col-sm-12">
                                <b>Pan Card Detail</b>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Pan Card Number<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Pan Card Number" [(ngModel)]="documentData.Pan_Number" name="Pan_Number" id="Pan_Number_Id" #Pan_Number="ngModel" />
                                <span class="text-danger" *ngIf="
                    (Pan_Number.touched || documentForm.submitted) &&
                    Pan_Number.errors?.required
                  ">
                  Pan Card Number is required
                </span>
                                <br />
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Pan Card Front Side </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="selectFile($event.target.files, 'pancard')" capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="uploadedImage" *ngIf="documentData.Pan_Card_Image_Image">
                                    <img src="{{ documentData.Pan_Card_Image_Image }}" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="buttonGroup">
                            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
            </div>
        </div>

        <!-----Bank_DetailsContainer---->
        <div *ngIf="tab == 'Bank_Details'">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                    <form #bankForm="ngForm" (ngSubmit)="bankForm.form.valid && submitBankDetail(bankForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Bank Name <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Bank Name" [(ngModel)]="bankData.Bank_Name" name="Bank_Name" #Bank_Name="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (Bank_Name.touched || bankForm.submitted) &&
                    Bank_Name.errors?.required
                  ">
                  Bank Name is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Account Holder Name<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Account Holder Name" [(ngModel)]="bankData.Account_Holder_Name" name="Account_Holder_Name" #Account_Holder_Name="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (Account_Holder_Name.touched || bankForm.submitted) &&
                    Account_Holder_Name.errors?.required
                  ">
                  Account Holder Name is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Account Number <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Account Number" [(ngModel)]="bankData.Bank_Account_Number" name="Bank_Account_Number" #Bank_Account_Number="ngModel" required (keypress)="dataService.appConstant.keyPress($event)" />
                                <span class="text-danger" *ngIf="
                    (Bank_Account_Number.touched || bankForm.submitted) &&
                    Bank_Account_Number.errors?.required
                  ">
                  Account Number is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>IFSC Code Number<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter IFSC Code Number" [(ngModel)]="bankData.IFSC_Code" name="IFSC_Code" #IFSC_Code="ngModel" required minlength="11" maxlength="11" />

                                <span class="text-danger" *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.minlength
                  ">
                  IFSC code must be 11 digit.
                </span>

                                <span class="text-danger" *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.required
                  ">
                  IFSC Code Number is required
                </span>
                                <span class="text-danger" *ngIf="
                    (IFSC_Code.touched || bankForm.submitted) &&
                    IFSC_Code.errors?.pattern
                  ">
                  Enter valid IFSC code</span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Account Type<span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="bankData.Account_Type" name="Account_Type" #Account_Type="ngModel" required>
                  <option value="">Select Account Type</option>
                  <option value="Saving Account">Saving Account</option>
                  <option value="Current Account">Current Account</option>
                </select>
                                <span class="text-danger" *ngIf="
                    (Account_Type.touched || bankForm.submitted) &&
                    Account_Type.errors?.required
                  ">
                  Account Type is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Enter Bank Address <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Bank Address" [(ngModel)]="bankData.Bank_Address" name="Bank_Address" #Bank_Address="ngModel" required />
                                <span class="text-danger" *ngIf="
      (Bank_Address.touched || bankForm.submitted) &&
      Bank_Address.errors?.required
    ">
                  Enter Bank Address is required
                </span>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
                                <label>Upload Document (Cancelled cheque/passbook) </label>
                                <div class="uploadImageContainer">
                                    <div class="flex100 flexAlignCenter">
                                        <div class="w40">
                                            <label class="custom-file-upload">
                        <input type="file" (change)="
                            selectFile($event.target.files, 'bankDocument')
                          " capture />
                        Choose file
                      </label>
                                        </div>
                                        <div class="no_file_choose">No file chosen</div>
                                        <div class="pictureIcon">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="uploadedImage" *ngIf="bankData.CancelledchequephotoImage">
                                    <img src="{{ bankData.CancelledchequephotoImage }}" />
                                </div>

                                <div class="uploadedImage" *ngIf="
                    !bankData.CancelledchequephotoImage &&
                    bankData.CancelledChequePhoto
                  ">
                                    <img src="{{ bankData.CancelledChequePhoto }}" />
                                </div>

                                <br />
                                <div class="buttonGroup">
                                    <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabled" [ngClass]="{ 'button--loading': isButtonDisabled }">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
            </div>
        </div>

        <div *ngIf="tab == 'Success_Page'">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
                    <br />
                    <br />
                    <!-----SUCCESS MESSAGE-->
                    <div class="processSuccess">
                        <i class="icofont-check-circled"></i>
                        <br />
                        <br />
                        <h6><b>Congratulations</b>.</h6>
                        <div class="font14 grayClr">
                            Your on boarding process has been completed.
                        </div>
                        <br />
                        <br />
                        <button class="btn btn-warning submitBtn" routerLink="">
              Go To Dashboard
            </button>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2"></div>
            </div>
        </div>
    </div>
</section>

<br />

<!-- <div class="fixedBtmRightBtn desktopView" routerLink="/Dashboard">
  SKIP NOW <i class="fa fa-angle-double-right"></i>
</div> -->