import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '../../service/data-service.service';
import { Location } from '@angular/common';
import { ExternalAPIService } from '../../service/external-api.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public sessionUserData: any = {};
  public walletData: any = {};

  constructor(
    public dataService: DataServiceService,
    private location: Location,
    public externalAPIService: ExternalAPIService,
  ) {
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage("Page_Dashboard");
    this.dataService.sendMessage('showSideBar');
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.location.replaceState('/');

    this.dataService.getMessage().subscribe((msg) => {
      if (msg == 'walletUpdated') {
        this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
      }
    })

  }

  ngOnInit(): void {
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage("Page_Dashboard");
    this.dataService.sendMessage('showSideBar');

    if (this.sessionUserData.RegistrationId) {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    }

    this.getProviders();
    // this.getBusStation();
  }


  /***
  * GET PAY2ALL MOBILE RECHARGE PROVIDERS LIST
  * SERVICE ID OF MOBILE RECHARGE IS : 1
  */
  getProviders() {
    sessionStorage.setItem('setheadType', 'Auth');
    this.externalAPIService.getProviders().subscribe(response => {
      sessionStorage.setItem("providers", JSON.stringify(response))
    })
  }


  /***
 * GET PAY2ALL MOBILE RECHARGE PROVIDERS LIST
 * SERVICE ID OF MOBILE RECHARGE IS : 1
 */
  getBusStation() {
    sessionStorage.setItem('setheadType', 'Auth');
    this.externalAPIService.getStation().subscribe(response => {

    })
  }

  // openTab(tab) {
  //   this.sideBarTab = tab;
  //   this.isProfileTab = false;
  //   this.router.navigate([tab]);
  // }

}
