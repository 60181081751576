import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-bank-account-transfer',
  templateUrl: './bank-account-transfer.component.html',
  styleUrls: ['./bank-account-transfer.component.scss']
})
export class BankAccountTransferComponent implements OnInit {


  fileName = 'BankTransferReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public reportFilter:any = {numPerPage:10,pageNum:1};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public sessionUserData:any = {};

  public reportList:any = [];
  public isLoading:boolean = false;
  public isShowFilter:boolean = true;
  constructor(
    public dataService:DataServiceService
  ) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    if (window.innerWidth < 768) {
      this.isShowFilter = false;
    }

  }

  ngOnInit(): void {
    
  }


  submit(){

  }





  
  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  
  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }


}
