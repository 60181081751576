import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DatePipe } from '@angular/common';
declare var $: any;

import { Router } from '@angular/router';


@Component({
  selector: 'app-pan-service-report',
  templateUrl: './pan-service-report.component.html',
  styleUrls: ['./pan-service-report.component.scss']
})
export class PanServiceReportComponent implements OnInit {

  fileName = 'PanCard.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public reportFilter: any = { numPerPage: 10, pageNum: 1 };
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public sessionUserData: any = {};

  public reportList: any = [];
  public isLoading: boolean = false;
  public isShowFilter: boolean = true;


  public startDate: any;
  public toDate: any;
  public formData: any = {};
  public isButtonDisabledPop: boolean = false;
  public agentId: any = false;

  constructor(
    public dataService: DataServiceService,
    private datePipe: DatePipe,
    public router: Router,
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    console.warn(this.sessionUserData);

    if (window.innerWidth < 768) {
      this.isShowFilter = false;
    }
    // var firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    // this.reportFilter.startDate =  this.datePipe.transform(firstDay, 'yyyy-MM-dd');
    // this.reportFilter.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  }

  ngOnInit(): void {
    this.getRport();
    this.formData.ComplaintRegisterProduct = "Pan Crad"
    this.getAgentId();
  }

  back() {
    this.router.navigate(['/Pan_Card_Services'], { replaceUrl: true });
  }


  submit() {
    if (this.reportFilter.startDate <= this.reportFilter.endDate) {
      this.isButtonDisabled = true;
      this.reportList = [];
      this.isLoading = true;
      this.getRport();
    } else {
      this.dataService.appConstant.errorToast("End date must be greater than start date")
    }
  }

  clear() {
    this.reportFilter.startDate = null;
    this.reportFilter.toDate = null;
    this.reportFilter.fromDate = null;
    this.reportFilter.endDate = null;
    this.reportFilter.searchKey = null;
    this.getRport();
  }


  getRport() {
    this.reportList = []
    this.isLoading = true;
    if (this.reportFilter.fromDate) {
      this.reportFilter.startDate = this.reportFilter.fromDate + "T23:39:14"
    }

    if (this.reportFilter.endDate) {
      this.reportFilter.toDate = this.reportFilter.endDate + "T23:39:14"
    }

    this.dataService.get(this.dataService.appConstant.APIS['GET_PAN_CARD_REPORT'] + "&fromdate=" + this.reportFilter.startDate + "&Todate=" + this.reportFilter.toDate + "&RegistrationId=" + this.sessionUserData.RegistrationId + "&PageNo=1").subscribe(result => {
      this.apiResponse = result;
      this.reportList = this.apiResponse;
      this.isLoading = false;
      this.isButtonDisabled = false;
    }, err => {
      this.isLoading = false;
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast('Something is wrong..please try again');
    });
  }



  openComplaintForm(item) {
    this.formData = item;
    this.formData.ComplaintRegisterProduct = "Pan Card";
    this.formData.TransactionId = this.formData.PanId
    $('#ComplaintForm').modal('show');
  }




  submitCompltain(appForm: NgForm) {
    this.isButtonDisabledPop = true;
    this.formData.TransactionId = this.formData.PanId
    this.formData.ComplaintType = "Pan Card"
    var firstDay = new Date();
    this.formData.CreatedDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd')
    this.formData.Message = `Transaction of Amount: ${this.formData.TotalAmount}, Message: ${this.formData.Message}`;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['ADD_COMPLAINT']).subscribe(response => {
      this.isButtonDisabledPop = false;
      this.dataService.appConstant.successToast("Complaint submitted successfully")
      appForm.onReset();
      $('#ComplaintForm').modal('hide');
      this.getRport();
    }, err => {
      this.isButtonDisabledPop = false;
    })
  }


  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  getAgentId() {
    this.dataService.get(this.dataService.appConstant.APIS['GET_PAN_AGENT_ID'] + this.sessionUserData.RegistrationId).subscribe(response => {
      if (response.Status == true) {
        console.log();
        if (response.data.PanAgentId != "" && response.data.PanAgentId != undefined && response.data.PanAgentId != null && response.data.PanAgentId != 0) {
          this.agentId = response.data.PanAgentId;
        } else {
          this.agentId = false;
        }
      } else {
        this.dataService.appConstant.errorToast('Something is wrong..please try again');
      }
    })
  }

}
