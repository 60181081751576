import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-income-tax-return',
  templateUrl: './income-tax-return.component.html',
  styleUrls: ['./income-tax-return.component.scss']
})
export class IncomeTaxReturnComponent implements OnInit {

  public sessionUserData: any = {};
  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public ITR1: any;
  public ITR2: any;
  public ITR3: any;
  public ITR4: any;
  public maxBirthDate: any = "";
  public itrDocuments: any = []
  public current_itr_payment: any = "";
  public ca_seal_amount: any = 0;
  public tab: any = 'table';
  public documentTab: any = 'table';

  constructor(
    public dataService: DataServiceService,
    public http: HttpClient
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.formData = {};
    this.formData.retailer_id = this.sessionUserData.RegistrationId.toString();
    this.formData.incomes = [];
    this.ITR1 = { amount: 450, comm: 20 }
    this.ITR2 = { amount: 450, comm: 20 }
    this.ITR3 = { amount: 450, comm: 20 }
    this.ITR4 = { amount: 450, comm: 20 }
    let date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.maxBirthDate = date;
  }

  ngOnInit(): void {
  }




  submit(appForm: NgForm) {
    if (this.formData.income && this.formData.company) {
      this.formData.incomes = [];
      this.formData.incomes.push({
        name: "income",
        value: this.formData.income
      });
      this.formData.incomes.push({
        name: "Employee company name",
        value: this.formData.company
      });
      delete this.formData["company"];
      delete this.formData["income"];
    }
      if (this.formData.agree_income) {
        this.formData.incomes.push({
          name: "agree_income",
          value: this.formData.agree_income
        });
        this.formData.incomes.push({
          name: "agree_address",
          value: this.formData.agree_address
        });
        this.formData.incomes.push({
          name: "agree_area",
          value: this.formData.agree_area
        });
        delete this.formData["agree_income"];
        delete this.formData["agree_address"];
        delete this.formData["agree_area"];
      }
      if (this.formData.business_income) {
        this.formData.incomes.push({
          name: "business_income",
          value: this.formData.business_income
        });
        this.formData.incomes.push({
          name: "business_name",
          value: this.formData.business_name
        });
        this.formData.incomes.push({
          name: "business_type",
          value: this.formData.business_type
        });
        delete this.formData["business_income"];
      }
      if (this.formData.other_income || this.formData.source_of_income) {
        this.formData.incomes.push({
          name: "other_income",
          value: this.formData.other_income
        });
        this.formData.incomes.push({
          name: "source_of_income",
          value: this.formData.source_of_income
        });
        delete this.formData["other_income"];
        delete this.formData["source_of_income"];
      }
      if (this.formData.balance_sheet) {
        this.formData.incomes.push({
          name: "balance_sheet",
          value: this.formData.balance_sheet
        });
        delete this.formData["balance_sheet"];
      }
      if (this.formData.profit_loss) {
        this.formData.incomes.push({
          name: "profit_loss",
          value: this.formData.profit_loss
        });
        delete this.formData["profit_loss"];
      }
      if (this.formData.capital_accounts) {
        this.formData.incomes.push({
          name: "capital_accounts",
          value: this.formData.capital_accounts
        });
        delete this.formData["capital_accounts"];
      }
    console.log(this.formData);
    this.dataService.post1(this.formData, this.dataService.appConstant.APIS['ITR']).subscribe(response => {
      this.apiResponse = response;
      console.log(response);
      if (this.apiResponse.requestid) {
        this.dataService.appConstant.successToast(this.apiResponse.msg);
        this.formData = {};
        this.formData.requestid = this.apiResponse.requestid;
        if (this.documentTab ===  'details1') {
          this.changeTab('document1', 'ITR1');
        } else if (this.documentTab ===  'details2') {
          this.changeTab('document2', 'ITR2');
        } else if (this.documentTab ===  'details3') {
          this.changeTab('document3', 'ITR3');
        } else if (this.documentTab ===  'details4') {
          this.changeTab('document4', 'ITR4');
        }
        this.isButtonDisabled = true;
      } else {
        this.dataService.appConstant.errorToast("there was an error saving record");
      }
    }, err => {
      console.log(err);
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }
  changeTab(tab, type) {
    this.documentTab = tab;
    this.formData.itr_type = type;
    if (type === 'ITR1') {
      this.current_itr_payment = this.ITR1;
    } else if (type === 'ITR2') {
      this.current_itr_payment = this.ITR2;
    } else if (type === 'ITR3') {
      this.current_itr_payment = this.ITR3;
    } else if (type === 'ITR4') {
      this.current_itr_payment = this.ITR4;
    }
  }
  isMale() {
    this.formData.gender = "M"
  }
  isFemale() {
    this.formData.gender = "F"
  }
  isOther() {
    this.formData.gender = "O"
  }
  convert(input1: string, input2: string) {
    this.formData.contact = Number(input1);
    this.formData.whatsapp_no = Number(input2);
  }
  handleFileChange(files) {
    let file = files.files[0];
    if (files.name === 'any_doc1' || files.name === 'any_doc2') {
      this.itrDocuments = this.itrDocuments.filter(doc => doc.name !== file.name);
      this.itrDocuments.push({
        name: file.name,
        document: file
      });
    } else {
      this.itrDocuments = this.itrDocuments.filter(doc => doc.name !== files.name);
      this.itrDocuments.push({
        name: files.name,
        document: file
      })
    }
    console.log(this.itrDocuments);
  }

  onlyNumberKey(evt) {
    // Only ASCII character in that range allowed
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
}

  uploadFiles(appForm: NgForm) {
    this.formData.documents = [];
    this.itrDocuments.forEach(obj => {
      this.isButtonDisabled = true;
      let file_data = new FormData();
      file_data.append('document', obj.document, obj.document.name);
      this.dataService.post_file(file_data, this.formData.requestid).subscribe(response => {
        this.apiResponse = response;
        console.log(response);
        if (this.apiResponse.resp) {
          this.isButtonDisabled = false;
          this.formData.documents.push({
            name: obj.name,
            s3_link: this.apiResponse.resp
          });
          this.dataService.appConstant.successToast(`uploaded ${obj.name} file`);
        } else {
          this.dataService.appConstant.errorToast(`failed to upload ${obj.name} file`);
        }
      }, err => {
        console.log(err);
        this.dataService.appConstant.errorToast("Server error....Please try again");
      })
    })
    appForm.resetForm();
  }

  submitDocument() {
    let requestid = this.formData.requestid;
    let documents = this.formData.documents;
    this.formData = {};
    this.formData.requestid = requestid;
    this.formData.documents = documents;
    console.log("formdata: ----------------");
    console.log(this.formData);
    this.isButtonDisabled = true;
    this.dataService.post1(this.formData, this.dataService.appConstant.APIS['ITR_DOCUMENTS']).subscribe(response => {
      this.apiResponse = response;
      console.log(response);
      if (this.apiResponse.requestid) {
        this.dataService.appConstant.successToast(`files uploaded sucessfully`);
        this.isButtonDisabled = false;
        if (this.documentTab ===  'document1') {
          this.changeTab('payment', 'ITR1');
        } else if (this.documentTab ===  'document2') {
          this.changeTab('payment', 'ITR2');
        } else if (this.documentTab ===  'document3') {
          this.changeTab('payment', 'ITR3');
        } else if (this.documentTab ===  'document4') {
          this.changeTab('payment', 'ITR4');
        }
      } else {
        this.dataService.appConstant.errorToast(`failed to upload files`);
      }
    }, err => {
      console.log(err);
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }
  handleCaSeal(seal) {
    if (seal) {
      this.ca_seal_amount = 50;
    } else {
      this.ca_seal_amount = 0;
    }
  }
  payItr() {
    console.log(`amount: ${this.current_itr_payment.amount}\n commision: ${this.current_itr_payment.comm}\n ca_seal: ${this.ca_seal_amount}`);
    let walletData = JSON.parse(sessionStorage.getItem('walletData'));
    console.log(walletData.TreadWallet);
    if (this.current_itr_payment.amount <= 0) {
      this.dataService.appConstant.errorToast("amount cannot be less then or equal to 0");
      return;
    } else if (this.current_itr_payment.amount > walletData.TreadWallet) {
      this.dataService.appConstant.errorToast("you have insufficient balance in tread wallet");
    } else {
      let distributorid = this.sessionUserData.Data.DistributorId;
      distributorid = distributorid ? distributorid : 561;
      let totalAmount = this.current_itr_payment.amount + this.current_itr_payment.comm + this.ca_seal_amount;
      let requestBody = {};
      requestBody['amount'] = totalAmount;
      requestBody['distributor'] = this.current_itr_payment.comm;
      requestBody['distributorid'] = distributorid;
      requestBody['openbalance'] = walletData.TreadWallet;
      //make payment
      this.http.post(`http://13.127.177.39:8081/payment/${this.sessionUserData.RegistrationId}`, requestBody, { responseType: 'json' }).subscribe(response => {
        this.apiResponse = response;
        console.log(response);
        if (this.apiResponse.paymentid) {
          console.log(this.apiResponse);
          this.dataService.appConstant.successToast(`payment sucessfull`);
          // updated payment status in database
          let requestBody = {};
          requestBody['requestid'] = this.formData.requestid;
          requestBody['payment_ref'] = this.apiResponse.paymentid.toString();
          requestBody['payment_desc'] = "payment done succesfully";
          requestBody['status'] = "paid";
          requestBody['ca_seal'] = this.ca_seal_amount === 0 ? false : true;
          this.dataService.put1(requestBody, this.dataService.appConstant.APIS['ITR_PAYMENT']).subscribe(response => {
            this.apiResponse = response;
            console.log(response);
            if (this.apiResponse.requestid) {
              this.dataService.appConstant.successToast(`payment status updated succesfully`);
              this.documentTab = 'table';
            } else {
              this.dataService.appConstant.errorToast(`failed to update status of payment`);
            }
          }, err => {
            console.log(err);
            this.dataService.appConstant.errorToast("Server error....Please try again");
          })
        } else {
          this.dataService.appConstant.errorToast(`payment unsuccessfull`);
        }
      }, err => {
        console.log(err);
        this.dataService.appConstant.errorToast("Server error....Please try again");
      })
    }
  }
  // saveData(){
  //   sessionStorage.setItem('itr_type','itr1');
  //   sessionStorage.setItem('retailer_id','MER0691');
  // }
  // getData(){
  //   return sessionStorage.getItem('itr_type');
  //}

}
