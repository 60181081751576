import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//ANGULAR Module
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DatePipe } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';

//SERVICE
import { DataServiceService } from './service/data-service.service';
import { AppConstantService } from './service/app-constant.service';
import { ExternalAPIService } from './service/external-api.service'
import { CustomHttpInterceptor } from './Interceptor/custom-http.interceptor';

//COMPONENT
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginComponent } from './component/login/login.component';
import { AddMoneyComponent } from './component/add-money/add-money.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AddPaymentHistoryComponent } from './component/add-payment-history/add-payment-history.component';
import { MyWalletHistoryComponent } from './component/my-wallet-history/my-wallet-history.component';
import { MobileRechargeComponent } from './component/mobile-recharge/mobile-recharge.component';
import { DthRechargeComponent } from './component/dth-recharge/dth-recharge.component';
import { AepsComponent } from './component/aeps/aeps.component';
import { MoneyTransferComponent } from './component/money-transfer/money-transfer.component';
import { BbpsComponent } from './component/bbps/bbps.component';
import { PanCardComponent } from './component/pan-card/pan-card.component';
import { InsuranceComponent } from './component/insurance/insurance.component';
import { TravelBookingComponent } from './component/travel-booking/travel-booking.component';
import { IncomeTaxReturnComponent } from './component/income-tax-return/income-tax-return.component';
import { GstComponent } from './component/gst/gst.component';
import { OtherServiceComponent } from './component/other-service/other-service.component';
import { ComplaintComponent } from './component/complaint/complaint.component';
import { ReportComponent } from './component/report/report.component';
import { SiderbarComponent } from './component/siderbar/siderbar.component';
import { LayoutComponent } from './component/layout/layout.component';
import { PanServiceReportComponent } from './component/pan-card/pan-service-report/pan-service-report.component';
import { BuyCouponComponent } from './component/pan-card/buy-coupon/buy-coupon.component';
import { BankAccountTransferComponent } from './component/bank-account-transfer/bank-account-transfer.component';
import { ProccedRechargeComponent } from './component/mobile-recharge/procced-recharge/procced-recharge.component';
import { ProccedDthRechargeComponent } from './component/dth-recharge/procced-dth-recharge/procced-dth-recharge.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MoneyTransferReportComponent } from './component/money-transfer/money-transfer-report/money-transfer-report.component';
import { OnBoardingComponent } from './component/on-boarding/on-boarding.component';
import { OperatorsComponent } from './component/bbps/operators/operators.component';
import { PaybillComponent } from './component/bbps/paybill/paybill.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { WalletTransferComponent } from './component/wallet-transfer/wallet-transfer.component';
import { ServiceRequestComponent } from './component/pan-card/service-request/service-request.component';
import { UtilityComponent } from './component/pan-card/utility/utility.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    AddMoneyComponent,
    ProfileComponent,
    AddPaymentHistoryComponent,
    MyWalletHistoryComponent,
    MobileRechargeComponent,
    DthRechargeComponent,
    AepsComponent,
    MoneyTransferComponent,
    BbpsComponent,
    PanCardComponent,
    InsuranceComponent,
    TravelBookingComponent,
    IncomeTaxReturnComponent,
    GstComponent,
    OtherServiceComponent,
    ComplaintComponent,
    ReportComponent,
    SiderbarComponent,
    LayoutComponent,
    PanServiceReportComponent,
    BuyCouponComponent,
    BankAccountTransferComponent,
    ProccedRechargeComponent,
    ProccedDthRechargeComponent,
    MoneyTransferReportComponent,
    OnBoardingComponent,
    OperatorsComponent,
    PaybillComponent,
    RegistrationComponent,
    WalletTransferComponent,
    ServiceRequestComponent,
    UtilityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule, // required animations module
    QRCodeModule,
  ],
  providers: [
    DataServiceService,
    ExternalAPIService,
    AppConstantService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
