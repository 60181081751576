import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-travel-booking',
  templateUrl: './travel-booking.component.html',
  styleUrls: ['./travel-booking.component.scss']
})
export class TravelBookingComponent implements OnInit {
  public sessionUserData:any = {};
  public formData:any = {};
  public isButtonDisabled:boolean = false;

  public busList:any = [{},{},{},{},{},{},{},{},{}]

  constructor() { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
  }

  submit(form){

  }

  openModal(){
    $('#ComplaintForm').modal('show');
  }

  exchangeCity(){
    let from = this.formData.sourceCity
    let to = this.formData.destinationCity
    
    
    this.formData.sourceCity = to;
    this.formData.destinationCity = from;

  }
}
