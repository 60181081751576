import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstantService } from '../service/app-constant.service';
//import { DataServiceService } from '../../../service/data-service.service';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';


@Injectable({
  providedIn: 'root'
})
export class ExternalAPIService {


  private subject = new Subject<any>();
  constructor(
    public appConstant: AppConstantService,
    public http: HttpClient,
  ) { }




  get(url: string): Observable<any> {
    return this.http.get<any>(this.appConstant.BASE_API + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }



  /***
   * GET DISTRIBUTOR INFORMATION
   */

  getDistributorData(): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('email', 'kpslice3@gmail.com');
    formData.append('password', 'Rahul@900');
    return this.http.post<any>("https://api.pay2all.in/token", formData, { responseType: 'json' });
  }

  refreshPay2allToken(afterRefresh) {
      this.getDistributorData().subscribe(response => {
        sessionStorage.setItem("access_token", response.access_token);
        sessionStorage.setItem('setheadType', 'PayToAllToken');
        afterRefresh();
      })
  }

  /***
   * GET QR DATA URL
   */

  getQRUrlData(data): Observable<any> {
    return this.http.post("https://partners.connect21.in/api/v2/upi/qrcode/create", data, { responseType: 'json' });
  }

  /***
  * SEND UPI PAYMENT LINK
  */

  sendUpiCollectLink(data): Observable<any> {
    return this.http.post("https://partners.connect21.in/api/v2/upi/collect/request", data, { responseType: 'json' });
  }

  /***
   * Add Amount to wallet after UPI collect request
   */

  AddAmountWalletDetails(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('RegistrationId', data.RegistrationId);
    params = params.append('Amount', data.Amount);
    return this.http.get(this.appConstant.BASE_API + this.appConstant.APIS.AUTOUPDATE_TRADE_WALLET, { params: params });

  }

 //ADD_UPI_REQUEST
  AddUpiRequestDetails(data): Observable<any> {
    let params = new HttpParams();
    
    params = params.append('upi_id', data.upi_id);
    params = params.append('amount', data.amount);
    params = params.append('provider_id', data.provider_id);
    params = params.append('note', data.note);
    params = params.append('client_id', data.client_id);
    params = params.append('status_id', data.status_id);
    params = params.append('message', data.message);
    params = params.append('order_id', data.order_id);
    params = params.append('payment_status', data.payment_status);
    params = params.append('RegistrationId', data.RegistrationId);

    return this.http.get(this.appConstant.BASE_API + this.appConstant.APIS.ADD_UPI_REQUEST, { params: params });

  }

 

  /***
   * GET PAY2ALL Providers
   */

  getProviders(): Observable<any> {
    return this.http.get<any>(" https://api.pay2all.in/v1/app/providers", { responseType: 'json' });
  }

  /***
  * GET STATION
  */

  getStation(): Observable<any> {
    return this.http.get<any>("https://www.pay2all.in/api/bus/v1/getStations", { responseType: 'json' });
  }


  /***
   * DONE RECHARGE 
   */

  paymentRecharge(data) {
    const formData: FormData = new FormData();
    formData.append('number', data.mobile);
    formData.append('amount', data.amount);
    formData.append('provider_id', data.provider_id);
    if (data.reference_id) {
      formData.append('reference_id', data.reference_id);
    }
    if (data.optional1) {
      formData.append('optional1', data.optional1);
    }
    if (data.optional2) {
      formData.append('optional2', data.optional2);
    }
    if (data.optional3) {
      formData.append('optional3', data.optional3);
    }
    if (data.optional4) {
      formData.append('optional4', data.optional4);
    }
    formData.append('api_id', '27');
    console.log(formData);
    formData.append('client_id', Math.floor(100000 + Math.random() * 900000).toString());
    return this.http.post("https://api.pay2all.in/v1/payment/recharge", formData, { responseType: 'json' });
  }

  doRecharge(data) {
    const formData: FormData = new FormData();
    formData.append('number', data.mobile);
    formData.append('amount', data.amount);
    formData.append('provider_id', data.providerId);
    formData.append('client_id', data.Client_Id);
    return this.http.post("https://api.pay2all.in/v1/payment/recharge", formData, { responseType: 'json' });
  }



  /****
   * GET RECHARGE PLAN
   */

  getPlans(data) {
    const formData: FormData = new FormData();
    formData.append('circle_id', data.circleId);
    formData.append('provider_id', data.providerId);
    return this.http.post("https://api.pay2all.in/v1/plan/mobile", formData, { responseType: 'json' });
  }

  // getRechargePlans(data) {
  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/json',
  //     'Token': 'ZKEY55dc9eeafb99831baec335f4c'
  //   })

  //   console.log(data)

    
  //   return this.http.post("https://api.zuelpay.com/utility/addon/tariffPlan", {request: data}, {headers: headers});
  // }
  
  getRechargePlans(data) {
    const formData: FormData = new FormData();
    formData.append('circle_id', data.circleId);
    formData.append('provider_id', data.providerId);

    return this.http.post("https://api.pay2all.in/v1/plan/mobile", formData, { responseType: 'json' });
  }



  /****
   * GET RECHARGE PLAN
   */

  getDTHPlan(data) {

    const formData: FormData = new FormData();
    formData.append('plan_type', 'dth-plan');
    formData.append('provider_id', data.providerId);

    
    return this.http.post("https://api.pay2all.in/v1/plan/dth", formData, { responseType: 'json' });
  }
  
  getDTHChannelPlan(data) {

    const formData: FormData = new FormData();
    formData.append('plan_type', 'channel-plan');
    formData.append('provider_id', data.providerId);

    
    return this.http.post("https://api.pay2all.in/v1/plan/dth", formData, { responseType: 'json' });
  }



  /***
   * GET BANK LIST
   */


  getAllBankDetails() {
    return this.http.get("https://api.pay2all.in/v1/money/banks", { responseType: 'json' })
  }


  /***
   * TRANSFER MONEY
   */
  transfer(transferDetails) {
    const formData: FormData = new FormData();
    formData.append('mobile_number', transferDetails.MobileNumber);
    formData.append('beneficiary_name', transferDetails.Beneficiry);
    formData.append('amount', transferDetails.transferAmount);
    formData.append('account_number', transferDetails.Accountno);
    formData.append('ifsc', transferDetails.BankIfsc);
    formData.append('channel_id', transferDetails.channel_id);
    formData.append('client_id', transferDetails.ClientId);
    formData.append('provider_id', '189');
    return this.http.post<any>("https://api.pay2all.in/v1/payout/transfer", formData, { responseType: 'json' })
  }


  /***
  * FETCH BILL
  **/


  fetchBill(formData) {
    return this.http.post<any>(" https://api.pay2all.in/v1/payment/verification", formData, { responseType: 'json' })
  }


  /****
  * AEPS GET LANDING PAGE
  */

  getAEPSLanding(data) {
    const formData: FormData = new FormData();
    formData.append('mobile_number', data.mobile_number);
    formData.append('api_id', data.api_id);
    return this.http.post("https://api.pay2all.in/v1/aeps/landing", formData, { responseType: 'text' });
  }

 /****
  * Internal Wallet Transfer
  */
  InternalWalletTransfer(data) {
  let params = new HttpParams();
    params = params.append('RegistrationId', data.RegistrationId);
    params = params.append('FromWallet', data.FromWallet);
    params = params.append('Amount', data.Amount);
    return this.http.get(this.appConstant.BASE_API + this.appConstant.APIS.INTERNAL_WALLET_TRANSFER, { params: params });
  }

  
}
