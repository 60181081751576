import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.scss']
})
export class UtilityComponent implements OnInit {
  @ViewChild('inputResizePhoto') inputResizePhoto: ElementRef;
  @ViewChild('inputResizeSignature') inputResizeSignature: ElementRef;
  public formData: any = {};
  public apiResponse: any;
  public myProfileData: any = {};
  public myApplicationData: any = {};
  public base64Image: any;
  public filePath: any;
  public FrontImage: any;
  public BackImage: any;
  public IDImage: any;
  public AddressProofName: any;
  public OtherProofName: any;
  public imageType: any;
  public base64ImageName: any;
  public sessionUserData: any = {};
  public walletData: any = {};
  constructor(public router: Router,public dataService: DataServiceService,
    public route: ActivatedRoute,public http: HttpClient) {
      this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
      this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
     }

  ngOnInit(): void {
  }


  selectFile(files: FileList, type) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (reader.result) {
        this.base64Image = reader.result;
        this.base64ImageName = files[0].name;
        this.imageType=type;
        //if (type == 'Profile') {
         // this.myProfileData.ProfilePhotoImage = this.base64Image;
          let base64 = this.base64Image.split(",")
         // this.myProfileData.Profileimagename = this.base64ImageName;
         // this.myProfileData.Profilephoto = base64[1];
          this.base64ImageName = base64[1];
          // this.dataService.post(this.myProfileData, this.dataService.appConstant.APIS['UPLOAD_RETPHOTO']).subscribe(response => {
          //   this.apiResponse = response
          //   if (this.apiResponse.status) {
          //     this.dataService.appConstant.successToast("Profile updated successfully");
          //   } else {
          //     this.dataService.appConstant.errorToast("Profile updated failed");
          //   }
          // }, err => {
          //   this.dataService.appConstant.errorToast("Server error....Please try again");
          // })
        }

      //}
    };
  }

  applicationUpload(files: FileList, type){
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (reader.result) {
        
        this.base64Image = reader.result;
        let base64 = this.base64Image.split(",")
         this.base64ImageName = base64[1];
         this.myApplicationData.IDProof='';
         this.myApplicationData.AddressProof='';
         this.myApplicationData.OtherProof='';
         this.myApplicationData.FrontSideImage='';
         this.myApplicationData.BackSideImage='';
         this.myApplicationData.MemberID = this.sessionUserData.RegistrationId;
         this.myApplicationData.RegistrationId = this.sessionUserData.RegistrationId;
          if (type == 'frontside') {
            this.myApplicationData.FrontSideImage=this.base64ImageName;
            this.myApplicationData.CallNumber=1;
            this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
                  {
                    this.apiResponse = response
                    if (this.apiResponse.status) {
                      this.FrontImage=this.apiResponse.FileName;
                    } else {
                      this.dataService.appConstant.errorToast("Upload failed");
                    }
                  }, err => {
                    this.dataService.appConstant.errorToast("Server error....Please try again");
                  })
          }
          if (type == 'backside') {
            this.myApplicationData.BackSideImage=this.base64ImageName;
            this.myApplicationData.CallNumber=2;
            this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
                  {
                    this.apiResponse = response
                    if (this.apiResponse.status) {
                      this.BackImage=this.apiResponse.FileName;
                    } else {
                      this.dataService.appConstant.errorToast("Upload failed");
                    }
                  }, err => {
                    this.dataService.appConstant.errorToast("Server error....Please try again");
                  })
          }
          // if (type == 'identityProof') {
          //   this.myApplicationData.IDProof=this.base64ImageName;
          // }
          // if (type == 'addressProof') {
          //   this.myApplicationData.AddressProof=this.base64ImageName;
          // }
          // if (type == 'otherProof') {
          //   this.myApplicationData.OtherProof=this.base64ImageName;
          // }
        }
    };
  }

  applicationUpl(files: FileList, type){
   
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (reader.result) {
        
        this.base64Image = reader.result;
        let base64 = this.base64Image.split(",")
         this.base64ImageName = base64[1];
         this.myApplicationData.IDProof='';
         this.myApplicationData.AddressProof='';
         this.myApplicationData.OtherProof='';
         this.myApplicationData.FrontSideImage='';
         this.myApplicationData.BackSideImage='';
         this.myApplicationData.MemberID = this.sessionUserData.RegistrationId;
         this.myApplicationData.RegistrationId = this.sessionUserData.RegistrationId;
          if (type == 'identityProof') {
            this.myApplicationData.IDProof=this.base64ImageName;
            this.myApplicationData.CallNumber=3;
            this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
                  {
                    this.apiResponse = response
                    if (this.apiResponse.status) {
                      this.IDImage=this.apiResponse.FileName;
                    } else {
                      this.dataService.appConstant.errorToast("Upload failed");
                    }
                  }, err => {
                    this.dataService.appConstant.errorToast("Server error....Please try again");
                  })
          }
          if (type == 'addressProof') {
            this.myApplicationData.AddressProof=this.base64ImageName;
            this.myApplicationData.CallNumber=4;
            this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
                  {
                    this.apiResponse = response
                    if (this.apiResponse.status) {
                      this.AddressProofName=this.apiResponse.FileName;
                    } else {
                      this.dataService.appConstant.errorToast("Upload failed");
                    }
                  }, err => {
                    this.dataService.appConstant.errorToast("Server error....Please try again");
                  })
          }
          if (type == 'otherProof') {
            this.myApplicationData.OtherProof=this.base64ImageName;
            this.myApplicationData.CallNumber=5;
            this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
                  {
                    this.apiResponse = response
                    if (this.apiResponse.status) {
                      this.OtherProofName=this.apiResponse.FileName;
                    } else {
                      this.dataService.appConstant.errorToast("Upload failed");
                    }
                  }, err => {
                    this.dataService.appConstant.errorToast("Server error....Please try again");
                  })
          }
          // if (type == 'frontside') {
          //   this.myApplicationData.FrontSideImage=this.base64ImageName;
          // }
          // if (type == 'backside') {
          //   this.myApplicationData.BackSideImage=this.base64ImageName;
          // }
        }
    };
  }

  getphoto(event) {
    event.preventDefault();
    if(this.imageType == "ResizeSignature")
    {
      this.myProfileData.SignatureImage = this.base64ImageName;
      this.inputResizeSignature.nativeElement.value = '';
    }
    else
    {
      this.myProfileData.PhotoImage= this.base64ImageName;
      this.inputResizePhoto.nativeElement.value = '';
    }

    this.myProfileData.MemberID = this.sessionUserData.RegistrationId;
    this.myProfileData.RegistrationId = this.sessionUserData.RegistrationId;
    this.base64Image=null;
    this.base64ImageName=null;

    this.dataService.post(this.myProfileData, (this.dataService.appConstant.APIS['UPLOAD_RETPHOTO'])+this.imageType).subscribe(response => {
            this.apiResponse = response
            if (this.apiResponse.status) {
              this.filePath=this.apiResponse.FileName;
              // window.open(this.filePath , '_blank', '');
              const pdfUrl = this.filePath;
              //const pdfName = 'shs.jpg';
              FileSaver.saveAs(pdfUrl);
              
              //this.dataService.appConstant.successToast("Profile updated successfully");
            } else {
              this.dataService.appConstant.errorToast("Upload failed");
            }
          }, err => {
            
            this.dataService.appConstant.errorToast("Server error....Please try again");
          })
  }

  submitApplication(event) {
    
    event.preventDefault();
    this.myApplicationData.MemberID = this.sessionUserData.RegistrationId;
    this.myApplicationData.RegistrationId = this.sessionUserData.RegistrationId;
    this.myApplicationData.FrontImage = this.FrontImage;
    this.myApplicationData.BackImage = this.BackImage;
    this.myApplicationData.IDImage = this.IDImage;
    this.myApplicationData.AddressProofName = this.AddressProofName;
    this.myApplicationData.OtherProofName = this.OtherProofName;
    this.base64Image=null;
    this.base64ImageName=null;
    this.myApplicationData.CallNumber=0;
    this.dataService.post(this.myApplicationData, this.dataService.appConstant.APIS['Merge_PDF']).subscribe(response => 
      {
            this.apiResponse = response
            if (this.apiResponse.status) {
              this.filePath=this.apiResponse.FileName;
              const pdfUrl = this.filePath;
              FileSaver.saveAs(pdfUrl);
             // this.dataService.appConstant.successToast("Profile updated successfully");
            } else {
              this.dataService.appConstant.errorToast("Upload failed");
            }
          }, err => {
            this.dataService.appConstant.errorToast("Server error....Please try again");
          })
  }

  back() {
    this.router.navigate(['/Pan_Card_Services'], { replaceUrl: true });
  }
}
