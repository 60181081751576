import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss']
})
export class OnBoardingComponent implements OnInit {
  fileName = 'FinancialReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public sessionUserData: any = {};
  public myProfileData: any = {};
  public shopData: any = {};
  public documentData: any = {};
  public bankData: any = {};
  public changePasswordData: any = {};
  public tab: any;
  public documentTab: any;

  public reportFilter: any = { numPerPage: 10, pageNum: 1 };
  public reportList: any = [{}, {}, {}];
  public isLoading: boolean = false;
  public isShowFilter: boolean = true;
  public stateList: any = [];
  public cityList: any = [];
  public tahsilList: any = [];

  public shopCityList: any = [];
  public shopTahsilList: any = [];

  //PROFILE VALIDATION
  public isProfileCompleted: boolean = true;
  public isProfileTabCompleted: boolean = true;
  public isShopTabCompleted: boolean = true;
  public isDocumentTabCompleted: boolean = true;
  public isBankTabCompleted: boolean = true;
  public birthDate: boolean;

  public base64Image: any;
  public base64ImageName: any;

  constructor(
    public dataService: DataServiceService,
    public router: Router,
    public route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage("Page_My_Profile");
    this.tab = 'Shop_Details';
    this.documentTab = "Aadhaar";
  }

  ngOnInit(): void {
    this.getSessionData();
    this.getState();
    console.log('cehckj profle validtaion', this.checkProfileValidation());
    this.isProfileCompleted = this.checkProfileValidation();
  }

  changeMainTab(tab) {
    this.isProfileCompleted = this.checkProfileValidation();
    //IF PROFILE COMPLETED THEN MOVE TO SHOP DETAIL
    if (tab == 'My_Profile') {
      if (this.isProfileTabCompleted) {
        this.tab = tab;
      } else {
        if (this.isShopTabCompleted) {
          this.tab = tab
        } else {
          this.dataService.appConstant.errorToast("Please complete shop detail detail..")
        }
      }
    } else if (tab == 'Documents_Details') {
      if (this.isDocumentTabCompleted) {
        this.tab = tab;
      } else {
        if (!this.isProfileTabCompleted) {
          this.dataService.appConstant.errorToast("Please complete profile detail..")
        } else if (!this.isShopTabCompleted) {
          this.dataService.appConstant.errorToast("Please complete shop detail detail..")
        } else {
          this.tab = tab
        }
      }
    } else if (tab == 'Bank_Details') {
      if (this.isBankTabCompleted) {
        this.tab = tab;
      } else {
        if (!this.isProfileTabCompleted) {
          this.dataService.appConstant.errorToast("Please complete profile detail..")
        } else if (!this.isShopTabCompleted) {
          this.dataService.appConstant.errorToast("Please complete shop detail detail..")
        } else if (!this.isDocumentTabCompleted) {
          this.dataService.appConstant.errorToast("Please complete document detail detail..")
        } else {
          this.tab = tab
        }
      }
    } else {
      this.tab = tab
    }
  }


  getSessionData() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    if (this.sessionUserData && this.sessionUserData.Data) {
      this.myProfileData = this.sessionUserData.Data;
      this.myProfileData.DOB = this.datePipe.transform(this.myProfileData.DOB, 'yyyy-MM-dd'),

        this.shopData = this.sessionUserData.Data;
      this.documentData = this.sessionUserData.Data;
      this.bankData = this.sessionUserData.Data;
      this.changePasswordData = this.sessionUserData.Data;
      if (this.myProfileData && this.myProfileData.ProfilePhoto) {
        this.myProfileData.ProfilePhotoImage = this.myProfileData.ProfilePhoto;
      }
      if (this.documentData && this.documentData.Adhar_Back_Image) {
        this.documentData.Adhar_Back_Image_Image = this.documentData.Adhar_Back_Image;
      }
      if (this.documentData && this.documentData.Adhar_Front_Image) {
        this.documentData.Adhar_Front_Image_Image = this.documentData.Adhar_Front_Image;
      }
      if (this.documentData && this.documentData.Pan_Card_Image) {
        this.documentData.Pan_Card_Image_Image = this.documentData.Pan_Card_Image;
      }

      if (this.shopData && this.shopData.ShopPhoto) {
        this.shopData.ShopPhotoImage = this.shopData.ShopPhoto;
      }
      if (this.bankData && this.bankData.CancelledChequePhoto) {
        this.bankData.CancelledchequephotoImage = this.bankData.CancelledChequePhoto;
      }


      if (!this.myProfileData.StateId) {
        this.myProfileData.StateId = "";
      }

      if (!this.myProfileData.CityId) {
        this.myProfileData.CityId = "";
      }
      if (!this.myProfileData.TahsilsId) {
        this.myProfileData.TahsilsId = "";
      }


      if (!this.shopData.ShopState) {
        this.shopData.ShopState = "";
      }

      if (!this.shopData.ShopCity) {
        this.shopData.ShopCity = "";
      }
      if (!this.shopData.ShopPostOffice) {
        this.shopData.ShopPostOffice = "";
      }

    }


    this.myProfileData.Profileimagename = null;
    this.myProfileData.Profilephoto = null;
    this.shopData.Shopimagename = null;
    this.shopData.ShopPhoto = null;
    this.documentData.Adhar_Front_Image_Name = null;
    this.documentData.Adhar_Front_Image = null;
    this.documentData.Adhar_Back_Image = null;
    this.documentData.Adhar_Back_Image_Name = null;
    this.documentData.Pan_Card_Image = null;
    this.documentData.Pan_Card_Image_Name = null;
    this.bankData.CancelledChequePhoto = null;
    this.bankData.CancelledChequeName = null;
  }


  public checkProfileValidation() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.myProfileData = this.sessionUserData.Data;
    this.shopData = this.sessionUserData.Data;
    this.documentData = this.sessionUserData.Data;
    this.bankData = this.sessionUserData.Data;

    if (this.documentData && this.documentData.Adhar_Back_Image) {
      this.documentData.Adhar_Back_Image_Image = this.documentData.Adhar_Back_Image;
    }


    if (this.shopData && this.shopData.ShopPhoto) {
      this.shopData.ShopPhotoImage = this.shopData.ShopPhoto;
    }

    if (this.documentData && this.documentData.Adhar_Front_Image) {
      this.documentData.Adhar_Front_Image_Image = this.documentData.Adhar_Front_Image;
    }
    if (this.documentData && this.documentData.Pan_Card_Image) {
      this.documentData.Pan_Card_Image_Image = this.documentData.Pan_Card_Image;
    }

    if (this.myProfileData && this.myProfileData.ProfilePhoto) {
      this.myProfileData.ProfilePhotoImage = this.myProfileData.ProfilePhoto;
    }

    //CHECK PROFILE VALIDATION
    if (!this.myProfileData.FullName) {
      console.log('1');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.MobileNo) {
      console.log('2');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.EmailId) {
      console.log('3');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.StateId) {
      console.log('4');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.CityId) {
      console.log('5');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.TahsilsId) {
      console.log('6');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.Pincode) {
      console.log('7');
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }
    if (!this.myProfileData.DOB) {
      this.isProfileCompleted = false;
      this.isProfileTabCompleted = false;
    }


    //SHOP DETAIL
    if (!this.sessionUserData.Data.ShopName) {
      console.log('8');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Data.ShopCategory) {
      console.log('9');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Data.ShopAddress1) {
      console.log('10');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Data.ShopAddress2) {
      console.log('11');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Data.ShopAreaLocality) {
      console.log('12');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }
    if (!this.sessionUserData.Data.ShopPostOffice) {
      console.log('13');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.ShopState) {
      console.log('14');
      this.isProfileCompleted = false;
      this.isShopTabCompleted = false;
    }

    if (!this.sessionUserData.Data.ShopCity) {
      console.log('15');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.ShopPincode) {
      console.log('16');
      this.isShopTabCompleted = false;
      this.isProfileCompleted = false;
    }

    //BANK DETAIL
    if (!this.sessionUserData.Data.Bank_Name) {
      console.log('17');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.Account_Holder_Name) {
      console.log('18');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.Bank_Account_Number) {
      console.log('19');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.IFSC_Code) {
      console.log('20');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.Account_Type) {
      console.log('21');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }

    if (!this.sessionUserData.Data.Bank_Address) {
      console.log('22');
      this.isBankTabCompleted = false;
      this.isProfileCompleted = false;
    }


    //DOCUMENT
    if (!this.sessionUserData.Data.Adhar_Number) {
      console.log('23');
      this.isDocumentTabCompleted = false;
      this.isProfileCompleted = false;
    }
    // if (!this.sessionUserData.Data.Adhar_Front_Image) {
    //   this.isDocumentTabCompleted = false;
    //   this.isProfileCompleted = false;
    // }
    // if (!this.sessionUserData.Data.Adhar_Back_Image) {
    //   this.isDocumentTabCompleted = false;
    //   this.isProfileCompleted = false;
    // }
    if (!this.sessionUserData.Data.Pan_Number) {
      console.log('24');
      this.isDocumentTabCompleted = false;
      this.isProfileCompleted = false;
    }
    // if (!this.sessionUserData.Data.Pan_Card_Image) {
    //   this.isDocumentTabCompleted = false;
    //   this.isProfileCompleted = false;
    // }

    let profileValidationData = {
      isProfileCompleted: this.isProfileCompleted
    }

    ////SESSION OPERATION
    this.myProfileData.DOB = this.datePipe.transform(this.myProfileData.DOB, 'yyyy-MM-dd');
    if (this.myProfileData && this.myProfileData.ProfilePhoto) {
      this.myProfileData.ProfilePhotoImage = this.myProfileData.ProfilePhoto;
    }
    if (this.documentData && this.documentData.Adhar_Back_Image) {
      this.documentData.Adhar_Back_Image_Image = this.documentData.Adhar_Back_Image;
    }
    if (this.documentData && this.documentData.Adhar_Front_Image) {
      this.documentData.Adhar_Front_Image_Image = this.documentData.Adhar_Front_Image;
    }
    if (this.documentData && this.documentData.Pan_Card_Image) {
      this.documentData.Pan_Card_Image_Image = this.documentData.Pan_Card_Image;
    }

    if (this.shopData && this.shopData.ShopPhoto) {
      this.shopData.ShopPhotoImage = this.shopData.ShopPhoto;
    }
    if (this.bankData && this.bankData.CancelledChequePhoto) {
      this.bankData.CancelledchequephotoImage = this.bankData.CancelledChequePhoto;
    }


    if (!this.myProfileData.StateId) {
      this.myProfileData.StateId = "";
    }

    if (!this.myProfileData.CityId) {
      this.myProfileData.CityId = "";
    }
    if (!this.myProfileData.TahsilsId) {
      this.myProfileData.TahsilsId = "";
    }


    if (!this.shopData.ShopState) {
      this.shopData.ShopState = "";
    }

    if (!this.shopData.ShopCity) {
      this.shopData.ShopCity = "";
    }
    if (!this.shopData.ShopPostOffice) {
      this.shopData.ShopPostOffice = "";
    }

    this.myProfileData.Profileimagename = null;
    this.myProfileData.Profilephoto = null;
    this.shopData.Shopimagename = null;
    this.shopData.ShopPhoto = null;
    this.documentData.Adhar_Front_Image_Name = null;
    this.documentData.Adhar_Front_Image = null;
    this.documentData.Adhar_Back_Image = null;
    this.documentData.Adhar_Back_Image_Name = null;
    this.documentData.Pan_Card_Image = null;
    this.documentData.Pan_Card_Image_Name = null;
    this.bankData.CancelledChequePhoto = null;
    this.bankData.CancelledChequeName = null;

    ////SESSION OPERATION


    sessionStorage.setItem('profileStatus', JSON.stringify(profileValidationData));

    return this.isProfileCompleted;
  }




  getState() {
    // this.dataService.get(this.dataService.appConstant.APIS['GET_STATE']).subscribe(response => {
    //   this.apiResponse = response
    //   if (this.apiResponse.status) {
    //     this.stateList = this.apiResponse.stateData;

    this.stateList = JSON.parse(localStorage.getItem('mEState'))


    this.stateList.forEach(element => {


      if (element.id == this.myProfileData.StateId) {
        this.cityList = element.Citys
        this.cityList.forEach(city => {
          if (city.id == this.myProfileData.CityId) {
            this.tahsilList = city.tahsil
          }
        });
      }
      if (element.id == this.shopData.ShopState) {
        this.shopCityList = element.Citys

        this.shopCityList.forEach(city => {
          if (city.id == this.shopData.ShopCity) {
            this.shopTahsilList = city.tahsil
          }
        });

      }


    });

    //   } else {
    //     this.dataService.appConstant.errorToast(this.apiResponse.message);
    //   }
    // }, err => {
    //   this.dataService.appConstant.errorToast("Server error....Please try again");
    // })
  }


  selectProfileState() {
    this.myProfileData.CityId = "";
    this.myProfileData.TahsilsId = "";
    this.cityList = [];
    this.tahsilList = [];
    this.stateList.forEach(element => {
      if (element.id == this.myProfileData.StateId) {
        this.cityList = element.Citys
      }
    });
  }

  selectProfileCity() {
    this.myProfileData.TahsilsId = "";
    this.tahsilList = [];
    this.cityList.forEach(city => {
      if (city.id == this.myProfileData.CityId) {
        this.tahsilList = city.tahsil
      }
    });
  }

  selectShopState() {
    this.shopData.ShopCity = "";
    this.shopData.ShopPostOffice = "";
    this.shopTahsilList = [];
    this.shopCityList = [];
    this.stateList.forEach(element => {
      if (element.id == this.shopData.ShopState) {
        this.shopCityList = element.Citys
      }
    });
  }

  selectShopCity() {
    this.shopData.ShopPostOffice = "";;
    this.shopCityList.forEach(city => {
      if (city.id == this.shopData.ShopCity) {
        this.shopTahsilList = city.tahsil
      }
    });
  }


  changeTab(tab) {
    this.documentTab = tab;
  }


  submitProfileDetail(appForm: NgForm) {
    console.log('skdfjsdddddddddddddddddasdkljf;');

    if (!this.myProfileData.DOB || this.myProfileData.DOB == null) {
      this.birthDate = true;
      return false;
    } else {
      this.birthDate = false;
    }
    this.isButtonDisabled = true;
    this.myProfileData.ProfilePhoto = this.base64ImageName
    this.dataService.post(this.myProfileData, this.dataService.appConstant.APIS['UPDATE_PROFILE']).subscribe(response => {
      this.apiResponse = response
      this.birthDate = false;
      if (this.apiResponse.status) {
        this.myProfileData.ProfilePhoto = this.myProfileData.ProfilePhotoImage;
        this.sessionUserData.Data = this.myProfileData;
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.checkProfileValidation();
        this.isProfileTabCompleted = true;
        this.getSessionData();
        this.tab = 'Documents_Details';
        this.dataService.appConstant.successToast("Profile updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast("Profile updated failed");
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }




  submitShopDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.shopData, this.dataService.appConstant.APIS['UPDATE_SHOP_DETAIL']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.Status) {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
        this.shopData.ShopPhoto = this.shopData.ShopPhotoImage;
        this.sessionUserData.Data = this.shopData;
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.getSessionData();
        this.isShopTabCompleted = true;
        this.tab = 'My_Profile';
        this.checkProfileValidation();
        this.dataService.appConstant.successToast("Shop detail updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }


  change_pan_value() {
    var pancard_span_id: any = document.getElementById('pan_card_span_id');
    if (typeof (pancard_span_id) != 'undefined' && pancard_span_id != null) {
      pancard_span_id.remove()
    }
  }

  submitDocumentDetail(appForm: NgForm) {
    var pancard: any = document.getElementById('Pan_Number_Id');
    var pancard_span_id: any = document.getElementById('pan_card_span_id');
    if (typeof (pancard_span_id) != 'undefined' && pancard_span_id != null) {
      pancard_span_id.remove()
    }
    if (pancard != null && pancard !== undefined && pancard && pancard.value !== undefined && pancard.value && pancard.value != "") {
      this.isButtonDisabled = true;
      this.isDocumentTabCompleted = true;
      this.documentData.Pan_Number = pancard.value;
      this.dataService.post(this.documentData, this.dataService.appConstant.APIS['ADD_PROFILE_DOCUMENT']).subscribe(response => {
        this.apiResponse = response
        if (this.apiResponse.Status) {
          this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
          this.sessionUserData.Data = this.apiResponse.Data
          sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
          this.getSessionData();
          this.tab = 'Bank_Details';
          this.checkProfileValidation();
          this.dataService.appConstant.successToast("Document detail updated successfully");
          this.isButtonDisabled = false;
        } else {
          this.dataService.appConstant.errorToast(this.apiResponse.message);
          this.isButtonDisabled = false;
        }
      }, err => {
        this.dataService.appConstant.errorToast("Server error....Please try again");
        this.isButtonDisabled = false;
      })
    } else {
      var el = document.createElement("span");
      el.innerHTML = "Pan Card Detail is required";
      el.id = "pan_card_span_id";
      el.className = "text-danger";
      pancard.parentNode.insertBefore(el, pancard.nextSibling);

    }
  }




  submitBankDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.bankData, this.dataService.appConstant.APIS['UPDATE_BANK_DETAIL']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.Status) {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
        this.sessionUserData.Data = this.apiResponse.Data
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.getSessionData();
        this.tab = 'Success_Page';
        this.checkProfileValidation();
        this.dataService.appConstant.successToast("Bank detail updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }



  goToDashboard() {
    if (this.isProfileCompleted) {
      this.router.navigate['/app']
    } else {
      this.dataService.appConstant.successToast("Profile Not Completed...")
    }
  }






  logOut() {
    if (confirm("Are you sure to logout ")) {
      sessionStorage.clear();
      this.router.navigate(['/login'], { replaceUrl: true });
      this.dataService.sendMessage("LoggedIn")
    }
  }

  selectFile(files: FileList, type) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (reader.result) {
        this.base64Image = reader.result;
        this.base64ImageName = files[0].name;

        if (type == 'Profile') {
          this.myProfileData.ProfilePhotoImage = this.base64Image;
          let base64 = this.base64Image.split(",")
          this.myProfileData.Profileimagename = this.base64ImageName;
          this.myProfileData.Profilephoto = base64[1];
        }


        if (type == 'ShopPhoto') {
          this.shopData.ShopPhotoImage = this.base64Image;
          let base64 = this.base64Image.split(",")
          this.shopData.Shopimagename = this.base64ImageName;
          this.shopData.ShopPhoto = base64[1];
        }

        if (type == 'AdharFront') {
          this.documentData.Adhar_Front_Image_Image = this.base64Image;
          this.documentData.Adhar_Front_Image_Name = this.base64ImageName;
          let base64 = this.base64Image.split(",")
          this.documentData.Adhar_Front_Image = base64[1];
        }

        if (type == 'adharback') {
          this.documentData.Adhar_Back_Image_Image = this.base64Image;
          this.documentData.Adhar_Back_Image_Name = this.base64ImageName;
          let base64 = this.base64Image.split(",")
          this.documentData.Adhar_Back_Image = base64[1];
        }

        if (type == 'pancard') {
          this.documentData.Pan_Card_Image_Image = this.base64Image;
          this.documentData.Pan_Card_Image_Name = this.base64ImageName;
          let base64 = this.base64Image.split(",")
          this.documentData.Pan_Card_Image = base64[1];
        }

        if (type == 'bankDocument') {

          this.bankData.CancelledchequephotoImage = this.base64Image;
          this.bankData.CancelledChequeName = this.base64ImageName;
          let base64 = this.base64Image.split(",")
          this.bankData.CancelledChequePhoto = base64[1];


        }

      }
    };
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('type', 'Profile');
    formData.append('fileName', this.base64ImageName);
    formData.append('RegistrationId', this.sessionUserData.RegistrationId);
    this.dataService.sendFormData(formData).subscribe()
  }




  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }



}
