<section>
  <div class="container sectionContainer">
    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-lx-10">
        <div class="breadCum">
          <span routerLink="/Dashboard"
            ><i class="icofont-home"></i> Home /
          </span>
          <span class="activeBreadCum">Complaint History</span>
        </div>
      </div>
    </div>

    <!-----Section Heading---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div class="sectionHeading">
          <h5>Complaint History</h5>
        </div>
      </div>
    </div>
    <!-----Section Contain---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <!---FILTER-->
        <div class="row">
          <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
            <label>Complaint Type<span class="astric">*</span></label>
            <select
              class="form-control"
              [(ngModel)]="reportFilter.service"
              name="service"
              #service="ngModel"
              required
              (ngModelChange)="getRport()"
            >
              <option value="">Select Services</option>
              <option value="Add Money">Add Money</option>
              <option value="Money Transfer">Money Transfer</option>
              <option value="Mobile Recharge">Mobile Recharge</option>
              <option value="DTH Recharge">DTH Recharge</option>
              <option value="Pan Card">Pan Card</option>
              <option value="Bill Payment">Bill Payment</option>
              <option value="Travel Booking">Travel Booking</option>
              <option value="ITR">ITR</option>
              <option value="GST">GST</option>
              <option value="Other Service">Other Service</option>
            </select>
          </div>
        </div>

        <!---FILTER-->
        <div *ngIf="isShowFilter">
          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit()">
            <div class="row">
              <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                <label>Start Date </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select Date"
                  [(ngModel)]="reportFilter.fromDate"
                  name="startDate"
                  #startDate="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (startDate.touched || appForm.submitted) &&
                    startDate.errors?.required
                  "
                >
                  Start date is required
                </span>
              </div>
              <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                <label>End Date </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select Date"
                  [(ngModel)]="reportFilter.endDate"
                  name="toDate"
                  #toDate="ngModel"
                  required
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (toDate.touched || appForm.submitted) &&
                    toDate.errors?.required
                  "
                >
                  End date is required
                </span>
              </div>
              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-2 col-lx-2"
              >
                <div class="flexAlignCenter">
                  <button
                    type="submit"
                    class="btn btn-warning submitBtn tableFilterBtn"
                    [disabled]="isButtonDisabled || !appForm.form.valid"
                    [ngClass]="{ 'button--loading': isButtonDisabled }"
                  >
                    <span class="button__text">SUBMIT</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="reset"
                    (click)="clear()"
                    class="btn btn-secondary tableFilterBtn"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div
                class="form-group col-12 col-sm-12 col-md-6 col-lg-4 col-lx-4"
              >
                <input
                  type="text"
                  class="form-control reportSearchFilter"
                  placeholder="Search record..."
                  [(ngModel)]="reportFilter.searchKey"
                  name="searchKey"
                  #searchKey="ngModel"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- <div class="row" >
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 ">
         <span class="grayClr font14">Show</span> <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
             </select>
          <span class="grayClr font14">entity</span>   
      </div>
      <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8 tRight ">
      </div>
    </div> -->

    <!---TABLE--->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div id="reportTable" class="tableMv">
          <table id="htmlData">
            <thead>
              <tr>
                <th class="brdWhiteR brdTLR">ID</th>
                <th class="brdWhiteR">Type</th>
                <th class="brdWhiteR">Transaction Id</th>
                <th class="brdWhiteR">Subject</th>
                <th class="brdWhiteR">Message</th>
                <th class="brdWhiteR">Date</th>
                <th class="brdTRR">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bodyTr"
                *ngFor="
                  let item of reportList
                    | filter: reportFilter.searchKey
                    | paginate
                      : {
                          id: 'reportFilterList',
                          itemsPerPage: reportFilter.numPerPage,
                          currentPage: reportFilter.pageNum,
                          totalItems: reportList.length
                        };
                  let i = index
                "
                id="eachRecord"
              >
                <td class="brdGrayR">{{ item.ComplaintId }}</td>
                <td class="brdGrayR">{{ item.ComplaintType }}</td>
                <td class="brdGrayR">{{ item.TransactionId }}</td>
                <td class="brdGrayR msgBOX">{{ item.Subject }}</td>
                <td class="brdGrayR msgBOX">{{ item.Message }}</td>
                <td class="brdGrayR">
                  {{ item.CreatedDate | date: "dd/MM/yyyy" }}
                </td>
                <td>
                  <div class="pendingComplaintSts">
                    {{ item.Status }}
                  </div>
                </td>
              </tr>
              <tr class="bodyTr" *ngIf="reportList?.length <= 0 && !isLoading">
                <td colspan="50" class="noTableRecord">
                  No record found.......
                </td>
              </tr>
              <tr class="bodyTr" *ngIf="isLoading">
                <td colspan="50" class="noTableRecord">
                  <div class="loader"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!---FOOTER--->
    <br />
    <div class="row cp tableFooterRow" *ngIf="reportList?.length > 0">
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3">
        <div class="font14">
          <span class="grayClr">Showing</span>
          <b> {{ reportFilter.pageNum - 1 + 1 }} </b>
          <span class="grayClr"> to </span>
          <b> {{ reportFilter.numPerPage }} </b>
          <span class="grayClr"> of </span> <b> {{ reportList?.length }} </b>
          <span class="grayClr"> entries</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4"></div>
      <div
        class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld"
        (click)="exportsExcel()"
      >
        <div><i class="icofont-download"></i> Excel</div>
      </div>
      <div
        class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld"
        (click)="exportPDF()"
      >
        <div><i class="icofont-download"></i> PDF</div>
      </div>
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3 tCenter font14">
        <pagination-controls
          id="reportFilterList"
          (pageChange)="reportFilter.pageNum = $event"
        ></pagination-controls>
      </div>
    </div>
  </div>
</section>
