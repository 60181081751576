import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../service/data-service.service';
import { ExternalAPIService } from '../../service/external-api.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import { Subscription, interval } from 'rxjs';

@Component({

  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
  
})
export class HeaderComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  sub: Subscription;

  public distributerData: any = { user: {} };
  public qrData: any = { user: {} };
  public walletData: any = {};
  public sessionUserData: any = { Data: {} };
  public apiResponse: any;
  public tab: any;
  public showQR: boolean = sessionStorage.getItem('qrCodeValue') ? true : false;
  public qrMobile: string = '';
  public qrLink: string = this.showQR ? sessionStorage.getItem('qrCodeValue') : '';
  public isButtonDisabled: boolean = false;

  public sideBarTab: any;
  public isProfileTab: any = false;
  public isShowSideBar: boolean = true;
  public showDownload: boolean = true;
  constructor(
    public router: Router,
    public dataService: DataServiceService,
    public externalAPIService: ExternalAPIService,
    private formBuilder: FormBuilder
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.dataService.appConstant.checkProfileValidation();
    this.dataService.getMessage().subscribe((msg) => {
      if (msg == 'Page_My_Profile') {
        this.isProfileTab = true;
      }
      if (msg == 'Page_Dashboard') {
        this.isProfileTab = false;
      }
      if (msg.substring(0, 5) == 'Page_') {
        this.sideBarTab = msg.substring(5)
      }
      if (msg == "walletUpdated") {
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
      }
      if (msg == 'hideSideBar') {
        this.isShowSideBar = false;
      }
      if (msg == 'showSideBar') {
        this.isShowSideBar = true;
      }
    });
    this.qrMobile = this.sessionUserData.Data.MobileNo;
    this.tab = "UPI"
  }

  ngOnInit(): void {
    if (this.sessionUserData.RegistrationId) {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    }

    this.sub = interval(10000).subscribe((val) => {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    });


    this.getDistributorData();

    this.form = this.formBuilder.group(
      {
        upi: ['', Validators.required],
        amount: [
          '',
          [
            Validators.required,

          ]
        ],
        remark: ['', [
          Validators.required,

        ]],


      },

    );


  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
  }

  changeTab(tab) {
    this.tab = tab;
  }

  goToProfile() {
    this.router.navigate(['/Profile']);
  }

  goToDashbarod() {
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage("Page_Dashboard");
    this.dataService.sendMessage('showSideBar');
    this.router.navigate(['/Dashboard']);
  }

  /***
   * GET PAY2ALL DISTRIBUTOR INFORMATION
   */

  getDistributorData() {
    this.externalAPIService.getDistributorData().subscribe(response => {
      this.apiResponse = response;
      this.distributerData = this.apiResponse;
      sessionStorage.setItem("access_token", this.distributerData.access_token)
    })
  }

  getQRUrlData() {
    sessionStorage.setItem('setheadType', 'PayToAll');
    let clientid = Math.floor(100000 + Math.random() * 900000);
    //sessionStorage.setItem('setheadType', null);
    this.externalAPIService.getQRUrlData({
      mobile_number: this.sessionUserData.Data.MobileNo,
      provider_id: 349,
      client_id: clientid,
      static: 1
    }).subscribe(response => {
      console.log(response.qr_value,"----- QR Value ------");
      if (response.qr_value) { //check response 
        this.showQR = true;
        this.qrLink = response.qr_value
        sessionStorage.setItem('qrCodeValue', this.qrLink);
        sessionStorage.setItem('setheadType', null);
        let requestparam = {
          upi_id: "QR code",
          amount: 0,
          provider_id: 349,
          note: `qr code: ${response.qr_value}`,
          client_id: clientid,
          status_id: 1,
          message: "qr code payment",
          order_id:"",
          payment_status:"",
          RegistrationId:this.sessionUserData.RegistrationId
          }
    
          this.externalAPIService.AddUpiRequestDetails(requestparam).subscribe(walletDetailsData => {
            console.log(walletDetailsData, '--------------UPI Request Added----------------')
            this.dataService.getWalletData(this.sessionUserData.RegistrationId);
          })
      }
      sessionStorage.setItem('setheadType', null);
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sendUpiCollectLink() {
    console.log((new Date()),'----- Request sending time to Pay2ALL');
    this.isButtonDisabled = true;
    sessionStorage.setItem('setheadType', 'PayToAll');
    let clientid = Math.floor(100000 + Math.random() * 900000)
    this.externalAPIService.sendUpiCollectLink({
      upi_id: this.form.value.upi,
      amount: this.form.value.amount,
      provider_id: 353,
      note: this.form.value.remark,
      client_id: clientid

    }).subscribe(response => {
      console.log((new Date()),'----- Response time from Pay2ALL');
      this.isButtonDisabled = false;
      alert("UPI request sent successfully");
      sessionStorage.setItem('setheadType', null);

      let requestparam = {
      upi_id: this.form.value.upi,
      amount: this.form.value.amount,
      provider_id: 353,
      note: this.form.value.remark,
      client_id: clientid,
      status_id:response.status_id,
      message:response.message,
      order_id:response.response,
      payment_status:response.payment_status,
      RegistrationId:this.sessionUserData.RegistrationId

      }

      this.externalAPIService.AddUpiRequestDetails(requestparam).subscribe(walletDetailsData => {
        console.log(walletDetailsData, '--------------UPI Request Added----------------')
        this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      })

      
      // let request = {
      //   "RegistrationId": this.sessionUserData.RegistrationId,
      //   "Amount": this.form.value.amount
      // }
      
      // this.externalAPIService.AddAmountWalletDetails(request).subscribe(walletDetailsData => {
      //   console.log(walletDetailsData, '--------------walletDetailsData ----------------')
      //   this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      // })
      this.form.reset();
      
    })
  }

  logOut() {
    if (confirm("Are you sure to logout ")) {
      sessionStorage.clear();
      this.router.navigate(['/login'], { replaceUrl: true });
      this.dataService.sendMessage("LoggedIn");
      sessionStorage.setItem('setheadType', null);
      location.reload();
    }
  }

  openTab(tab) {
    this.sideBarTab = tab;
    this.isProfileTab = false;
    this.router.navigate([tab]);
  }

  openTabProfile(tab) {
    this.sideBarTab = tab;
    this.router.navigate(['Profile', tab]);
  }

  // saveAsImage(parent) {
  //   const parentElement = parent.qrcElement.nativeElement.querySelector("img").src;
  //   let blobData = this.convertBase64ToBlob(parentElement);
  //   const blob = new Blob([blobData], { type: "image/png" });
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = 'Qrcode';
  //   link.click();
  // }

  downloadImage() {
    this.showDownload = false;
    var container: any = document.getElementById("image-section");
    html2canvas(container, { width: 384, height: 576, scale: 5, x: 50 }).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "html_image.png";
      link.href = canvas.toDataURL("image/png");
      link.target = '_blank';
      link.click();
    });
  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }
}
