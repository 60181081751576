<section>
    <div class="container sectionContainer">
  
      <!-----Bread-cum---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="breadCum">
            <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
            <span class="activeBreadCum">GST</span>
          </div>
        </div>
      </div>
  
      <!-----Section Heading---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          <div class="sectionHeading">
            <h5>GST</h5>
          </div>
        </div>
      </div>
  
      <!-----Section Container---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
          
          <img src="assets/images/coming_soon.jpg" alt="coming soon" width="70%">
          
        </div>
      </div>
    </div>
  </section>