

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    let setHeaderType = sessionStorage.getItem('setheadType');
    if (setHeaderType == 'Auth') {
      let sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User")).AccessToken;
      if (sessionUserData != null) {
        authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + sessionUserData) });
      }
    } else if (setHeaderType == 'PayToAll') {
      // this is for collet money (upi send request, qrcode) (services which uses connect21 api)
      let payToallToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiZmIyZTcwYzYyZWY4NTk1MDFlMDE3NWU2ZTY2ZjZkODgwZjg2NmUyYmY5NTg1ZDM5NjQ0MTU4YzdkYTQ1MWYzZTgwMWQxYzA1ZjY0MTVmNTEiLCJpYXQiOjE2NDkyMjk0NjYuMDQ2ODc4MDk5NDQxNTI4MzIwMzEyNSwibmJmIjoxNjQ5MjI5NDY2LjA0Njg4MTkxNDEzODc5Mzk0NTMxMjUsImV4cCI6MTY2NTA0MDY2NS45ODI5NTU5MzI2MTcxODc1LCJzdWIiOiIxMSIsInNjb3BlcyI6W119.njqSBd2QN1x9FxV8Tz0qTrjNLh0P7Edhhz8UNdQPOegPjBQwN9-PUmvGoKFgzoRpmUggo5-QROOH5G55Y2VPdI3FgrPR-M-K6Z6ihYrKQZMGswKVcnFa6rezgBoWvaBtrgHNMzc6R_iyRVCYYLpVWRN0fTg-CmqnRHkarddeMSwIZgZDgbeOBNSOBBYrq2gYkZwj50k9Mw4UwJpZvP4a1aJwWjAZ3GX68J7UmTQW2752aPDCMXJiiNguZUyya82tOjF6ZX4xpIM4cnUwvapuPh4Q_-H13bR4P3Lylz3Fl8IKwm-r5A7aTmLbiXl-bHq2A5FpjDUV3no2lyZMu3BoUEdKAx0BRuS910qJcIzW_rvooXH59Y3iPxB6zI__2sLSzc928IGE4MFCyKUvcqHgURkg3PeNu4irdAHb8I86hp0Dd6YEtqww-LciDig7irNY0jMkfSR9PCwYVFOjBOcW9HQJp3-LIdWaGMHCfL3Ph6nwTKg_GXf8XzKAm7AS3X4JEVNOxjPV6SaCOuImYSvzzDuml8fg8GCwZHd1LhR0oNIc20WOlFmlQrUTEiVkNltuKv71nFHvS4SEWl7omun7FpL2Li94hPvAPDAYApcc151f2Yzu-IEAJjVDRT9GH_fZmThK6bMrOP_0joy_aXhHSVWAY3ucyX6Y5IHOGYYC2uQ";
      authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + payToallToken) });
    } else if (setHeaderType == 'PayToAllIn') {
      let payToallToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQ3NTdiYTA2MGI3NTU3ODBjNjgzMjA0MjU4MWM5N2M4MmJjNmM3MDg2NGUyZWE3MTlkYzYyMDFmZmY1MGRkZjZjNmZjZmQ5NGRmOTA4N2JhIn0.eyJhdWQiOiIxIiwianRpIjoiNDc1N2JhMDYwYjc1NTc4MGM2ODMyMDQyNTgxYzk3YzgyYmM2YzcwODY0ZTJlYTcxOWRjNjIwMWZmZjUwZGRmNmM2ZmNmZDk0ZGY5MDg3YmEiLCJpYXQiOjE2MzUzMTEzMzksIm5iZiI6MTYzNTMxMTMzOSwiZXhwIjoxNjY2ODQ3MzM5LCJzdWIiOiIxNDkiLCJzY29wZXMiOltdfQ.dx4HqCAwCejXKbV2j6PLr1O8OpZJOw1hmyeojY5v5Ov78oYCQ4JwTNQP8jD3O58cChIwut3yTxVZQdpIAbvJfI7VhPTwEVJi4E3cBfxsb2U2sofMejVs_b-riG4CGiQKGzNu8I1p1zROOaSimuqiTybgxVISTRFXT4Vy2kCJsekJ6yISWtKgoXBEc_KRIAyL5igvfd-HKcTZiwz3a5IZ1WxGNgGSpN-TsN0qTV-n74m5Bt7jpgH4xfnf-TEZUNbmE89ZwfCuErSoqArSuIQAsvZ9Tpqwqur0EQ_EpMCxVf-A2662x6UN49_HbyNTxrdvwSoTMk3cGeI_Rdxbrpww9yTE0J-ogXHPDQgBu6xEi-9wRuLMTZ-GvZj8vEoqsM2loKisjI1IYWkQe9ofFnnHX15nTbRtyh6hm4nFPTxxDdzzwr-fSjDAYJETqq0AyC7_IzgpBKAIN3Pzvt9e8HziSjXZlzVxffHbe__yic8DMNDuDtdulwCYn5ApsGiwF-AM5wJSth_Z093aq7XYZbFS6qT5VshdEzZTTBETIzlpk7yBugtgrrXhFl03XN96uGtgFqQ9UT-5y7wG-oVLjR92ug4lulhx4aloJTf1u7OIidK_bftGG5Fyiirm-WfaoyX9N1cLDIKiaE6T0x3PnUmkYcXUPkCHh-II9uC81mElJRs";
      authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + payToallToken) });
    } else if (setHeaderType == 'PayToAllToken') {
      let AccessToken = sessionStorage.getItem('access_token');
      authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + AccessToken) });
    }
    else {
      sessionStorage.setItem('setheadType', null);
    }
    console.log(authReq, "authReq=-=-=-=-=-")
    return next.handle(authReq);
  }
}
