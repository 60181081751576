<section>
  <div class="container sectionContainer">

    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
        <div class="breadCum">
          <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
          <span class="activeBreadCum">Add Money </span>
        </div>
      </div>
    </div>

    <!-----Section Heading---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
        <div class="sectionHeading">
          <h5>Add Money</h5>
        </div>
      </div>
    </div>

       <!-----Section Heading---->
       <div class="row tabRowMV">
        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3 pad0">
          <div class="tabs" [ngClass]="{'activeTabs':tab=='main'}" (click)="changeTab('main')">
            Main Wallet
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-lx-3 pad0">
          <div class="tabs" [ngClass]="{'activeTabs':tab=='trade'}" (click)="changeTab('trade')">
            Trade Wallet
          </div>
        </div>
      </div>

    <!-----Section Container---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">


        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-lx-8">
            <div class="row modalFixedInfo" *ngIf="tab=='main'">
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Bank Name</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>HDFC Bank</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Account Holder</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm"  >
                <b>SKATMOZZO SERVICES LLP</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Account Number</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>50200040805888</b>
              </div>
    
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>IFSC</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>HDFC0002241</b>
              </div>
    
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Branch</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>RAVER, JALGAON</b>
              </div>
    
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 ">
                <div>UPI ID </div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight">
                <b>9859003900@ybl</b>
              </div>
    
            </div>
    
            <div class="row modalFixedInfo" *ngIf="tab=='trade'">
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Bank Name</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>ICICI Bank</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Account Holder</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm"  >
                <b>SKATMOZZO SERVICES LLP</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Account Number</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>093005003886</b>
              </div>
    
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>IFSC</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>ICIC0000930</b>
              </div>
    
              <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-lx-4 bBrdBtm">
                <div>Branch</div>
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter bBrdBtm">
                <b>:</b>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-lx-7 tRight bBrdBtm">
                <b>AHMEDABAD BAPUNAGAR</b>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4">
            <img class="walletQRCode" *ngIf="tab=='trade'" src="assets/images/tradeWalletQR.jpg">
            <img class="walletQRCode" *ngIf="tab=='main'" src="assets/images/mainWalletQR.jpg">
          </div>
        </div>


        <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
          <div class="row">
            <!-- <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Account<span class="astric">*</span></label>
              <select class="form-control" [(ngModel)]="formData.PaymentId" disabled name="Account" #Account="ngModel" required>
                <option value="">Select Account</option>
                <option value="50200040805888 (IFSC Code-HDFC0002241) SKATMOZZO SERVICE LLP">50200040805888 (IFSC Code-HDFC0002241) SKATMOZZO SERVICE LLP</option>
              </select>
              <span class="text-danger" *ngIf="(Account.touched || appForm.submitted) && Account.errors?.required">
                Account is required
              </span>
            </div> -->
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Payment Mode<span class="astric">*</span></label>
              <select class="form-control" [(ngModel)]="formData.PaymentMode" name="PaymentMode" #PaymentMode="ngModel"
                required>
                <option value="">Select Payment Mode</option>
                <option value="card">Debit/Credit Card</option>
                <option value="UPI">UPI</option>
                <option value="Netbanking">Net Banking</option>
                <option value="cheque">Cheque</option>
                <option value="Other">Other</option>
              </select>
              <span class="text-danger"
                *ngIf="(PaymentMode.touched || appForm.submitted) && PaymentMode.errors?.required">
                Payment mode is required
              </span>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Amount <span class="astric">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Amount" [(ngModel)]="formData.PaidAmount"
                name="Amount" #Amount="ngModel" required>
              <span class="text-danger" *ngIf="(Amount.touched || appForm.submitted) && Amount.errors?.required">
                Amount is required
              </span>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Date <span class="astric">*</span></label>
              <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="formData.PaidDate"
                name="Date" #Date="ngModel" required>
              <span class="text-danger" *ngIf="(Date.touched || appForm.submitted) && Date.errors?.required">
                Date is required
              </span>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Transaction ID <span class="astric">*</span> </label>
              <input type="text" class="form-control" placeholder="Enter transaction id"
                [(ngModel)]="formData.ReferenceNo" name="TransactionId" #TransactionId="ngModel" required>
              <span class="text-danger"
                *ngIf="(TransactionId.touched || appForm.submitted) && TransactionId.errors?.required">
                Transaction ID is required
              </span>

      
            </div>
           
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
            <br class="desktopView">
            <div class="buttonGroup desktopView">
              <button type="submit" class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
              <button type="reset" class="btn btn-secondary">Cancel</button>
            </div>
          </div>
       
         
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6">
              <label>Upload Receipt </label>
              <div class="uploadImageContainer">
                <div class="flex100 flexAlignCenter">
                  <div class="w40">
                    <label class="custom-file-upload">
                      <input type="file" (change)="selectFile($event.target.files)" capture />
                      Choose file
                    </label>
                  </div>
                  <div class="no_file_choose ">
                    No file chosen
                  </div>
                  <div class="pictureIcon">
                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6"></div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-lx-6" *ngIf="formData.UploadfileIMage">
              <div >
                <div class="uploadedImage">
                  <img src="{{formData.UploadfileIMage}}">
                </div>
              </div>
            </div>

            <br class="mobileView">
            <div class="buttonGroup mobileView btnG">
              <button type="submit" class="btn btn-primary submitBtn"
                [disabled]="isButtonDisabled" [ngClass]="{'button--loading':isButtonDisabled}">
                <span class="button__text">SUBMIT</span>
              </button> &nbsp;&nbsp;
              <button type="reset" class="btn btn-secondary">Cancel</button>
            </div>


          </div>

        </form>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-lx-4">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>User Wallet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>RECHARGE(MOBILE+DTH)</td>
              <td class="tCenter">MAIN WALLET</td>
            </tr>
            <tr>
              <td>BILL PAYMENT</td>
              <td class="tCenter">MAIN WALLET</td>
            </tr>
            <tr>
              <td>AEPS</td>
              <td class="tCenter">Amount Deposited at TRADE WALLET</td>
            </tr>
            <tr>
              <td>MONEY TRANSFER</td>
              <td class="tCenter">TRADE WALLET</td>
            </tr>
            <tr>
              <td>PAN CARD</td>
              <td class="tCenter">TRADE WALLET</td>
            </tr>
            <tr>
              <td>TRAVEL</td>
              <td class="tCenter">MAIN WALLET</td>
            </tr>
            <tr>
              <td>INCOME TAX RETURN</td>
              <td class="tCenter">TRADE WALLET</td>
            </tr>
            <tr>
              <td>GST</td>
              <td class="tCenter">TRADE WALLET</td>
            </tr>
            <tr>
              <td>OTHER SERVICES</td>
              <td class="tCenter">TRADE WALLET</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</section>