import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';


@Component({
  selector: 'app-bbps',
  templateUrl: './bbps.component.html',
  styleUrls: ['./bbps.component.scss']
})
export class BbpsComponent implements OnInit {
  public sessionUserData:any = {};

  public providerList:any = [
    {
      option:"light",
      img:"https://s3.amazonaws.com/billericons/utility_electricity_new.png",
      name:"Electricity"
    },
    {
      option:"landline",
      img:"https://s3.amazonaws.com/billericons/utility_landline_new.png",
      name:"Landline",
      comingsoon: true,
    },
    {
      option:"pipedGas",
      img:"https://s3.amazonaws.com/billericons/utility_piped_gas_new.png",
      name:"Piped Gas"
    },
    {
      option:"water",
      img:"https://s3.amazonaws.com/billericons/utility_water_new.png",
      name:"Water",
      comingsoon: true,
    },
    {
      option:"broadband",
      img:"https://s3.amazonaws.com/billericons/utility_broadband_new.png",
      name:"Broadband",
      comingsoon: true,
    },
    {
      option:"insurance",
      img:"https://s3.amazonaws.com/billericons/utility_insurance_premium_new.png",
      name:"Insurance Premium"
    },
    {
      option:"lpgGas",
      img:"https://s3.amazonaws.com/billericons/utility_lpg_gas_new.png",
      name:"Book Cylinder",
      comingsoon: true,
    }
  ]

  constructor(
    public router:Router
  ) { 
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {}

   open(provider,tab){
       this.router.navigate(['/BBPS/operator'], { queryParams: {uid:provider,Provider:tab}});
   }
}
