import { Component, OnInit } from '@angular/core';
import { ExternalAPIService } from '../../service/external-api.service';
import { DataServiceService } from '../../service/data-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router} from '@angular/router';


@Component({
  selector: 'app-aeps',
  templateUrl: './aeps.component.html',
  styleUrls: ['./aeps.component.scss']
})
export class AepsComponent implements OnInit {

  public sessionUserData:any = {};
  public userData:any = {}
  public tab:any = false;

  public formData:any = {};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public isLoading:boolean = false;

  constructor(
    public externalAPIService:ExternalAPIService,
    public dataService:DataServiceService,
    protected sanitizer: DomSanitizer,
    public router:Router
  ) { 
    // this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    // if(this.sessionUserData){
    //   this.userData = this.sessionUserData.Data;
    //   console.log(this.userData,"this.userData==-----------------------")
    //   if(this.userData.OutlateIdStatus === 'pending' && !this.userData.OutlateId){
    //     this.tab = 'successMessage';
    //   }else if(this.userData.OutlateId){
    //           this.launchAEPS();
    //   }else{
    //     this.tab = 'formSection';
    //   }
    // }
   
  }

  ngOnInit(): void {
  }

  goToProfile(){}


  submit(){
    this.dataService.get(this.dataService.appConstant.APIS['SEND_REQUEST']+this.userData.RegistrationId).subscribe(response => {
      this.tab = 'successMessage';
    },err=>{
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })

  }


  /**
   * Go to update shop detail page
   ****/

   goToUpdateShopDetail(){
    this.router.navigate(['/Profile','Shop_Details']);
   }



   launchAEPS(){
    sessionStorage.setItem('setheadType', 'Auth');
    this.formData.mobile_number = this.userData.MobileNo;
    this.formData.api_id = "29";
    this.isLoading = true;
    this.externalAPIService.getAEPSLanding(this.formData).subscribe(response => {
      this.apiResponse = this.sanitizer.bypassSecurityTrustHtml(response);
      this.isLoading = false;
    },err=>{
      this.isLoading = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }



}
