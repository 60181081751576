import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ExternalAPIService } from '../../../service/external-api.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { keyframes } from '@angular/animations';


declare var $: any;

@Component({
  selector: 'app-procced-recharge',
  templateUrl: './procced-recharge.component.html',
  styleUrls: ['./procced-recharge.component.scss']
})
export class ProccedRechargeComponent implements OnInit {

  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public sessionUserData: any = {};
  public walletData: any = {};
  public open_recharge_plan: any;
  public recharge: any;
  public Provider: any;
  public uid: any;
  public rechargePlan: any = {};


  //Plans
  public planMobileType: any;
  public isLoading: boolean = false;
  public selectedPlanList: any = [];
  public selectedIndex: any;
  public selectedPlan: any = {};
  public plans: any = undefined;
  public isShowPlanList: boolean = false;
  public tab: any;
  public circles: any = undefined;

  public currentPlan: string;

  jokes: Object[];

  constructor(
    public dataService: DataServiceService,
    public router: ActivatedRoute,
    public _location: Location,
    public externalAPIService: ExternalAPIService,
    private datePipe: DatePipe,
    public http: HttpClient,
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.planMobileType = "TOPUP"
    this.router.queryParams.subscribe(params => {
      if (params.recharge) {
        this.recharge = params.recharge;
      }
      if (params.Provider) {
        this.Provider = params.Provider;
      }
      if (params.uid) {
        this.uid = params.uid;
      }
    })
    this.dataService.getMessage().subscribe((msg) => {
      if (msg == "walletUpdated") {
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'))
      }
    })

    console.log(this.currentPlan)



    this.jokes = [
      {
        setup: "What did the cheese say when it looked in the mirror?",
        punchline: "Hello-Me (Halloumi)"
      },
      {
        setup: "What kind of cheese do you use to disguise a small horse?",
        punchline: "Mask-a-pony (Mascarpone)"
      },
      {
        setup: "A kid threw a lump of cheddar at me",
        punchline: "I thought 'That's not very mature'"
      },
    ]
  }

  ngOnInit(): void {
    this.fetchCircles();
  }

  back() {
    this._location.back();
  }



  /**
   * Following api are use to recharge this api made recharge by p2all from backen side
   * 
   * 
   */


  submit(appForm: NgForm) {
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if (this.walletData.MainWallet > this.formData.amount) {
      this.isButtonDisabled = true;
      
      let request = {
        "RegistrationId": this.sessionUserData.RegistrationId,
        "CustomerMobile": this.formData.mobile,
        "OperatorCode": parseInt(this.uid),
        "OperatorName": this.Provider,
        "Date": this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        "RechargeAmount": parseInt(this.formData.amount),
        "CommissionAmt": parseFloat("1"),
        "BillAmount": parseInt(this.formData.amount),
        "PaymentType": "Main Wallet",
        "RechargeType": this.recharge,
        "OrderType": "Mobile Recharge"
      }
      this.dataService.post(request, this.dataService.appConstant.APIS['DO_RECHARGE']).subscribe(response => {
        this.apiResponse = response;
        //       MainWallet: 76.41
        // Message: "Recharge Failed"
        // Status: false
        // TreadWallet: 0
        if (this.apiResponse.Status) {
          console.log("-----Recharge Success part----");
          this.tab = 'successMessage';
          this.isButtonDisabled = false;
        } else {
          console.log("-----Recharge Fail part----");
          this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..");
          this.isButtonDisabled = false;
        }
        this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      }, err => {
        this.isButtonDisabled = false;
        this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..");
      })
    } else {
      alert("You have insufficient balance in MAIN WALLET, Please add money in to wallet MAIN WALLET.")
    }
  }




  /**
   * Following api we was using when we are doing recharge from front end side
   * 
   * 
   */

  submit1(appForm: NgForm) {
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if (this.walletData.MainWallet > this.formData.amount) {
      this.isButtonDisabled = true;
      this.formData.providerId = this.uid;



      this.formData.RegistrationId = this.sessionUserData.RegistrationId;
      this.formData.OrderType = "Mobile Recharge";
      this.formData.CustomerMobile = this.formData.mobile;
      this.formData.OperatorCode = this.formData.providerId;
      this.formData.OperatorName = this.Provider;
      this.formData.Date = new Date();
      this.formData.RechargeAmount = this.formData.amount;
      this.formData.CommissionAmt = 0.0;
      this.formData.BillAmount = 0.0;
      this.formData.CloseBalance = 0.0;
      this.formData.OpenBalance = null;
      this.formData.PaymentType = 'Main Wallet';
      this.formData.TransactionId = this.formData.reports_id;
      this.formData.RechargeType = this.recharge;
      this.dataService.post(this.formData, this.dataService.appConstant.APIS['INITIATE_RECHARGE_TRANSACTION']).subscribe(response => {
        this.formData.Client_Id = response.ClientId;
        sessionStorage.setItem('setheadType', 'Auth');
        this.externalAPIService.doRecharge(this.formData).subscribe(response => {
          this.apiResponse = response
          this.isButtonDisabled = false;

          this.formData.report_Id = this.apiResponse.report_id;
          this.formData.Utr = this.apiResponse.utr;
          this.formData.OrderId = this.apiResponse.OrderId;
          this.formData.Message = this.apiResponse.message;
          this.formData.status = this.apiResponse.status;
          this.formData.status_id = this.apiResponse.status_id;
          if (this.apiResponse.status_id == 0 || this.apiResponse.status_id == 1) {
            this.tab = 'successMessage';
          }
          if (this.apiResponse.status_id == 2) {
            this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
          }
          this.saveRechargeData();
        }, err => {
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
      })
    } else {
      alert("You have insufficient balance in MAIN WALLET, Please add money in to wallet MAIN WALLET.")
    }
  }



  /***
   * SAVE RECHARGE REPONSE
   */

  saveRechargeData() {
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['SAVE_RECHARGE_DETAIL']).subscribe(response => {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    })
  }


  showPlans(plan) {
    this.planMobileType = plan
    this.selectedPlanList = this.rechargePlan.data[plan];
  }

  showPlan(){
    console.log(this.currentPlan);
    this.selectedPlanList = this.rechargePlan[this.currentPlan];
  }

  scrollToPlan() {
    console.log("Recharge")
    console.log(this)
    this.isLoading = true;
    this.formData.circleId = 4;
    this.formData.providerId = this.uid;
    this.externalAPIService.refreshPay2allToken(() => {
        this.externalAPIService.getPlans(this.formData).subscribe(response => {
          this.apiResponse = response
          this.rechargePlan = this.apiResponse;
          this.selectedPlanList = this.rechargePlan.data.TOPUP;
          this.isShowPlanList = true;
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
    });
  }



  getRechargePlan() {
    this.isLoading = true;
    // this.formData.service = this.Provider.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    // this.formData.state = this.sessionUserData.StateName.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    this.formData.circleId = this.formData.circle;
    this.formData.providerId = this.uid;
    this.externalAPIService.refreshPay2allToken(() => {
        this.externalAPIService.getRechargePlans(this.formData).subscribe(response => {
            this.apiResponse = response
            
              this.rechargePlan = this.apiResponse.data;
              this.plans = Object.keys(this.rechargePlan);
              this.currentPlan = this.plans[0];
              this.isShowPlanList = true;
              this.isLoading = false;
              this.selectedPlanList = this.rechargePlan[this.currentPlan];
              console.log(this.currentPlan)
              $('#rechargePlanModal').modal('show');
        }, err => {
          this.isLoading = false;
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
    });
    sessionStorage.setItem('setheadType', null);

  }

  
  closePlanModal(){
    $('#rechargePlanModal').modal('hide');
  }


  selectPlan(plan) {
    
    this.formData.amount = plan.rs
    $('#rechargePlanModal').modal('hide')
  }

  changeNo() {
    this.selectedPlan = {}
    this.isShowPlanList = false;
  }

  fetchCircles() {
    if (this.circles) {
      return;
    }
    this.externalAPIService.refreshPay2allToken(() => {
      this.http.get("https://api.pay2all.in/v1/app/circle").subscribe(response => {
        if (response["data"]) {
          response["data"].forEach(ci => {
            this.circles = {...this.circles, [ci.id]: ci.circle_name};
          });
        }
      })
    });
  }
}
