import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../service/data-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.scss'],
})
export class SiderbarComponent implements OnInit {
  sideBarTab: any;
  public sessionUserData: any = {};
  public isProfileTab: any = false;

  public profileStatus: any = {};

  constructor(public dataService: DataServiceService, public router: Router) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem('myEReturn_User'));

    this.dataService.getMessage().subscribe((msg) => {
      if (
        msg == 'My_Profile' ||
        msg == 'Shop_Details' ||
        msg == 'Documents_Details' ||
        msg == 'Bank_Details' ||
        msg == 'Download' ||
        msg == 'Change_Password'
      ) {
        this.sideBarTab = msg;
        this.isProfileTab = true;
      }
      if (msg == 'Page_Dashboard') {
        this.isProfileTab = false;
      }
      if (msg.substring(0, 5) == 'Page_') {
        this.sideBarTab = msg.substring(5);
      }
      if (msg.substring(0, 5) == 'Page_') {
        this.sideBarTab = msg.substring(5);
      }
    });
  }

  ngOnInit(): void {}

  openTab(tab) {
    this.sideBarTab = tab;
    this.isProfileTab = false;
    this.router.navigate([tab]);
  }

  openTabProfile(tab) {
    this.sideBarTab = tab;
    this.router.navigate(['Profile', tab]);
  }

  logOut() {
    if (confirm('Are you sure to logout ')) {
      sessionStorage.clear();
      this.router.navigate(['/login'], { replaceUrl: true });
      this.dataService.sendMessage('LoggedIn');
      location.reload();
    }
  }
}
