import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {

  public sessionUserData: any = {};
  public providerList: any = []
  public providerListFiltered: any = [];
  public Provider: any;

  constructor(
    public route: Router,
    public router: ActivatedRoute) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.router.queryParams.subscribe(params => {
      if (params.Provider) {
        this.Provider = params.Provider;
      }
    })
  }

  ngOnInit(): void {
    if(this.Provider == 'Electricity'){
      this.electricity();
    }

    if(this.Provider == 'Landline'){
      this.landline();
    }


    if(this.Provider == 'Water'){
      this.waterBill();
    }


    if(this.Provider == 'Piped Gas'){
      this.gasProvider();
    }
    if(this.Provider == 'Broadband'){
      this.broadBrand();
    }

    if(this.Provider == 'Insurance Premium'){
      this.insurancePrimium();
    }
    if(this.Provider == 'Book Cylinder'){
      this.lpgGas();
    }
    this.providerListFiltered = this.providerList;
  }


  proccedToPay(operator) {
    this.route.navigate(['/BBPS/Pay_bill'], { queryParams: { Provider: this.Provider, operator: operator.option, uid: operator.id } });
  }

  electricity() {
    this.providerList = [
      {
        option: "Adani Electricity Mumbai Limited",
        id: 49,
        img: "assets/images/adani.png",
        name: "Adani Electricity Mumbai Limited",
        states: ["maharashtra"]
      },
      {
        option: "BEST",
        id: 53,
        img: "assets/images/best.png",
        name: "BEST",
        states: ["maharashtra"]
      },
      {
        option: "MSEDC Limited",
        id: 77,
        img: "assets/images/msedcLtd.png",
        name: "MSEDC Limited",
        states: ["maharashtra"]
      },
      {
        option: "Torrent Power",
        id: 47,
        img: "assets/images/torrent.png",
        name: "Torrent Power",
        states: ["maharashtra","gujarat"]
      },
      // {
      //   option: "Torrent Power AHMEDABAD",
      //   id: 163,
      //   img: "assets/images/torrent.png",
      //   name: "Torrent Power AHMEDABAD",
      //   states: ["gujarat"]
      // },
      // {
      //   option: "Torrent Power SURAT",
      //   id: 164,
      //   img: "assets/images/torrent.png",
      //   name: "Torrent Power SURAT",
      //   states: ["gujarat"]
      // },
      {
        option: "SNDL Nagpur",
        id: 107,
        img: "assets/images/SNDL_electri.png",
        name: "SNDL Nagpur",
        states: ["maharashtra"]
      },
      {
        option: "Tata Power - Mumbai",
        id: 119,
        img: "assets/images/tata_electri.png",
        name: "Tata Power - Mumbai",
        states: ["maharashtra"]
      },
      {
        option: "UTTAR GUJARAT VIJ COMPANY",
        id: 48,
        img: "assets/images/Uttar Gujarat Vij Company Limited.png",
        name: "UTTAR GUJARAT VIJ COMPANY",
        states: ["gujarat"]
      },
      {
        option: "DAKSHIN GUJARAT VIJ COMPANY",
        id: 59,
        img: "assets/images/Dakshin Gujarat Vij Company Limited.png",
        name: "DAKSHIN GUJARAT VIJ COMPANY",
        states: ["gujarat"]
      },
      {
        option: "MADHYA GUJARAT VIJ COMPANY",
        id: 60,
        img: "assets/images/Madhya Gujarat Vij Company Limited.png",
        name: "MADHYA GUJARAT VIJ COMPANY",
        states: ["gujarat"]
      },
      {
        option: "PASCHIM GUJARAT VIJ COMPANY",
        id: 61,
        img: "assets/images/Paschim Gujarat Vij Company Limited.png",
        name: "PASCHIM GUJARAT VIJ COMPANY",
        states: ["gujarat"]
      }
    ]
  }


  landline() {
    this.providerList = [
      {
        option: "Airtel",
        id: 33,
        img: "assets/images/airtelLand.png",
        name: "Airtel",
        states: []
      },
      {
        option: "MTNL-Delhi",
        id: 31,
        img: "assets/images/mtnlDel.png",
        name: "MTNL Delhi",
        states: []
      },
      {
        option: "BSNL Landline Corporate",
        id: 293,
        img: "assets/images/bsnlLandCorpo.png",
        name: "BSNL Landline Corporate",
        states: []
      },
      {
        option: "MTNL-Mumbai",
        id: 126,
        img: "assets/images/mtnlDel.png",
        name: "MTNL-Mumbai",
        states: []
      },
      {
        option: "BSNL Landline Individual",
        id: 32,
        img: "assets/images/bsnlLandInd.png",
        name: "BSNL Landline Individual",
        states: []
      },
      {
        option: "Tata Docomo CDMA Landline",
        id: 41,
        img: "assets/images/tataDoco.png",
        name: "Tata Docomo CDMA Landline",
        states: []
      }
    ]
  }



  waterBill() {
    this.providerList = [
      {
        option: "Pune Municipal Corporation -Water",
        id: 211,
        img: "assets/images/pmc_water.png",
        name: "Pune Municipal Corporation -Water",
        states: []
      },
      {
        option: "Pimpri Chinchwad Municipal Corporation(PCMC)",
        id: 0,
        img: "assets/images/pcmc_water.png",
        name: "Pimpri Chinchwad Municipal Corporation(PCMC)",
        states: []
      },
      {
        option: "Kalyan Dombivali",
        id: 293,
        img: "assets/images/kalyanDomb.png",
        name: "Kalyan Dombivali",
        states: []
      }
    ]
  }




  gasProvider() {
    this.providerList = [
      {
        option: "Mahanagar Gas Limited",
        id: 67,
        img: "assets/images/mahanagargas.png",
        name: "Mahanagar Gas Limited",
        states: ["maharashtra"]
      },
      {
        option: "Unique Central Piped Gas Pvt Ltd",
        id: 135,
        img: "assets/images/uniqueGas.png",
        name: "Unique Central Piped Gas Pvt Ltd",
        states: ["maharashtra"]
      },
      {
        option: "Maharashtra Natural Gas Ltd",
        id: 322,
        img: "assets/images/mahaNaturalGas.png",
        name: "Maharashtra Natural Gas Ltd",
        states: ["maharashtra"]
      },
      {
        option: "ADANI GAS",
        id: 92,
        img: "assets/images/Adani Gas.png",
        name: "ADANI GAS",
        states: ["gujarat"]
      },
      {
        option: "GUJARAT GAS LIMITED",
        id: 62,
        img: "assets/images/Gujarat Gas.png",
        name: "GUJARAT GAS LIMITED",
        states: ["gujarat"]
      },
      {
        option: "SABARMATI GAS LIMITED",
        id: 123,
        img: "assets/images/Sabarmati Gas Limited.png",
        name: "SABARMATI GAS LIMITED",
        states: ["gujarat"]
      },
      {
        option: "Vadodara Gas Limited",
        id: 136,
        img: "assets/images/Vadodara Gas Limited.png",
        name: "Vadodara Gas Limited",
        states: ["gujarat"]
      },
      {
        option: "Charotar Gas Sahakari Mandali Ltd",
        id: 318,
        img: "assets/images/Charotar Gas Sahakari Mandali Ltd.png",
        name: "Charotar Gas Sahakari Mandali Ltd",
        states: ["gujarat"]
      },
      {
        option: "IRM Energy Private Limited",
        id: 321,
        img: "assets/images/IRM Energy Private Limited.png",
        name: "IRM Energy Private Limited",
        states: ["gujarat"]
      }
    ]
  }




  broadBrand() {
    this.providerList = [
      {
        option: "Connect Broadband",
        id: 299,
        img: "assets/images/ConnBroad.png",
        name: "Connect Broadband",
        states: []
      },
      {
        option: "Hathway Broadband",
        id: 137,
        img: "assets/images/hathBroad.png",
        name: "Hathway Broadband",
        states: []
      },
      {
        option: "Nextra Broadband",
        id: 301,
        img: "assets/images/nexBroad.png",
        name: "Nextra Broadband",
        states: []
      },
      {
        option: "Airtel Broadband",
        id: 302,
        img: "assets/images/airBroad.png",
        name: "Airtel Broadband",
        states: []
      },
      {
        option: "Spectra Broadband",
        id: 310,
        img: "assets/images/specBroad.png",
        name: "Spectra Broadband",
        states: []
      },
      {
        option: "Tikona Infinet Pvt Ltd",
        id: 52,
        img: "assets/images/ConnBroad.png",
        name: "Tikona Infinet Pvt Ltd",
        states: []
      }
    ]
  }


  insurancePrimium() {
    this.providerList = [
      {
        option: "Tata AIG Life",
        id: 0,
        img: "assets/images/tataAig.png",
        name: "Tata AIG Life",
        states: []
      },
      {
        option: "ICICI Prudential Life Insurance",
        id: 0,
        img: "assets/images/ICICILife.png",
        name: "ICICI Prudential Life Insurance",
        states: []
      },
      {
        option: "HDFC Life Insurance Co. Ltd",
        id: 301,
        img: "assets/images/HDFCLife.png",
        name: "HDFC Life Insurance Co. Ltd",
        states: []
      },
      {
        option: "Pramerica Life Insurance Limited",
        id: 302,
        img: "assets/images/pramLife.png",
        name: "Pramerica Life Insurance Limited",
        states: []
      },
      {
        option: "Exide Life Insurance",
        id: 310,
        img: "assets/images/exideLife.png",
        name: "Exide Life Insurance",
        states: []
      },
      {
        option: "Future Generali India Life Insurance Company Limited",
        id: 52,
        img: "assets/images/futurLife.png",
        name: "Future Generali India Life Insurance Company Limited",
        states: []
      },
      {
        option: "Shriram Life Insurance Co Ltd",
        id: 0,
        img: "assets/images/shriLife.png",
        name: "Shriram Life Insurance Co Ltd",
        states: []
      },
      {
        option: "Reliance Nippon Life Insurance",
        id: 0,
        img: "assets/images/reliLife.png",
        name: "Reliance Nippon Life Insurance",
        states: []
      },
      {
        option: "LIC",
        id: 84,
        img: "assets/images/LIC.png",
        name: "LIC",
        states: []
      }
    ]
  }






  lpgGas() {
    this.providerList = [
      {
        option: "Bharat Gas (BPCL)",
        id: 328,
        img: "assets/images/bharatGas.png",
        name: "Bharat Gas (BPCL)",
        states: []
      },
      {
        option: "HP Gas (HPCL)",
        id: 330,
        img: "assets/images/hpGas.png",
        name: "HP Gas (HPCL)",
        states: []
      },
      {
        option: "Indane Gas (Indian Oil)",
        id: 329,
        img: "assets/images/indianGas.png",
        name: "Indane Gas (Indian Oil)",
        states: []
      }
    ]
  }

  onProviderChange(provider) {
    if (provider === "") {
      this.providerListFiltered = this.providerList;
    } else {
      this.providerListFiltered = this.providerList.filter(pro => pro.states.includes(provider));
    }
  } 

  onKeySearch(term) {
    let provider = (<HTMLInputElement>document.getElementById("selectState")).value;
    if (term === "") {
      this.onProviderChange(provider);
      return;
    }
    this.onProviderChange(provider);
    term = term.toLowerCase();
    this.providerListFiltered = this.providerListFiltered.filter(pro => pro.name.toLowerCase().includes(term) || pro.option.toLowerCase().includes(term));
  }

}
