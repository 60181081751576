import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DataServiceService } from '../../service/data-service.service';

@Component({
  selector: 'app-pan-card',
  templateUrl: './pan-card.component.html',
  styleUrls: ['./pan-card.component.scss']
})
export class PanCardComponent implements OnInit {
  public sessionUserData: any = {};
  public isShowSideBar: boolean = true;
  public ispanserviceactive: any = false;
  @ViewChild('clonse_btn_id') clonse_btn_id: ElementRef;


  close_modal() {
    let el: HTMLElement = this.clonse_btn_id.nativeElement as HTMLElement;
    el.click()
  }


  constructor(
    public dataService: DataServiceService,
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
  }

  ngOnInit(): void {
    this.check_panservice()
  }

  openModalpopup() {
    this.isShowSideBar = true;
  }

  closePopup() {
    this.isShowSideBar = false;
  }

  openUTI() {
    // SERVICE_PAN_REQUEST
    window.open('https://www.psaonline.utiitsl.com/psaonline/', '_blank')
  }

  check_panservice() {
    // this.ispanserviceactive = true;
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.sessionUserData = this.sessionUserData.Data;
    this.dataService.get(this.dataService.appConstant.APIS['SERVICE_PAN_REQUEST'] + this.sessionUserData.RegistrationId + '&IsRequest=' + false).subscribe((result) => {
      if (result.status = true) {
        switch (result.Message) {
          case "Pending":
            this.ispanserviceactive = 'Pending'
            break;
          case "Active":
            this.ispanserviceactive = 'Active'
            break
          case "Reject":
            this.ispanserviceactive = 'Reject'
            break
          case "Not Requested":
            this.ispanserviceactive = 'notrequestd'
            break
          default:
            this.ispanserviceactive = "notrequestd";
            break;
        }
      } else {
        this.dataService.appConstant.errorToast('Something is wrong..');
      }
    }, (err) => {
      this.dataService.appConstant.errorToast('Something is wrong..please try again');
      this.close_modal()
    });
  }

  requestpanserveice() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.sessionUserData = this.sessionUserData.Data;
    this.dataService.get(this.dataService.appConstant.APIS['SERVICE_PAN_REQUEST'] + this.sessionUserData.RegistrationId + '&IsRequest=' + true).subscribe((result) => {
      console.log(result);

      if (result.status = true) {
        this.dataService.appConstant.successToast('Pancard Service Request Send Successfully');
        this.close_modal()
        this.ispanserviceactive = 'Pending';
      } else {
        this.dataService.appConstant.errorToast('Something is wrong..please try again');
        this.close_modal()
        this.check_panservice()
      }
    }, (err) => {
      this.dataService.appConstant.errorToast('Something is wrong..please try again');
      this.check_panservice()
      this.close_modal()
    });
  }

  // SERVICE_PAN_REQUEST
}
