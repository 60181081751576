<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">Wallet Transfer</span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tab != 'successMessage'">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12">
                <div class="rechargeDialogue">
                    <div class="rechageCartHeading">
                        <h5><b>Wallet Transfer</b></h5>
                    </div>
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit(appForm)">
                        <div class="row">
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>From Wallet <span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="formData.fromWallet" name="fromWallet"
                                    #fromWallet="ngModel" required (ngModelChange)="selectWalletType()">
                                    <option value="TradeWallet" selected>Trade Wallet</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(fromWallet.touched || appForm.submitted) && fromWallet.errors?.required">
                                    From wallet is required
                                </span>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>To Wallet <span class="astric">*</span></label>
                                <select class="form-control" [(ngModel)]="formData.toWallet" name="toWallet"
                                    #toWallet="ngModel" required [disabled]="disabled">
                                    <option value="TradeWallet" selected>
                                        Trade Wallet
                                    </option>
                                    <option value="MainWallet">Main Wallet</option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(toWallet.touched || appForm.submitted) && toWallet.errors?.required">
                                    To wallet is required
                                </span>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Amount <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Amount"
                                    [(ngModel)]="formData.amount" name="Amount" #Amount="ngModel" required
                                    (keypress)="dataService.appConstant.keyPress($event)" autocomplete="off">
                                <span class="text-danger"
                                    *ngIf="(Amount.touched || appForm.submitted) && Amount.errors?.required">
                                    Amount is required
                                </span>
                            </div>
                        </div>
                        <br>
                        <div class="buttonGroup">
                            <button type="submit" class="btn btn-primary submitBtn"
                                [disabled]="isButtonDisabled || !appForm.form.valid "
                                [ngClass]="{'button--loading':isButtonDisabled}">
                                <span class="button__text">SUBMIT</span>
                            </button> &nbsp;&nbsp;
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <br>
        <div class="loader" *ngIf="isLoading"></div>

        <!-----SUCCESS MESSAGE-->
        <div class="processSuccess" *ngIf="tab == 'successMessage'">
            <i class="icofont-check-circled"></i>
            <br> <br>
            <h6><b>Done</b>.</h6>
            <div class="font14 grayClr">Rs. {{formData.amount}} </div>
            <div class="font14 grayClr"> Transfer successfull</div>
            <br>
            <br>
            <button class="btn btn-warning submitBtn" (click)="back()">OK</button>
        </div>
    </div>
</section>