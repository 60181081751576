<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span class="activeBreadCum">Pan Card Services </span>
                </div>
            </div>
        </div>

        <!-----Section Heading---->
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>Pan Card Services
                        </h5>
                    </div>
                </div>
            </div>
            <div class="row" >
                <div  *ngIf="ispanserviceactive !== 'Active'">
                    <div class="loader"></div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngIf="ispanserviceactive == 'Active'">
                    <div class="tCenter cp" routerLink="/Pan_Card_Services/Buy_Coupon">
                        <img src="assets/images/pan1.png" class="panServicesIcon">
                        <p class="tCenter">Buy Coupon</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngIf="ispanserviceactive == 'Active'" routerLink="/Pan_Card_Services/Reports">
                    <div class="tCenter cp">
                        <img src="assets/images/pan2.png" class="panServicesIcon">
                        <p class="tCenter">Report</p>
                    </div>
                </div>
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngIf="ispanserviceactive == 'Active'">
                    <div class="tCenter cp" (click)="openUTI()">
                        <img src="assets/images/pan3.png" class="panServicesIcon">
                        <p class="tCenter">Login to UTI Portal</p>
                    </div>
                </div>
                <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngIf="ispanserviceactive == 'Active'" routerLink="/Pan_Card_Services/Utility">
                    <div class="tCenter cp" >
                        <img src="assets/images/pan3.png" class="panServicesIcon">
                        <p class="tCenter">Utility</p>
                    </div>
                </div> -->
                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-lx-2" *ngIf="ispanserviceactive == 'notrequestd'">
                    <div class="tCenter cp exampleModalCenter" [ngClass]="{ addPanCardNoSidebar: !isShowSideBar }">
                        <span data-toggle="modal" data-target="#exampleModalCenter">
                            <img src="assets/images/pan1.png" class="panServicesIcon" />
                            <p class="tCenter">Service Request</p>
                        </span>
                    </div>
                </div>
                <div class="processSuccess" *ngIf="ispanserviceactive == 'Reject'">
                    <i class="icofont-close-circled text-danger"></i>
                    <br /><br />
                    <h6>
                        Your request has been Rejected Please Contact Customer Care For
                        <b>Pan Card Service </b> activation.
                    </h6>
                    <div class="font14 grayClr">Our team will get back to you shortly.</div>
                    <div class="font14 grayClr">
                        Thank you for your interest in <b>Pan Card Service</b>!
                    </div>
                </div>
                <div class="processSuccess" *ngIf="ispanserviceactive == 'Pending'">
                    <i class="icofont-check-circled"></i>
                    <br /><br />
                    <h6>
                        Your request has been successfully submitted to for
                        <b>Pan Card Service </b> activation.
                    </h6>
                    <div class="font14 grayClr">Our team will get back to you shortly.</div>
                    <div class="font14 grayClr">
                        Thank you for your interest in <b>Pan Card Service</b>!
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="pan-card-modal-header">

                        <button #clonse_btn_id id="close_modal_btn" type="button" class="close pan-card-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modalPanCard">
                        <div class="card bg-light">
                            <div class="pan-card-modal">
                                <h5 class="pan-card-title">Limited Time Offer</h5>
                                <p class="pan-card-subtitle mb-2 text-muted">Activete PAN Card Service</p>
                                <!-- <p class="pan-card-subtitle2 mb-2">at just <strong class="card-money">₹699</strong> only </p> -->
                                <p class="pan-card-text mb-2 text-muted">If You are interested, kindly enter your Register Number.</p>
                                <hr />
                                <h5 class="pan-card-title">सीमित समय का अवसर</h5>
                                <p class="pan-card-subtitle mb-2 text-muted">पैन कार्ड सर्विस एक्टिवेट करे</p>
                                <!-- <p class="pan-card-subtitle2 mb-2">at just <strong class="card-money">₹699</strong> only </p> -->
                                <p class="pan-card-text mb-2 text-muted">यदि आप चाहते हैं, तो अपना रजिस्टर मोबाइल नंबर दर्ज करें.</p>
                            </div>
                        </div>
                        <!-- <form>
                            <div class="form-group mt-3"> -->
                        <!-- <label class="pan-card-label" for="phone">Mobile Number</label>
                                <div class="input-group">
                                    <input id="phone" class="form-control" placeholder="Enter Altemate Mobile Number">

                                </div> -->
                        <div class="form-group mt-3">
                            <button type="button" (click)="requestpanserveice()" class="btn btn-card-submit form-control">Send Request</button>
                        </div>
                        <!-- </div>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>

        <br><br>
        <div class="sectionCard">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
                    <div class="sectionHeading">
                        <h5>Scanning Specification
                        </h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <ol>
                        <li>PAN Application Form (front Page & Back side) and Supporting Document (POI , POA ,DOB Proof as provided by applicant) to be scanned in 200dpi color - PDFA format (Size: Maximum upto 2MB)
                        </li>
                        <li>Photo Scanning 300 dpi,Colour,213 X 213 px (Size:less than 30 kb)</li>
                        <li>Signature scanning 600 dpi black and white 1023X680 (Size:less than 60 kb)</li>
                    </ol>
                </div>
            </div>
        </div>

    </div>
</section>