<section>
  <div class="container sectionContainer">
    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-11  col-lx-11 ">
        <div class="breadCum">
          <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
          <span routerLink="/BBPS">BBPS / </span>
          <span (click)="back()">Providers / </span>
          <span class="activeBreadCum">{{Provider}} Bill Payment</span>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="tab != 'successMessage'">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-lx-12">
        <div class="rechargeDialogue">
          <i (click)="back()" class="icofont-close closeIcon"></i>
          <div class="rechageCartHeading">
            <h5><b>{{operator}}</b></h5>
          </div>
          <br>

          <!--------Electricity PAY BILL------->
          <div *ngIf="Provider == 'Electricity'">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && fetchBill(appForm)">
              <div class="row">

                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                  *ngIf="operator == 'MSEDC Limited' || operator == 'Adani Electricity Mumbai Limited'  
                || operator == 'BEST' || operator == 'SNDL Nagpur' || operator == 'SNDL Nagpur' || operator == 'Tata Power - Mumbai' 
                || operator == 'UTTAR GUJARAT VIJ COMPANY' || operator == 'DAKSHIN GUJARAT VIJ COMPANY' || operator == 'MADHYA GUJARAT VIJ COMPANY' || operator == 'PASCHIM GUJARAT VIJ COMPANY'">
                  <label>Mobile No.<span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Mobile no."
                    [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Mobile no is required
                  </span>  
                  <label>Consumer No. <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter consumer no" [(ngModel)]="formData.CID"
                    name="CustomerId" #CustomerId="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Consumer number is required
                  </span>
                </div>


                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                  *ngIf="operator == 'Torrent Power'">
                  <label>Mobile No.<span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Mobile no."
                    [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Mobile no is required
                  </span>
                  <label>Service No. <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter service no" [(ngModel)]="formData.ServiceNo"
                    name="ServiceNo" #ServiceNo="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Service no is required
                  </span>
                </div>

                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                  *ngIf="operator == 'Torrent Power'">
                  <label>City<span class="astric">*</span></label>
                  <select class="form-control" [(ngModel)]="formData.city" name="City" #City="ngModel" required>
                    <option value="">Select City</option>
                    <option value="Agra">Agra</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Bhiwandi">Bhiwandi </option>
                    <option value="Surat">Surat</option>
                  </select>
                  <span class="text-danger" *ngIf="(City.touched || appForm.submitted) && City.errors?.required">
                    City is required
                  </span>
                </div>


                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                  *ngIf="operator == 'MSEDC Limited'">
                  <label>BU No.<span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter BU" [(ngModel)]="formData.BU" name="BU"
                    #BU="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(BU.touched || appForm.submitted) && BU.errors?.required">
                    BU is required
                  </span>
                </div>


                <!-- <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                  *ngIf="operator == 'MSEDC Limited'">
                  <label>Processing Cycle No. <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Processing Cycle"
                    [(ngModel)]="formData.procCycle" name="procCycle" #procCycle="ngModel" required autocomplete="off">
                  <span class="text-danger"
                    *ngIf="(procCycle.touched || appForm.submitted) && procCycle.errors?.required">
                    Processing Cycle is required
                  </span>
                </div> -->

              </div>
              <br>

              <div class="buttonGroup">
                <button type="submit" class="btn btn-primary submitBtn"
                  [disabled]="isButtonDisabled || !appForm.form.valid "
                  [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">FETCH BILL</span>
                </button> &nbsp;&nbsp;<br>
                <span style="color: #ff0f0f; font-size: 12px;">Note: 0.20 INR charge for every bill fetching</span>
              </div>
           
            </form>
          </div>

          <!--------LANDLINE PAY BILL FORM------->
          <div *ngIf="Provider == 'Landline' && operator == 'Airtel' || operator == 'Tata Docomo CDMA Landline' ">
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && payBillNow(appForm)">
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                  <label>Landline Number with STD code. <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter landline number"
                    [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                  <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                    Landline number is required
                  </span>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                  <label>Amount <span class="astric">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Amount" [(ngModel)]="formData.amount"
                    name="Amount" #Amount="ngModel" required (keypress)="dataService.appConstant.keyPress($event)"
                    autocomplete="off">
                  <span class="text-danger" *ngIf="(Amount.touched || appForm.submitted) && Amount.errors?.required">
                    Amount is required
                  </span>
                </div>
              </div>
              <br>
              <div class="buttonGroup">
                <button type="submit" class="btn btn-primary submitBtn"
                  [disabled]="isButtonDisabled || !appForm.form.valid "
                  [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">PAY NOW</span>
                </button> &nbsp;&nbsp;
              </div>
            </form>
          </div>

            <!--------LANDLINE FETCH BILL FORM------->
            <div *ngIf="Provider == 'Landline' && 
            (operator == 'MTNL-Delhi' || operator == 'BSNL Landline Corporate' || operator == 'MTNL-Mumbai' 
            || operator == 'BSNL Landline Individual' )">
              <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && fetchBill(appForm)">
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                    <label>Account No.<span class="astric">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter account No."
                      [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                    <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                      Account no is required
                    </span>
                  </div>

                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" 
                  *ngIf="operator == 'MTNL-Delhi' || operator == 'MTNL-Mumbai'">
                    <label>Telephone No.<span class="astric">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter telephone No."
                      [(ngModel)]="formData.telephone" name="telephone" #telephone="ngModel" required autocomplete="off">
                    <span class="text-danger" *ngIf="(telephone.touched || appForm.submitted) && telephone.errors?.required">
                      Telephone no is required
                    </span>
                  </div>

                </div>
                <br>
                <div class="buttonGroup">
                  <button type="submit" class="btn btn-primary submitBtn"
                    [disabled]="isButtonDisabled || !appForm.form.valid "
                    [ngClass]="{'button--loading':isButtonDisabled}">
                    <span class="button__text">FETCH BILL</span>
                  </button> &nbsp;&nbsp;<br>
                  <span style="color: #ff0f0f; font-size: 12px;">Note: 0.20 INR charge for every bill fetching</span>
                </div>
              </form>
            </div>


                  <!--------GAS PIPE FETCH BILL FORM------->
                  <div *ngIf="Provider == 'Piped Gas'">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && fetchBill(appForm)">
                      <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                            <label>Mobile No.<span class="astric">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Mobile no."
                              [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                            <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                              Mobile no is required
                            </span>
                        </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                        *ngIf="operator == 'Mahanagar Gas Limited'">
                          <label>CA No.<span class="astric">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter CA no."
                            [(ngModel)]="formData.CA" name="CA" #CA="ngModel" required autocomplete="off">
                          <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                            CA no is required
                          </span>
                        </div>
      
                        <!-- <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" 
                        *ngIf="operator == 'Mahanagar Gas Limited'">
                          <label>Bill Group No.<span class="astric">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter bill group no."
                            [(ngModel)]="formData.billGroupNo" name="billGroupNo" #billGroupNo="ngModel" required autocomplete="off">
                          <span class="text-danger" *ngIf="(billGroupNo.touched || appForm.submitted) && billGroupNo.errors?.required">
                            Bill group  no is required
                          </span>
                        </div> -->


                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                        *ngIf="operator == 'ADANI GAS' || operator == 'GUJARAT GAS LIMITED' || operator == 'SABARMATI GAS LIMITED'
                        || operator == 'IRM Energy Private Limited'">
                            <label>Customer ID<span class="astric">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter customer ID."
                              [(ngModel)]="formData.CID" name="CustomerId" #CustomerId="ngModel" required autocomplete="off">
                            <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                              Customer ID is required
                            </span>
                        </div>
                        
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                        *ngIf="operator == 'Vadodara Gas Limited'">
                          <label>Consumer No.<span class="astric">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter Consumer no."
                            [(ngModel)]="formData.ConsumerNo" name="ConsumerNo" #ConsumerNo="ngModel" required autocomplete="off">
                          <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                            Consumer no is required
                          </span>
                        </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                        *ngIf="operator == 'Unique Central Piped Gas Pvt Ltd' || operator == 'Charotar Gas Sahakari Mandali Ltd'">
                          <label>Customer No.<span class="astric">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter Customer no."
                            [(ngModel)]="formData.CustomerNo" name="CustomerNo" #CustomerNo="ngModel" required autocomplete="off">
                          <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                            Customer no is required
                          </span>
                        </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12"
                        *ngIf="operator == 'Maharashtra Natural Gas Ltd'">
                          <label>BP No.<span class="astric">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter BP no."
                            [(ngModel)]="formData.BP" name="BP" #BP="ngModel" required autocomplete="off">
                          <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                            BP no is required
                          </span>
                        </div>
      
                      </div>
                      <br>
                      <div class="buttonGroup">
                        <button type="submit" class="btn btn-primary submitBtn"
                          [disabled]="isButtonDisabled || !appForm.form.valid "
                          [ngClass]="{'button--loading':isButtonDisabled}">
                          <span class="button__text">FETCH BILL</span>
                        </button> &nbsp;&nbsp;<br>
                        <span style="color: #ff0f0f; font-size: 12px;">Note: 0.20 INR charge for every bill fetching</span>
                      </div>
                    </form>
                  </div>



                         <!--------Water FETCH BILL FORM------->
                         <div *ngIf="Provider == 'Water'">
                          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && fetchBill(appForm)">
                            <div class="row">
                              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Consumer No.<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter consumer no."
                                  [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                                <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                                  Consumer no is required
                                </span>
                              </div>
                            </div>
                            <br>
                            <div class="buttonGroup">
                              <button type="submit" class="btn btn-primary submitBtn"
                                [disabled]="isButtonDisabled || !appForm.form.valid "
                                [ngClass]="{'button--loading':isButtonDisabled}">
                                <span class="button__text">FETCH BILL</span>
                              </button> &nbsp;&nbsp;<br>
                              <span style="color: #ff0f0f; font-size: 12px;">Note: 0.20 INR charge for every bill fetching</span>
                            </div>
                          </form>
                        </div>



                        <!--------Insurance Premium PAY------->
                        <div *ngIf="Provider == 'Insurance Premium'">
                          <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && payBillNow()">
                            <div class="row">
                              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                                <label>Policy No.<span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Policy no."
                                  [(ngModel)]="formData.mobile" name="mobile" #mobile="ngModel" required autocomplete="off">
                                <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                                  Policy no is required
                                </span>
                                <label>Amount <span class="astric">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Amount"
                                  [(ngModel)]="formData.amount" name="amount" #amount="ngModel" required autocomplete="off">
                                <span class="text-danger" *ngIf="(mobile.touched || appForm.submitted) && mobile.errors?.required">
                                  amount is required
                                </span>
                              </div>
                            </div>
                            <br>
                            <div class="buttonGroup">
                              <button type="submit" class="btn btn-primary submitBtn"
                                [disabled]="isButtonDisabled || !appForm.form.valid "
                                [ngClass]="{'button--loading':isButtonDisabled}">
                                <span class="button__text">PAY</span>
                              </button> &nbsp;&nbsp;
                            </div>
                          </form>
                        </div>

        </div>
      </div>
    </div>


    <br>
    <!-----SUCCESS MESSAGE-->
    <!-- <div class="processSuccess" *ngIf="tab == 'successMessage'">
      <i class="icofont-check-circled"></i>
      <br> <br>
      <h6><b>Done</b>.</h6>
      <div class="font14 grayClr">Rs. {{formData.amount}} </div>
      <div class="font14 grayClr"> Your recharge done successfully</div>
      <br>
      <br>
      <button class="btn btn-warning submitBtn" (click)="back()">OK</button>
    </div> -->
  </div>
</section>


<!-- PAID BILL DETAIL -->
<div id="billReceipt" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content" id="bill-receipt-image">
      <div class="modal-header modalHeader">
        <div class="row w100">
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-lx-10">
            {{Provider}} Bill Receipt
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-lx-2 tRight cp">
            <i class="icofont-close" data-dismiss="modal" (click)="closeModal('billReceipt')"></i>
          </div>
        </div>
      </div>
      <div style="text-align: center; color: #ee4532; padding: 0.5rem 0;">
        Transaction Receipt
      </div>
      <div style="display: flex; justify-content: space-around;">
        <div class="row w100">
          <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-lx-8">
            <div style="display: flex; flex-direction: column; justify-content: center;">
              <h6>{{sessionUserData.Data.ShopName}}</h6>
              <div style="font-size: small;">
                {{sessionUserData.Data.ShopAddress1}}, {{sessionUserData.Data.ShopAddress2}}, {{sessionUserData.Data.ShopAreaLocality}}, {{sessionUserData.CityName}}, {{sessionUserData.StateName}}, {{sessionUserData.CountryName}} - {{sessionUserData.Data.ShopPincode}}
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 tRight cp">
            <img src="assets/images/logo1.png" width="100%">
          </div>
        </div>
      </div>
      <div class="modal-body modelFrom">
        <div class="row modalFixedInfo">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Service Provider</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{operator}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Shop Name</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{sessionUserData.Data.ShopName}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Payment date , time</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{formData.paydate ? formData.paydate.toLocaleString() : today.toLocaleString()}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Paid Amount</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>₹ {{formData.amount}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>customer name</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7 tRight">
            <b>{{formData.name}}</b>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-around; padding-top: 0.1rem;">
        <button class="btn btn-primary submitBtn" (click)="downloadReceipt()">
          <span class="button__text">Download as image</span>
        </button>
        <button class="btn btn-primary submitBtn" (click)="downloadReceiptAsPDF()">
          <span class="button__text">Print Receipt</span>
        </button>
      </div>
      <div style="color: #ee4532; padding: 0.2rem 0.6rem; font-size: small;">
        For any query email : help@myereturn.com <br>
        Please Note: Dear Consumer, the bill payment will reflect in next 72 working hours or in the next billing cycle, at your service provider’s end <br>
DECLARATION:
This is not an invoice but only a confirmation of the receipt of the amount paid against for the service as described above. <br><br>


(This is computer generated receipt and does not require a physical signature.)
      </div>
    </div>
  </div>
</div>






<!-- BILL DETAIL -->
<div id="billDetail" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        <div class="row w100">
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-lx-10">
            {{Provider}} Bill Payment
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-lx-2 tRight cp">
            <i class="icofont-close" data-dismiss="modal" (click)="closeModal('billDetail')"></i>
          </div>
        </div>
      </div>
      <div class="modal-body modelFrom">
        <div class="row modalFixedInfo" id="bill-image-section">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Operator</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{operator}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Consumer Name</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{formData.name}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Consumer No</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>{{formData.mobile}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Bill Amount</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7  tRight">
            <b>₹ {{formData.amount}}</b>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
            <div>Due Date</div>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
            <b>:</b>
          </div>
          <div class="col-7 col-sm-7 col-md- col-lg-7 col-lx-7 tRight">
            <b>{{formData.duedate}}</b>
          </div>
        </div>
        <div class="buttonGroup" style="display: flex; justify-content: space-between; align-items: baseline;">
          <button class="btn btn-primary" [disabled]="isBillButtonDisabled"
          [ngClass]="{'button--loading':isBillButtonDisabled}" (click)="downloadBill()">
          <div class="button__text">Bill As Image</div>
        </button>
        <button type="submit" class="btn btn-primary submitBtn payNow" [disabled]="isButtonDisabled"
          [ngClass]="{'button--loading':isButtonDisabled}" (click)="payBillNow()">
          <span class="button__text">PAY NOW</span>
        </button>
          <button class="btn btn-primary" [disabled]="isBillButtonDisabled"
            [ngClass]="{'button--loading':isBillButtonDisabled}" (click)="downloadBillAsPDF()">
            <div class="button__text">Bill as PDF</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>