import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {

  public tab:any;
  
  public formData:any = {};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;

  fileName = 'ComplaintReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public reportFilter:any = {numPerPage:10,pageNum:1};

  public reportList:any = [];
  public isLoading:boolean = false;
  public isShowFilter:boolean = true;
  public sessionUserData:any = {};

  constructor(
    public dataService:DataServiceService
  ) {
    this.tab  = "Register"
    if (window.innerWidth < 768) {
      this.isShowFilter = false;
    }
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
    this.reportFilter.service = "Add Money"
   }

  ngOnInit(): void {
    this.getRport()
  }

  changeTab(tab){
    this.tab = tab;
  }

  clear(){
    this.reportFilter.startDate = null;
    this.reportFilter.toDate = null;
    this.reportFilter.fromDate= null;
    this.reportFilter.endDate= null;
    this.reportFilter.searchKey= null;
    this.getRport();
   }

  
  getRport(){
    this.isLoading = true;
    this.reportList = [];
    sessionStorage.setItem('setheadType', null);
    if(this.reportFilter.fromDate){
      this.reportFilter.startDate = this.reportFilter.fromDate +"T23:39:14"
    }

    if(this.reportFilter.endDate){
      this.reportFilter.toDate = this.reportFilter.endDate +"T23:39:14"
    }

    this.dataService.get(this.dataService.appConstant.APIS['GET_COMPLAINT']+"&fromdate="+this.reportFilter.startDate+"&Todate="+this.reportFilter.toDate+"&Type="+this.reportFilter.service +"&PageNo=1&RegistrationId="+this.sessionUserData.RegistrationId).subscribe(result => {
      this.apiResponse = result;
      this.reportList = this.apiResponse;
      this.isLoading = false;
      this.isButtonDisabled = false;
    }, err => {
      this.isLoading = false;
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast('Something is wrong..please try again');
    });
  }


  submit(){
    if(this.reportFilter.fromDate <= this.reportFilter.endDate ){
      this.isButtonDisabled  = true;
      this.reportList = [];
      this.isLoading = true;
      this.getRport();
     }else{
       this.dataService.appConstant.errorToast("End date must be greater than start date")
     }
  }


  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  
  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }


}
