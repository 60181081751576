import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ExternalAPIService } from '../../service/external-api.service';
import { DataServiceService } from '../../service/data-service.service';

declare var $: any;
@Component({
  selector: 'app-money-transfer',
  templateUrl: './money-transfer.component.html',
  styleUrls: ['./money-transfer.component.scss'],
})
export class MoneyTransferComponent implements OnInit {
  fileName = 'MoneyTransferReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public senderData: any = {};
  public beneficiaryData: any = {};
  public transferData: any = {};

  public newSenderData: any = {};
  public newBeneficiaryData: any = {};
  public newBeneficiaryList: any = [];

  public isSenderLoading: boolean = false;
  public isSenderDisabled: boolean = false;

  public isBeneficiaryLoading: boolean = false;
  public isBeneficiaryDisabled: boolean = false;

  public isTransferLoading: boolean = false;
  public isTransferDisabled: boolean = false;

  public isdeleteloding: boolean = false;

  public isVerifyOTPLoading: boolean = false;
  public isVerifyOTPDisabled: boolean = false;

  public data: any = {};
  public searchKeySender: any;
  public isVendorSearching: boolean = false;
  public isAddSenderEnable: boolean = false;
  public isSenderOtpFormEnabled: boolean = false;

  public isAddBeneficiaryEnabled: boolean = false;
  public userData: any = {};
  public isTransferAmountSection: boolean = true;
  public isTransferSuccessSection: boolean = false;
  public myDate = new Date();
  public searchBeneficiary: any;
  public bankData: any = [];
  public walletData: any = {};
  public sessionUserData: any = {};
  public apiResponse: any;
  public tab: any;
  public transactionDate: any;
  public transactionTime: any;
  public BankNameList: any;
  public isverify_benificiary_name: any = false

  constructor(
    public dataService: DataServiceService,
    private externalAPIService: ExternalAPIService,
    private datePipe: DatePipe
  ) {
    this.sessionUserData = JSON.parse(sessionStorage.getItem('myEReturn_User'));
    this.walletData = JSON.parse(sessionStorage.getItem('walletData'));
    this.dataService.sendMessage('pageChange');
    this.dataService.sendMessage('showSideBar');
    this.tab = 'transferForm';

    this.transactionDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.transactionTime = new Date().toLocaleTimeString();

    this.dataService.getMessage().subscribe((msg) => {
      if (msg == 'walletUpdated') {
        this.walletData = JSON.parse(sessionStorage.getItem('walletData'));
      }
    });
  }

  ngOnInit(): void {
    this.transferData.customerCharges = 0;
    this.transferData.retailerCharges = 0;
    this.transferData.retailerCommission = 0;
    this.transferData.Amount = 0;

    if (this.sessionUserData.RegistrationId) {
      this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    }

    this.getBankNameData();
    this.getBankName()
  }

  getBankName() {
    this.dataService.get(this.dataService.appConstant.APIS['GET_ALL_BANKNAME']).subscribe(
      (result) => {
        let bankCodes = ["6", "100", "7", "1", "52", "44", "15", "11", "2", "3", "24"];
        result.Banks.sort((a,b) => bankCodes.includes(a.BankId) ? -1 : bankCodes.includes(b.BankId) ? 1 : 0);
        this.BankNameList = result.Banks
      })

  }

  change_beneficiary_name(e) {
    var beneficiary_name_span_id: any = document.getElementById('beneficiary_name_pan_id');
    if (typeof (beneficiary_name_span_id) != 'undefined' && beneficiary_name_span_id != null) {
      beneficiary_name_span_id.remove()
    }
  }

  getBankNameData() {
    sessionStorage.setItem('setheadType', 'Auth');
    this.externalAPIService.getAllBankDetails().subscribe((data) => {
      this.bankData = JSON.parse(JSON.stringify(data)).banks;
    });
    sessionStorage.removeItem('setheadType');
  }

  addSender(form: NgForm) {
    this.isSenderDisabled = true;
    this.senderData.RetailerId = this.sessionUserData.Data.RegistrationId;
    this.dataService
      .post(this.senderData, this.dataService.appConstant.APIS['ADD_SENDER'])
      .subscribe(
        (result) => {
          this.data = result;
          this.isSenderDisabled = false;
          if (true === this.data.Status) {
            // this.senderData.otp = this.data.Otp;
            this.dataService.appConstant.successToast(
              'Please Verify Otp Send To Your Mobile Number'
            );
            this.isSenderOtpFormEnabled = true;
            this.isAddSenderEnable = false;
          } else {
            this.dataService.appConstant.errorToast(this.data.Message);
          }
        },
        (err) => {
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
      );
  }

  cancelVerifyOtp() {
    this.isSenderOtpFormEnabled = false;
    this.isAddSenderEnable = false;
  }

  VerifyOtp() {
    this.isVerifyOTPDisabled = true;
    this.isVerifyOTPLoading = true;
    this.dataService
      .post(
        this.senderData,
        this.dataService.appConstant.APIS['VERIFY_OTP'] +
        this.senderData.MobileNumber +
        '&Otp=' +
        this.senderData.otp
      )
      .subscribe(
        (result) => {
          this.data = result;
          this.isVerifyOTPDisabled = false;
          this.isVerifyOTPLoading = false;
          if (true === this.data.Status) {
            this.dataService.appConstant.successToast(
              'Otp Verification Successfully Done'
            );
            this.searchKeySender = this.senderData.MobileNumber;
            this.isSenderOtpFormEnabled = false;
            this.isAddSenderEnable = false;
            this.searchSender();
          } else {
            this.dataService.appConstant.errorToast(this.data.Message);
          }
        },
        (err) => {
          this.isVerifyOTPDisabled = false;
          this.isVerifyOTPLoading = false;
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
      );
  }

  searchSender() {
    if (this.searchKeySender && this.searchKeySender.length > 9) {
      this.isVendorSearching = true;
      this.dataService
        .get(
          this.dataService.appConstant.APIS['SEARCH_SENDER'] +
          this.searchKeySender +
          '&RegistrationId=' +
          this.sessionUserData.Data.RegistrationId
        )
        .subscribe(
          (result) => {
            this.data = result;
            this.isVendorSearching = false;
            if (true === this.data.Status) {
              this.newSenderData = this.data;
              this.isVendorSearching = false;
              this.getBeneficiaryList();
              this.dataService.appConstant.successToast(
                'Sender detail find successfully'
              );
            } else {
              this.dataService.appConstant.errorToast(
                'Oops..Sender detail not find...'
              );
              this.isVendorSearching = false;
              this.isAddSenderEnable = true;
              this.senderData.MobileNumber = this.searchKeySender;
            }
          },
          (err) => {
            this.isVendorSearching = false;
            this.dataService.appConstant.errorToast(
              'Something is wrong..please try again'
            );
          }
        );
    } else {
      this.newSenderData = {};
      this.beneficiaryData = {};
      this.newBeneficiaryList = [];
      this.newBeneficiaryData = {};
      this.transferData.customerCharges = 0;
      this.transferData.retailerCharges = 0;
      this.transferData.retailerCommission = 0;
      this.transferData.Amount = 0;
      this.isAddBeneficiaryEnabled = false;
      this.isAddSenderEnable = false;
      this.isSenderOtpFormEnabled = false;
      this.isAddSenderEnable = false;
      this.senderData = {};
      this.transferData = {};
      this.searchBeneficiary = null;
    }
  }

  clearSender() {
    if (
      this.senderData.MobileNumber &&
      this.senderData.MobileNumber.length > 9
    ) {
      this.senderData.MobileNumber = null;
      this.resetSender();
    }
  }

  resetSender() {
    this.searchKeySender = null;
    this.newSenderData = {};
    this.beneficiaryData = {};
    this.newBeneficiaryList = [];
    this.newBeneficiaryData = {};
    this.transferData.customerCharges = 0;
    this.transferData.retailerCharges = 0;
    this.transferData.retailerCommission = 0;
    this.transferData.Amount = 0;
    this.isAddBeneficiaryEnabled = false;
    this.isAddSenderEnable = false;
    this.isSenderOtpFormEnabled = false;
    this.isAddSenderEnable = false;
    this.senderData = {};
    this.transferData = {};
    this.searchBeneficiary = null;
  }

  openBeneficiaryForm() {
    this.isAddBeneficiaryEnabled = true;
    setTimeout(() => {
      let sep = new Option("-------------------", "");
      sep.setAttribute("disabled", "disabled");
      let selector = document.getElementsByName("BankName")[0][11];
      selector.insertAdjacentElement("afterend", sep);
    }, 1000);
  }

  getBeneficiaryList() {
    this.dataService
      .get(
        this.dataService.appConstant.APIS['BENEFICIARY_LIST'] +
        this.newSenderData.SenderId
      )
      .subscribe(
        (result) => {
          this.data = result;
          this.newBeneficiaryList = this.data;
          this.newBeneficiaryList.reverse();
          if (this.newBeneficiaryList && this.newBeneficiaryList.length > 0) {
            this.newBeneficiaryData = this.newBeneficiaryList[0];
          }
        },
        (err) => {
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
      );
  }

  selectBeneficiary(data) {
    this.newBeneficiaryData = data;
  }

  changeVerify(e) {
    var beneficiary_name = document.getElementById('beneficiary_name_div');
    if (e.target.checked) {
      this.isverify_benificiary_name = true
      beneficiary_name.style.display = 'none';
    } else {
      this.isverify_benificiary_name = false;
      beneficiary_name.style.display = 'block';
    }
  }

  addBeneficiary(form: NgForm) {
    var beneficiary_name: any = document.getElementById('beneficiary_name_id');
    var beneficiary_name_span_id: any = document.getElementById('beneficiary_name_pan_id');
    if (typeof (beneficiary_name_span_id) != 'undefined' && beneficiary_name_span_id != null) {
      beneficiary_name_span_id.remove()
    }
    if (this.isverify_benificiary_name == true) {
      this.add_beneficiary()
      form.resetForm();
    } else {
      if (beneficiary_name.value) {
        this.add_beneficiary()
        form.resetForm();
      } else {
        var el = document.createElement("span");
        el.innerHTML = "Beneficiary Name is required";
        el.id = "beneficiary_name_pan_id";
        el.className = "text-danger";
        beneficiary_name.parentNode.insertBefore(el, beneficiary_name.nextSibling);
        form.resetForm();
      }
    }
  }

  add_beneficiary() {
    this.isBeneficiaryDisabled = true;
    this.isBeneficiaryLoading = true;
    (this.beneficiaryData.SenderId = this.newSenderData.SenderId),
      (this.beneficiaryData.RetailerId =
        this.sessionUserData.Data.RegistrationId);
    this.dataService
      .post(
        this.beneficiaryData,
        this.dataService.appConstant.APIS['ADD_BENEFICIARY']
      )
      .subscribe(
        (result) => {
          this.data = result;
          this.isBeneficiaryDisabled = false;
          this.isBeneficiaryLoading = false;
          if (true === this.data.Status) {
            this.dataService.appConstant.successToast(
              'Beneficiary Account Added Successfully'
            );
            this.isAddBeneficiaryEnabled = false;
            this.getBeneficiaryList();
          } else {
            this.dataService.appConstant.errorToast(this.data.Message);
          }
        },
        (err) => {
          this.isBeneficiaryDisabled = false;
          this.isBeneficiaryLoading = false;
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
      );

  }

  calculateTotalTransfer() {
    if (
      this.transferData.transferAmount &&
      this.transferData.transferAmount > 0
    ) {

      if (this.transferData.transferAmount < 200001) {
        this.transferData.customerCharges = 0;
        this.transferData.retailerCharges = 0;
        this.transferData.retailerCommission = 0;
        this.transferData.Amount = 0;

        //CUSTOMER CHARGES
        if (this.transferData.transferAmount <= 1200) {
          this.transferData.customerCharges = 12;
        } else {
          this.transferData.customerCharges =
            (1 / 100) * parseInt(this.transferData.transferAmount);
          this.transferData.customerCharges = Math.ceil(
            this.transferData.customerCharges
          );
          if (this.transferData.customerCharges < 12) {
            this.transferData.customerCharges = 12;
          }
        }

        //RETAILER CHARGES
        this.dataService.get(this.dataService.appConstant.APIS['GET_ALL_CHARGES'] + '?Amount=' + this.transferData.transferAmount).subscribe((result) => {
          if (result) {
            this.transferData.retailerCharges = result.Retailer;

            this.transferData.retailerCommission =
              parseInt(this.transferData.customerCharges) -
              parseInt(this.transferData.retailerCharges);
            this.transferData.Amount =
              parseInt(this.transferData.transferAmount) +
              parseInt(this.transferData.retailerCharges);
          }
        }, (err) => {
          //  this.isTransferLoading = false;
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
        );

        console.log(this.transferData.retailerCharges, '--------  retailer charge ---------------');
        // if (this.transferData.transferAmount <= 5000) {
        //   this.transferData.retailerCharges = 12;
        // } else if (
        //   this.transferData.transferAmount > 5000 &&
        //   this.transferData.transferAmount <= 10000
        // ) {
        //   this.transferData.retailerCharges = 24;
        // } else if (
        //   this.transferData.transferAmount > 10000 &&
        //   this.transferData.transferAmount <= 15000
        // ) {
        //   this.transferData.retailerCharges = 36;
        // } else if (
        //   this.transferData.transferAmount > 15000 &&
        //   this.transferData.transferAmount <= 25000
        // ) {
        //   this.transferData.retailerCharges = 60;
        // }
        // else if (
        //   this.transferData.transferAmount > 25000 &&
        //   this.transferData.transferAmount <= 200000
        // ) {
        //   this.transferData.retailerCharges = 120;
        // }



        // this.transferData.retailerCommission =
        //   parseInt(this.transferData.customerCharges) -
        //   parseInt(this.transferData.retailerCharges);
        // this.transferData.Amount =
        //   parseInt(this.transferData.transferAmount) +
        //   parseInt(this.transferData.retailerCharges);
      } else {
        this.transferData.transferAmount = 0;
        alert('Amount should not exceed 2,00,000 /-');
      }
    } else {
      this.transferData.customerCharges = 0;
      this.transferData.retailerCharges = 0;
      this.transferData.retailerCommission = 0;
      this.transferData.Amount = 0;
    }
  }



  deleteBeneficiary() {    
    if (this.sessionUserData.RegistrationId) {
      this.transferData.Sender = this.newSenderData.Name;
      this.transferData.MobileNumber = this.newSenderData.MobileNumber;
      this.transferData.Beneficiry = this.newBeneficiaryData.BeneficiaryName;
      this.transferData.BankIfsc = this.newBeneficiaryData.BankIfsc;
      this.transferData.Accountno = this.newBeneficiaryData.AccountNumber;
      this.transferData.Servicecharge = 0;
      this.transferData.Type = 'IMPS';
      this.transferData.SenderId = this.newSenderData.SenderId;
      this.transferData.RegistrationId =
        this.sessionUserData.Data.RegistrationId;
      this.isdeleteloding = true;
      let request = {
        "TransferDateTime": this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        "Sender": this.newSenderData.Name,
        "Beneficiry": this.newBeneficiaryData.BeneficiaryName,
        "AccountNumber": this.newBeneficiaryData.AccountNumber,
        "RegistrationId": this.sessionUserData.RegistrationId,
        "SenderId": this.newSenderData.SenderId,
        "RetailerId": this.sessionUserData.RegistrationId,
        "MobileNumber": this.newBeneficiaryData.MobileNumber,
        "BankIfsc": this.newBeneficiaryData.BankIfsc
      }
      this.dataService.post(request, this.dataService.appConstant.APIS['DELETE_BENEFICIAY']).subscribe((result) => {
        this.data = result;
        if (this.data.status) {
          this.isdeleteloding = false;
          this.dataService.appConstant.successToast('Your Beneficiary has been deleted successfully');
          this.getBeneficiaryList()
        } else {
          this.isdeleteloding = false;
          this.dataService.appConstant.errorToast('Your Beneficiay has been deleted unsuccessfully');
          this.getBeneficiaryList()
        }
      },
        (err) => {
          this.isdeleteloding = false;
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
          this.getBeneficiaryList()
        }
      );

    }
  }



  /***
   * This api are use to transfer money 
   *  Pay2All api integrated at backend side
   */

  transferMoney(form: NgForm) {
    if (this.transferData.transferAmount >= 100) {
      if (this.sessionUserData.RegistrationId) {
        this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      }
      if (this.walletData.TreadWallet > this.transferData.Amount) {


        this.transferData.Sender = this.newSenderData.Name;
        this.transferData.MobileNumber = this.newSenderData.MobileNumber;
        this.transferData.Beneficiry = this.newBeneficiaryData.BeneficiaryName;
        this.transferData.BankIfsc = this.newBeneficiaryData.BankIfsc;
        this.transferData.Accountno = this.newBeneficiaryData.AccountNumber;
        this.transferData.Servicecharge = 0;
        this.transferData.Type = 'IMPS';
        this.transferData.SenderId = this.newSenderData.SenderId;
        this.transferData.RegistrationId =
          this.sessionUserData.Data.RegistrationId;
        this.isTransferLoading = true;



        let request = {
          "TransferDateTime": this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
          "Sender": this.newSenderData.Name,
          "Beneficiry": this.newBeneficiaryData.BeneficiaryName,
          "Accountno": this.newBeneficiaryData.AccountNumber,
          "Amount": parseInt(this.transferData.transferAmount),
          "Servicecharge": 1.0,
          "Type": "IMPS",
          "Status": "Process",
          "BankRefNo": "00",
          "retailerCharges": this.transferData.retailerCharges,
          "retailerCommission": this.transferData.retailerCommission,
          "RegistrationId": this.sessionUserData.RegistrationId,
          "SenderId": this.newSenderData.SenderId,
          "RetailerId": this.sessionUserData.RegistrationId,
          "MobileNumber": this.newSenderData.MobileNumber,
          "IFSC": this.newBeneficiaryData.BankIfsc
        }

        this.dataService.post(request, this.dataService.appConstant.APIS['MONEY_TRANSFER']).subscribe((result) => {
          this.data = result;
          console.log(this.data, '----------response transfer -----------');
          if (this.data.Status) {
            this.dataService.appConstant.successToast('Your transaction has been successfully');
            this.tab = 'successMessage';
            this.transferData.OrderId = this.data.OrderNo;
            this.transferData.BankRefNo = this.data.UTR;
            this.isTransferLoading = false;
            if (this.sessionUserData.RegistrationId) {
              this.dataService.getWalletData(this.sessionUserData.RegistrationId);
            }
          } else {
            this.isTransferLoading = false;
            this.dataService.appConstant.errorToast('Your transaction has been unsuccessfully');
          }
        },
          (err) => {
            this.isTransferLoading = false;
            this.dataService.appConstant.errorToast(
              'Something is wrong..please try again'
            );
          }
        );
      } else {
        alert(
          'You have insufficient balance in TRADE WALLET, Please add money in to wallet TRADE WALLET.'
        );
      }

    } else {
      alert('Minimum transfer amount should be 100  /-');
    }

  }





  /*****
   * This api are use to transfer money 
   * And Pay2All api integrated at front end side
   */
  transferMoney1(form: NgForm) {
    if (this.transferData.transferAmount >= 100) {
      if (this.sessionUserData.RegistrationId) {
        this.dataService.getWalletData(this.sessionUserData.RegistrationId);
      }
      if (this.walletData.TreadWallet > this.transferData.Amount) {
        this.transferData.Sender = this.newSenderData.Name;
        this.transferData.MobileNumber = this.newSenderData.MobileNumber;
        this.transferData.Beneficiry = this.newBeneficiaryData.BeneficiaryName;
        this.transferData.BankIfsc = this.newBeneficiaryData.BankIfsc,
          this.transferData.Accountno = this.newBeneficiaryData.AccountNumber;
        this.transferData.Servicecharge = 0;
        this.transferData.Type = 'IMPS';
        this.transferData.SenderId = this.newSenderData.SenderId;
        this.transferData.RegistrationId =
          this.sessionUserData.Data.RegistrationId;
        this.isTransferLoading = true;
        this.dataService
          .post(
            this.transferData,
            this.dataService.appConstant.APIS['INITIAL_TRANSACTION']
          )
          .subscribe(
            (result) => {
              this.data = result;
              if (this.data.Status) {
                // this.isTransferSuccessSection = false;
                // this.isTransferAmountSection = true;
                this.transferData.ClientId = this.data.ClientId;
                this.dataService.appConstant.successToast(
                  'Transaction Initialization Successfully'
                );
                this.isAddBeneficiaryEnabled = false;
                this.MoneyTransfer();
              } else {
                this.isTransferLoading = false;
                this.dataService.appConstant.errorToast(this.data.Message);
              }
            },
            (err) => {
              this.isTransferLoading = false;
              this.dataService.appConstant.errorToast(
                'Something is wrong..please try again'
              );
            }
          );
      } else {
        alert(
          'You have insufficient balance in TRADE WALLET, Please add money in to wallet TRADE WALLET.'
        );
      }

    } else {
      alert('Minimum transfer amount should be 100  /-');
    }

  }

  MoneyTransfer() {
    this.dataService.getWalletData(this.sessionUserData.RegistrationId);
    if (this.walletData.TreadWallet > this.transferData.Amount) {
      sessionStorage.setItem('setheadType', 'Auth');
      this.externalAPIService.transfer(this.transferData).subscribe(
        (data) => {
          this.apiResponse = data;

          this.isTransferLoading = false;
          this.transferData.CCF = this.transferData.customerCharges;
          this.transferData.Servicecharge = this.transferData.Servicecharge;
          this.transferData.Sender = this.newSenderData.Name;
          this.transferData.Beneficiry =
            this.newBeneficiaryData.BeneficiaryName;
          this.transferData.bankname = this.newBeneficiaryData.BankName;
          this.transferData.Accountno = this.newBeneficiaryData.AccountNumber;
          this.transferData.ifsc = this.newBeneficiaryData.BankIfsc;
          this.transferData.TransferDateTime =
            this.transactionDate + 'T' + this.transactionTime;
          this.transferData.Type = 'IMPS';
          this.transferData.SenderId = this.newSenderData.SenderId;
          this.transferData.RetailerId = this.sessionUserData.Data.RegistrationId;
          this.transferData.Type = 'Saveing';





          if (this.apiResponse['status'] == 0) {
            this.dataService.appConstant.successToast(
              'Your transaction has been successfully'
            );

            this.tab = 'successMessage';
          }
          if (this.apiResponse['status'] == 2) {
            this.isTransferLoading = false;
            this.dataService.appConstant.errorToast(
              'Your transaction has been unsuccessfully'
            );
          }





          this.transferData.BankRefNo = this.apiResponse.utr;
          this.transferData.utr = this.apiResponse.utr;
          this.transferData.Client_Id = this.transferData.ClientId;
          this.transferData.report_Id = this.apiResponse.report_id;
          this.transferData.Utr = this.apiResponse.utr;
          this.transferData.OrderId = this.apiResponse.orderid;
          this.transferData.Message = this.apiResponse.message;
          this.transferData.status = this.apiResponse.status;
          this.transferData.status_id = this.apiResponse.status_id;
          if (this.apiResponse['status'] == 0) {
            this.tab = 'successMessage';
          }
          if (this.apiResponse['status'] == 2) {
            this.dataService.appConstant.errorToast("Oops...Mobile recharge failed..Contact to distributor");
          }


          this.dataService
            .post(
              this.transferData,
              this.dataService.appConstant.APIS['SUBMIT_TRANSFER_AMOUNT']
            )
            .subscribe((data) => {
              this.dataService.getWalletData(
                this.sessionUserData.RegistrationId
              );
            });


        },
        (err) => {
          this.isTransferLoading = false;
          this.dataService.appConstant.errorToast(
            'Server Error....Transaction has been unsuccessfully'
          );
        }
      );
      sessionStorage.removeItem('setheadType');
    } else {
      this.isTransferLoading = false;
      alert(
        'You have insufficient balance in TRADE WALLET, Please add money in to wallet TRADE WALLET.'
      );
    }
  }

  updateStatus(form: NgForm) {
    this.transferData.Sender = this.newSenderData.Name;
    this.transferData.Beneficiry = this.newBeneficiaryData.BeneficiaryName;
    this.transferData.Accountno = this.newBeneficiaryData.AccountNumber;
    this.transferData.Servicecharge = 0;
    this.transferData.Type = 'IMPS';
    this.transferData.SenderId = this.newSenderData.SenderId;
    this.transferData.RetailerId = this.sessionUserData.Data.RegistrationId;
    this.dataService
      .post(
        this.transferData,
        this.dataService.appConstant.APIS['UPDATE_STATUS']
      )
      .subscribe(
        (result) => {
          this.data = result;
          if (200 === this.data.StatusCode) {
            // this.isTransferSuccessSection = false;
            // this.isTransferAmountSection = true;
            this.transferData.ClientId = this.data.ClientId;
            this.dataService.appConstant.successToast(
              'Update Status Successfully'
            );
            this.isAddBeneficiaryEnabled = false;
            this.getBeneficiaryList();
          } else {
            this.dataService.appConstant.errorToast(this.data.Message);
          }
        },
        (err) => {
          this.dataService.appConstant.errorToast(
            'Something is wrong..please try again'
          );
        }
      );
  }

  clearTransaction() {
    this.newSenderData = {};
    this.senderData = {};
    this.newBeneficiaryData = {};
    this.newBeneficiaryList = {};
    this.beneficiaryData = {};
    this.isVendorSearching = false;
    this.isAddSenderEnable = false;
    this.isSenderOtpFormEnabled = false;
    this.isAddBeneficiaryEnabled = false;
    this.isTransferLoading = false;
    this.transferData.customerCharges = 0;
    this.transferData.retailerCharges = 0;
    this.transferData.transferAmount = 0;
    this.transferData.Amount = 0;
    this.searchKeySender = null;
    this.tab = 'transferForm';
  }

  backToSuccess() {
    console.log('BACK_TO');
    this.tab = 'successMessage';
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  printToCart() {
    $('#printReceiptModal').modal('show');
    setTimeout(() => {
      var innerContents = document.getElementById('printSectionId').innerHTML;
      var popupWinindow = window.open(
        '',
        '_blank',
        'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
      );
      popupWinindow.document.open();
      popupWinindow.document.write(
        '<html><head><link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="resources/dist/css/AdminLTE.min.css" /><script>function printData(){setTimeout(function(){window.print();},500);}</script></head><body onload="printData();">' +
        innerContents +
        '</html>'
      );
      popupWinindow.document.close();
    }, 1000);
  }
}
