
<section>
  <div class="container sectionContainer">
 <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10  col-lx-10 ">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
                    <span>Pan Card Services / </span>
                    <span class="activeBreadCum"> Buy Coupon </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2 ">
                <button (click)="back()" class="btn btn-warning submitBtn">
          <span class="button__text">Back</span>
        </button>
            </div>
        </div>

  <div class="signature-popup">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-5">
        <div class="row pr-50">
          <div class="left-box">
            <h4 class="text-center"><img src="assets/images/user-icon.jpg" width="18px"> Get Photo</h4>
            <label class="d-block dark-color">Upload Photo</label>
            <input class="d-block dark-color" #inputResizePhoto type="file" (change)="selectFile($event.target.files, 'ResizePhoto')" />
            <p>Width = 213, Height = 213, DPI = 300</p>
            <div class="text-center pt-10 d-block">
              <a href="#" class="button" (click)="getphoto($event)">GET PHOTO</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="row text-right pl-50">

          <div class="left-box gradient-box">
            <h4 class="text-center">Get Signature</h4>
            <label class="d-block dark-color text-left">Upload Photo</label>
            <input class="d-block dark-color" #inputResizeSignature type="file" (change)="selectFile($event.target.files, 'ResizeSignature')" />
            <p class="text-left">Width = 400, Height = 200, DPI = 600</p>
            <div class="text-center pt-10 d-block">
              <a href="#" class="button" (click)="getphoto($event)">GET SIGNATURE</a>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div class="panapplication">
      Pan Application
    </div>
    <div class="grey-box">
      <div class="row">
        <div class="col-3">
          <label><span class="red">*</span>Application No.</label>
          <input type="text"  />
        </div>
        <div class="col-md-1"></div>
        <div class="col-3">
          <label><span class="red">*</span>Upload Scanned application front side</label>
          <input type="file" (change)="applicationUpload($event.target.files, 'frontside')"/>
          <div class="red">Upload JPEG image Only (Size 1000KB) </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-3" >
          <label><span class="red">*</span>Upload Scanned application back side</label>
          <input type="file" (change)="applicationUpload($event.target.files, 'backside')"/>
          <div class="red">Upload JPEG image Only (Size 1000KB)</div>
        </div>
      </div>
    </div>
    <div class="grey-box mt-10">
      <div class="row">
        <div class="col-3">
          <label>identity Proof</label>
          <input type="file" (change)="applicationUpl($event.target.files, 'identityProof')"/>
        </div>
        <div class="col-1"></div>
        <div class="col-3">
          <label>Address Proof</label>
          <input type="file" (change)="applicationUpl($event.target.files, 'addressProof')"/>
        </div>
        <div class="col-2"></div>
        <div class="col-3">
          <label>Other Proof </label>
          <input type="file" (change)="applicationUpl($event.target.files, 'otherProof')"/>
        </div>
      </div>
      <div class="mt-10 text-center">
        <a href="#" class="downlaod" (click)="submitApplication($event)">Download</a>
      </div>
    </div>


  </div>
  <br><br>
  <div class="sectionCard">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
            <div class="sectionHeading">
                <h5>Scanning Specification
                </h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
            <ol>
                <li>PAN Application Form (front Page & Back side) and Supporting Document (POI , POA ,DOB Proof as provided by applicant) to be scanned in 200dpi color - PDFA format (Size: Maximum upto 2MB)
                </li>
                <li>Photo Scanning 300 dpi,Colour,213 X 213 px (Size:less than 30 kb)</li>
                <li>Signature scanning 600 dpi black and white 1023X680 (Size:less than 60 kb)</li>
            </ol>
        </div>
    </div>
</div>
</div>
</section>

        

