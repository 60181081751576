<footer class="footer" id="footer">
    <div class="copyright">
       <div class="container">
          <div class="row">
             <div class="col-12 col-sm-12 col-md-6 col-lg-6  col-lx-6 ">
                <div class="copyright-info"><span>Copyright © 2020 MyEReturn. All Rights Reserved.</span></div>
             </div>
             <div class="col-12 col-sm-12 col-md-6 col-lg-6  col-lx-6">
                <div class="footer-menu">
                  <ul class="list-inline">
                           <li>
                               <a href="#">
                                   <img src="/assets/images/f1.png">
                               </a>
                           </li>
                           <li>
                               <a href="#">
                                   <img src="/assets/images/f2.png">
                               </a>
                           </li>
                           <li>
                               <a href="#">
                                   <img src="/assets/images/f3.png">
                               </a>
                           </li>
                           <li>
                               <a href="#">
                                   <img src="/assets/images/f4.png">
                               </a>
                           </li>
                           <li>
                               <a href="#">
                                   <img src="/assets/images/f5.png">
                               </a>
                           </li>
                       </ul>
                </div> 
             </div>
          </div>
       </div>
      
    </div>
 </footer>
