<div class="app-tab-menu desktopView">
    <div>
        <!---WITHOUT MY PROFILE --->
        <ul *ngIf="!isProfileTab">
            <li class="active" (click)="openTab('Dashboard')" [ngClass]="{ active: sideBarTab == 'Dashboard' }">
                <img src="assets/images/d1.png" /> <span>Dashboard</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTab('Mobile_Recharge')" [ngClass]="{ active: sideBarTab == 'Mobile_Recharge' }">
                <img src="assets/images/d2.png" /> <span>Mobile Recharge</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <!-- <li class="active" (click)="openTab('Wallet_Transfer')" [ngClass]="{ active: sideBarTab == 'Wallet_Transfer' }">
                <img src="assets/images/d5.png" /> <span>Wallet Transfer</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li> -->

            <li class="active" (click)="openTab('DTH_Recharge')" [class.active]="sideBarTab == 'DTH_Recharge'">
                <img src="assets/images/d3.png" /> <span>DTH Recharge</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li (click)="openTab('AEPS')" [class.active]="sideBarTab == 'AEPS'">
                <img src="assets/images/d4.png" /> <span>AEPS</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('Money_Transfer')" [class.active]="sideBarTab == 'Money_Transfer'">
                <img src="assets/images/d5.png" /> <span>Money Transfer</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('BBPS')" [class.active]="sideBarTab == 'BBPS'">
                <img src="assets/images/d6.png" /> <span>BBPS</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('Pan_Card_Services')" [class.active]="sideBarTab == 'Pan_Card_Services'">
                <img src="assets/images/d7.png" /> <span>Pan Card</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <!-- <li (click)="openTab('Insurance')" [class.active]="sideBarTab == 'Insurance'">
                <img src="assets/images/d8.png" /> <span>Insurance <img style="width: 50px;" src="assets/images/Coming-Soon-png.png" /></span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li> -->
            <!-- <li (click)="openTab('Travel_booking')" [class.active]="sideBarTab == 'Travel_booking'">
                <img src="assets/images/d9.png" /> <span>Travel Booking <img style="width: 50px;" src="assets/images/Coming-Soon-png.png" /></span><i class="fa fa-angle-right" aria-hidden="true"></i>
            </li> -->
            <li (click)="openTab('Income_Tax_Service')" [class.active]="sideBarTab == 'Income_Tax_Service'">
                <img src="assets/images/d10.png" /> <span>Income Tax Return <img style="width: 50px;" src="assets/images/Coming-Soon-png.png" /></span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('GST')" [class.active]="sideBarTab == 'GST'">
                <img src="assets/images/d11.png" /> <span>GST <img style="width: 50px;" src="assets/images/Coming-Soon-png.png" /></span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('Other_Services')" [class.active]="sideBarTab == 'Other_Services'">
                <img src="assets/images/d12.png" /> <span>Other Services <img style="width: 50px;" src="assets/images/Coming-Soon-png.png" /></span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('Complaint')" [class.active]="sideBarTab == 'Complaint'">
                <img src="assets/images/d13.png" /> <span>Complaint</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="openTab('Reports')" [class.active]="sideBarTab == 'Reports'">
                <img src="assets/images/d14.png" /> <span>Report</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li (click)="logOut()" [class.active]="sideBarTab == 'Logout'">
                <i class="fa fa-logout text-danger" aria-hidden="true"></i>
                <span><b>Logout</b></span>
            </li>
        </ul>

        <!---MY PROFILE TAB--->
        <ul *ngIf="isProfileTab">
            <li class="active" (click)="openTab('Dashboard')" [ngClass]="{ active: sideBarTab == 'Dashboard' }">
                <img src="assets/images/d1.png" /> <span>Dashboard</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('My_Profile')" [ngClass]="{ active: sideBarTab == 'My_Profile' }">
                <img src="assets/images/pro1.png" /> <span>My Profile</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('Shop_Details')" [ngClass]="{ active: sideBarTab == 'Shop_Details' }">
                <img src="assets/images/pro2.png" /><span>Shop Details </span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('Documents_Details')" [ngClass]="{ active: sideBarTab == 'Documents_Details' }">
                <img src="assets/images/pro3.png" /><span>Documents Details</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('Bank_Details')" [ngClass]="{ active: sideBarTab == 'Bank_Details' }">
                <img src="assets/images/pro4.png" /><span>Bank Details</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('Download')" [ngClass]="{ active: sideBarTab == 'Download' }">
                <img src="assets/images/downloadIc.png" /><span>Download</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>

            <li class="active" (click)="openTabProfile('Change_Password')" [ngClass]="{ active: sideBarTab == 'Change_Password' }">
                <img src="assets/images/pro6.png" /><span>Change Password</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
        </ul>
    </div>
</div>