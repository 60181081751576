import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-service',
  templateUrl: './other-service.component.html',
  styleUrls: ['./other-service.component.scss']
})
export class OtherServiceComponent implements OnInit {
  public sessionUserData:any = {};

  constructor() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"))
   }

  ngOnInit(): void {
  }

}
