import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../../service/data-service.service';
import { Router,ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import html2canvas from 'html2canvas';
import { ExternalAPIService } from '../../../service/external-api.service';
import jsPDF from 'jspdf';
declare var $: any;
@Component({
  selector: 'app-paybill',
  templateUrl: './paybill.component.html',
  styleUrls: ['./paybill.component.scss']
})
export class PaybillComponent implements OnInit {

  public formData:any = {};
  public apiResponse:any;
  public isButtonDisabled:boolean = false;
  public isBillButtonDisabled: boolean = false;
  public sessionUserData:any = {};
  public walletData:any = {};
  public today = new Date();


  public Provider:any;
  public uid:any;
  public operator:any;

  //Plans
  public isLoading:boolean = false;
  public tab:any;

  constructor(
    public dataService:DataServiceService,
    public router:ActivatedRoute,
    public _location:Location,
    public externalAPIService:ExternalAPIService
  ) {
    this.formData.city = "";
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    this.walletData = JSON.parse(sessionStorage.getItem("walletData"));
    this.router.queryParams.subscribe(params => {
      if (params.Provider) {
        this.Provider = params.Provider;
      }
      if (params.uid) {
        this.uid = params.uid;
      }
      if (params.operator) {
        this.operator = params.operator;
      }
    })
   }

  ngOnInit(): void { }

  back(){
    this._location.back();
  }


  payBillNow(){
     if(this.walletData.MainWallet > this.formData.amount ){
       this.isButtonDisabled = true;
       this.formData.providerId =  this.uid;
       // save the initial reacharge data
       this.saveRechargeData("initial", (clientid) => {
         // referesh the pay2all token
          this.externalAPIService.refreshPay2allToken(() => {
            // recharge
            this.externalAPIService.paymentRecharge(this.formData).subscribe(response => {
            this.apiResponse = response
            this.isButtonDisabled = false;
      
            this.formData.reports_id =  this.apiResponse.report_id;
            
              this.formData.Client_Id = clientid;
            if(this.apiResponse['status'] == 0){
              this.formData.orderid = this.apiResponse["orderid"];
              this.formData.report_id = this.apiResponse["report_id"];
              this.formData.utr = this.apiResponse["utr"];
              this.formData.status_id = 1;
              this.saveRechargeData("update", null);
              $('#billDetail').modal('hide');
              $('#billReceipt').modal('show');
            }
            if(this.apiResponse['status'] == 2){
              this.formData.status_id = 2;
              this.saveRechargeData("update", null);
              this.dataService.appConstant.errorToast("Oops...Bill payment failed..Contact to distributor");
            }
            if(this.apiResponse['status'] == 3){
              this.formData.status_id = 3;
              this.saveRechargeData("update", null);
            }
          },err=>{
            this.dataService.appConstant.errorToast("Server error....Please try again");
          })
          });
       });
    }else{
      alert("You have insufficient balance in MAIN WALLET, Please add money in to wallet MAIN WALLET.")
    }
  }



  /***
   * SAVE RECHARGE REPONSE
   */

  saveRechargeData(type, afterInitialRecharge){
    sessionStorage.setItem('setheadType', null);
    if (type === "initial") {
      this.formData.RegistrationId = this.sessionUserData.RegistrationId;
      this.formData.CustomerMobile = this.formData.mobile;
      this.formData.OperatorCode = this.formData.providerId;
      this.formData.OperatorName = this.Provider;
      this.formData.Date = new Date();
      this.formData.RechargeAmount = this.formData.amount;
      this.formData.CommissionAmt = 0.0;
      this.formData.BillAmount = this.formData.amount;
      this.formData.CloseBalance = 0.0;
      this.formData.OpenBalance = null;
      this.formData.PaymentType ='Main Wallet';
      this.formData.TransactionId = this.formData.reports_id;
      this.formData.RechargeType = "BBPS";
      this.formData.OrderType = "Bill Payment";
      this.formData.paydate = new Date();
       this.dataService.post(this.formData, this.dataService.appConstant.APIS['INITIATE_RECHARGE_TRANSACTION']).subscribe(response => {
         let clientid = response["ClientId"];
         if (afterInitialRecharge) {
           afterInitialRecharge(clientid);
         }
        })
      } else {
      this.dataService.post(this.formData, this.dataService.appConstant.APIS['SAVE_RECHARGE_DETAIL']).subscribe(response => console.log(response));
    }
  }


 

  fetchBill(){
      this.isButtonDisabled = true;
      this.formData.providerId =  this.uid;
      this.formData.number = this.formData.mobile;
      this.formData.provider_id = this.formData.providerId;
      this.formData.api_id = '27';
      if(this.formData.BU && this.formData.CID){
        this.formData.optional1 = this.formData.BU;
        this.formData.optional2 = this.formData.CID;
      } else if (this.formData.CID ) {
        this.formData.optional1 = this.formData.CID;
      }
      if (this.formData.CustomerNo) {
        this.formData.optional1 = this.formData.CustomerNo;
      }
      if (this.formData.ConsumerNo) {
        this.formData.optional1 = this.formData.ConsumerNo;
      }
      if (this.formData.BP) {
        this.formData.optional1 = this.formData.BP;
      }
      if (this.formData.CA) {
        this.formData.optional1 = this.formData.CA;
      }
      if (this.formData.city) {
        this.formData.optional1 = this.formData.city;
        this.formData.optional2 = this.formData.ServiceNo;
        if (this.formData.city == 'Agra') {
          this.formData.provider_id = '165';
        } else if (this.formData.city == 'Ahmedabad') {
          this.formData.provider_id = '163';
        } else if (this.formData.city == 'Surat') {
          this.formData.provider_id = '164';
        } else if (this.formData.city == 'Bhiwandi') {
          this.formData.provider_id = '47';
        }
      }
      this.isButtonDisabled = true;
      this.externalAPIService.refreshPay2allToken(() => {
          this.externalAPIService.fetchBill(this.formData).subscribe(response => {
          this.apiResponse = response
          if(this.apiResponse.status == 0){
          this.isButtonDisabled = false;
          this.formData.amount = this.apiResponse.amount
          this.formData.name = this.apiResponse.name
          this.formData.duedate = this.apiResponse.duedate
          this.formData.reference_id = this.apiResponse.reference_id
          $('#billDetail').modal('show');
          }else{
                  this.isButtonDisabled = false;
              alert(this.apiResponse.message)
          }
        },err=>{
          this.isButtonDisabled = false;
          this.dataService.appConstant.errorToast("Server error....Please try again");
        })
      });
  }

  downloadBill() {
    this.isBillButtonDisabled = true;
    var container: any = document.getElementById("bill-image-section");
    html2canvas(container).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "bill.png";
      link.href = canvas.toDataURL("image/png");
      link.target = '_blank';
      link.click();
    });
    this.isBillButtonDisabled = false;
  }
  
  downloadBillAsPDF() {
    this.isBillButtonDisabled = true;
    var container: any = document.getElementById("bill-image-section");
    html2canvas(container).then(function (canvas) {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      let imageData = canvas.toDataURL("image/png");
      let doc = new jsPDF();
      doc.addImage(imageData, 'PNG', 1, 1, fileWidth, fileHeight);
      doc.save('bill.pdf');
    });
    this.isBillButtonDisabled = false;
  }

  downloadReceipt() {
    var container: any = document.getElementById("bill-receipt-image");
    html2canvas(container).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "receipt.png";
      link.href = canvas.toDataURL("image/png");
      link.target = '_blank';
      link.click();
    });
  }
  
  downloadReceiptAsPDF() {
    var container: any = document.getElementById("bill-receipt-image");
    html2canvas(container).then(function (canvas) {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      let imageData = canvas.toDataURL("image/png");
      let doc = new jsPDF();
      doc.addImage(imageData, 'PNG', 1, 1, fileWidth, fileHeight);
      doc.save('receipt.pdf');
    });
  }
  closeModal(name) {
    $("#" + name).modal('hide');
  }

}
