<section>
  <div class="container sectionContainer">
    <!-----Bread-cum---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-10  col-lx-10 ">
        <div class="breadCum">
          <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
          <span routerLink="/Dashboard">Dashboard / </span>
          <span class="activeBreadCum">Payment History </span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2 ">
        <button routerLink="/Dashboard" class="btn btn-warning submitBtn">
          <span class="button__text">Back</span>
        </button>
      </div>
    </div>

    <!-----Section Heading---->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
        <div class="sectionHeading">
          <h5>Payment History</h5>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 ">
        <span class="grayClr font14">Show</span> <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option [value]="reportList?.length">{{reportList?.length}}</option>
        </select>
        <span class="grayClr font14">entity</span>
      </div>
    </div> -->


    <!---TABLE--->
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
        <div id="reportTable" class="tableMv">
          <table id="htmlData">
            <thead>
              <tr>
                <th class="brdWhiteR brdTLR">Date and Time</th>
                <th class="brdWhiteR">Reference No</th>
                <th class="brdWhiteR">Payment Mode</th>
                <!--<th class="brdWhiteR">Amount</th>-->
                <th class="brdWhiteR">Payment Type</th>
                <th class="brdWhiteR">Amount Added</th>
                <!--<th class="brdWhiteR">Remarks</th>-->
                <th class="brdWhiteR">Status</th>
                <th class="brdTRR">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bodyTr" *ngFor="let item of reportList  | filter:reportFilter.searchKey  | paginate: { id: 'reportFilterList', itemsPerPage: reportFilter.numPerPage, 
                  currentPage: reportFilter.pageNum ,totalItems:reportList.length};let i=index;" id="eachRecord">
                <td class="brdGrayR">{{item.PaidDate | date:'dd/MM/yyyy'}} {{item.PaidDate | date:'hh:mm'}} </td>
                <td class="brdGrayR">{{item.ReferenceNo}}</td>
                <td class="brdGrayR">{{item.PaymentMode}}</td>
                <!--<td class="brdGrayR">₹ {{item.PaidAmount}}</td>-->
                <td class="brdGrayR">
                  <b class="mainThemeBgClr" *ngIf="item.PaymentType == 'main' "> {{item.PaymentType}}</b>
                  <b class="secondaryThemeClr" *ngIf="item.PaymentType == 'trade' "> {{item.PaymentType}}</b>
                  <b *ngIf="item.PaymentType != 'trade' && item.PaymentType != 'main' && item.PaymentType  ">
                    {{item.PaymentType}}</b>
                </td>
                <td class="brdGrayR">₹ {{item.PaidAmount}}</td>
                <!--<td class="brdGrayR">{{item.Remarks}}</td>-->
                <td class="brdGrayR">
                  <span class="tableStatus Success" *ngIf="item.PaymentStatus == 'Approve' ">
                    {{item.PaymentStatus}}</span>
                  <span class="tableStatus Fail" *ngIf="item.PaymentStatus == 'Rejected' ">
                    {{item.PaymentStatus}}</span>
                  <span class="tableStatus grayGb"
                    *ngIf="item.PaymentStatus != 'Rejected' &&  item.PaymentStatus != 'Approve' && item.PaymentStatus">
                    {{item.PaymentStatus}}</span>
                </td>
                <td>
                  <button type="button" *ngIf="!item.Complaint || !item.Complaint.ComplaintId" class="btn btn-warning ComplaintBTN" (click)="openComplaintForm(item)">
                    Submit Complaint </button>
                    <div *ngIf="item.Complaint && item.Complaint.ComplaintId">
                      <b class="greenCLR">Submitted</b>
                     <div class="pendingComplaintSts" >
                       Admin Status : {{item.Complaint.Status}}
                     </div>
                    </div>
                </td>
              </tr>
              <tr class="bodyTr" *ngIf="reportList?.length  <= 0 && !isLoading">
                <td colspan="50" class="noTableRecord">No record found.......
                </td>
              </tr>
              <tr class="bodyTr" *ngIf="isLoading">
                <td colspan="50" class="noTableRecord">
                  <div class="loader"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <!---FOOTER--->
    <br>
    <div class="row cp tableFooterRow" *ngIf="reportList?.length > 0">
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3">
        <div class="font14"><span class="grayClr">Showing</span> <b> {{(reportFilter.pageNum - 1) + 1}} </b> <span
            class="grayClr"> to </span> <b> {{ reportFilter.numPerPage}} </b> <span class="grayClr"> of </span> <b>
            {{reportList?.length}} </b> <span class="grayClr"> entries</span></div>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-lx-2"></div>
      <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportsExcel()">
        <div><i class="icofont-download"></i> Excel</div>
      </div>
      <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportPDF()">
        <div><i class="icofont-download"></i> PDF</div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-lx-5 tCenter font14">
        <pagination-controls id="reportFilterList" (pageChange)="reportFilter.pageNum = $event"></pagination-controls>
      </div>
    </div>
  </div>
</section>











<!-- Modal -->
<div id="ComplaintForm" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        Submitting a complaint
      </div>
      <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submitCompltain(appForm)">
        <div class="modal-body modelFrom">

          <div class="row modalFixedInfo">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
              <div>Complaint For</div>
            </div>
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
              <b>:</b>
            </div>
            <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
              <b>Add money in wallet</b>
            </div>


            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
              <div>Transaction Id</div>
            </div>
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
              <b>:</b>
            </div>
            <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
              <b>{{formData.ReferenceNo}}</b>
            </div>


            
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
              <div>Transaction Date</div>
            </div>
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
              <b>:</b>
            </div>
            <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
              <b>{{formData.PaidDate | date:'dd/MM/yyyy'}} {{formData.PaidDate | date:'hh:mm'}}</b>
            </div>


          </div>
          <br>
          <div class="row">
            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
              <label>Subject <span class="astric">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Subject" [(ngModel)]="formData.Subject"
                name="Subject" #Subject="ngModel" required>
              <span class="text-danger" *ngIf="(Subject.touched || appForm.submitted) && Subject.errors?.required">
                Subject is required
              </span>
            </div>

            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
              <label>Message <span class="astric">*</span></label>
              <textarea type="text" class="form-control" placeholder="Enter Message" [(ngModel)]="formData.Message"
                name="Message" #Message="ngModel" required> </textarea>
              <span class="text-danger" *ngIf="(Message.touched || appForm.submitted) && Message.errors?.required">
                Message is required
              </span>
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <div class="buttonGroup">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">Cancel</button>&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabledPop"
              [ngClass]="{'button--loading':isButtonDisabledPop}">
              <span class="button__text">SUBMIT</span>
            </button> 
          </div>
        </div>
      </form>
    </div>
  </div>
</div>