<section>
    <div class="container sectionContainer">
      <!-----Bread-cum---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-10  col-lx-10 ">
          <div class="breadCum">
            <span routerLink="/Dashboard"><i class="icofont-home"></i> Home / </span>
            <span routerLink="/Dashboard">Dashboard / </span>
            <span class="activeBreadCum">Bank Account Transfer </span>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-lx-2 ">
          <button routerLink="/Dashboard" class="btn btn-warning submitBtn">
            <span class="button__text">Back</span>
          </button>
        </div>
      </div>
  
      <!-----Section Heading---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12 ">
          <div class="sectionHeading">
            <h5>Bank Account Transfer</h5>
          </div>
        </div>
      </div>
      <!-----Section Contain---->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">

  
        <div *ngIf="isShowFilter">
          <form  #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit()">
          <div class="row">
            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3 ">
              <label>Start Date </label>
              <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.startDate"
                name="startDate" #startDate="ngModel" required>
              <span class="text-danger" *ngIf="(startDate.touched || appForm.submitted) && startDate.errors?.required">
                Start date is required
              </span>
            </div>
            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
              <label>End Date </label>
              <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.endDate"
                name="endDate" #endDate="ngModel" required>
              <span class="text-danger" *ngIf="(endDate.touched || appForm.submitted) && endDate.errors?.required">
                End date is required
              </span>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-2 col-lx-2">
                <div class="flexAlignCenter">
                  <button type="submit" class="btn btn-warning submitBtn tableFilterBtn"
                  [disabled]="isButtonDisabled || !appForm.form.valid " [ngClass]="{'button--loading':isButtonDisabled}">
                  <span class="button__text">SUBMIT</span>
                </button> &nbsp;&nbsp;
                <button type="reset" class="btn btn-secondary tableFilterBtn">Reset</button>
                </div>
            </div>
  
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-4 col-lx-4">
              <input type="text" class="form-control reportSearchFilter" placeholder="Search record..." [(ngModel)]="reportFilter.searchKey"
              name="searchKey" #searchKey="ngModel" >
            </div>
  
          </div>
          </form>
        </div>  
        </div>
      </div>
  
  
      <div class="row" >
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 ">
           <span class="grayClr font14">Show</span> <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
               </select>
            <span class="grayClr font14">entity</span>   
        </div>
      </div>
  
  
       <!---TABLE--->
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12" >
          <div id="reportTable" class="tableMv">
            <table id="htmlData">
              <thead>
                  <tr>
                      <th class="brdWhiteR brdTLR">Date</th>
                      <th class="brdWhiteR">Amount</th>
                      <th class="brdWhiteR">Customer Mobile</th>
                      <th class="brdWhiteR">Recharge Type</th>
                      <th class="brdWhiteR">Transaction ID</th>
                      <th class="brdWhiteR">Status</th>
                      <th class="brdTRR">Action</th>
                  </tr>
              </thead>  
              <tbody>
                  <tr class="bodyTr" *ngFor="let item of reportList  | filter:reportFilter.searchKey  | paginate: { id: 'reportFilterList', itemsPerPage: reportFilter.numPerPage, 
                  currentPage: reportFilter.pageNum ,totalItems:reportList.length};let i=index;" id="eachRecord">
                      <td class="brdGrayR">Date</td>
                      <td class="brdGrayR">Date</td>
                      <td class="brdGrayR">Date</td>
                      <td class="brdGrayR">Date</td>
                      <td class="brdGrayR">Date</td>
                      <td class="brdGrayR">Date</td>
                      <td (click)="printRow()"><i class="icofont-print"></i></td>
                  </tr>
                  <tr class="bodyTr" *ngIf="reportList?.length  <= 0 && !isLoading">
                    <td colspan="50" class="noTableRecord">No record found.......
                    </td>
                  </tr>
                  <tr class="bodyTr" *ngIf="isLoading">
                    <td colspan="50" class="noTableRecord">
                      <div class="loader"></div>
                    </td>
                  </tr>
              </tbody>
          </table>
          </div>
        </div>
      </div>
  
        <!---FOOTER--->
        <br>
      <div class="row cp tableFooterRow" *ngIf="reportList?.length > 0">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3">
          <div class="font14"><span class="grayClr">Showing</span> <b> {{(reportFilter.pageNum - 1) + 1}} </b> <span  class="grayClr"> to </span> <b> {{ reportFilter.numPerPage}} </b> <span  class="grayClr"> of </span> <b> {{reportList?.length}} </b> <span  class="grayClr"> entries</span></div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-lx-4"></div>
        <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportsExcel()">
          <div><i class="icofont-download"></i> Excel</div>
        </div>
        <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportPDF()">
          <div><i class="icofont-download"></i> PDF</div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3 tCenter font14">
          <pagination-controls id="reportFilterList" (pageChange)="reportFilter.pageNum = $event"></pagination-controls>
        </div>
      </div>   
    </div>
  </section>