<section>
    <div class="container sectionContainer">
        <!-----Bread-cum---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-lx-11">
                <div class="breadCum">
                    <span routerLink="/Dashboard"><i class="icofont-home"></i> Home /
          </span>
                    <span class="activeBreadCum">Reports / </span>
                    <span class="activeBreadCum">Transaction Report </span>
                </div>
            </div>
        </div>

        <!-----Section Heading---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div class="sectionHeading">
                    <h5>Transaction Report</h5>
                </div>
            </div>
        </div>
        <!-----Section Contain---->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <!---FILTER-->
                <div class="row">
                    <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                        <label>Report Type <span class="astric">*</span></label>
                        <select class="form-control" [(ngModel)]="reportFilter.reportType" name="ReportType" #ReportType="ngModel" required>
              <option value="">Select Report Type</option>
              <option value="Transction Report">Transaction Report</option>
              <option value="Your Earning">Your Earning</option>
            </select>
                    </div>
                    <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3" *ngIf="reportFilter.reportType == 'Transction Report'">
                        <label>Services<span class="astric">*</span></label>
                        <select class="form-control" [(ngModel)]="reportFilter.service" name="service" #service="ngModel" required (ngModelChange)="getRport()">
              <option value="All">All</option>
              <option value="Mobile Recharge">Mobile Recharge</option>
              <option value="DTH Recharge">DTH Recharge</option>
              <option value="Bill Payment">Bill Payment</option>
              <option value="Travel Booking">Travel Booking</option>
              <!-- <option value="ITR"> ITR </option>
              <option value="GST"> GST </option>
              <option value="Other Service"> Other Service </option> -->
            </select>
                    </div>
                </div>

                <div *ngIf="isShowFilter && reportFilter.reportType != 'Your Earning'">
                    <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submit()">
                        <div class="row">
                            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                                <label>Start Date </label>
                                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.fromDate" name="startDate" #startDate="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (startDate.touched || appForm.submitted) &&
                    startDate.errors?.required
                  ">
                  Start date is required
                </span>
                            </div>
                            <div class="form-group col-6 col-sm-6 col-md-6 col-lg-3 col-lx-3">
                                <label>End Date </label>
                                <input type="date" class="form-control" placeholder="Select Date" [(ngModel)]="reportFilter.endDate" name="endDate" #endDate="ngModel" required />
                                <span class="text-danger" *ngIf="
                    (endDate.touched || appForm.submitted) &&
                    endDate.errors?.required
                  ">
                  End date is required
                </span>
                            </div>
                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-2 col-lx-2">
                                <div class="flexAlignCenter">
                                    <button type="submit" class="btn btn-warning submitBtn tableFilterBtn" [disabled]="isButtonDisabled || !appForm.form.valid" [ngClass]="{ 'button--loading': isButtonDisabled }">
                    <span class="button__text">SUBMIT</span>
                  </button> &nbsp;&nbsp;
                                    <button type="reset" (click)="clear()" class="btn btn-secondary tableFilterBtn">
                    Reset
                  </button>
                                </div>
                            </div>

                            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-4 col-lx-4" *ngIf="reportFilter.reportType == 'Transction Report'">
                                <input type="text" class="form-control reportSearchFilter" placeholder="Search record..." [(ngModel)]="reportFilter.searchKey" name="searchKey" #searchKey="ngModel" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- <div class="row" >
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4 ">
         <span class="grayClr font14">Show</span> <select class="showEntity" [(ngModel)]="reportFilter.numPerPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
             </select>
          <span class="grayClr font14">entity</span>   
      </div>
    </div> -->

        <!---TABLE--->
        <div class="row" *ngIf="reportFilter.reportType == 'Transction Report'">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <div id="reportTable" class="tableMv">
                    <table id="htmlData">
                        <thead>
                            <tr>
                                <th class="brdWhiteR brdTLR">Date</th>
                                <th class="brdWhiteR">Transaction ID</th>
                                <th class="brdWhiteR">Service Type</th>
                                <th class="brdWhiteR">Service ID</th>
                                <th class="brdWhiteR">Operator</th>
                                <th class="brdWhiteR">Opening Balance</th>
                                <th class="brdWhiteR">Amount</th>
                                <th class="brdWhiteR">Commission</th>
                                <th class="brdWhiteR">Closing Balance</th>
                                <th class="brdWhiteR">Status</th>
                                <th class="brdTRR">Complaint Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bodyTr" *ngFor="
                  let item of reportList
                    | filter: reportFilter.searchKey
                    | paginate
                      : {
                          id: 'reportFilterList',
                          itemsPerPage: reportFilter.numPerPage,
                          currentPage: reportFilter.pageNum,
                          totalItems: reportList.length
                        };
                  let i = index
                " id="eachRecord">
                                <td class="brdGrayR">
                                    {{ item.Date | date: "dd/MM/yyyy" }} {{ item.Date | date: "hh:mm" }}
                                </td>
                                <td class="brdGrayR">{{ item.TransactionId }}</td>
                                <td class="brdGrayR">{{ item.RechargeType }}</td>
                                <td class="brdGrayR">{{ item.CustomerMobile }}</td>
                                <td class="brdGrayR">{{ item.OperatorName }}</td>
                                <td class="brdGrayR">₹{{ item.OpenBalance ? item.OpenBalance : "0" }}</td>
                                <td class="brdGrayR">₹{{ item.RechargeAmount }}</td>
                                <td class="brdGrayR">₹{{ item.CommissionAmt }}</td>
                                <td class="brdGrayR">₹{{ item.CloseBalance ? item.CloseBalance : "0" }}</td>
                                <td class="brdGrayR">{{ item.RechargeStatus }}</td>
                                <td>
                                    <button type="button" *ngIf="!item.Complaint || !item.Complaint.ComplaintId" class="btn btn-warning ComplaintBTN" (click)="openComplaintForm(item)">
                    Submit Complaint
                  </button>
                                    <div *ngIf="item.Complaint && item.Complaint.ComplaintId">
                                        <b class="greenCLR">Submitted</b>
                                        <div class="pendingComplaintSts">
                                            Admin Status : {{ item.Complaint.Status }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="bodyTr" *ngIf="reportList?.length <= 0 && !isLoading">
                                <td colspan="50" class="noTableRecord">
                                    No record found.......
                                </td>
                            </tr>
                            <tr class="bodyTr" *ngIf="isLoading">
                                <td colspan="50" class="noTableRecord">
                                    <div class="loader"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="reportFilter.reportType == 'Your Earning'">
            <table class="earningReport" style="
          border-radius: 10px;
          height: 100%;
          width: 100%;
          border: solid 1px #bfbfbf;
          margin-top: 20px;
        ">
                <thead class="border:0">
                    <tr style="background-color: #2154a4; color: #fff">
                        <th style="background-color: #f4a460; color: #000000" colspan="0">
                            Your Earn
                        </th>
                        <th style="background-color: #afeeee; color: #000000" colspan="3">
                            Your Commsion
                        </th>
                        <th style="background-color: #faebd7; color: #000000" colspan="3">
                            Commsion Detail
                        </th>
                    </tr>

                    <tr style="background-color: #2154a4; color: #fff">
                        <th style="background-color: #f4a460; color: #f1fcfc"></th>
                        <th style="background-color: #9acd32; color: #000000" class="alignCenter">
                            Recharge
                        </th>
                        <th style="background-color: #9acd32; color: #000000" class="alignCenter">
                            AEPS
                        </th>

                        <th style="background-color: #9acd32; color: #000000" class="alignCenter">
                            Travel
                        </th>

                        <th style="background-color: #ffff00; color: #000000" class="alignCenter">
                            Total Earn
                        </th>
                        <th style="background-color: #ffff00; color: #000000" class="alignCenter">
                            TDS
                        </th>
                        <th style="background-color: #ffff00; color: #000000" class="alignCenter">
                            Net Commission
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="earningTr">
                        <td>₹{{earningReport.TotalEarning}}</td>
                        <td>₹{{earningReport.Recharges}}</td>
                        <td>₹{{earningReport.AEPS}}</td>
                        <td>₹{{earningReport.Travels}}</td>
                        <td>₹{{earningReport.TotalEarning}}</td>
                        <td>₹{{earningReport.TDS}}</td>
                        <td>₹{{earningReport.NetCommsions}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!---FOOTER--->
        <br />
        <div class="row cp tableFooterRow" *ngIf="
        reportList?.length > 0 && reportFilter.reportType == 'Transction Report'
      ">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-lx-3">
                <div class="font14">
                    <span class="grayClr">Showing</span>
                    <b> {{ reportFilter.pageNum - 1 + 1 }} </b>
                    <span class="grayClr"> to </span>
                    <b> {{ reportFilter.numPerPage }} </b>
                    <span class="grayClr"> of </span> <b> {{ reportList?.length }} </b>
                    <span class="grayClr"> entries</span>
                </div>
            </div>
            <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportsExcel()">
                <div><i class="icofont-download"></i> Excel</div>
            </div>
            <div class="col-4 col-sm-4 col-md-1 col-lg-1 col-lx-1 font14 themeClrBld" (click)="exportPDF()">
                <div><i class="icofont-download"></i> PDF</div>
            </div>
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-lx-7 tCenter font14">
                <pagination-controls id="reportFilterList" (pageChange)="reportFilter.pageNum = $event"></pagination-controls>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div id="ComplaintForm" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header modalHeader">Submitting a complaint</div>
            <form #appForm="ngForm" (ngSubmit)="appForm.form.valid && submitCompltain(appForm)">
                <div class="modal-body modelFrom">
                    <div class="row modalFixedInfo">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Complaint For</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.ComplaintRegisterProduct }}</b>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Transaction Id</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.TransactionId }}</b>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-lx-6">
                            <div>Transaction Date</div>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-lx-1 tCenter">
                            <b>:</b>
                        </div>
                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-lx-5 tRight">
                            <b>{{ formData.Date | date: "dd/MM/yyyy" }}
                {{ formData.Date | date: "hh:mm" }}</b>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                            <label>Subject <span class="astric">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Subject" [(ngModel)]="formData.Subject" name="Subject" #Subject="ngModel" required />
                            <span class="text-danger" *ngIf="
                  (Subject.touched || appForm.submitted) &&
                  Subject.errors?.required
                ">
                Subject is required
              </span>
                        </div>

                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-lx-12">
                            <label>Message <span class="astric">*</span></label>
                            <textarea type="text" class="form-control" placeholder="Enter Message" [(ngModel)]="formData.Message" name="Message" #Message="ngModel" required>
              </textarea>
                            <span class="text-danger" *ngIf="
                  (Message.touched || appForm.submitted) &&
                  Message.errors?.required
                ">
                Message is required
              </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="buttonGroup">
                        <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              Cancel</button>&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary submitBtn" [disabled]="isButtonDisabledPop" [ngClass]="{ 'button--loading': isButtonDisabledPop }">
              <span class="button__text">SUBMIT</span>
            </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>