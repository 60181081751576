import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  fileName = 'FinancialReport.xlsx';
  @ViewChild('htmlData') htmlData: ElementRef;

  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public sessionUserData: any = {};
  public myProfileData: any = {};
  public shopData: any = {};
  public documentData: any = {};
  public bankData: any = {};
  public changePasswordData: any = {};
  public tab: any;
  public documentTab: any;

  public reportFilter: any = { numPerPage: 10, pageNum: 1 };
  public reportList: any = [{}, {}, {}];
  public isLoading: boolean = false;
  public isShowFilter: boolean = true;
  public stateList: any = [];
  public cityList: any = [];
  public tahsilList: any = [];

  public shopCityList: any = [];
  public shopTahsilList: any = [];
  

  //PROFILE VALIDATION
  public isProfileCompleted: boolean = true;
  public base64Image:any; 
  public base64ImageName:any; 

  constructor(
    public dataService: DataServiceService,
    public router: Router,
    public route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
  

    this.documentTab = "Aadhaar";
    this.getSessionData();
    this.route.params.subscribe(params => {
      this.tab = params.tab
      if (!this.tab) {
        this.tab = 'My_Profile';
      }
    });
  }

  ngOnInit(): void {
    this.dataService.sendMessage("pageChanged");
    this.dataService.sendMessage(this.tab);
    this.getState();
  }



  getSessionData() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
    if (this.sessionUserData && this.sessionUserData.Data) {
      this.myProfileData = this.sessionUserData.Data;
      this.myProfileData.DOB = this.datePipe.transform(this.myProfileData.DOB, 'yyyy-MM-dd'),

      this.shopData = this.sessionUserData.Data;
      this.documentData = this.sessionUserData.Data;
      this.bankData = this.sessionUserData.Data;
      this.changePasswordData = this.sessionUserData.Data;
      if(this.myProfileData && this.myProfileData.ProfilePhoto){
        this.myProfileData.ProfilePhotoImage = this.myProfileData.ProfilePhoto;
      }
      if(this.documentData && this.documentData.Adhar_Back_Image){
        this.documentData.Adhar_Back_Image_Image = this.documentData.Adhar_Back_Image;
      }
      if(this.documentData && this.documentData.Adhar_Front_Image){
        this.documentData.Adhar_Front_Image_Image = this.documentData.Adhar_Front_Image;
      }
      if(this.documentData && this.documentData.Pan_Card_Image){
        this.documentData.Pan_Card_Image_Image = this.documentData.Pan_Card_Image;
      }

      if(this.shopData && this.shopData.ShopPhoto){
        this.shopData.ShopPhotoImage = this.shopData.ShopPhoto;
      }


      if(this.bankData && this.bankData.CancelledChequePhoto){
        this.bankData.CancelledchequephotoImage = this.bankData.CancelledChequePhoto ;
      }


      if(!this.myProfileData.StateId){
        this.myProfileData.StateId = "";
      }
      
      if(!this.myProfileData.CityId){
        this.myProfileData.CityId = "";
      }
      if(!this.myProfileData.TahsilsId){
        this.myProfileData.TahsilsId = "";
      }

      
      if(!this.shopData.ShopState){
        this.shopData.ShopState = "";
      }
      
      if(!this.shopData.ShopCity){
        this.shopData.ShopCity = "";
      }
      if(!this.shopData.ShopPostOffice){
        this.shopData.ShopPostOffice = "";
      }

    }


      
    this.myProfileData.Profileimagename    = null;
    this.myProfileData.Profilephoto   = null;
    this.shopData.Shopimagename =  null;
    this.shopData.ShopPhoto = null;
    this.documentData.Adhar_Front_Image_Name  = null;
    this.documentData.Adhar_Front_Image = null;
    this.documentData.Adhar_Back_Image =null;
    this.documentData.Adhar_Back_Image_Name  = null;
    this.documentData.Pan_Card_Image = null;
    this.documentData.Pan_Card_Image_Name  = null;
    this.bankData.CancelledChequePhoto  = null;
    this.bankData.CancelledChequeName   = null;
  }



  getState() {
  
    //   this.apiResponse = response
    //   if (this.apiResponse.status) {
    //     this.stateList = this.apiResponse.stateData;

    this.stateList =   JSON.parse(localStorage.getItem('mEState'))


    this.stateList.forEach(element => {
      if (element.id == this.myProfileData.StateId) {
        this.cityList = element.Citys
        this.cityList.forEach(city => {
          if (city.id == this.myProfileData.CityId) {
            this.tahsilList = city.tahsil
          }
        });
      }
      if (element.id == this.shopData.ShopState) {
        this.shopCityList = element.Citys

        this.shopCityList.forEach(city => {
          if (city.id == this.shopData.ShopCity) {
            this.shopTahsilList = city.tahsil
          }
        });

      }
    });

//   } else {
//     this.dataService.appConstant.errorToast(this.apiResponse.message);
//   }
// }, err => {
//   this.dataService.appConstant.errorToast("Server error....Please try again");
// })
  }


 
  selectProfileState() {
    this.myProfileData.CityId = "";
    this.myProfileData.TahsilsId = "";
    this.cityList = [];
    this.tahsilList = [];
    this.stateList.forEach(element => {
      if (element.id == this.myProfileData.StateId) {
        this.cityList = element.Citys
      }
    });
  }

  selectProfileCity(){
    this.myProfileData.TahsilsId = "";
    this.tahsilList = [];
    this.cityList.forEach(city => {
      if (city.id == this.myProfileData.CityId) {
        this.tahsilList = city.tahsil
      }
    });
  }

  selectShopState(){
    this.shopData.ShopCity = "";
    this.shopData.ShopPostOffice = "";
    this.shopTahsilList = [];
    this.shopCityList = [];
    this.stateList.forEach(element => {
      if (element.id == this.shopData.ShopState) {
        this.shopCityList = element.Citys
      }
    });
  }

  selectShopCity(){
    this.shopData.ShopPostOffice = "";;
    this.shopCityList.forEach(city => {
      if (city.id == this.shopData.ShopCity) {
        this.shopTahsilList = city.tahsil
      }
    });
  }

  changeTab(tab) {
    this.documentTab = tab;
  }


  submitProfileDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.myProfileData, this.dataService.appConstant.APIS['UPDATE_PROFILE']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.status) {
        this.dataService.appConstant.successToast("Profile updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast("Profile updated failed");
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }




  submitShopDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.shopData, this.dataService.appConstant.APIS['UPDATE_SHOP_DETAIL']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.Status) {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
        this.sessionUserData.Data = this.apiResponse.Data;
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.getSessionData();
        this.dataService.appConstant.successToast("Shop detail updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }




  submitDocumentDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.documentData, this.dataService.appConstant.APIS['ADD_PROFILE_DOCUMENT']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.Status) {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
        this.sessionUserData.Data =  this.apiResponse.Data
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.getSessionData();
        this.dataService.appConstant.successToast("Document detail updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }




  submitBankDetail(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.bankData, this.dataService.appConstant.APIS['UPDATE_BANK_DETAIL']).subscribe(response => {
      this.apiResponse = response
      if (this.apiResponse.Status) {
        this.sessionUserData = JSON.parse(sessionStorage.getItem("myEReturn_User"));
        this.sessionUserData.Data =  this.apiResponse.Data
        sessionStorage.setItem('myEReturn_User', JSON.stringify(this.sessionUserData));
        this.getSessionData();
        this.dataService.appConstant.successToast("Bank detail updated successfully");
        this.isButtonDisabled = false;
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
        this.isButtonDisabled = false;
      }
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
      this.isButtonDisabled = false;
    })
  }



  submitChangePasswordData(appForm: NgForm) {
    if (this.changePasswordData.oldPassword == this.changePasswordData.LoginPassword) {
      this.isButtonDisabled = true;
      this.dataService.post(this.formData, this.dataService.appConstant.APIS['CHANGE_PASSWORD']+this.changePasswordData.MobileNo+"&Password="+this.changePasswordData.newPassword).subscribe(response => {
        this.apiResponse = response
        if (this.apiResponse.Status) {
          appForm.onReset();
          this.dataService.appConstant.successToast("Password changed successfully");
          this.isButtonDisabled = false;
        } else {
          this.dataService.appConstant.errorToast(this.apiResponse.message);
          this.isButtonDisabled = false;
        }
      }, err => {
        this.dataService.appConstant.errorToast("Server error....Please try again");
        this.isButtonDisabled = false;
      })
    } else {
      this.dataService.appConstant.errorToast("Old password is wrong");
      this.isButtonDisabled = false;
    }

  }



  selectFile(files: FileList, type) {
    const reader = new FileReader();
     reader.readAsDataURL(files[0]);
     reader.onload = () => {
         if(reader.result){
            this.base64Image = reader.result;
             this.base64ImageName = files[0].name;
 
           if(type == 'Profile'){
             this.myProfileData.ProfilePhotoImage = this.base64Image;
             let base64 = this.base64Image.split(",")
             this.myProfileData.Profileimagename    = this.base64ImageName;
             this.myProfileData.Profilephoto   = base64[1];
           }
           this.myProfileData.Profileimagename    = null;
           this.myProfileData.Profilephoto   = null;
 
           if(type == 'ShopPhoto'){
            this.shopData.ShopPhotoImage = this.base64Image;
             let base64 = this.base64Image.split(",")
             this.shopData.Shopimagename = this.base64ImageName;
             this.shopData.ShopPhoto = base64[1];

           
           }
           
           if(type == 'AdharFront'){
             this.documentData.Adhar_Front_Image_Image = this.base64Image;
             this.documentData.Adhar_Front_Image_Name  = this.base64ImageName;
             let base64 = this.base64Image.split(",")
             this.documentData.Adhar_Front_Image = base64[1];

           }
 
           if(type == 'adharback'){
             this.documentData.Adhar_Back_Image_Image = this.base64Image;
             this.documentData.Adhar_Back_Image_Name  = this.base64ImageName;
             let base64 = this.base64Image.split(",")
             this.documentData.Adhar_Back_Image = base64[1];


           
           }
 
           if(type == 'pancard'){
             this.documentData.Pan_Card_Image_Image = this.base64Image;
            this.documentData.Pan_Card_Image_Name  = this.base64ImageName;
             let base64 = this.base64Image.split(",")
             this.documentData.Pan_Card_Image = base64[1];
           }
 
           if(type == 'bankDocument'){

             this.bankData.CancelledchequephotoImage = this.base64Image;
             this.bankData.CancelledChequeName   = this.base64ImageName;
              let base64 = this.base64Image.split(",")
              this.bankData.CancelledChequePhoto  = base64[1];


           }
 
         }
     };
   }
 
   uploadFile(file,type){
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('type', type);
    formData.append('fileName', this.base64ImageName);
    formData.append('RegistrationId', this.sessionUserData.RegistrationId);
    // this.dataService.sendFormData(formData).subscribe(response => {
    //   this.apiResponse = response;
    //   console.log(JSON.stringify(  this.apiResponse))
    //    if(type == 'ShopPhoto'){
    //     this.shopData.ShopPhoto = this.apiResponse.FileName;
    //    }
    // })

    this.dataService.sendFormData(formData).subscribe(response => {
      this.apiResponse = response;
      if(this.apiResponse.body){
        if(type == 'ShopPhoto'){
              this.shopData.ShopPhoto = this.apiResponse.body.FileName;
        }
      }
    })

   }




  exportsExcel() {
    /* table id is passed over here */
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  exportPDF() {
    let DATA = document.getElementById('htmlData');
    html2canvas(DATA).then(canvas => {
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      PDF.save('MoneyTransferReport.pdf');
    });

  }


  printRow() {
    let popupWinindow
    let innerContents = document.getElementById("eachRecord").innerHTML;
    popupWinindow = window.open('top=0,left=0,height=100%,width=auto');
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = innerContents;
    window.print();
    document.body.innerHTML = originalContents;
  }




}
