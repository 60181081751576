import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataServiceService } from '../../service/data-service.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public formData: any = {};
  public apiResponse: any;
  public isButtonDisabled: boolean = false;
  public forgotPasswordTab: any;
  public tab: any;


  time: any;
  timer: number = 120;
  timeLeft: number;
  interval;
  disableStatus: boolean = true;
  isOTPSelected: boolean = false;
  isOTPVerified: boolean = false;

  public stateList: any = [];
  public cityList: any = [];
  public tahsilList: any = [];

  public retailersignupurl = 'https://retailer.myereturn.com/#/registration';

  //REGISTRATION
  public isOTPRequested: boolean = false;
  public isRegistrationOTPVerified: boolean = false;


  public pass: string;
  public passIcon: string;
  public pass1: string;
  public passIcon1: string;


  public sessionUserData: any = {};
  public isProfileCompleted: boolean = false;

  public mouserHoverOnLogin: boolean = false;



  public tearmConditonPage: any = "https://myereturn.com/#about"

  constructor(
    public dataService: DataServiceService,
    public router: Router,
    private location: Location
  ) {
    this.tab = 'login';
    this.formData.Terms_Condition = true;
    this.forgotPasswordTab = 'EnterUserName';

    this.formData.CityId = "";
    this.formData.StateId = "";
    this.formData.TahsilsId = "";

    this.pass = "password";
    this.passIcon = "hide";

    this.pass1 = "password";
    this.passIcon1 = "hide";

    this.location.replaceState('/');
  }

  ngOnInit(): void {
    this.getState();
  }

  openTab(tab) {
    this.formData = {};
    this.tab = tab;
    this.isRegistrationOTPVerified = false;
    this.isOTPRequested = false;
    this.isOTPSelected = true;
    this.isOTPVerified = false;
    this.timeLeft = 120;
    this.forgotPasswordTab = 'EnterUserName';

    this.formData.City = "";
    this.formData.State = "";
    this.formData.PostOffice = "";
    this.formData.Category = "";

    this.pass = "password";
    this.passIcon = "hide";

    this.pass1 = "password";
    this.passIcon1 = "hide";
  }

  /***
 * HIDE SHOW PASSWORD OTP
 */
  public hideShowPassword(): void {
    this.pass = this.pass === "password" ? "mobile" : "password";
    this.passIcon = this.passIcon === "hide" ? "show" : "hide";
  }

  public hideShowPassword1(): void {
    this.pass1 = this.pass1 === "password" ? "mobile" : "password";
    this.passIcon1 = this.passIcon1 === "hide" ? "show" : "hide";
  }



  getState() {
    this.dataService.get(this.dataService.appConstant.APIS['GET_STATE']).subscribe(response => {
      this.apiResponse = response
      this.stateList = this.apiResponse.stateData;
      localStorage.setItem('mEState', JSON.stringify(this.stateList))
    }, err => {
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }


  selectProfileState() {
    this.stateList.forEach(element => {
      if (element.id == this.formData.State) {
        this.cityList = element.Citys
        this.cityList.forEach(city => {
          if (city.id == this.formData.City) {
            this.tahsilList = city.tahsil
          }
        });
      }
    });
  }


  submit(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['LOGIN']).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Data) {
        this.sessionUserData = this.apiResponse
        sessionStorage.setItem("myEReturn_User", JSON.stringify(this.apiResponse));
        this.dataService.sendMessage("LoggedIn")
        this.dataService.appConstant.successToast("Login successfully");


        //IF PROFILE STATUS COMPLETED THEN REDIRECT TO DASHBOARD OR REDIRECT TO COMPLETE PROFILE SCREE
        if (this.dataService.appConstant.checkProfileValidation()) {
          this.router.navigate(['/Dashboard'], { replaceUrl: true });
        } else {
          this.dataService.appConstant.errorToast("please complete your profile");
          this.router.navigate(['/complete-your-profile'], { replaceUrl: true });
        }
        sessionStorage.setItem('setheadType', null);

      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.message);
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }




  requestForOTP(appForm: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['FORGOT_PASSWORD'] + this.formData.mobileNo).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.forgotPasswordTab = 'enterOTP'
        this.isOTPSelected = true;
        this.isOTPVerified = false;
        this.timeLeft = 120;
        this.startTimer();
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.Message);
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }



  verifyOTP() {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['ONBOARD_VERIFY_OTP'] + this.formData.mobileNo + "&Otp=" + this.formData.Otp).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.isOTPVerified = true;
        this.forgotPasswordTab = 'changePassword';
        this.dataService.appConstant.successToast("OTP verified successfully..")
      } else {
        this.dataService.appConstant.errorToast("You have entered the wrong OTP....");
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }

  resendOTP() {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['RESEND_OTP'] + this.formData.mobileNo).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.timeLeft = 120;
        this.isOTPSelected = true;
        this.isOTPVerified = false;
        this.startTimer();
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.Message);
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })


  }

  changePassword(form: NgForm) {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['CHANGE_PASSWORD'] + this.formData.mobileNo + "&Password=" + this.formData.newPassword).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.tab = 'login';
        this.formData = {};
        this.forgotPasswordTab = 'EnterUserName';
        this.dataService.appConstant.successToast("New password updated successfully..")
      } else {
        this.dataService.appConstant.errorToast("User name or password is wrong....");
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }


  //REGISTRATION

  submitRegistration() {
    if (!this.isOTPRequested) {
      this.sendRegistrationOTP();
    } else {
      if (!this.isRegistrationOTPVerified) {
        this.dataService.appConstant.errorToast("Verify OTP....");
      } else {
        this.registration();
      }
    }
  }


  sendRegistrationOTP() {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['SEND_REGISTRATION_OTP'] + this.formData.MobileNumber).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.isOTPRequested = true;
        this.timeLeft = 120;
        this.startTimer();
        this.dataService.appConstant.successToast("OTP Sent Successfully");
      } else {
        this.dataService.appConstant.errorToast(this.apiResponse.Message);
      }
    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })



  }


  verifyOTPRegistration() {
    if (this.formData.Otp && this.formData.Otp.length == 4) {
      this.dataService.post(this.formData, this.dataService.appConstant.APIS['ONBOARD_VERIFY_OTP'] + this.formData.MobileNumber + "&Otp=" + this.formData.Otp).subscribe(response => {
        this.apiResponse = response
        this.isButtonDisabled = false;
        if (this.apiResponse.Status) {
          this.isRegistrationOTPVerified = true;
          this.dataService.appConstant.successToast("OTP verified successfully..")
        } else {
          this.dataService.appConstant.errorToast("You have entered the wrong OTP....");
        }
      }, err => {
        this.isButtonDisabled = false;
        this.dataService.appConstant.errorToast("Server error....Please try again");
      })

    }
  }


  registration() {
    this.isButtonDisabled = true;
    this.dataService.post(this.formData, this.dataService.appConstant.APIS['NEW_REGISTRATION']).subscribe(response => {
      this.apiResponse = response
      this.isButtonDisabled = false;
      if (this.apiResponse.Status) {
        this.tab = 'login';
        this.formData = {};
        this.dataService.appConstant.successToast("Registration successful..")
        this.router.navigate(['/login'], { replaceUrl: true });
      }

    }, err => {
      this.isButtonDisabled = false;
      this.dataService.appConstant.errorToast("Server error....Please try again");
    })
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      else {
        this.disableStatus = false;
        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }


}
